import React, { Component,Fragment } from 'react';
import { socketConnect } from 'socket.io-react';
import PropTypes from 'prop-types';
import { Grid } from 'material-ui';
import { browserHistory, Link } from 'react-router';
import { AddAlert } from '@material-ui/icons/index';
import { connect } from 'react-redux';
import { getArrays } from 'utils';
import moment from 'moment';
import RegularCard from 'components/Cards/RegularCard';
import ItemGrid from 'components/Grid/ItemGrid';
import Snackbar from 'components/Snackbar/Snackbar';

class OrderLogs extends Component {
  constructor(props) {
    super(props);
    const { id } = this.props.params;
    this.state = {
      id,
      mealPlan: {},
      planParams: {},
      note: false,
      message: '',
      orderlogs: [],
      closeNote: () => this.setState({ note: false, message: '' })
    };
    this.getOrderLogs(id);
  }

  showNotification(message) {
    if (this.OrderLogsRef) {
      this.setState({ note : true, message });
      setTimeout(() => this.setState({ note : false, message: '' }), 5000);
    }
  }

  listener = ({ data, type }) => {
    if (this.OrderLogsRef) {
      switch (type) {
        case 'getOrderLogsOk':
          this.setState({orderlogs:data.orderlogs});
          break;
        case 'getOrderLogsErr':
          console.log(data);
          //browserHistory.push('/');
          break;
      }
    }
  };

  componentWillMount() {
    this.props.socket.on('order', this.listener);
  }

  componentWillUnmount() {
    this.props.socket.removeListener('order', this.listener);
  }

  componentDidMount() {
   // const { socket, dataArray } = this.props;
    // const types = ['typeList', 'productsFull'].filter(el => !(el in dataArray));
    // types.length && getArrays(socket, types);
  }

  getOrderLogs = orderId => this.props.socket.emit('order', { type: 'getOrderLogs', data: { orderId } });

  render() {
    const { orderlogs,isToggleOn } = this.state;
    const { user_id ,dataArray} = this.props;
    const {products,typeList,slotsList}=dataArray;
    var logs = [];
    //console.log(this.props);
   // console.log(orderlogs);
    var message='';
    if (orderlogs) {
      Object.keys(orderlogs).map( (el, idx) => {
        orderlogs[el].map( (el1, idx) => {
          message='';
          var data=(el1.data!=null)?JSON.parse(el1.data):[];
          switch(el1.action){
            case 'saveEditPlan':
            case 'modifyPlan':
              if(data.new_data!=undefined&&data.new_data.length>0){
                data.new_data.map((nd, ni) => {
                  // console.log(nd);
                  // console.log(products[data.old_data[ni].dish_id]);
                  if(typeof(data.old_data[ni])!=undefined&&data.old_data[ni]!=null&&typeof(data.old_data[ni].dish_id)!=undefined&&typeof(products[data.old_data[ni].dish_id])!='undefined'){
                    if(nd.date!=undefined){
                        message+="Date: "+moment.unix(nd.date).format('DD MMM, YYYY')+',Meal Type:"'+typeList[data.old_data[ni].mealType_id].title+'", Old dish:"'+products[data.old_data[ni].dish_id].title+'", New Dish:"'+products[nd.dish_id].title+'".\n';
                     }else if(nd.dish_id!=undefined){
                        message+'From dish "'+products[data.old_data[ni].dish_id].title+'" to "'+products[nd.dish_id].title+'".\n';
                     }
                  }else if(nd.cutlery!=undefined){
                    if(nd.cutlery!=data.old_data[ni].cutlery){
                       message+='Changed the cutlery from '+data.old_data[ni].cutlery+' to '+nd.cutlery;
                    }
                    if(nd.slot_id!=data.old_data[ni].slot_id){
                      message+='Changed the slot from '+slotsList[data.old_data[ni].slot_id]+' to '+slotsList[nd.cutlery.slot_id];
                   }
                  }
                });
              }
            break;
          }
        logs.push(
          <li>
          <a target="_blank" href="#">{el1.action} by {el1.modified_by} : {el1.user_type} </a>
        
          <a href="javascript:void(0);" className="float-right">{moment.unix(el1.created_date).format('DD MMM, YYYY h:mm:ss A')}</a>
          <p>{el1.description}</p>
          <Fragment>
            <p>{message}</p>
          </Fragment>
        </li>
            )
            });
      });
    }

    return (
     <div ref={el => (this.OrderLogsRef = el)}>
        <Grid container>
          <ItemGrid xs={12} sm={12} md={12}>
            <RegularCard
              cardTitle={'Order Logs'}
              cardSubtitle='View Logs'
              content={
                <div className="progress scrollable-h">
                  <Grid container>
                  <ul className="timeline">
                    {logs}
                  </ul>
                  </Grid>
                </div>
              }
            />
          </ItemGrid>
        </Grid>
       
      </div>   
    );
  }
}

OrderLogs.propTypes = {
  socket: PropTypes.object.isRequired,
  params: PropTypes.object.isRequired, 
  dataArray: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  dataArray: state.dataArray
});

export default socketConnect(connect(mapStateToProps, null)(OrderLogs));
