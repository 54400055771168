import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment/moment';
import { Checkbox, Grid } from 'material-ui';
import Button from 'components/CustomButtons/Button';
import { Check, ChevronRight, ChevronLeft } from '@material-ui/icons/index';
import { Text } from 'components/Typography';
import noImage from 'assets/no_img.svg';
import { intersect,diff } from 'lib';
require('moment-weekday-calc');

class DishesInfoedit extends Component {
  constructor(props) {
    super(props);
    const { errors, mealPlan, planParams,prevPlanParams  } = props;
    const dates = Object.keys(mealPlan);
    const currentDate = dates[0] || planParams.date;
    const addmealType=diff(planParams.mealType,prevPlanParams.mealType);
    const removemealType=diff(prevPlanParams.mealType,planParams.mealType);
    const currentType = addmealType[0];
    this.state = {
      errors,
      planParams,
      currentDate,
      currentType,
      addmealType,
      removemealType,
      dates
    };
  }

  DishList = ({ productsFull, prodKeys, todayDishes }) => {
    const { currentDate, currentType, dates } = this.state;
    const { changePlan,userData} = this.props;
    const { allergies,dislikes,dishdislikes,eliminate_groups}=userData;
    let nextDishid=0;
    prodKeys = prodKeys.filter(function (dishId) {
      const { group,spicy } = productsFull[dishId] || {};
      let isEliminate = true;
      if(eliminate_groups.length>0){ 
        // isEliminate = !(eliminate_groups.indexOf(group)>-1); 
          isEliminate =(group.length>0)?!intersect(group,eliminate_groups).length:false;   
          if(isEliminate){
          isEliminate=!(eliminate_groups.indexOf(14)>-1 && spicy==1);  // Spicy Group and dish set as spicy
        }
      }
      if(dishdislikes.length>0 && isEliminate){
        isEliminate = !(dishdislikes.indexOf(+dishId)>-1); 
      }
      return isEliminate;
    });
    return productsFull ? prodKeys.map((dishId, idx) => {
      const { title, image, calories, rating,ingredients,group,spicy } = productsFull[dishId] || {};
      let todayDish = (todayDishes[+currentType]!=undefined && prodKeys.includes(todayDishes[+currentType].toString()))?todayDishes[+currentType]:+prodKeys[0];
      const imgUrl = image ? `${image}` : noImage;
      /* Start Disable the dislike/allergies dish */
      let is_allergies=false;
      if(ingredients!=undefined&&ingredients.length>0){
        if (allergies.length>0) {
          is_allergies = intersect(ingredients,allergies).length;
        }
        if(dislikes.length>0&&!is_allergies){
          is_allergies = intersect(ingredients,dislikes).length;   
        }
     }
    //  if(!is_allergies&&eliminate_groups!=undefined&&eliminate_groups.length>0){
    //       is_allergies =eliminate_groups.includes(group);
    //       if(!is_allergies){
    //         is_allergies=(eliminate_groups.includes(14)&& spicy==1);  // Spicy Group and dish set as spicy
    //       }
    //   }
      if((todayDish === +dishId||nextDishid== +dishId)&&is_allergies){
        nextDishid=((prodKeys[idx+1])!=undefined)?prodKeys[idx+1]:'';
      }
      /* End Disable the dislike/allergies dish */
      return (
        <div className='dish' key={idx}>
          <p className='image' style={{ backgroundImage: `url(${imgUrl})` }} />
          {dates.includes(currentDate) && <div className='meal-check-holder'>
            <Checkbox
              checked={(todayDish == +dishId||+nextDishid==+dishId)&&!is_allergies}
              checkedIcon={<Check className='checked' />}
              icon={<Check className='unChecked' />}
              onClick={() => changePlan(+currentDate, +currentType, +dishId)}
            />
          </div>}
          {/* {+nextDishid==+dishId&&changePlan(+currentDate, +currentType, +dishId)} */}
          {(!dates.includes(currentDate) && (todayDish === +dishId||+nextDishid==+dishId)) && <div className='meal-check-holder'>
            <Checkbox
              checked='false'
              icon={<Check className='unChecked' />}
            />
          </div>}
          <p className='title'>{title}</p>
          <p>Add Note</p>
          <p className='cal'>{ calories / 1000 } kCal</p>
          <p className='rating'><this.Rating rating={rating} /></p>
        </div>
      );
    }) : [];
  };

  Rating = ({ rating }) => {
    return Array(5).fill(0).map((el, idx) => idx < Math.round(rating) ? <span key={idx}>&#x2605;</span> : <span key={idx}>&#x2606;</span>);
  };

  render() {
    const { planParams, currentDate, currentType, dates,addmealType,removemealType } = this.state;
    const { dataArray, userData, mealPlan, customPlans,build,prevPlanParams } = this.props;
    console.log(mealPlan);
    const dayNum = moment.unix(currentDate).date() - 1;
    const plan = customPlans.find(el => +el.mealType_id === +currentType) || {};
    let prodKeys = (plan.data || {})[dayNum] || [];
    const { dietList, typeList, productsFull } = dataArray;
    const { diet: dietId, mealType: types } = planParams || {};
    const { firstName, lastName } = userData;
    const userName = `${firstName} ${lastName}`;
    const dietName = dietList && dietId ? dietList[dietId] : '';
    const todayDishes = mealPlan[currentDate];
    const tabsButtons = addmealType && typeList ? addmealType.map((typeId, idx) => {
      const type = typeList[typeId];
      return (
        <Button key={idx} color={+typeId === +currentType ? 'darkBlue' : 'white'} onClick={() => this.setState({ currentType: typeId })}>{type.title}</Button>
      );
    }) : [];
    const removemeals = removemealType && typeList ? removemealType.map((typeId, idx) => {
      const type = typeList[typeId];
      return (
        <p>{type.title}</p>
      );
    }) : [];
    const curDateIdx = dates.indexOf(currentDate);
    const prevDate = curDateIdx > -1 ? dates[curDateIdx - 1] : false;
    const nextDate = curDateIdx > -1 ? dates[curDateIdx + 1] : false;
    console.log(addmealType.length);
    return (
      <div>
        <div className='edit-modal-inner' style={{ paddingBottom: '40px' }}>
          <Grid container >
            <Grid item md={12}>
              <Text bold customColor='#000' inline style={{ fontSize: 'large' }}>{userName}</Text>
              <Text bold color='success' inline style={{ fontSize: 'large' }}>&nbsp;{dietName}</Text>
            </Grid>
            {removemealType.length>0&&<Grid item md={12}>
            <Text bold customColor='#DC143C' inline style={{ fontSize: 'large' }}>Removed Meal Types</Text>
              {removemeals}
            </Grid>}
            {addmealType.length>0&&<Grid item md={4} style={{ textAlign: 'left', paddingTop: '20px' }}>
              {prevDate && <Text className='link-butt prev-date' onClick={() => this.setState({ currentDate: prevDate })}>
                <ChevronLeft style={{ verticalAlign: 'bottom' }} /> Prev: {moment.unix(prevDate).format('MMM DD')}
              </Text>}
            </Grid>}
            {addmealType.length>0&&<Grid item md={4} style={{ textAlign: 'center', paddingTop: '20px' }}>
              <div className='day-editing'>Menu editing for <span>{moment.unix(currentDate).format('MMM DD')}</span></div>
            </Grid>}
            {addmealType.length>0&&<Grid item md={4} style={{ textAlign: 'right', paddingTop: '20px' }}>
              {nextDate && <Text className='link-butt next-date' onClick={() => this.setState({ currentDate: nextDate })}>
                Next: {moment.unix(nextDate).format('MMM DD')} <ChevronRight style={{ verticalAlign: 'bottom' }} />
              </Text>}
            </Grid>}
            {addmealType.length>0&&<Grid item md={12}>
              {tabsButtons}
            </Grid>}
            {addmealType.length>0&&<Grid item md={12}>
              <div className='holder'>
                <div className='day'>
                  <this.DishList {...{ productsFull, todayDishes, prodKeys}} />
                </div>
              </div>
            </Grid>}
          </Grid>
        </div>
      </div>
    );
  }
}

DishesInfoedit.propTypes = {
  errors: PropTypes.object.isRequired,
  planParams: PropTypes.object.isRequired,
  customPlans: PropTypes.array.isRequired,
  mealPlan: PropTypes.object.isRequired,
  userData: PropTypes.object.isRequired,
  dataArray: PropTypes.object.isRequired,
  changePlan: PropTypes.func.isRequired
};

export default DishesInfoedit;