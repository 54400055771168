import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { ArrowForward, Check } from '@material-ui/icons';
import noImage from 'assets/no_img.svg';
import Button from 'components/CustomButtons/Button';
import { updatePlanParams } from 'store/planBuilder';
import { Checkbox } from 'material-ui';

class DishList extends Component {
  render() {
    const { products, thediet, includedDishes, clickEdit, day, activeDay, updatePlanParams, save, defaultDishes, defaults, changeDefaults, saveDefaults, dishGroups, dishLabels} = this.props;
    const getRating = (rating) => Array(5).fill(0).map((el, idx) => idx < Math.round(rating) ? <span key={idx}>&#x2605;</span> : <span key={idx}>&#x2606;</span>);
    const dishList = products ? includedDishes.map(id => {
      const { title, nickname, image, calories, rating, active, group, label_id, dishType } = products[id] || {};   
      const imgUrl = image ? `${image}` : noImage;
      var label = false;
      if (label_id && (typeof(dishLabels[label_id]) != 'undefined') ) {
        label = dishLabels[label_id];
      }
      return (
        <div className='dish' key={id}>
          <p className='image' style={{ backgroundImage: `url(${imgUrl})` }} />
          {!!active && <div className='meal-check-holder'>
            <Checkbox
              checked={+(defaults[day] || defaultDishes[day]) === +id}
              checkedIcon={<Check className='checked' />}
              icon={<Check className='unChecked' />}
              onClick={() => changeDefaults(id)}
              classes={{
                checked: 'unChecked'
              }}
            />
          </div>}
          <p className='title'>{nickname != '' ? nickname : title} - {id}</p>
          {/* {label && <span className='bage-label'>{label}</span>} */}
          <p className='cal'>{calories / 1000} kCal</p>
          {/*<p className='rating'>{getRating(rating)}</p>*/}
          {/*<p className='group'>{dishGroups[group]}</p>*/}
          <p className='del' onClick={() => updatePlanParams({ [day]: includedDishes.filter(el => el !== id) })}><ArrowForward /></p>
        </div>
      );
    }) : [];
    const editing = (activeDay !== null && activeDay === day);
    return (
      <div className={`holderWithBg week ${activeDay === day && 'active'}`} ref={el => (this.ActiveDayRef = el)}>
        <div className='holder'>
          <h5>Day {day + 1} of 31 menu pattern</h5>
          <p className={`edit-button-holder ${activeDay === day && 'active'}`}>
            {+day in defaults && <Button color='success' style={{ marginRight: '10px' }} onClick={saveDefaults}>Save default dishes</Button>}
            <Button
              color='darkBlue'
              disabled={editing && dishList.length === 0}
              onClick={() => editing ? (dishList.length && save()) : clickEdit(this.ActiveDayRef)} >
              {editing ? 'Save' : 'Edit'}
            </Button>
          </p>
          <p className='left' />
          <div className='day'> 
            {dishList.length ? dishList : <p className='empty'>Click "Edit" to build the plan.
              By default, if the choice is not made all available dishes for the selected type
              of dishes (paleo, keto, etc. for dinner, lunch, etc.).
              will be available on this day. </p>}
          </div>
          <p className='right' />
        </div>
      </div>
    );
  }
}

DishList.propTypes = {
  includedDishes: PropTypes.array,
  products: PropTypes.object,
  dishGroups: PropTypes.object,
  defaultDishes: PropTypes.object,
  defaults: PropTypes.object,
  clickEdit: PropTypes.func,
  day: PropTypes.number,
  activeDay: PropTypes.number,
  updatePlanParams: PropTypes.func,
  save: PropTypes.func,
  changeDefaults: PropTypes.func,
  saveDefaults: PropTypes.func
};

const actions = dispatch => ({
  updatePlanParams: obj => dispatch(updatePlanParams(obj))
});

export default connect(null, actions, null, { withRef: true })(DishList);
