import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Grid } from 'material-ui';
import { Close } from '@material-ui/icons/index';
import ItemGrid from 'components/Grid/ItemGrid';
import Button from 'components/CustomButtons/Button';
import Select from 'components/Select';
import CustomInput from 'components/CustomInput/CustomInput';
import { setNotify, clearNotify } from 'store/notify';
import { ErrorOutline } from '@material-ui/icons/index';
import { socketConnect } from 'socket.io-react';
import { connect } from 'react-redux';

class Calories extends Component {
  constructor(props) {
    super(props);
    const {product_id} = props;
    this.state = {
      errors : {},
      calories: {},
    };
    this.getCalories(product_id);
  }

  showNotification(message, color = 'success') {
    const { setNotify, clearNotify } = this.props;
    setNotify({
      place: 'tc',
      color,
      icon: ErrorOutline,
      message: message,
      open: true
    });
    setTimeout(() => clearNotify(), 5000);
  }

  getCalories = (product_id) => {
    this.props.socket.emit('menu_builder', { type: 'getCalories', data: { product_id: product_id } });
  }

  componentWillMount() {
    this.props.socket.on('menu_builder', this.listener);
  }

  componentWillUnmount() {
    this.props.socket.removeListener('menu_builder', this.listener);
  }

  listener = data => {
      switch (data.type) {
        case 'getCaloriesOk':
          this.setState({
            calories: data.res
          });
        break;
        case 'saveCaloriesOk':
          this.showNotification(data.data.message);
        break;
      }
  };

  onChange(type, diet_id, val) {
    var {calories} = this.state;
    calories[diet_id][type] = val;
    this.setState({
      calories: calories
    });
  }

  saveCalories() {
    const {calories} = this.state;
    const {product_id} = this.props;
    console.log(' ======= save =======');
    console.log(calories);
    console.log(' ======= save =======');
    this.props.socket.emit('menu_builder', { type: 'saveCalories', data: { product_id: product_id, calories: calories } });
  }

  renderCaloriesFields = () => {
    const {dietList} = this.props;
    const {errors, calories} = this.state;

    console.log(' ======= get =======');
    console.log(calories);
    console.log(' ======= get =======');

    if (Object.keys(calories).length > 0) {

    var rows = [];
    Object.keys(dietList).map( (diet_id, idx) => {

      var protein = 0;
      var cho = 0;
      var fat = 0;
      var cals = 0;
      var symbol = '';

      if (typeof(calories[diet_id]) != 'undefined') {
        protein = calories[diet_id].protein;
        cho = calories[diet_id].cho;
        fat = calories[diet_id].fat;
        cals = calories[diet_id].calories;
        symbol = calories[diet_id].symbol;
      }

      rows.push(<ItemGrid md={12} container>
                  <ItemGrid md={3}>
                    <span className="diet-span-list">{dietList[diet_id]}</span>
                  </ItemGrid>
                  <ItemGrid md={1}>
                      <CustomInput
                        labelText={'Protein'}
                        formControlProps={{
                            fullWidth: true
                        }}
                        
                        error={'name' in errors}
                          inputProps={{
                              value: protein || '',
                              type: 'text',
                              name: 'protein',
                              onChange: (e) => { this.onChange('protein', diet_id, e.target.value)}
                          }}
                      />
                  </ItemGrid>
                  <ItemGrid md={1}>
                      <CustomInput
                        labelText={'Fat'}
                        formControlProps={{
                            fullWidth: true
                        }}
                        
                        error={'name' in errors}
                          inputProps={{
                              value: fat || '',
                              type: 'text',
                              name: 'fat',
                              onChange: (e) => { this.onChange('fat', diet_id, e.target.value)}
                          }}
                      />
                  </ItemGrid>
                  <ItemGrid md={1}>
                      <CustomInput
                        labelText={'Cho'}
                        formControlProps={{
                            fullWidth: true
                        }}
                        
                        error={'name' in errors}
                          inputProps={{
                              value: cho || '',
                              type: 'text',
                              name: 'cho',
                              onChange: (e) => { this.onChange('cho', diet_id, e.target.value)}
                          }}
                      />
                  </ItemGrid>
                  <ItemGrid md={2}>
                      <CustomInput
                        labelText={'Calories'}
                        formControlProps={{
                            fullWidth: true
                        }}
                        
                        error={'name' in errors}
                          inputProps={{
                              value: cals || '',
                              type: 'text',
                              name: 'calories',
                              onChange: (e) => { this.onChange('calories', diet_id, e.target.value)}
                          }}
                      />
                  </ItemGrid>
                  <ItemGrid md={2}>
                      <CustomInput
                        labelText={'Symbol'}
                        formControlProps={{
                            fullWidth: true
                        }}
                        
                        error={'name' in errors}
                          inputProps={{
                              value: symbol || '',
                              type: 'text',
                              name: 'symbol',
                              onChange: (e) => { this.onChange('symbol', diet_id, e.target.value)}
                          }}
                      />
                  </ItemGrid>
                </ItemGrid>
      );
    });
    rows.push(<Button color='darkBlue' onClick={ (e) => this.saveCalories()}>Save</Button>);
    return rows;
    } else {
      return <p>Save main product's info first</p>
    }
  };

  render() {
    return (
      <Grid container>
        {this.renderCaloriesFields()}<br/>
      </Grid>
    );
  }
}

Calories.propTypes = {
  dietList: PropTypes.object.isRequired
};

const props = state => ({
  //user: state.user,
  //dataArray: state.dataArray
});

const actions = dispatch => ({
  clearNotify: () => dispatch(clearNotify()),
  setNotify: props => dispatch(setNotify(props))
});

export default socketConnect(connect(props, actions)(Calories));