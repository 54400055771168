import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment/moment';
import { Grid } from 'material-ui';
import { connect } from 'react-redux';
import Button from 'components/CustomButtons/Button';
import { Text } from 'components/Typography';
import Calendar from 'components/DateTime';

require('moment-weekday-calc');

class EditMealPlanModal extends Component {
  constructor(props) {
    super(props);
    const { errors, mealPlan, planParams } = props;
    const minDate = moment().utc().add(2, 'd').startOf('day').unix();
    const allDates = Object.keys(mealPlan);
    const dates = allDates.filter(el => +el > +minDate);
    this.state = {
      errors,
      mealPlan,
      planParams,
      skipped: {},
      dates,
      allDates,
      resumeDate: false,
      startDate: false,
    };
  }

  recalculateDates = (resumeDate, dates, includesDay) => {
    const { excludedDates, defaultDishes, save } = this.props;
    const { mealPlan, planParams } = this.state;
    const { mealType: types } = planParams || {};
    const firstDate = dates[0];
    const lastDate = dates.reverse()[0];
    const unchangedDates = resumeDate > lastDate ? [] : dates.filter(date => date >= resumeDate);
    const mapDatesToDays = unchangedDates.reduce((acc, date) => {
      const dayOfMonth = moment.unix(date).date();
      return { ...acc, [dayOfMonth]: date };
    }, {});
    const date = moment.unix(resumeDate > lastDate ? resumeDate : lastDate);
    const newDatesPlan = {};
    const amount = dates.length - unchangedDates.length;
    const nextDate = (date, amount) => {
      return date.clone().addWeekdaysFromSet(amount, includesDay, excludedDates);
    };
    for (let i = +!(resumeDate > lastDate); i <= amount; i++) {
      const newDate = nextDate(date, i);
      const dayNum = newDate.date();
      newDatesPlan[newDate.unix()] = dayNum in mapDatesToDays
        ? mealPlan[mapDatesToDays[dayNum]]
        : types.reduce((acc, type) => ({ ...acc, [+type]: +defaultDishes[type][dayNum - 1] }), {});
    }
    const prevPlan = Object.keys(mealPlan)
      .filter(date => ((date < firstDate) || unchangedDates.includes(date)))
      .reduce((acc, date) => ({ ...acc, [date]: mealPlan[date] }), {});
    save({ mealPlan: { ...prevPlan, ...newDatesPlan }, planParams }, 'pause');
  };

  render() {
    const { planParams, resumeDate, startDate, dates } = this.state;
    const { userData, dataArray, excludedDates, savePause } = this.props;
    const { dietList, planList } = dataArray;
    const { firstName, lastName } = userData;
    const userName = `${firstName} ${lastName}`;
    const { diet: dietId, mealPlan: planId } = planParams || {};
    const excluded = (planList && planList[planId] && planList[planId]['excludeWeekDay']) || [];
    const includesDay = Array(7).fill(0).map((el, key) => key).filter(el => !excluded.includes(el));
    const dietName = dietList && dietId ? dietList[dietId] : '';
    const today = moment();
    !today.isUTC() && today.utc();
    today.startOf('day');
    const newDate = today.addWeekdaysFromSet(3, includesDay, excludedDates);
    const renderDay = (props, curd) => {
     /* if (!curd.isBetween(newDate, newDate.clone().add(3, 'months'), null, '[]') || excluded.includes(curd.day()) || excludedDates.includes(curd.format('DD/MM/YYYY'))) {
        props.className += ' disabled';
        delete props.onChange;
        delete props.onClick;
      } else */if (curd.isSame(resumeDate || newDate)) {
        props.className += ' selected';
      }
      return <td {...props}>{curd.date()}</td>;
    };
    return (
      <div ref={el => (this.PausePlanModalRef = el)} className='edit-modal-inner'>
        <div>
          <Text bold customColor='#000' inline style={{ fontSize: 'large' }}>{userName}</Text>
          <Text bold color='success' inline style={{ fontSize: 'large' }}>&nbsp;{dietName}</Text>
        </div>
        <div className='day-editing text-center'>Select start date</div>
        <div style={{ borderTop: '1px solid #bebebe', borderBottom: '1px solid #bebebe', padding: '10px' }}>
          <Calendar
            controlled
            value={startDate || newDate}
            onChange={val => this.setState({ startDate: val })}
            onViewModeChange={() => false}
            renderDay={renderDay}
            onNavigateBack={(a, t, c) => moment().utc().startOf(t).isBefore(c.utc())}
            onNavigateForward={(a, t, c) => c.clone().add(a, t).startOf(t).isBefore(moment().add(3, 'month'))}
          />
        </div>
        <div className='day-editing text-center'>Select end date</div>
        <p><Button color="red" onClick={() => {this.setState({resumeDate: 'infinity'});} }>End Date is Unknown</Button></p>
        <div style={{ borderTop: '1px solid #bebebe', borderBottom: '1px solid #bebebe', padding: '10px' }}>
          {resumeDate !== 'infinity' && <Calendar
            controlled
            value={resumeDate || newDate}
            onChange={val => this.setState({ resumeDate: val })}
            onViewModeChange={() => false}
            renderDay={renderDay}
            onNavigateBack={(a, t, c) => moment().utc().startOf(t).isBefore(c.utc())}
            onNavigateForward={(a, t, c) => c.clone().add(a, t).startOf(t).isBefore(moment().add(3, 'month'))}
          />}
        </div>

        <div className='modal-button-handler'>
          <Button
            color='darkBlue'
            onClick={() => {
              savePause(startDate, resumeDate);
              //this.recalculateDates((resumeDate || newDate).unix(), [...dates], includesDay);
            }}
          >
            Save
          </Button>
        </div>
      </div>
    );
  }
}

EditMealPlanModal.propTypes = {
  errors: PropTypes.object.isRequired,
  userData: PropTypes.object.isRequired,
  mealPlan: PropTypes.object,
  planParams: PropTypes.object,
  excludedDates: PropTypes.array,
  currentDate: PropTypes.number,
  dataArray: PropTypes.object.isRequired,
  customPlans: PropTypes.array,
  save: PropTypes.func.isRequired,
  defaultDishes: PropTypes.object
};

const props = ({ customPlans, defaultDishes }) => ({ customPlans, defaultDishes });

export default connect(props)(EditMealPlanModal);
