import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Grid, Checkbox } from 'material-ui';
import { Close, Check } from '@material-ui/icons/index';
import ItemGrid from 'components/Grid/ItemGrid';
import Button from 'components/CustomButtons/Button';
import Select from 'components/Select';
import CustomInput from 'components/CustomInput/CustomInput';
import { Text } from 'components/Typography';
import Ingredients from './Ingredients';

class DishComponent extends Component {
  constructor(props) {
    super(props);
    console.log(props.compontents);
     this.state = {
       name:props.name,
       value:props.value || '',
       componentCount:(props.compontents && Object.keys(props.compontents).length>0)?Object.keys(props.compontents).length:1,
       compontents:props.compontents || {}
     }; 
   }
   componentDidMount() {
      console.log(this.props);
   }
   changeForm = (e) =>{
    const { compontents } = this.state;
    let indexValue = e.target.name;
    this.setState({ compontents: { ...compontents, [indexValue]:{ ...compontents[indexValue], [e.target.id]: e.target.value }}});
    // onChangeCom(e);
   } 
   onChangeCompIng =(cindex,ingredients)=>{
    const { compontents } = this.state;
    this.setState({ compontents: { ...compontents, [cindex]:{ ...compontents[cindex], ['ingredients']:ingredients}}});
  }
  addNewComponent = () =>{
    const { componentCount } =this.state;
    this.setState({componentCount:componentCount+1})
  }
  renderComponent= () =>{
    const { dataArray,ingredientPrices } = this.props;
    const { ingredientList, ingredientListFull } = dataArray;
    const { componentCount,compontents } = this.state;
    console.log(Object.keys(compontents).length);
    return Array.from({length:componentCount}).map((_, index) => {
       const name = 'component-'+index;
       console.log(name);
       const componentData = compontents[name] || {};      
       console.log(componentData);
       const { ingredients } = componentData ;
       const props = { ingredientListFull, ingredientList, type:'simple',ingredients,ingredientPrices,cindex:name };  
       const value = (componentData.name!=undefined)?componentData.name.replace(/&amp;/g, "&"):'';
       const prepare_method = (componentData.prepare_method!=undefined)?componentData.prepare_method.replace(/&amp;/g, "&"):'';
      return <ItemGrid md={12}><ItemGrid md={12}><h6>Ingredients & Directions For <CustomInput
        labelText={'Dish'}
        formControlProps={{
          fullWidth: false
        }}
        inputProps={{
          value: value,
          type:'text',
          name:name,
          id:'name',
          onChange:this.changeForm
        }}
  /></h6></ItemGrid>
    <ItemGrid md={12}>{ingredientList && ingredientListFull && ingredientPrices &&  <Ingredients {...props} onChangeCompIng={this.onChangeCompIng} ref={el => (this.IngredientsRef = el)} />}</ItemGrid>
    <ItemGrid md={12}>
    <CustomInput
        labelText={'Preparation Method'}
        formControlProps={{
          fullWidth: true
        }}
        inputProps={{
          value: prepare_method,
          type:'text',
          name:name,
          id:'prepare_method',
          indexVal:'prepare_method',
          multiline:true,
          rows:3,
          onChange:this.changeForm
        }}
  />
    </ItemGrid>
  </ItemGrid>});
  }
  render() {
      
        return (
          <Grid container ref={el => (this.DishCompRef = el)}>
            {this.renderComponent()}
            <Button color='darkBlue' onClick={this.addNewComponent}>Add New Ingredients & Directions</Button>
          </Grid>
        );
      }
}

DishComponent.propTypes = {
    dataArray: PropTypes.object.isRequired
  };
  
  export default DishComponent;