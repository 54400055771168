import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment/moment';
import { Checkbox, Grid } from 'material-ui';
import Text from 'components/Typography/Text';
import { Check, RadioButtonChecked, RadioButtonUnchecked, ErrorOutline } from '@material-ui/icons/index';
import SelectInput from 'components/SelectInput';
import ChoiceSelect from 'components/ChoiceSelect';
import Calendar from 'components/InputCalendar';
import Tooltip from 'components/ToolTip';

class PlanInfoNew extends Component {
  constructor(props) {
    super(props);
    const {planParams} = this.props;
    const {deliverySlot} = planParams;
    var is_evening = false;
    if (deliverySlot == 4) {
      is_evening = true;
    }
    this.state = { 
      step: 1,
      is_evening: is_evening
    };
  }

  renderDay = (props, curd, seld, exWeekdays, exDates, minDate) => {
    const {is_evening} = this.state;
    var day = false;
    if (is_evening) {
      day = moment(curd._d).format('dddd');  
    }
   
    if(minDate && curd.isBefore(minDate)){
      props.className = `${props.className} disabled`;
      delete props.onChange;
      delete props.onClick;
    }
    /*if ( (minDate && curd.isBefore(minDate)) || (day == 'Friday' || day == 'Thursday') ) {
      props.className = `${props.className} disabled`;
      delete props.onChange;
      delete props.onClick;
    }*/
    return <td {...props}>{ curd.date() }</td>;
  };
  validateMealType = (name,typeId) =>{
    const { changeParams,planParams } =this.props;
    const { mealType} = planParams;
    let value = !mealType && [+typeId];
    if (mealType && !value) {
      //const req = mealType.filter(el => requiredTypes.includes(el)).length;
      if (mealType.includes(+typeId)) {
        /*if (req > 2 || (req === 2 && !required)) {
          value = mealType.filter(el => +el !== +typeId);
        } else {
          value = mealType;
        }*/
        value = mealType.filter(el => +el !== +typeId);
      } else {
        value = [...mealType, +typeId];
      }
    } 
    changeParams('mealType', value);
  }
  render() {
    const { dataArray, userData, planParams, changeParams, errors, excludedDates, build, minDate: minRenewDate, bagPrice, changeBag, prevPlanParams,mostUsedValues } = this.props;
    const useMinRenewDate = build === 'renew';
    const { vouchers,delivery_partner_id:delPartnerId } = userData || {};
    const { voucher,date:prevdate } = prevPlanParams;
    const { mealType, diet, cutlery, mealPlan, date, deliverySlot, coupon, price, paymentMethod, payed, pricetype,exclude_week_day,is_single_lunch_box,days_count:dayCount, delivery_partner_id:delOrderPartnerId, payment_method_id} = planParams;
    const { typeList, requiredTypes, dietList, planList, slotsList, paymentMethods, commonVouchers,dietListFull,deliveryPartner,bagPrice:bagprice } = dataArray;
   
    const deliveryTimesList = (slotsList || []).reduce((acc, { id, name }) => ({ ...acc, [id]: name }), {});
    const { excludeWeekDay: excluded,count:day } = (planList || {})[mealPlan] || {};
    const days_count = (dayCount!=undefined && dayCount)?dayCount:day; 
    const delivery_partner_id = delOrderPartnerId || delPartnerId; 
    // console.log(days_count);
    const now = moment().utc().unix();
    const vouchersList = [...(vouchers || []), ...(commonVouchers || [])].reduce((acc, { id, type, value, code, start_date, end_date, active }) => {
      const format = {
        fixed: (val) => `AED ${val / 100}`,
        percent: val => `${val}%`
      };
      const title = `${format[type](value)} (${code})`;
      if (start_date && end_date) {
        if (now > start_date && now < end_date && active) {
          return { ...acc, [`c_${id}`]: `Common: ${title}` };
        } else {
          return acc;
        }
      } else {
        return { ...acc, [`p_${id}`]: `Personal: ${title}` };
      }
    }, { '': 'None' });
    const selectvoucher='c_'+voucher;
    
    //const minDate = useMinRenewDate ? moment.unix(minRenewDate).utc() : null;
 
    const DateSelectionTooltip = () => {
      return <div className='tooltip-yn my-tt' style={{ width: '100%', textAlign: 'center', fontSize: '16px', marginTop: '5px', fontWeight: 600 }}>
        Select diet and plan first
      </div>;
    };
    const today = moment().utc().startOf('d');
    let minDate = useMinRenewDate ? today : null;
    minDate =(build==='editplan')?moment.unix(prevdate).startOf('d'):minDate;
    const vouchersAreDisabled = Object.keys(vouchersList).length < 2;
    const editaredisabled=build==='editplan';
    const disabledDietList =[30,33];
    var displayPrice = 'N\A';
    if (typeof(price) != 'undefined') {
      displayPrice =  (price / 100) + ' AED';
    }
    // console.log(dietList);
    var pricetypes={1:'New Price',0:'Old Price'};
    var priceTypeval = pricetype;
    if(pricetype==undefined){
      changeParams('pricetype', 1);
      priceTypeval=1;
    }
    const {mainDiets,mainDays,mainMealTypes} =mostUsedValues;
    const mainmealtypevalues=mainMealTypes;
    const mainDietvalues=mainDiets;
    // const mainDays=[5,6,20,24,30];
    console.log(mostUsedValues);
    let daysList={
          5:'5 Days',
          6:'6 Days',
          7:'7 Days'
    }
    for(let i=8;i<=99;i++){
      daysList[i]=i+' Days';
    }
  
    let mealTypes={};
     !!(typeList) && Object.keys(typeList).sort((a, b) => typeList[a].order - typeList[b].order).map((typeId, idx) => {
      const { title } = typeList[typeId];
        mealTypes[typeId]=title;
    });
    console.log(exclude_week_day);
    const skipdays = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'];
    return (
      <div>
        <div className='scroll-wrapper' style={{ paddingBottom: '40px' }}>
          <Grid container>
           
           
            <Grid item md={12}>
              <Text className='title-heading' bold style={{ fontSize: 'large',textAlign:'center' }} customColor='#555'>Select Diet Type</Text>
              <ChoiceSelect
                          labelText=''
                          name='diet'
                          formControlProps={{
                            fullWidth: true
                          }}
                          mainvalues={mainDietvalues}
                          value={diet || ''}
                          items={dietList || {}}
                          onClick={changeParams}
                          inputProps={{
                            onChange: e => changeParams('diet', e.target.value)
                          }}
                        />
              {'diet' in errors && <ErrorOutline color='error' />}
              </Grid>
              <Grid item md={12}>
                
                <Text className='title-heading' bold style={{ fontSize: 'large',textAlign:'center' }} customColor='#555'>Select Meals</Text>
                <ChoiceSelect
                            labelText=''
                            name='mealType'
                            formControlProps={{
                              fullWidth: true
                            }}
                            mainvalues={mainmealtypevalues}
                            value={mealType || []}
                            items={mealTypes || {}}
                            multiple ={true}
                            onClick={this.validateMealType}
                            inputProps={{
                              // onChange: e =>this.validateMealType(e.target.value) 
                              onChange: e => changeParams('mealType', e.target.value)
                            }}
                          />
              </Grid>
              <Grid item md={12}>
              <Text className='title-heading' bold style={{ fontSize: 'large',textAlign:'center' }} customColor='#555'>Select No of Days</Text>
              <ChoiceSelect
                          labelText=''
                          name='days_count'
                          formControlProps={{
                            fullWidth: true
                          }}
                          mainvalues={mainDays}
                          onClick={changeParams}
                          value={days_count || ''}
                          items={daysList || {}}
                          inputProps={{
                            onChange: e => changeParams('days_count', e.target.value)
                          }}
                        />
              {'mealPlan' in errors && <ErrorOutline color='error' />}
             
            </Grid>
            <Grid item md={12}>
              <Text className='title-heading' bold style={{ fontSize: 'large',textAlign:'center' }} customColor='#555'>Select Days to Skip</Text>
             
              <Grid container>
              {!!(skipdays) && skipdays.map((skipday, idx) => {
               
                const chk = (exclude_week_day).includes(idx);
                return (
                  <Grid item key={idx}>
                    <Checkbox
                      checked={chk}
                      icon={<Check className='unChecked' />}
                      checkedIcon={<Check className='checked' />}
                      onClick={() => {
                        let value = !exclude_week_day && [idx];
                        if (exclude_week_day && !value) {
                          if (exclude_week_day.includes(idx)) {
                            value = exclude_week_day.filter(el => el != idx);
                          } else {
                            value = [...exclude_week_day,idx];
                          }
                        } 
                        changeParams('exclude_week_day', value);
                      }}
                      classes={{ checked: 'unChecked' }}
                    />
                    <Text className='text-capitalize'>{skipday}</Text>
                  </Grid>
                );
              })}     
               </Grid>
            </Grid>
           
            <Grid item md={12}>
              <Grid container rowspacing={2} spacing={8}>
                <Grid item md={3}>
                  <Text inline={false} bold style={{ fontSize: 'large' }}  className='mini-heading' customColor='#555'>Start date</Text>
                  {'date' in errors && <ErrorOutline color='error' />}
                  <Tooltip
                    tooltipText={<DateSelectionTooltip />}
                    tooltipDirection={'top'}
                    trigger={['hover']}
                    hide={!!(diet && mealPlan)}
                  >
                    <div>
                      <Calendar
                        controlled
                        value={date || today.unix()}
                        placeholder={'Date'}
                        disable={!(diet && mealPlan)}
                        locale={'GB'}
                        onChange={date => changeParams('date', date)}
                        onViewModeChange={() => false}
                        renderDay={(a, b, c) => this.renderDay(a, b, c, excluded, excludedDates, minDate)}
                        onNavigateForward={(a, t, c) => c.clone().add(a, t).startOf(t).isBefore(moment().add(1, 'year'))}
                      />
                    </div>
                  </Tooltip>
                </Grid>
                <Grid item md={3}>
                  <Text inline={false} bold style={{ fontSize: 'large' }} className='mini-heading' customColor='#555'>Delivery Time</Text> 
                  {'deliverySlot' in errors && <ErrorOutline color='error' />}
                  <SelectInput
                    labelText='Delivery time'
                    items={deliveryTimesList || {}}
                   
                    value={'' + deliverySlot}
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      disabled:editaredisabled,
                      onChange: e => {
                        if (e.target.value == 4) {
                          this.setState({ is_evening: true });
                        } else {
                          this.setState({ is_evening: false });
                        }
                        changeParams('deliverySlot', e.target.value);
                      }
                    }}
                  />
                </Grid>
                <Grid item md={2} >
                <Text inline={false} bold style={{ fontSize: 'large' }} className='mini-heading' customColor='#555'>Delivery Partner</Text> 
                  {'delivery_partner_id' in errors && <ErrorOutline color='error' />}
                        <SelectInput
                          labelText='Delivery Partner'
                          name='delivery_partner_id'
                          formControlProps={{
                            fullWidth: true
                          }}
                          value={delivery_partner_id || ''}
                          items={deliveryPartner || {}}
                          inputProps={{
                            onChange: e => changeParams('delivery_partner_id', e.target.value)
                          }}
                        />
                      </Grid>
                <Grid item md={2}>
                  <Text inline={false} bold style={{ fontSize: 'large' }}  className='mini-heading' customColor='#555'>Payment Method</Text>
                  {'payment_method_id' in errors && <ErrorOutline color='error' />}
                  <SelectInput
                    labelText='Payment method'
                    items={paymentMethods || {}}
                    value={payment_method_id || ''}
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      disabled:editaredisabled,
                      onChange: e => changeParams('payment_method_id', e.target.value)
                    }}
                  />
                </Grid>
                <Grid item md={2}>
                  <Text inline={false} bold style={{ fontSize: 'large' }} className='mini-heading' customColor='#555'>Discount / Offers</Text>
                  <SelectInput
                    labelText='Vouchers'
                    items={vouchersList}
                    value={'' + coupon}
                    disabled={ build==='editplan'}
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      disabled: vouchersAreDisabled || editaredisabled,
                      title: (vouchers || []).length ? '' : 'This user have no any vouchers',
                      onChange: e => changeParams('coupon', e.target.value)
                    }}
                  />
                </Grid>
                
                </Grid>
                </Grid>
                <Grid item md={12}>
                <Grid container>
                <Grid item md={3}>
                  <Text inline={false} bold style={{ fontSize: 'large',textAlign:'center' }} className='mini-heading' customColor='#555'>Cutlery</Text>
                  <Grid item>
                  <Checkbox
                    checked={cutlery==true}
                    icon={<RadioButtonUnchecked className='unchecked-radio' />}
                    checkedIcon={<RadioButtonChecked className='checked-radio' />}
                    disabled={ build==='editplan'}
                    onClick={e => changeParams('cutlery', true)}
                  /> <Text>Yes</Text></Grid>
                  <Grid item>
                  <Checkbox
                    checked={cutlery==false}
                    icon={<RadioButtonUnchecked className='unchecked-radio' />}
                    checkedIcon={<RadioButtonChecked className='checked-radio' />}
                    disabled={ build==='editplan'}
                    
                    onClick={e => changeParams('cutlery', false)}
                  /> <Text>No</Text></Grid>
                </Grid>
                <Grid item md={3}>
                  <Text inline={false} bold style={{ fontSize: 'large',textAlign:'center' }} className='mini-heading' customColor='#555'>Box/Bag</Text>
                  <Grid item>
                  <Checkbox
                    checked={is_single_lunch_box==true}
                    icon={<RadioButtonUnchecked className='unchecked-radio' />}
                    checkedIcon={<RadioButtonChecked className='checked-radio' />}
                    disabled={ build==='editplan'}
                    onClick={e => changeParams('is_single_lunch_box', true)}
                  /> <Text> Box</Text>
                  </Grid>
                    <Grid item>
                  <Checkbox
                    checked={is_single_lunch_box==false}
                    icon={<RadioButtonUnchecked className='unchecked-radio' />}
                    checkedIcon={<RadioButtonChecked className='checked-radio' />}
                    disabled={ build==='editplan'}
                    onClick={e => changeParams('is_single_lunch_box', false)}
                  /> <Text>Bag</Text></Grid>
                </Grid>
                <Grid item md={3}>
                  <Text bold style={{ fontSize: 'large',textAlign:'center' }} className='mini-heading' customColor='#555'>Price Type</Text>
                  {'pricetype' in errors && <ErrorOutline color='error' />}
                  {pricetypes && Object.keys(pricetypes).map((priceType, idx) => {
                    return (
                      <Grid item key={idx}>
                        <Checkbox
                          checked={+priceTypeval === +priceType}
                          // disabled={priceType==0}
                          icon={<RadioButtonUnchecked className='unchecked-radio' />}
                          checkedIcon={<RadioButtonChecked className='checked-radio' />}
                          onClick={() => changeParams('pricetype', +priceType)}
                          classes={{ checked: 'unChecked' }}
                        />
                        <Text>{pricetypes[priceType]}</Text>
                      </Grid>
                    );
                  })}
                  
                </Grid>
                 <Grid item md={3}>
                  <Text inline={false} bold style={{ fontSize: 'large',textAlign:'center' }} className='mini-heading' customColor='#555'>Bag Price(+{bagprice} AED)</Text>
                  <Grid item >
                  <Checkbox
                    checked={(bagPrice>0)}
                    icon={<RadioButtonUnchecked className='unchecked-radio' />}
                    checkedIcon={<RadioButtonChecked className='checked-radio' />}
                    disabled={ build==='editplan'}
                    onClick={e => changeBag(true)}
                  /> <Text>Add</Text>
                  </Grid>
                  <Grid item>
                  <Checkbox
                    checked={(bagPrice==0)}
                    icon={<RadioButtonUnchecked className='unchecked-radio' />}
                    checkedIcon={<RadioButtonChecked className='checked-radio' />}
                    disabled={ build==='editplan'}
                    onClick={e => changeBag(false)}
                  /> <Text>Remove</Text>
                  </Grid>
                </Grid>
              </Grid>
              
            </Grid>
            {!editaredisabled&&<Grid item  md={12}> 
            <Grid container rowspacing={2} spacing={8}>
                <Grid item md={6}>
                <Text inline={false} bold style={{ fontSize: 'large' }} className='mini-heading' customColor='#555'>Price Meal Plan:</Text>
                  <Text bold style={{ fontSize: 'large' }} customColor='#555'>
                   <Text bold style={{ fontSize: 'large' }} color='success'>{displayPrice}</Text> 
                  </Text>
                  </Grid>
                  <Grid item md={6}>
                  <Text inline={false} bold style={{ fontSize: 'large' }} className='mini-heading' customColor='#555'>Total with Bag:</Text>
                   <Text bold style={{ fontSize: 'large' }} customColor='#555'>
                    Bag Price: <Text bold style={{ fontSize: 'large' }} color='success'>AED {bagPrice}</Text> <br/>
                    Total Price: <Text bold style={{ fontSize: 'large' }} color='success'>{ `AED ${price/100 + parseFloat(bagPrice)}` }</Text>
                    </Text>
                  </Grid>
                  </Grid>
                </Grid>}
          </Grid>
        </div>
      </div>
    );
  }
}

PlanInfoNew.propTypes = {
  errors: PropTypes.object.isRequired,
  planParams: PropTypes.object.isRequired,
  userData: PropTypes.object.isRequired,
  dataArray: PropTypes.object.isRequired,
  changeParams: PropTypes.func.isRequired,
  excludedDates: PropTypes.array,
  build: PropTypes.string,
  minDate: PropTypes.number
};

export default PlanInfoNew;
