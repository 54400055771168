import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { connect } from 'react-redux';
import { socketConnect } from 'socket.io-react';
import CustomCard from 'components/Cards/CustomCard';
import ItemGrid from 'components/Grid/ItemGrid';
import { Grid } from 'material-ui';
import Button from 'components/CustomButtons/Button';
import CustomTabs from 'components/CustomTabs';
import { TabInfo, TabFeedback, TabMealPlan, TabNotes, TabProgress, Map, OrdersHistory, TabDeliveryNotes, Files, Photos,TabLogs, TabDeliverySchedule, TabMobileCheckout, TabBodyAnalysis } from './Tabs';
import { addPin, removePin } from 'store/pins';
import { clearNotify, setNotify } from 'store/notify';
import { ErrorOutline } from '@material-ui/icons';

const tabsHashes = [
  { title: 'Info', hash: '#info' },
  { title: 'Meal Plan', hash: '#meal-plan' },
  { title: 'Progress', hash: '#progress' },
  { title: 'Feedback', hash: '#feedback' },
  { title: 'Notes', hash: '#notes' },
  { title: 'Delivery Schedule', hash: '#delivery-schedule' },
  { title: 'Map', hash: '#map' },
  { title: 'Order History', hash: '#orders-history' },
  { title: 'Mobile Checkout', hash: '#mobile-checkout' },
  { title: 'Logs', hash: '#orders-logs' }
];

class Details extends Component {
  constructor(props) {
    super(props);
    const { id, logged_user } = props;
    this.state = {
      errors: {},
      userData: false,
      adminUsers: false,
      kitchenUsers: false,
      avatar: null,
      remainDeliveries: [],
      manager_notes: [],
      nutritionist_notes: [],
    };
    id && this.getUserData(id);
    this.getUsersList('admin', 0, 999);
    //this.getUsersList('kitchen', 0, 999);
  }

  listener = ({ type, data, message }) => {
    if (this.DetailsRef) {
      switch (type) {
        case 'tryOk':
          this.showNotification(message);
        break;
        case 'listOk':
          const {userType} = data;
          switch(userType) {
            case 'admin':
              this.setState({
                adminUsers: data.users
              });
            break;
            
            case 'kitchen':
              this.setState({
                kitchenUsers: data.users
              });
            break;

            default:
              this.setState(data);
            break;
          }
          break;
        case 'getOk':
          this.setState(data);
          this.props.setCurrent(+(data.userData || {})['user_id']);
          this.setState({avatar: null});
          const {userData} = data;
          const {user_id} = userData;
          this.getAvatar(user_id);
        break;
        case 'getAvatarOk':
          this.setState({avatar: data.avatar});
        break;
        case 'saveLocOk':
          this.setState(data);
          break;
      }
    }
  };

  getAvatar = (id) => this.props.socket.emit('profile', { type: 'getAvatar', data: { userId: id } });

  orderListener = ({ type, data }) => {
    if (this.DetailsRef) {
      const { message, remainDeliveries } = data;
      switch (type) {
        case 'delRemainOk':
        case 'moveRemainOk':
          this.showNotification(message);
          this.setState({ remainDeliveries });
          break;
        case 'delRemainErr':
        case 'moveRemainErr':
          this.showNotification(message, 'warning');
          break;
      }
    }
  };

  showNotification = (message, color = 'success', timeout = 5000) => {
    const { setNotify, clearNotify } = this.props;
    setNotify({
      message,
      color,
      place: 'tc',
      icon: ErrorOutline,
      open: true
    });
    setTimeout(() => clearNotify(), timeout);
  };

  componentWillMount() {
    this.props.socket.on('profile', this.listener);
    this.props.socket.on('restore_password', this.listener);
    this.props.socket.on('order', this.orderListener);
  }

  componentWillUnmount() {
    this.props.socket.removeListener('profile', this.listener);
    this.props.socket.removeListener('order', this.orderListener);
  }

  getUserData = (userId) => {
    userId && this.props.socket.emit('profile', {
      type: 'get',
      data: {
        userId
      }
    });
  };

   getUsersList(userType, offset, limit, _filters) {
    let filters = { ..._filters };
    if (['hasActive', 'noActive'].includes(this.activeTab)) {
      filters = { ...filters, activePlan: { value: this.activeTab === 'hasActive' || !(this.activeTab === 'noActive') } };
    }

    if (userType == 'admin' || userType == 'kitchen') {
      filters = null;
    }
    this.props.socket.emit('profile', {
      type: 'list',
      data: {
        userType,
        offset,
        limit,
        filters
      }
    });
  }

  componentWillReceiveProps(next) {
    if (!this.state.userData || next.id !== this.props.id) {
      this.getUserData(next.id);
    }
  }

  saveLocations = (user_id, locations) => {
    this.props.socket.emit('profile', { type: 'saveLoc', data: { user_id, locations } });
  };

  sendMailReset = (email) => {
    this.props.socket.emit('restore_password', { type: 'try', data: { email: email } });
  };

  render() {
    const { userData, errors, remainDeliveries, adminUsers, kitchenUsers } = this.state;
    const { addPin, removePin, pinsList, showForm, location, closeUser,dataArray } = this.props;
    const { firstName, lastName, user_id: id, plan } = userData;
    const { hash: curHash } = location;
    const fullName = (lastName && lastName) ? `${firstName} ${lastName}` : 'First and last name are not set';
    const props = { userData: userData || {}, user_id: +userData.user_id };
    const tabs = {
      'Info': <TabInfo {...{ ...props, showForm, errors }} adminUsers={this.state.adminUsers} kitchenUsers={this.state.kitchenUsers} avatar={this.state.avatar} sendMailReset={this.sendMailReset} />,
      'Meal Plan': <TabMealPlan {...{ ...props, getUserInfo: () => this.getUserData(id), remainDeliveries, adminUsers }} />,
      'Progress': <TabBodyAnalysis {...props} />,
      'Feedback': <TabFeedback {...props} />,
      'Notes': <TabNotes {...props} manager_notes={this.state.manager_notes} nutritionist_notes={this.state.nutritionist_notes} adminUsers={this.state.adminUsers} />,
      'Delivery Schedule': <TabDeliverySchedule {...props} dataArray={dataArray} />,
      'Map': <Map {...props} saveLocations={this.saveLocations} />,
      'Order History': <OrdersHistory {...props} />,
      'Mobile Checkout': <TabMobileCheckout {...props} />,
      'Files': <Files user_id={id} />,
      'Photos': <Photos user_id={id} />,
      'Logs': <TabLogs {...props} />
    };
    const tabsData = Object.keys(tabs).reduce((acc, cur) => {
      const curTab = tabs[cur];
      const tabData = {
        icon: '',
        label: cur,
        renderTabContent: () => {
          return curTab;
        }
      };
      return { ...acc, [cur]: tabData };
    }, {});
    return (
      <Fragment>
        <div ref={el => (this.DetailsRef = el)} className='customer-item t'>
          {userData && <Fragment>
            <CustomCard noPadding>
              <div className='customer-head new'>
                <Grid container>
                  <ItemGrid md={8} sm={8}>
                    <div className='name'>
                      <h4>{fullName}</h4>
                      {plan && <p className='money-info'>
                        <span className='price bold'>{`AED ${plan['price'] / 100}`}</span>
                        <span className='status paid'>{plan['payed'] ? 'already payed' : `Collection date ${moment.unix(plan['start']).format('DD.MM')}`}</span>
                      </p>}
                    </div>
                  </ItemGrid>
                  <ItemGrid md={3} sm={3}>
                    <div className='info'>
                      {pinsList.includes('' + id) ? <Button
                        color='darkBlue'
                        size='small'
                        onClick={() => removePin('customers', id)}
                        pullRight
                      >
                        unpin
                      </Button> : <Button
                        color='darkBlue'
                        size='small'
                        onClick={() => addPin('customers', { [id]: { title: fullName } })}
                      >
                        pin
                      </Button>}
                      <span className='price bold'>{`id: ${id}`}</span>
                      {/* <span className='status paid'>{(slotsList && slot_id) ? slotsList.find(el => +el.id === +slot_id)[name] : 'No plan'}</span> */}
                    </div>
                  </ItemGrid>
                  <ItemGrid md={1} sm={1}>
                    <span className={'close'} onClick={() => closeUser(id)}>&times;</span>
                  </ItemGrid>
                </Grid>
              </div>
            </CustomCard>
            <div className='customer-tabs'>
              <CustomTabs
                customClasses={'tabs-outher'}
                data={tabsData}
                headerTitle={''}
                onChangeTab={val => (window.location.hash = (tabsHashes.find(({ title }) => title === val) || { hash: '' })['hash'])}
                headerColor='darkGreen'
                defaultTab={(tabsHashes.find(({ hash }) => hash === curHash) || { title: 'Meal Plan' })['title']}
              />
            </div>
          </Fragment>}
        </div>
      </Fragment>
    );
  }
}

Details.propTypes = {
  socket: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  id: PropTypes.number,
  dataArray: PropTypes.object.isRequired,
  pinsList: PropTypes.array.isRequired,
  showForm: PropTypes.func.isRequired,
  addPin: PropTypes.func.isRequired,
  removePin: PropTypes.func.isRequired,
  closeUser: PropTypes.func.isRequired,
  setCurrent: PropTypes.func,
  setNotify: PropTypes.func.isRequired,
  clearNotify: PropTypes.func.isRequired
}; 

const props = state => ({
  dataArray: state.dataArray,
  pinsList: Object.keys(state.pins.customers)
});

const actions = dispatch => ({
  addPin: (group, id) => dispatch(addPin(group, id)),
  removePin: (group, id) => dispatch(removePin(group, id)),
  clearNotify: () => dispatch(clearNotify()),
  setNotify: props => dispatch(setNotify(props))
});

export default socketConnect(connect(props, actions, null, { withRefs: true })(Details));
