function Exception(message) {
  return message;
}

function diff(targetArr, ...rest) {
  if (arguments.length > 1) {
    if (!Array.isArray(targetArr)) {
      throw Exception('Invalid argument');
    }
    const other = [];
    rest.forEach(arr => {
      if (Array.isArray(arr)) {
        other.push(...arr);
      } else {
        throw Exception('Invalid argument');
      }
    });
    return targetArr.filter(el => !other.includes(el));
  } else {
    throw Exception('Invalid number of arguments');
  }
}

function intersect() {
  if (arguments.length > 1) {
    const targetArr = arguments[0];
    if (!Array.isArray(targetArr)) {
      throw Exception('Invalid argument');
    }
    let other = [];
    for (let i = 1; i < arguments.length; i++) {
      if (Array.isArray(arguments[i])) {
        other = [...other, ...arguments[i]];
      } else {
        throw Exception('Invalid argument');
      }
    }
    return targetArr.filter(el => other.includes(el));
  } else {
    throw Exception('Invalid number of arguments');
  }
}

function compareObjects(object1, object2) {
  if (!object1 || !object2 || typeof object1 !== 'object' || typeof object2 !== 'object' || Object.keys(object1).length !== Object.keys(object2).length) {
    return false;
  }
  return !Object.keys(object1).filter(key => {
    if (key in object2) {
      if (typeof object1[key] === 'object' && typeof object2[key] === 'object') {
        return !compareObjects(object1[key], object2[key]);
      } else if (Array.isArray(object1[key]) && Array.isArray(object2[key])) {
        return object1[key].length !== object2[key].length || intersect(object1[key], object2[key]).length !== object1[key].length;
      } else {
        return object1[key] !== object2[key];
      }
    } else {
      return true;
    }
  }).length;
}

function getArrays(socket, type) {
  if (socket.emit && type.length) {
    socket.emit('get_arrays', {
      type: 'get',
      data: { type }
    });
  }
}

function parseDates(datesString = '', delimiter = ';') {
  const dates = datesString.split(delimiter);
  const currentYear = (new Date()).getUTCFullYear();
  const full = /^\d{4}-(02-(0[1-9]|[12]\d)|((01|03|05|07|08|10|12)-(0[1-9]|[12]\d|3[01]))|(04|06|09|11)-(0[1-9]|[12\d]|30))$/i;
  const short = /^(02-(0[1-9]|[12]\d)|((01|03|05|07|08|10|12)-(0[1-9]|[12]\d|3[01]))|(04|06|09|11)-(0[1-9]|[12\d]|30))$/i;
  return dates.reduce((acc, date) => {
    if (date.match(short)) {
      return [...acc, `${currentYear}-${date}`];
    } else if (date.match(full)) {
      return [...acc, date];
    } else {
      return acc;
    }
  }, []);
}
function getExcludeWeek (exclude_week_day){
  const skipdays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
  return (exclude_week_day!=undefined && exclude_week_day.length>0)?'No '+exclude_week_day.map(el => skipdays[el]).join(' & '):'Full Week';
}
export { intersect, diff, compareObjects, getArrays, parseDates, getExcludeWeek };
