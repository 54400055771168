import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { socketConnect } from 'socket.io-react';
import { connect } from 'react-redux';
import { Grid } from 'material-ui';
import Dropzone from 'react-dropzone';
import { ErrorOutline } from '@material-ui/icons';
import { browserHistory } from 'react-router';

import RegularCard from 'components/Cards/RegularCard';
import Button from 'components/CustomButtons/Button';
import CustomInput from 'components/CustomInput/CustomInput';
import ItemGrid from 'components/Grid/ItemGrid';
import SelectInput from 'components/SelectInput';
import { unsetErrors } from 'utils';
import { PHOTOS_PATH } from 'variables/constants';
import { setNotify, clearNotify } from 'store/notify';

import sendImage from '../../handler/sendImage';
import sendImageAws from '../../handler/sendImageAws';
import TinyMCE from 'react-tinymce';
import Script from 'react-load-script';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

class PostEditor extends Component {
  constructor(props) {
    super(props);
    const { params } = this.props;
    this.state = {
      errors: {},
      postData: {},
      image: {},
      isDataLoad:false,
      needUploadImage: false,
      tinymceLoaded: !!window.tinymce || false
    };
    this.getPostData(params.id);
  }

  listener = ({ data, type }) => {
    if (this.PostEditorRef) {
      switch (type) {
        case 'getOk':
          const { postData } = data;
          let text = '';
          try {
            text = decodeURI(atob(postData.text || ''));
          } catch (e) {
            text = postData.text || '';
          }
          this.setState({ postData: { ...postData, text },isDataLoad:true });
          break;
        case 'setOk':
          const { needUploadImage, image } = this.state;
          const saved = (message) => {
            this.props.setNotify({
              place: 'tc',
              color: 'success',
              icon: ErrorOutline,
              message: message,
              open: true
            });
            setTimeout(() => this.props.clearNotify(), 5000);
            browserHistory.push(`/${this.props.userType}/blog/`);
          };
          if (needUploadImage) {
            this.saveImage(data.id, image, () => {
              saved(data.message);
            });
          } else {
            saved(data.message);
          }
          break;
      }
    }
  };

  getPostData = postId => {
    this.props.socket.emit('blog', {
      type: 'get',
      data: {
        postId
      }
    });
  };

  submit = e => {
    e.preventDefault();
    const { params } = this.props;
    let postData = { ...this.state.postData };
    const isNewRecord = (params.id || 'add') === 'add';
    const err = Object.keys(postData)
      .filter(key => !['image', 'published', ...(isNewRecord ? ['id'] : [])].includes(key))
      .reduce((flag, cur) => !flag ? !postData[cur] : flag, false);
    const newImg = !!this.state.image.preview;
    const imgErr = isNewRecord ? !newImg : (!postData.image && !newImg);
    if (err || imgErr) {
      this.props.setNotify({
        place: 'tc',
        color: 'warning',
        icon: ErrorOutline,
        message: 'Please, fill in all fields',
        open: true
      });
      setTimeout(() => this.props.clearNotify(), 5000);
      return null;
    }
    postData.text = btoa(encodeURI(postData.text));
    const data = { postData, postId: isNewRecord ? null : params.id };
    this.setState({ needUploadImage: true });
    delete postData['image'];
    this.props.socket.emit('blog', { type: 'set', data });
  };

  componentWillMount() {
    this.props.socket.on('blog', this.listener);
  }

  componentWillUnmount() {
    this.props.socket.removeListener('blog', this.listener);
  }

  sendImageData = (data, id) => {
    // console.log(data);
    // console.log(id);
    if(data.imagedata!=undefined){
      this.props.socket.emit('blog', {
        type: 'setAWSFile',
        data: { data, id }
      }); 
    }
  };

  saveImage = (id, image, callback) => {
    const dataForm = new FormData();
    dataForm.append('access_token', this.props.userToken);
    dataForm.append('type', 'modelImage');
    dataForm.append('modelName', 'BlogPost');
    dataForm.append('id', id);
    dataForm.append('attribute', 'image');
    dataForm.append('BlogPost[image]', image);
    // sendImage.call(this, dataForm, callback);
    var  dishinfo =  new Object();
    dishinfo['id']=id;
    dishinfo['attribute']='image';
    dishinfo['modelName']='BlogPost';
    dishinfo['path']='photos';
    sendImageAws.call(this, image,dishinfo, (info,id)=>{this.sendImageData(info,id);});
  };

  renderImagePicker = (name, value) => {
    const { image: img } = this.state;
    const image = (img && img.preview) || (value && (PHOTOS_PATH + value)) || '';
    return (
      <Dropzone
        onDrop={(files) => this.PostEditorRef && this.setState({ image: files[0] })}
        className='drop-zone'
        style={{
          backgroundImage: `url(${image})`
        }}
      >
        {image === '' && <div className='drop-zone-label'><p>Drop file here</p></div>}
      </Dropzone>);
  };

  render() {
    const { blogCategories } = this.props;
    const { postData, errors, tinymceLoaded, isDataLoad } = this.state;
    const { title, text, tags, description, blogCategoryId, published, image } = postData;
    const changeData = (name, value, errorsToUnset) => this.PostEditorRef && this.setState({
      postData: { ...postData, [name]: value },
      errors: errorsToUnset ? unsetErrors(errors, errorsToUnset) : unsetErrors(errors, [name])
    });
    const flag = !!Object.keys(postData).length;
    return (
      <div ref={el => (this.PostEditorRef = el)} className='blog'>
        {/* {!window.tinymce && <Script url='//cdn.tinymce.com/4/tinymce.min.js' onLoad={() => this.setState({ tinymceLoaded: true })} />} */}
        <Grid container>
          <ItemGrid md={8}>
            {/* {(window.tinymce && tinymceLoaded && flag) && <TinyMCE
              content={text}
              config={{
                plugins: 'autolink link lists print image imagetools preview code',
                toolbar: 'undo redo | bold italic | alignleft aligncenter alignright code',
                min_height: 500,
                extended_valid_elements: 'span[class]',
                content_css: '/client-styles/main.css',
                imagetools_toolbar: 'imageoptions',
                file_picker_callback: function (callback, value, meta) {
                  const input = document.createElement('input');
                  input.type = 'file';
                  input.accept = 'image/*,image/jpeg,image/png';
                  input.onchange = function () {
                    const file = this.files[0];
                    const reader = new FileReader();
                    reader.onload = function () {
                      const id = 'blobid' + (new Date()).getTime();
                      // eslint-disable-next-line no-undef
                      const blobCache = tinymce.activeEditor.editorUpload.blobCache;
                      const base64 = reader.result.split(',')[1];
                      const blobInfo = blobCache.create(id, file, base64);
                      blobCache.add(blobInfo);
                      callback(blobInfo.blobUri(), { title: file.name });
                    };
                    reader.readAsDataURL(file);
                  };
                  input.click();
                },
                file_picker_types: 'image',
                images_upload_handler: (blobInfo, success, failure) => {
                  if (blobInfo.blob()) {
                    const formData = new FormData();
                    formData.append('access_token', this.props.userToken);
                    formData.append('type', 'blogPostImage');
                    formData.append('Gallery[image]', blobInfo.blob(), blobInfo.filename());
                    sendImage.call(this, formData, action => {
                      if (action.status === 'success') {
                        success(action.location);
                      } else if (action.status === 'error') {
                        failure(action.message);
                      }
                    });
                  }
                }
              }}
              onChange={e => changeData('text', e.target.getContent())}
            />} */}
             <RegularCard
                  cardTitle='Description'
                  cardSubtitle=''
                  content={
                  <ReactQuill theme="snow" value={text} onChange={val => {
                    if(isDataLoad){
                      changeData('text', val);
                    }
                  }} />}
            />
            <Button color='success' onClick={this.submit}>Save</Button>
          </ItemGrid>
          <ItemGrid md={4}>
            <Grid container>
              <ItemGrid md={12}>
                <RegularCard
                  cardTitle='Info'
                  cardSubtitle=''
                  content={
                    <Grid container>
                      <ItemGrid md={12}>
                        <CustomInput
                          labelText='Title'
                          formControlProps={{
                            fullWidth: true
                          }}
                          error={'title' in errors}
                          inputProps={{
                            value: title || '',
                            onChange: e => changeData('title', e.target.value)
                          }}
                        />
                      </ItemGrid>
                      <ItemGrid md={8}>
                        <SelectInput
                          labelText='Category'
                          items={blogCategories}
                          value={'' + blogCategoryId || ''}
                          name='blogCategoryId'
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={{
                            onChange: e => changeData('blogCategoryId', e.target.value)
                          }}
                        />
                      </ItemGrid>
                      <ItemGrid md={4}>
                        <SelectInput
                          labelText='Published'
                          items={{
                            '1': 'Yes',
                            '0': 'No'
                          }}
                          value={'' + published || ''}
                          name='published'
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={{
                            onChange: e => changeData('published', e.target.value)
                          }}
                        />
                      </ItemGrid>
                      <ItemGrid md={12}>
                        <CustomInput
                          labelText='Description'
                          formControlProps={{
                            fullWidth: true
                          }}
                          error={'description' in errors}
                          inputProps={{
                            value: description || '',
                            onChange: e => changeData('description', e.target.value),
                            multiline: true,
                            rows: '5'
                          }}
                        />
                      </ItemGrid>
                      <ItemGrid md={12}>
                        <CustomInput
                          labelText='Tags List'
                          formControlProps={{
                            fullWidth: true
                          }}
                          error={'tags' in errors}
                          inputProps={{
                            value: tags || '',
                            onChange: e => changeData('tags', e.target.value)
                          }}
                        />
                      </ItemGrid>
                    </Grid>
                  }
                />
              </ItemGrid>
              <ItemGrid md={12}>
                <RegularCard
                  cardTitle='Image'
                  cardSubtitle=''
                  content={
                    <div>
                      <Grid container>
                        {this.renderImagePicker('image', image)}
                      </Grid>
                    </div>
                  }
                />
              </ItemGrid>
            </Grid>
          </ItemGrid>
        </Grid>
      </div>
    );
  }
}

PostEditor.propTypes = {
  socket: PropTypes.object.isRequired,
  blogCategories: PropTypes.object,
  params: PropTypes.object.isRequired,
  userType: PropTypes.string.isRequired,
  userToken: PropTypes.string.isRequired,
  setNotify: PropTypes.func.isRequired,
  clearNotify: PropTypes.func.isRequired
};

PostEditor.defaultProps = {
  blogCategories: {}
};

const props = state => ({
  blogCategories: state.dataArray.blogCategories,
  userType: state.user.type,
  userToken: state.user.token
});

const actions = dispatch => ({
  clearNotify: () => dispatch(clearNotify()),
  setNotify: props => dispatch(setNotify(props))
});

export default socketConnect(connect(props, actions)(PostEditor));
