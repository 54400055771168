import React, { Component } from 'react';
import { connect } from 'react-redux';
import {createBrowserHistory} from 'history';
import { socketConnect } from 'socket.io-react';
import PropTypes from 'prop-types';
import { browserHistory } from 'react-router';
import { AddAlert, Add, ChevronLeft } from '@material-ui/icons/index';
import GridView from 'components/GridView';
import Snackbar from 'components/Snackbar/Snackbar';
import Button from 'components/CustomButtons/Button';
import { setModal, clearModal } from 'store/modal';
import YesNo from 'components/Dialog/YesNo';

class Model extends Component {
  constructor(props) {
    super(props);
    const { params, customModelName, customEditCallback } = this.props;
    const page = (params || {}).page ? params.page : 1;
    const modelName = (params || {}).modelName ? this.formatModelName(params.modelName) : (customModelName || '');
    const modelName1 = customModelName || (params || {}).modelName;
    const limit = 20;
    this.state = {
      list: false,
      limit,
      page,
      modelName,
      maxPages: 1,
      errors: {},
      labels: {},
      actions: this.getActions(modelName, undefined, customEditCallback,modelName1),
      filters: {},
      note: false,
      message: '',
      mappedList:false
    };
    this.getInfo(modelName, (page - 1) * limit, limit);
  }

  formatModelName = name => {
    const capitalize = str => `${str.substr(0, 1).toUpperCase()}${str.substr(1)}`;
    return name.split('-').reduce((acc, cur) => `${acc}${capitalize(cur)}`, '');
  };

  getActions(modelName, filter, customEditCallback, modelURL) {
    const { sheetsPath, userType } = this.props;
    const editCallback = customEditCallback || (id => browserHistory.push(`/${userType}/model/${modelURL}/edit/${id}`));
    let actions = {
      update: {
        tooltip: 'Edit',
        button: 'Edit',
        callback: editCallback
      },
      close: {
        tooltip: 'Delete',
        button: 'Delete',
        callback: id => this.delete(modelName, id)
      }
    };
    if (modelName === 'Order') {
      actions = {
        view: {
          tooltip: 'View',
          button: 'View',
          callback: id => browserHistory.push(`/${this.props.userType}/order-details/${id}`)
        } // TODO: add delete button with validation
      };
    }
    if (modelName === 'Spreadsheet') {
      actions = {
        download: {
          tooltip: 'Download',
          button: 'Download',
          callback: file => window.open(`${sheetsPath.value || '/'}${file}`)
        },
        ...actions
      };
    }
    if (modelURL == 'ingredient') {
      actions = {
        add: {
          tooltip: 'Add/Edit Supplier Prices',
          button: 'Add Supplier',
          callback: id => browserHistory.push(`/${this.props.userType}/ingredient-price/${id}`)
        },
        ...actions
      };
    }
    if (modelURL == 'emirate') {
      actions = {
        add: {
          tooltip: 'Add/Edit Slots',
          button: 'Add Slot',
          callback: id => browserHistory.push(`/${this.props.userType}/emirate-slot/${id}`)
        },
        ...actions
      };
    }
    if (filter) {
      return Object.keys(actions).reduce((acc, cur) => filter.includes(cur) ? { ...acc, [cur]: actions[cur] } : acc, {});
    } else {
      return actions;
    }
  }

  submitFilters = (filters) => {
    const { modelName, page, limit } = this.state;
    this.setState({ filters });
    this.getInfo(modelName, (page - 1) * limit, limit, filters);
  };

  listener = action => {
    if (this.ModelRef) {
      const { modelName, limit, page, filters: _filters } = this.state;
      const { onLoaded } = this.props;
      switch (action.type) {
        case 'listOk':
          const { labels } = action.data;
          const filters = Object.keys(labels).reduce((acc, cur) => {
            const { type, props, label, filter } = labels[cur];
            return !['index', 'actions'].includes(cur) && type && filter !== false ? {
              ...acc,
              [cur]: {
                type,
                value: _filters[cur] ? _filters[cur].value : '',
                label,
                placeholder: props.placeholder,
                items: props ? props.items : {}
              }
            } : acc;
          }, {});
          this.setState({ ...action.data, filters });
          onLoaded && onLoaded();
          break;
        case 'delOk':
          this.showNotification(action.data.message);
          this.getInfo(modelName, (page - 1) * limit, limit, _filters);
          break;
        case 'listErr':
          this.setState({ errors: action.data.errors });
          break;
        case 'delErr':
          this.setState({ errors: action.data.errors });
          break;
        case 'err':
          const { message, code, redirect } = action.data;
          if (+code === 404 && redirect) {
            browserHistory.push('/404');
          } else {
            this.showNotification(message);
          }
          break;
      }
    }
  };

  getInfo(modelName, offset, limit, filters) {
    this.props.socket.emit('other_tables', {
      type: 'list',
      data: {
        modelName,
        offset,
        limit,
        filters
      }
    });
  }

  delete(modelName, id) {
    const { setModal, clearModal, socket } = this.props;
    const del = () => {
      socket.emit('other_tables', {
        type: 'del',
        data: {
          modelName,
          id
        }
      });
      clearModal();
    };
    setModal({
      content: (<YesNo actionYes={del} actionNo={() => clearModal()} message='Are you sure?' />),
      width: '30%',
      height: '20vh'
    });
  }

  componentWillReceiveProps(next) {
    const nextModelName = next.customModelName || this.formatModelName((next.params || {})['modelName']);
    const { limit, modelName, filters, page } = this.state;
    if (nextModelName !== modelName && this.ModelRef) {
      let filterVal = filters;
      if(filters["user_id"] !== undefined && nextModelName=='CommonVoucher'){
        delete filterVal["user_id"];
      }
      this.setState({ modelName: nextModelName, actions: this.getActions(nextModelName),filters:filterVal });
      setTimeout(() => this.getInfo(nextModelName, (page - 1) * limit, limit, filterVal), 10);
    }
  }

  componentWillMount() {
    this.props.socket.on('other_tables', this.listener);
  }

  componentWillUnmount() {
    this.props.socket.removeListener('other_tables', this.listener);
  }

  showAll() {
    this.setState({
      limit: 9999,
    });
  }

  goTo = page => {
    const { limit, filters, modelName } = this.state;
    const { modelName: routeModelName } = this.props.params;
    const history = createBrowserHistory();
    history.push(`/${this.props.userType}/model/${routeModelName}/page/${page}`);
    this.setState({ page });
    this.getInfo(modelName, (page - 1) * limit, limit, filters);
  };

  showNotification(message) {
    this.setState({ note : true, message });
    setTimeout(() => this.setState({ note : false, message: '' }), 5000);
  }

  render() {
    const { page, maxPages, list, limit, labels, actions, note, message, filters, mappedList } = this.state;
    const { customBackLink, customAddLink, needBackButt, customModelName, params, needTitle } = this.props;
    const modelName = customModelName || (params || {}).modelName;
    let content = '';
    if (Object.keys(labels).length) {
      const props = {
        ...{ page, maxPages, limit, actions, filters },
        rows: list || [],
        mappedList: mappedList || [],
        tableHead: labels,
        goTo: this.goTo,
        submitFilters: this.submitFilters
      };
      content = <GridView {...props} />;
    }
    const backLink = customBackLink || '/admin/catalogs';
    const formLink = customAddLink || `/${this.props.userType}/model/${modelName}/add`;
    return <div ref={el => (this.ModelRef = el)} className='model full-w'>
      {needTitle && <h3>{(modelName=='manage-pd')?'Support Staff: Name':modelName}</h3>}
      {needBackButt && <Button color='darkBlue' size='medium' onClick={() => browserHistory.push(backLink)}><ChevronLeft /></Button>}
      <Button fullWidth={false} color='success' size='medium' onClick={() => browserHistory.push(formLink)}><Add /></Button>
      {content}
      <Snackbar
        place='tc'
        color='info'
        icon={AddAlert}
        message={message}
        open={note}
        closeNotification={() => this.setState({ note: false, message: '' })}
        close
      />
    </div>;
  }
}

Model.propTypes = {
  socket: PropTypes.object.isRequired,
  params: PropTypes.object,
  route: PropTypes.object,
  dataArray: PropTypes.object.isRequired,
  setModal: PropTypes.func.isRequired,
  clearModal: PropTypes.func.isRequired,
  userType: PropTypes.string.isRequired,
  customModelName: PropTypes.string,
  customBackLink: PropTypes.string,
  customAddLink: PropTypes.string,
  sheetsPath: PropTypes.object,
  customEditCallback: PropTypes.func,
  needBackButt: PropTypes.bool,
  needTitle: PropTypes.bool,
  onLoaded: PropTypes.func
};

Model.defaultProps = {
  needBackButt: true,
  needTitle: true
};

const mapStateToProps = state => ({
  dataArray: state.dataArray,
  userType: state.user.type,
  sheetsPath: state.settings['SHEETS_PATH'] || {}
});

const bindAction = dispatch => ({
  setModal: object => dispatch(setModal(object)),
  clearModal: () => dispatch(clearModal())
});

export default socketConnect(connect(mapStateToProps, bindAction)(Model));
