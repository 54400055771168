import React from 'react';
import { Route, browserHistory, IndexRoute, IndexRedirect } from 'react-router';
/* common */
import DashboardLayout from '../layouts/DashboardLayout/DashboardLayout';
import SignIn from './SignIn';
import NotFound from './ErrorPages/NotFound';
import Unauthorized from './ErrorPages/Unauthorized';
import Test from './Test';

import PassRestore from './PassRestore';

/* admin */
import Dashboard from './Dashboard/Dashboard';
import OrderDetails from './OrderDetails';
import IngredientPrice from './IngredientPrice';
import OrderLogs from './OrderLogs';
import DishLog from './DishLog';

import Users from './Users';
import Form from './Users/Form';
import UserSettings from './Users/Settings';

import SystemSettings from './SystemSettings';

import Blog from './Blog';
import EditPost from './Blog/Editor';

import Customers from './Customers';
import CustomersDetails from './Customers/Details';

import Notifications from './Notifications';
import Reports from './Reports';

import Catalogs from './Catalogs';
import Model from './Model';
import ModelForm from './Model/Form';

import MenuBuilder from './MenuBuilder';
import MBDishes from './MenuBuilder/Dishes';
import MBProgramDetails from './MenuBuilder/Programs/ProgramDetails';
import MBProgramLogs from './MenuBuilder/Programs/Logs';
import MBPrices from './MenuBuilder/Prices';
import MBNewPrices from './MenuBuilder/NewPrices';
import MBDiscountPrices from './MenuBuilder/DiscountPrices';
import MBForm from './MenuBuilder/Form';
import Packaging from './Packaging';
import PAll from './Packaging/All';
import PAvailable from './Packaging/Available';

import Finances from './Finances';
import Sales from './Finances/Sales';
import DailySales from './Finances/DailySales';
import RevenueSales from './Finances/RevenueSales';
import Accounting from './Finances/Accounting';
import Refunds from './Finances/Refunds';
import CarryForward from './Finances/CarryForward';
import PaymentAnalysis from './Finances/PaymentAnalysis';

import Vouchers from './Finances/Vouchers';
import VouchersForm from './Finances/Vouchers/Form';

import StaticPages from './StaticPages';
import EditStaticPage from './StaticPages/Editor';

/* logistic */
import LogisticDashboard from './Logistic/WithDeliveryParnter';

/* kitchen */
import Cooking from './Kitchen';
import Sheet from './Kitchen/Sheet';
import Receipe from './Kitchen/Receipe';
import ViewRecipe from './ViewRecipe';
import Special from './Kitchen/Special';
import EditPlan from './Customers/EditPlan';
import FoodCost from './Kitchen/FoodCost';
import FoodCostAll from './Kitchen/FoodCostAll';
import MarketList from './Kitchen/MarketList';
import CheckoutDetails from './CheckoutDetails';
import CheckoutLogs from './CheckoutLogs';
import ChefsReceipes from './Kitchen/ChefsReceipes';
import ChefsDishForm from './Kitchen/ChefsReceipes/Form';
import ViewChefsReceipe from './Kitchen/ChefsReceipes/ViewChefsReceipe';
import EmirateSlots from './EmirateSlots';

const notAuth = store => {
  return () => {
    let { user } = store.getState();
    if (user.token) {
      // const path = `/${user.type}`;
      const path = `/admin`;
      browserHistory.push(path);
    }
  };
};

const needAuth = (store, roles) => {
  roles = roles ? [...roles, 'admin'] : roles;
  return () => {
    console.log(store.getState());
    const { user,settings } = store.getState();
    const { usersPrivilege } = settings;
    const { pathname } = browserHistory.getCurrentLocation();
    const userType = user.type;
    // console.log(usersPrivilege);
    // console.log(pathname);
    // console.log(userType);
    // if (!user.token || (roles && user.type && !roles.includes(user.type))) {
    //   browserHistory.push('/');
    // }
    if (!user.token) {
      browserHistory.push('/');
    }
    let acpath = pathname.replaceAll("/",'-');
    if(usersPrivilege!=undefined&&usersPrivilege[userType]!=undefined && usersPrivilege[userType][acpath]!=undefined && (!usersPrivilege[userType][acpath]&&acpath!='-admin')){
      console.log(usersPrivilege[userType][acpath]);
      browserHistory.push('/401');
    }
  };
};

const DetailsPlaceholder = () => <div className='customer-item-empty'>
  <p>Click on customer to see details</p>
  <p className='to-arrow'>⤶</p>
</div>;

const noMobiles = (component) => {
  const PlaceHolder = () => <div className='on-mobile'> 
    <p>This page available only on desktop or landscape tablet version</p>
  </div>;
  return window.innerWidth <= 800 ? PlaceHolder : component;
};

const createRoutes = store => {
  return <Route path='/' component={DashboardLayout}>
    <IndexRoute component={SignIn} onEnter={notAuth(store)} />
    <Route path='admin' onEnter={needAuth(store, ['admin'])}>
      <IndexRoute component={Dashboard} />
      <Route path='users/:type(/page/:page(\d+))' >
        <IndexRoute component={Users} />
        <Route path='edit/:id(\d+)' component={Form} />
        <Route path='add' component={Form} />
      </Route>
      <Route path='customers' component={Customers}  onEnter={needAuth(store, ['admin'])}>
        <IndexRoute component={DetailsPlaceholder} />
        <Route path='details/:id' component={CustomersDetails}/>
        <Route path='add' component={Form} />
      </Route>
      <Route path='finances' component={Finances}  onEnter={needAuth(store, ['admin'])}>
        <IndexRedirect to={'sales'} />
        <Route path='accounting' component={Accounting} />
        <Route path='sales' component={Sales} />
        <Route path='dailysales' component={DailySales} />
        <Route path='revenuesales' component={RevenueSales} />
        <Route path='refunds' component={Refunds} />
        <Route path='carry-forward' component={CarryForward} />
        <Route path='payment-analysis' component={PaymentAnalysis} />
        <Route path='vouchers/:type(/page/:page(\d+))'>
          <IndexRoute component={Vouchers} />
          <Route path='edit/:id(\d+)' component={VouchersForm} />
          <Route path='add' component={VouchersForm} />
        </Route>
      </Route>
      <Route path='menu' component={MenuBuilder}  onEnter={needAuth(store, ['admin'])}>
        <IndexRoute component={MBDishes} />
        <Route path='programs' component={noMobiles(MBProgramDetails)} />
        <Route path='programslogs' component={noMobiles(MBProgramLogs)} />
        <Route path='edit/:id(\d+)' component={MBForm} />
        <Route path='add' component={MBForm} />
        <Route path='prices' component={MBPrices} />
        <Route path='newprices' component={MBNewPrices} />
        <Route path='discountprices' component={MBDiscountPrices} />
      </Route>
      <Route path='packaging' component={Packaging}  onEnter={needAuth(store, ['admin'])}>
        <IndexRedirect to='all' />
        <Route path='all' component={PAll} />
        <Route path='available' component={PAvailable} />
      </Route>
      <Route path='model/:modelName(/page/:page(\d+))'  onEnter={needAuth(store, ['admin'])}>
        <IndexRoute component={Model} />
        <Route path='edit/:id' component={ModelForm} />
        <Route path='add' component={ModelForm} />
      </Route>
      <Route path='blog(/page/:page(\d+))' >
        <IndexRoute component={Blog} />
        <Route path='edit/:id(\d+)' component={EditPost} />
        <Route path='add' component={EditPost} />
      </Route>
      <Route path='static-pages' >
        <IndexRoute component={StaticPages} />
        <Route path='edit/:id(\d+)' component={EditStaticPage} />
        <Route path='add' component={EditStaticPage} />
      </Route>
      <Route path='user-setting' component={UserSettings}  onEnter={needAuth(store, ['admin'])}/>
      <Route path='settings' component={SystemSettings}  onEnter={needAuth(store, ['admin'])} />
      <Route path='catalogs' component={Catalogs}  onEnter={needAuth(store, ['admin'])} />
      <Route path='blog' component={Blog}  onEnter={needAuth(store, ['admin'])} />
      <Route path='order-details/:id(\d+)' component={OrderDetails} />
      <Route path='ingredient-price/:id(\d+)' component={IngredientPrice} />
      <Route path='emirate-slot/:id(\d+)' component={EmirateSlots} />
      <Route path='order-logs/:id(\d+)' component={OrderLogs} />
      <Route path='dishlog/:id(\d+)' component={DishLog} />
      <Route path='viewrecipe/:id(\d+)' component={ViewRecipe} />
      <Route path='checkout-details/:id(\d+)' component={CheckoutDetails} />
      <Route path='checkout-logs/:id(\d+)' component={CheckoutLogs} />
    </Route>
    <Route path='logistic' onEnter={needAuth(store, ['logistic'])}>
      <IndexRoute component={noMobiles(LogisticDashboard)} />
    </Route>
    <Route path='kitchen' onEnter={needAuth(store, ['kitchen'])}>
      <IndexRedirect to='cooking' />
      <Route path='cooking' component={Cooking}>
        <IndexRedirect to='sheet' />
        <Route path='sheet' component={Sheet} />
        <Route path='special' component={Special} />
        <Route path='recipe' component={Receipe} />
        <Route path='food-cost' component={FoodCost} />
        <Route path='food-cost-all' component={FoodCostAll} />
        <Route path='market-list' component={MarketList} />
        <Route path='chefs-receipes' component={ChefsReceipes} />
        <Route path='dish-edit/:id' component={ChefsDishForm} />
        <Route path='dish-add' component={ChefsDishForm} />
        <Route path='view-chefs-receipe/:id(\d+)' component={ViewChefsReceipe} />
      </Route>
    </Route>
    <Route path='packager' onEnter={needAuth(store, ['packager'])}>
      <IndexRedirect to='packaging' />
      <Route path='packaging' component={Packaging}>
        <IndexRedirect to='all' />
        <Route path='all' component={PAll} />
        <Route path='available' component={PAvailable} />
      </Route>
    </Route>
    <Route path='404' component={NotFound} />
    <Route path='401' component={Unauthorized} />
    <Route path='pass/:type/:token' component={PassRestore} onEnter={notAuth(store)} />
    <Route path='editplan/:user_id(\d+)/:order_id(\d+)' component={EditPlan} onEnter={needAuth(store)} />
    <Route path='test' component={Test} onEnter={needAuth(store)} />
    <Route path='notifications' component={Notifications}  onEnter={needAuth(store, ['admin'])} />
    <Route path='reports' component={Reports}  onEnter={needAuth(store, ['admin'])} />
    <Route path='*' component={NotFound} onEnter={needAuth(store)} />
  </Route>;
};

export default createRoutes;
