import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { socketConnect } from 'socket.io-react';
import PropTypes from 'prop-types';
import moment from 'moment/moment';
import { AddModal, EditModal, BagpriceModal, PauseModal, DiscountModal, CutleryModal, AdditionalNotesModal, DishtypeModal, MoveModal, UnpauseModal, AddIssueModal, ReasonModal, ReasonPayModal, EditAdditional, RefundPlanModal,ChangeMealPlanModal } from '../Modals';
import { clearModal, setModal } from 'store/modal';
import { spin, stop } from 'store/spinner';
import { clearDefaultDishes } from 'store/defaultDishes';
import { getArrays, parseDates } from 'utils';
import Button from 'components/CustomButtons/Button';
import { Close, CardGiftcard, MonetizationOn, Check } from '@material-ui/icons';
import ToolTip from 'components/ToolTip';
import Text from 'components/Typography/Text';
import { browserHistory } from 'react-router';
import MealPlanButtons from 'components/DropdownButtons';

const classes = { 
  '-1': {
    day: 'day-past',
    actions: 'delivered'
  },
  '0': {
    day: 'day-active',
    actions: 'today'
  },
  '1': {
    day: 'tomorrow',
    actions: 'tomorrow'
  }
};

class TabMealPlan extends Component {
  constructor(props) {
    super(props);
    const { plan, futurePlan, user_id } = props.userData;
    this.initialState = {
      planParams: {},
      mealPlan: {},
      futureMealPlan: {},
      futurePlanParams: {},
      orderIssues: [],
      showModal: false,
      hideDialog: false,
      errors: {},
      dish_notes: {},
      moveStats: {},
      futureMoveStats: {},
      additionalMeals: {},
      futureAdditionalMeals: {},
      futureOrderId:'',
      lastAdditionalBag:'',
      pauses: false,
      pause_is_active:false,
      onhold_id: false,
      onpaused_id: false,
      onhold_future_id: false,
      discountstats: false,
      futurediscountstats: false,
      futurelasteditdby:false,
      futurepreparedby:false,
      lasteditdby:false,
      preparedby:false,
      paymenttran:{},
      isNewFormat: false,
      futurepaymenttran:{},
      mostUsedValues:{}
    };

  
    this.checkHold(user_id);
    this.checkPauseInfinity(user_id);
    this.getMostUsedValues();
    this.state = this.initialState;
    if (plan && plan.order) {
      this.getPlanInfo(plan.order, 0);
      this.getMoveCopyStats(plan.order);
      this.getPauseInfo(plan.order);
      this.getAdditionalMeals(plan.order);
      this.getDiscountStats(plan.order);
    }
    if (futurePlan && futurePlan.order) {
      this.getPlanInfo(futurePlan.order, 1);
      this.getMoveCopyStats(futurePlan.order, 1);
      this.getAdditionalMeals(futurePlan.order, 1);
      this.getDiscountStats(futurePlan.order, 1);
    }
  }

  getPauseInfo = (orderId) => {
    this.props.socket.emit('order', { type: 'getPause', data: { orderId: orderId } });
  };

  getPlanInfo = (orderId, is_future=0) => {
    this.props.socket.emit('order', { type: 'get', data: { orderId: orderId, isFuture: is_future } });
  };

  getMoveCopyStats = (orderId, is_future=0) => {
    this.props.socket.emit('order', { type: 'getMoveStats', data: { orderId: orderId, is_future: is_future } });
  }

  getDiscountStats = (orderId, is_future=0) => {
    this.props.socket.emit('order', { type: 'getDiscountStats', data: { orderId: orderId, is_future: is_future } });
  }

  unpause = (orderId) => {
    this.props.socket.emit('order', { type: 'unpause', data: { orderId: orderId } }); 
  }

  delAdditional = (stamp, name, order_id) => {
    const {userData} = this.props;

    this.props.socket.emit('order', { type: 'delAdditional', data: { orderId: order_id, 'date': stamp, 'name': name } });
  }

  setDiscount = (orderId, coupon, comment, is_future) => {
    this.props.socket.emit('order', { type: 'setDiscount', data: { orderId: orderId, coupon: coupon, comment: comment, is_future: is_future } });
  }

  getAdditionalMeals = (orderId, is_future=0) => {
    this.props.socket.emit('order', { type: 'getAdditionalMeals', data: { orderId: orderId, is_future: is_future } });
  }

  setOnHold = (status, orderId, is_future=false) => {
    this.props.socket.emit('order', { type: 'setOnHold', data: { orderId: orderId, status: status, 'is_future': is_future} });
  }

  checkHold = (userId) => {
    this.props.socket.emit('order', { type: 'checkHold', data: { userId: userId } });
  }
  checkPauseInfinity = (userId) => {
    this.props.socket.emit('order', { type: 'checkPauseInfinity', data: { userId: userId } });
  }
  getMostUsedValues = () => {
    this.props.socket.emit('order', { type: 'mostUsedDietMealType'});
  };
  listUpdate = () => {
      this.props.socket.emit('profile', {
          type: 'list',
          data: {
            userType: 'customer',
            offset: 0,
            limit: 10000,
            filters: {activePlan: {value: true}},
          }
      });
  }

  userRefresh = (userId) => {
    this.props.socket.emit('profile', {
        type: 'get',
        data: {
            userId
        }
    });
  }

  componentWillMount() {
    this.props.socket.on('order', this.listener);
  }

  componentWillUnmount() {
    this.props.socket.removeListener('order', this.listener);
  }

  addDishType(customer_id, date, order_id) {
    const {dataArray} = this.props;
    const {typeList} = dataArray;
    
    var types = {};
    Object.keys(typeList).map( (id, el) => {
      types[id] = typeList[id].title;
    });

    this.showModal('addtype', {types, customer_id, order_id, date});

  }

  componentDidMount() {
    const { dataArray, socket, clearDefaultDishes } = this.props;
    const types = ['planList','planDayRange', 'typeList', 'dietList', 'slotsList', 'productsFull', 'products', 'priceList', 'commonVouchers','paymentMethodsFull'].filter(el => !(el in dataArray));
    getArrays(socket, types);
    clearDefaultDishes();
  }
  
  downloadPdf(id, order_id = false,$is_arabic=false) {
    window.open(
      'https://basiligo:basiligo2019!@report.basiligo.ae/admin/site/getpdf?user_id='+id+'&order_id='+order_id+'&is_arabic='+$is_arabic,
      '_blank' // <- This is what makes it open in a new window.
    );
  }

  viewwebPdf(id, order_id = false) {
    window.open(
      'https://basiligo:basiligo2019!@report.basiligo.ae/admin/site/pdf?user_id='+id+'&order_id='+order_id,
      '_blank' // <- This is what makes it open in a new window.
    );
  }

  downloadDoc(id, order_id = false) {
    window.open(
      'https://basiligo:basiligo2019!@report.basiligo.ae/admin/site/doc?user_id='+id+'&order_id='+order_id,
      '_blank' // <- This is what makes it open in a new window.
    );
  }

  dailymenuView(id, date, order_id=false) {
    window.open(
      'https://basiligo:basiligo2019!@report.basiligo.ae/admin/site/menu?user_id='+id+'&date='+date+'&order_id='+order_id,
      '_blank' // <- This is what makes it open in a new window.
    );
  }

  dailymenuPdf(id, date, order_id=false) {
    window.open(
      'https://basiligo:basiligo2019!@report.basiligo.ae/admin/site/menupdf?user_id='+id+'&date='+date+'&order_id='+order_id,
      '_blank' // <- This is what makes it open in a new window.
    );
  }

  setRenew(order_id, stat, reason = null, comment = null) {
    const {socket} = this.props;
    const data = {orderId: order_id, stat: stat, 'reason': reason, 'comment': comment};
    socket.emit('order', { type: 'setRenew', data });
    this.props.clearModal();
  }

  setCutlery (order_id, cutlery) {
    const {socket} = this.props;
    const data = {orderId: order_id, 'cutlery': cutlery};
    socket.emit('order', { type: 'setCutlery', data });
    this.props.clearModal();
  }

  setPayStatus(order_id, reason = null) {
    const {socket, userData, dataArray} = this.props;
    const {user_id} = userData;
    const { paymentMethodsFull } = dataArray;
    const paymentData= paymentMethodsFull[reason] || {};
    const paymentMethod = paymentData.type || null;
    const data = {orderId: order_id, 'paymentMethod': paymentMethod,'payment_method_id':+reason,'payment_data':paymentData, 'userId': user_id};
    socket.emit('order', { type: 'setPayStatus', data });
    this.props.clearModal();
  }

  setOrderTime(orderId, slotId = null) {
    const {socket, userData} = this.props;
    const {user_id} = userData;
    const data = {orderId: orderId, userId: user_id, slotId: slotId};
    //console.log(data);
    socket.emit('order', { type: 'setOrderTime', data });
    this.props.clearModal();
  }

  listener = ({ type, data }) => {
    
    const { orderId, planParams, message, errors, dish_notes, is_future, mealPlan, userId, preparedby, lasteditdby,paymenttran} = data;
    switch (type) {
      case 'checkHoldOk':
          var future = false;
          if (data.futureId) {
            future = data.futureId;
          }
          this.setState({
            onhold_id: data.orderId,
            onhold_future_id: future,
          });
          if(data.orderId){
            this.getPauseInfo(data.orderId);
          }
          
      break;
      case 'toggleSingleBoxOk':
        this.getPlanInfo(data.orderId);
      break;
      case 'checkPauseInfinityOk':
        this.setState({
          onpaused_id: data.orderId
        });
        if(data.orderId){
         this.getPauseInfo(data.orderId);
        }
    break;
      case 'getDiscountStatsOk':
        if (data.is_future == 1) {
          this.setState({
            futurediscountstats: data
          });
        } else {
          this.setState({
            discountstats: data
          });
        }
      break;
      case 'setDiscountOk':
      case 'setCutleryOk':
        this.props.stop();
            this.props.setModal({
              display: true,
              width: '25%',
              height: '10vh',
              content: <div className='text-center' style={{ padding: 'calc(5vh - 1.5em)' }}>
                <Text color='success' paragraph style={{ fontSize: 'larger' }}>{message}</Text>
              </div>
            });
      break;
      case 'addDishTypeOk':
        this.props.stop();
        //alert('ok');
        this.setState({showModal: false});
        this.userRefresh(userId);
        this.getPlanInfo(orderId);
      break;
      case 'setOnHoldOk':
        this.props.stop();
        if (is_future) {
          this.userRefresh(userId);
          this.getPlanInfo(orderId, 1);
        } else {
          this.userRefresh(userId);
          this.getPlanInfo(orderId);
        }
      break;
      case 'getPauseOk':
          /*console.log('----------');
          console.log('get pause');
          console.log(data);    
          console.log('----------');*/
          if (typeof(data.end_date) != 'undefined') {
            this.setState({
                // pauses: true,
                pauses: data.pauses,
                pause_is_active:true,
                pause_manager: data.manager_id,
                pause_start: data.date_set,
                pause_end: data.end_date,
            });
          } else {
            this.setState({
                pauses: data.pauses,
                pause_is_active:false,
                pause_manager: null,
                pause_start: null,
                pause_end: null,
            });
          }
      break;
      case 'setPauseOk':
        this.props.stop();
          //const {message} = data;
          /*console.log('---------');
          console.log('pause set');
          console.log(data.orderId);
          console.log(data.userId);
          console.log('---------');*/
          if (message) {
            //this.userRefresh(data.userId);
            this.getPlanInfo(data.orderId);
            this.getPauseInfo(data.orderId);
            this.getAdditionalMeals(data.orderId);
            this.getMoveCopyStats(data.orderId);
            this.props.setModal({
              display: true,
              width: '25%',
              height: '10vh',
              content: <div className='text-center' style={{ padding: 'calc(5vh - 1.5em)' }}>
                <Text color='success' paragraph style={{ fontSize: 'larger' }}>{message}</Text>
              </div>
            });
          }
      break;
      case 'unpauseOk':
        this.props.stop();
          const {daysDiff, orderId, start,enddate} = data;
          this.getPauseInfo(orderId);
          this.userRefresh(userId);
          this.getPlanInfo(orderId);
          this.showModal('unpause', {daysDiff, orderId, start,enddate});
      break;
      case 'afterPauseSetOk':
        this.props.stop();
          //console.log(data);
          this.getPauseInfo(orderId);
          this.userRefresh(userId);
          this.getPlanInfo(orderId);
          this.getAdditionalMeals(orderId);
          this.getMoveCopyStats(orderId);
          this.props.setModal({
            display: true,
            width: '25%',
            height: '10vh',
            content: <div className='text-center' style={{ padding: 'calc(5vh - 1.5em)' }}>
              <Text color='success' paragraph style={{ fontSize: 'larger' }}>{data.message}</Text>
            </div>
          });
      break;
      case 'sendmailOk':
        this.props.stop();
        this.props.setModal({
          display: true,
          width: '25%',
          height: '10vh',
          content: <div className='text-center' style={{ padding: 'calc(5vh - 1.5em)' }}>
            <Text color='success' paragraph style={{ fontSize: 'larger',padding:'20px' }}>{data.message}</Text>
          </div>
        });
      break;
      case 'getAdditionalMealsOk':
        console.log('AAAAAAAAAAA');
          console.log(data);
          console.log('AAAAAAAAAAA');
          this.setState({
            lastAdditionalBag: data.bagCount,
            futureOrderId: data.orderId
          })
          if (data.is_future) {
            this.setState({
              futureAdditionalMeals: data.dishes,
            });    
          } else {
            this.setState({
              additionalMeals: data.dishes,
            });    
          }
      break;
      case 'getMoveStatsOk':
        this.setState({
          moveStats: data
        });
      break;
      case 'getMoveStatsFutureOk':
        this.setState({
          futureMoveStats: data
        });
      break;
      case 'getOk':
        /*console.log('get done');
        console.log(data);*/
        this.getAdditionalMeals(data['planParams'].id, 1);
        const { diet, mealType } = planParams || {};
        this.getCustomPlans(diet, mealType);
        if (is_future === '1') {
          this.setState({
            futureMealPlan : mealPlan,
            futurePlanParams : planParams,
            futurepreparedby:preparedby,
            futurelasteditdby:lasteditdby,
            futurepaymenttran:paymenttran
          });
        } else {
          this.setState(data);
        }
        this.setState({
          dish_notes: dish_notes,
        });
        break;
      case 'renewDeclineOk':
        this.props.stop();
         this.setState({renewed: false});
         this.userRefresh(userId);
      break;
      case 'setRenewOk':
        this.props.stop();
        this.setState({renewed: true});
        this.listUpdate();
        this.props.getUserInfo(this.props.userData.user_id);
        break;
      case 'setPayStatusOk':
      case 'setOrderTimeOk':
        this.props.stop();
        this.userRefresh(userId);
        this.listUpdate();
        this.props.setModal({
          display: true,
          width: '25%',
          height: '10vh',
          content: <div className='text-center' style={{ padding: 'calc(5vh - 1.5em)' }}>
            <Text color='success' paragraph style={{ fontSize: 'larger' }}>{message}</Text>
          </div>
        });
      break;
      case 'addAdditionalMealsNotesOk':
        this.props.stop();
        this.props.setModal({
          display: true,
          width: '25%',
          height: '10vh',
          content: <div className='text-center' style={{ padding: 'calc(5vh - 1.5em)' }}>
            <Text color='success' paragraph style={{ fontSize: 'larger' }}>{data.message}</Text>
          </div>
        });
      break;
      case 'cancelOk':
        case 'sendPayemntLinkMailOk':
        this.props.stop();
        this.props.setModal({
          display: true,
          width: '25%',
          height: '10vh',
          content: <div className='text-center' style={{ padding: 'calc(5vh - 1.5em)' }}>
            <Text color='success' paragraph style={{ fontSize: 'larger' }}>{message}</Text>
          </div>
        });
        
        this.getPlanInfo(orderId);        
        this.props.getUserInfo(this.props.userData.user_id);
        break;
      case 'saveRefundOk':
        this.props.stop();
          console.log('this.props.userData.user_id');
          console.log(this.props.userData.user_id);
          
          this.props.setModal({
            display: true,
            width: '25%',
            height: '10vh',
            content: <div className='text-center' style={{ padding: 'calc(5vh - 1.5em)' }}>
              <Text color='success' paragraph style={{ fontSize: 'larger' }}>{message}</Text>
            </div>
          });
          
          this.getPlanInfo(orderId);        
          this.props.getUserInfo(this.props.userData.user_id);
          break;  
      case 'setOk':
        this.props.stop();
        this.props.getUserInfo(this.props.userData.user_id);
        this.props.socket.emit('profile', {
          type: 'list',
          data: {
            userType: 'customer',
            offset: 0,
            limit: 10000,
            filters: {activePlan: {value: true}},
          }
        });
        this.props.clearModal();
        break;
      case 'editplanOk': 
      case 'changeplanOk':
        this.props.stop();
        this.props.getUserInfo(this.props.userData.user_id);
        this.props.socket.emit('profile', {
          type: 'list',
          data: {
            userType: 'customer',
            offset: 0,
            limit: 10000,
            filters: {activePlan: {value: true}},
          }
        });
        this.getPlanInfo(orderId);
        this.props.clearModal();
      break;  
      case 'modifyOk':
        this.props.stop();
        this.getPlanInfo(orderId);
        this.props.clearModal();
        break;
      case 'moveOrderOk':
      case 'delDayOk':
      case 'addmealsOk':
      case 'addmealsdayOk':
      case 'copyOrderOk':
      case 'delAdditionalOk':
      case 'editadditionalOk':
      case 'complimentaryOk':
      case 'chargedOk':
      case 'changestartdateOk':
      case 'changepriceOk':
        this.props.stop();
        this.props.clearModal();
        if (data.is_future === '1') {
          //console.log('future plan');
          this.getPlanInfo(data.orderId, 1);
          this.getMoveCopyStats(data.orderId, 1);
          this.getAdditionalMeals(data.orderId, 1);
        } else {
          //console.log('existed plan');
          this.getPlanInfo(data.orderId);
          this.getMoveCopyStats(data.orderId);
          this.getAdditionalMeals(data.orderId);
        }
        this.props.getUserInfo(this.props.userData.user_id);
        this.userRefresh(userId);
        this.listUpdate(); 
      break; 
      case 'setBagPriceOk':
        this.props.stop();
        console.log('here')
        this.props.clearModal();
      break;
      case 'toggleDeliveredOk':
        this.props.stop();
        this.getPlanInfo(orderId);
        break;
      case 'getLastMealCutleryOk':
        this.setState({
          planParams: { ...planParams, ['cutlery']: data.cutlery } 
        });
        this.showModal('add');
      break;
      case 'mostUsedDietMealTypeOk':
        this.setState({mostUsedValues:data});
      break;
      default:
        this.props.stop();
        if (errors) {
          this.setState({ errors });
        }
        if (type.includes('Err')) {
          console.error({ type, data });
        }
        break;
    }
  };

  componentWillReceiveProps(next) {
    const { userData: nextUserData } = next;
    const { userData: curUserData, userData } = this.props;
    if (nextUserData.user_id !== curUserData.user_id) {
      this.getMostUsedValues();
      const { plan, futurePlan } = nextUserData;
      if (plan && plan.order) {
        this.getPlanInfo(plan.order);
        this.getPauseInfo(plan.order);
        this.getMoveCopyStats(plan.order);
        this.getAdditionalMeals(plan.order);
        this.getDiscountStats(plan.order);
      } else {
        this.setState(this.initialState);
      }

      if (futurePlan && futurePlan.order) {
        this.getPlanInfo(futurePlan.order, 1);
      }
    }
    const {user_id} = userData;
    this.checkHold(user_id);
    this.checkPauseInfinity(user_id);
  }

  savePlan = (dataObj, _type, build = 'newPlan', bagPrice=false, clearPrice=false) => {
    _type !== 'pause' && this.props.spin();
    const { mealPlan: detailsData, planParams, skipped } = dataObj;
    const { id: orderId, deliveryTime, cutlery } = planParams;
    const { dataArray } = this.props;
    const { paymentMethodsFull } = dataArray;
    const clearData = data => {
      return Object.keys(data).reduce((acc, date) => {
        const { cooked, packed, delivered, total, ...rest } = data[date];
        return { ...acc, [date]: rest };
      }, {});
    };
    //this.props.stop();
    switch (_type) {
      case 'edit':
        this.props.socket.emit('order', {
          type: 'modify',
          data: {
            orderId,
            deliverySlot: deliveryTime,
            cutlery,
            detailsData: clearData(detailsData),
            skipped
          }
        });
        break;
      case 'add':
      case 'editplan':
        const { userData } = this.props;
        const {planParams} = dataObj;
        const { mealPlan, diet, deliverySlot, price, payed, voucher_field, coupon_id, pricetype, date, mealType,days_count,exclude_week_day } = planParams;
        const paymentDataEdit= paymentMethodsFull[planParams.payment_method_id] || {};
        const paymentMethodEdit = paymentDataEdit.type || planParams.paymentMethod;
        const orderData = {
          user_id: userData.user_id,
          [(voucher_field || 'coupon_id')]: +coupon_id || null,
          paymentMethod: paymentMethodEdit,
          payment_method_id: +planParams.payment_method_id,
          payment_data:paymentDataEdit,
          mealPlan_id: +mealPlan,
          price,
          cutlery,
          diet_id: +diet,
          shipping: 0,
          payed: +!!payed,
          pricetype,
          days_count:parseInt(days_count),
          exclude_week_day,
          mealType
        };
        const details = Object.keys(detailsData).reduce((data, unix) => {
          const date = detailsData[unix];
          const datePlan = Object.keys(date).reduce((acc, cur) => [...acc, {
            date: +unix,
            'mealType_id': +cur,
            'diet_id': +diet,
            'dish_id': +date[cur]
          }], []);
          return [...data, ...datePlan];
        }, []);
        if(_type=='editplan'){
          // console.log(dataObj);
          const {addMealtype,removeMealtype,oldorderId} = dataObj;
            this.props.socket.emit('order', {
              type: 'editplan',
              data: {
                addMealtype:addMealtype,
                removeMealtype:removeMealtype,
                orderId:oldorderId,
                orderData,
                detailsData: details,
                deliverySlot,
                build,
                bagPrice: bagPrice,
                clearPrice: clearPrice,
                date
              }
            });
        }else{
          this.props.socket.emit('order', {
            type: 'set',
            data: {
              orderData,
              detailsData: details,
              deliverySlot,
              build,
              bagPrice: bagPrice,
              clearPrice: clearPrice,
            }
          });
        }
        break;
        case 'changeplan':
          const { userData:userData1 } = this.props;
          const {prevPlanParams,planParams:planParamsVal} = dataObj;
          const paymentData= paymentMethodsFull[planParamsVal.payment_method_id] || {};
          const paymentMethod = paymentData.type || planParamsVal.paymentMethod;
          const orderData1 = {
            user_id: userData1.user_id,
            [(voucher_field || 'coupon_id')]: +coupon_id || null,
            paymentMethod: paymentMethod,
            payment_method_id: +planParamsVal.payment_method_id,
            payment_data:paymentData,
            mealPlan_id: +mealPlan,
            price:planParamsVal.price,
            diet_id: planParamsVal.diet,
            shipping: 0,
            payed: +!!payed,
            pricetype:planParamsVal.pricetype
          };
          const prevpaymentData= paymentMethodsFull[prevPlanParams.payment_method_id] || {};
          const prevpaymentMethod = paymentData.type || prevPlanParams.paymentMethod;
          const prevorderData = {
            user_id: userData1.user_id,
            [(voucher_field || 'coupon_id')]: +coupon_id || null,
            paymentMethod: prevpaymentMethod,
            payment_method_id: +prevPlanParams.payment_method_id,
            payment_data:prevpaymentData,
            mealPlan_id:prevPlanParams.mealPlan,
            price:prevPlanParams.price,
            full_price:prevPlanParams.full_price,
            diet_id: prevPlanParams.diet,
            shipping: 0,
            payed: +!!payed
          };
          const details1 = Object.keys(detailsData).reduce((data, unix) => {
            const date = detailsData[unix];
            const datePlan = Object.keys(date).reduce((acc, cur) => [...acc, {
              date: +unix,
              'mealType_id': +cur,
              'diet_id': +planParamsVal.diet,
              'dish_id': +date[cur]
            }], []);
            return [...data, ...datePlan];
          }, []);
          this.props.socket.emit('order', {
            type: 'changeplan',
            data: {
              orderId:prevPlanParams.id,
              orderData:orderData1,
              prevorderData,
              detailsData: details1,
              bagPrice: bagPrice,
              clearPrice: clearPrice,
            }
          });
        break; 
      /*case 'pause':
        //this.setState({ mealPlan: detailsData, planParams });
        this.setState({ mealPlan: detailsData });
        setTimeout(() => this.showModal('edit'), 200);
        break;*/
    }
  };

  saveBag = (orderId, price) => {
    console.log(orderId);
    console.log(price);
    this.props.socket.emit('order', {
          type: 'setBagPrice',
          data: {
            orderId: orderId,
            price: price,
          }
    });
  }

  savePause = (startDate, endDate) => {
    const {planParams} = this.state;
    this.props.spin();
    if (startDate) {
      startDate = startDate.unix();
    } else {
      startDate = 'null';
    }
    if (endDate !== 'infinity') {
      endDate = endDate.unix();
    }
    this.props.socket.emit('order', {
          type: 'setPause',
          data: {
            orderId: planParams.id,
            startDate: startDate,
            endDate: endDate,
          }
    });
    //setTimeout(() => this.showModal('edit'), 200);
  }

  saveRefund = (orderId,cancelFrom,refundDayCount, cancelAmount,creditAmount,cancellationFee,creditCardFee,removeDiscounts,removeBagFee,reundAmount,reasonForCancellation,cancellationDescription,removeCompday,consumeDays,price) => {
    this.props.spin();
    const {planParams} = this.state;
    const { user_id: userId } = this.props.userData;
    this.props.socket.emit('order', {
          type: 'saveRefund',
          data: {
            orderId: orderId,
            userId:userId,
            cancelled_from: cancelFrom,
            refund_day_count: refundDayCount,
            cancellation_amount: cancelAmount,
            credit_card_amount: creditAmount,
            cancellation_fee: cancellationFee,
            credit_card_fee: creditCardFee,
            is_remove_discount: removeDiscounts,
            is_remove_bag_price: removeBagFee,
            reund_amount: reundAmount,
            reason: reasonForCancellation,
            reason_description: cancellationDescription,
            is_remove_comp: removeCompday,
            consumed_days: consumeDays,
            totalprice: price,
          }
    });
    //setTimeout(() => this.showModal('edit'), 200);
  }

  getCustomPlans = (diet_id, mealTypes) => {
    this.props.socket.emit('custom_plan', { type: 'get', data: { diet_id, mealTypes } });
    this.props.socket.emit('default_dishes', { type: 'get', data: { dietId: diet_id, types: mealTypes } });
  };

  saveNote = (order_id, user_id, dish_id, note_text, date) => {
    this.props.socket.emit('default_dishes', { type: 'setNote', data: { order_id: order_id, user_id: user_id, dish_id: dish_id, note_text: note_text, date: date } });
  }

  renewDecline = (orderId) => {
    this.props.socket.emit('order', { type: 'renewDecline', data: {orderId: orderId} });
  }

  cancelPlan = (orderId, makeCoupon) => {
    const { user_id: userId } = this.props.userData;
    this.props.socket.emit('order', { type: 'cancel', data: { orderId, makeCoupon, userId } });
  };

  removePlan = (orderId) => {
    this.props.socket.emit('order', { type: 'removePlan', data: { order_id: orderId } });
  };
  sendMail = (orderId, is_revised) => {
    this.props.spin();
    const { user_id: userId } = this.props.userData;
    this.props.socket.emit('order', { type: 'sendmail', data: { orderId, is_revised, userId } });
  };
  toggleSingleBox = (order_id) => {
    this.props.socket.emit('order', { type: 'toggleSingleBox', data: { orderId: order_id } });
  }
  sendPayemntLinkMail = (orderId) => {
    this.props.spin();
    this.props.socket.emit('order', { type: 'sendPayemntLinkMail', data: { orderId } });
  };
  viewPaymentTran = (paymenttran)=>{
    const content=<div className='paymentinfo'>
        <ul>
          <li>Status : {(paymenttran.status=='init')?'Mail Sent':paymenttran.status}</li>
          <li>Amount : {paymenttran.price/100} AED</li>
          {paymenttran.status=='Completed' && <li>Paid Date : {moment.unix(paymenttran.paid_date).format('LLL')}</li>}
          <li>Send At :  {moment.unix(paymenttran.create_at).format('LLL')}</li>
          <li>Update At :  {moment.unix(paymenttran.update_at).format('LLL')}</li>
        </ul>
    </div>;
    this.props.setModal({
      display: true,
      width: '50%',
      height: '10vh',
      content: content
    });
  }
  getLastMealCutlery = (userId,isNewFormat) =>{ // At the time of add new plan set old cutlery value
    this.setState({isNewFormat});
    this.props.socket.emit('order', { type: 'getLastMealCutlery', data: { userId } });
  };
  showModal = (type, extraData, is_future=false) => {
    const { setModal, userData, holidays, remainDeliveries } = this.props;
    var {dataArray} = this.props;
    const { mealPlan, planParams,futureMealPlan, futurePlan, futurePlanParams, errors,pause_start, pause_end ,moveStats,pauses,isNewFormat,mostUsedValues,discountstats,futurediscountstats } = this.state;
    console.log(mostUsedValues);
    const excludedDates = parseDates(holidays);
    const props = {
      width: '55%',
      height: '56vh',
      display: true
    };
    const Nomealplamodal = () => {
        return <div><p>THE MEAL PLAN IS NOT PAID</p></div>
    }
    const modalProps = {
          build: 'newPlan',
          save: this.savePlan,
          errors,
          userData,
          dataArray,
          excludedDates,
          isNewFormat
    };
    let planDetails = (!is_future)?mealPlan:futureMealPlan;
    modalProps.mostUsedValues=mostUsedValues;
    if(type === 'add'){
      modalProps.prevPlanParams = (!is_future)? planParams || {}:futurePlanParams||{};
    }else if (type === 'renew' || type === 'renewNew') {  
      modalProps.build = 'renew';
      modalProps.minDate = +Math.max(...Object.keys(planDetails || {})) + 3600 * 24;
      modalProps.prevPlanParams = (!is_future)? planParams || {}:futurePlanParams||{};
      modalProps.stamps = Object.keys(planDetails) || {};
      modalProps.isNewFormat = (planParams.days_count>0 || type == 'renewNew')?true:false;
      type = 'add';
    }else if(type === 'editplan'||type === 'changeplan'){
      modalProps.build = 'editplan';
      modalProps.minDate = +Math.min(...Object.keys(planDetails || {}));
      modalProps.prevPlanParams = (!is_future)? planParams || {}:futurePlanParams||{};
      modalProps.stamps = Object.keys(planDetails) || {};
    }
    switch (type) {
      case 'editadditional':
        props.content = <EditAdditional
         data={extraData}
         socket={this.props.socket}
         save={ (stamp, name, meal_type, old_id, new_id, orderId) => {
          this.props.socket.emit('order', { type: 'editadditional', data: { stamp, name, meal_type, old_id, new_id, orderId } });
         }}
        />;
      break;
      case 'edit':
        props.content = <EditModal
          {...modalProps}
          mealPlan={mealPlan}
          planParams={planParams}
          saveNote={this.saveNote}
          dish_notes={this.state.dish_notes}
        />;
        break;
      case 'add':
        props.content = <AddModal
          {...modalProps}
          getCustomPlans={this.getCustomPlans}
        />;
        props.width = '75%';
        props.height = '60vh';
        break;
      case 'editplan':
        props.content = <AddModal
          {...modalProps}
          getCustomPlans={this.getCustomPlans}
        />;
        props.width = '75%';
        props.height = '60vh';
        break;    
      case 'changeplan':
        modalProps.build = 'changeplan';
        props.content = <ChangeMealPlanModal
          {...modalProps}
          getCustomPlans={this.getCustomPlans}
        />;
        props.width = '75%';
        props.height = '60vh';
        break;  
      case 'pause':

        props.content = <PauseModal
            {...modalProps}
            mealPlan={mealPlan}
            planParams={planParams}
            savePause={this.savePause}
        />;
        
        props.width = '400px';
        props.height = '60vh';
        break;
      case 'refund':
          // this.getSkipDayInfo(planParams.id);
          props.content = <RefundPlanModal
              {...modalProps}
              mealPlan={mealPlan}
              planParams={planParams}
              pauses = {pauses}
              pause_start={pause_start}
              pause_end={pause_end}
              moveStats={moveStats}
              discountstats={discountstats}
              saveRefund={this.saveRefund}
          />;
          
          props.width = '75%';
          props.height = '60vh';
        break;
        case 'futurerefund':
          // this.getSkipDayInfo(planParams.id);
          props.content = <RefundPlanModal
              {...modalProps}
              mealPlan={futureMealPlan}
              planParams={futurePlanParams}
              pauses={pauses}
              pause_start={pause_start}
              pause_end={pause_end}
              moveStats={moveStats}
              discountstats={futurediscountstats}
              skipDays={skipDays}
              saveRefund={this.saveRefund}
          />;
          
          props.width = '75%';
          props.height = '60vh';
        break;
      case 'notpaid':
        props.content =<Nomealplamodal />;
        props.width = '640px';
        props.height = '480px';
      break;  
      case 'bagprice':

        props.content = <BagpriceModal
            {...modalProps}
            orderId={extraData.orderId}
            bagprice={extraData.bagPrice}
            save={this.saveBag}
        />;
        
        props.width = '400px';
        props.height = '60vh';
        break;
      case 'copy':
      case 'move':
      case 'addmeals':
      case 'complimentary':
      case 'charged':
      case 'changestartdate':
      case 'changeprice':
        var {dataArray} = this.props;
        var {lastAdditionalBag} = this.state;
        
        var thetype = type;
        let days_count =0;
        let exclude_week_day =null;
        if (is_future) {
          days_count=futurePlanParams.days_count;
          exclude_week_day=futurePlanParams.exclude_week_day;
          var planId = futurePlanParams.id;
          var pricetype1 = futurePlanParams.pricetype;
          var allBusyDates = [
            ...(remainDeliveries || []).map(({ date }) => date),
            ...Object.keys(futurePlan || {})
          ].reduce((acc, stamp) => { 
            const date = moment.unix(stamp).format('DD/MM/YYYY');
            return acc.includes(date) ? acc : [...acc, date];
          }, excludedDates);
        } else {
          days_count=planParams.days_count;
          exclude_week_day=planParams.exclude_week_day;
          var pricetype1 = planParams.pricetype;
          var allBusyDates = [
            ...(remainDeliveries || []).map(({ date }) => date),
            ...Object.keys(mealPlan || {})
          ].reduce((acc, stamp) => { 
            const date = moment.unix(stamp).format('DD/MM/YYYY');
            return acc.includes(date) ? acc : [...acc, date];
          }, excludedDates);
        }
        if(thetype == 'addmeals'){
          var currentDateSelect = moment.unix(extraData.date).format('MM/DD/YYYY');
        }
        if (is_future) {
          const { mealPlan: planId } = futurePlanParams || {};
        } else {
          const { mealPlan: planId } = planParams || {};
        }

        const { planList } = dataArray;
        let exclude_weeks= (days_count>0)?exclude_week_day:(planList && planList[planId] && planList[planId]['excludeWeekDay']) || [];
        const excluded =(thetype != 'move')?exclude_weeks:[];
        var includesDay = Array(7).fill(0).map((el, key) => key).filter(el => !excluded.includes(el));

        if (thetype == 'addmeals') {
          allBusyDates = [];
          includesDay = Array(7).fill(0).map((el, key) => key);
        }

        props.content = <MoveModal
          excludedDates={allBusyDates}
          includedDays={includesDay}
          ifComplimentary={thetype}
          freezeDate= {currentDateSelect}
          lastBagCount = {lastAdditionalBag && lastAdditionalBag[extraData.date] && lastAdditionalBag[extraData.date].lastBag || 1}
          pricetype={pricetype1}
          save={ (newDate, reason=false, comreason=false, addmealsday=false, deliveryName=false, pricetype=false) => {
            const { type: actionType, ...rest } = extraData;
            var type = { order: 'moveOrder', remain: 'moveRemain' }[actionType];
            if (thetype == 'copy') {
              type = { order: 'copyOrder', remain: 'moveRemain' }[actionType];
            }
            if (thetype == 'addmeals') {
              type = { order: 'addmeals', remain: 'moveRemain' }[actionType];
            }

            if (thetype == 'complimentary') {
              type = { order: 'complimentary', remain: 'moveRemain'}[actionType]; 
              deliveryName=null;
            }

            if (thetype == 'charged') {
              type = { order: 'charged', remain: 'moveRemain'}[actionType]; 
              deliveryName=null;
            }
            if (thetype == 'changeprice') {
              type = 'changeprice'; 
            }
            if (thetype == 'changestartdate') {
              type = 'changestartdate'; 
            }

            this.props.clearModal();
            
            var data = { ...rest, newDate, reason, comreason, addmealsday, deliveryName, is_future, pricetype}
            if (is_future) {
              data.order_id = futurePlanParams.id;
            }

            this.props.socket.emit('order', { type, data: data });
          }}

        />;
        props.width = '400px';
        props.height = '57vh';
        break;
      
      case 'issue':
        props.content = <AddIssueModal {...extraData} save={(comment) => {
          this.props.socket.emit('issues', { type: 'set', data: { ...extraData, comment } });
        }} />;
        props.width = '600px';
        props.height = '40vh';
        break;
      case 'paystatus': 
        //const orderId = extraData;
        var selectedVal = is_future?futurePlanParams.payment_method_id:planParams.payment_method_id;
        props.content = <ReasonPayModal dataArray={dataArray} selectedVal={(selectedVal)?selectedVal.toString():''} orderId={extraData} save={ (order_id, reason) => {
          this.setPayStatus(order_id, reason);
          this.setState({showModal: false});
        }} />;
        props.width = '640px';
        props.height = '480px';
      break;
      case 'deliverytime':
        //const orderId = extraData;
        var {dataArray} = this.props;
        var selectedVal = is_future?futurePlanParams.slot_id:planParams.slot_id;
        props.content = <ReasonPayModal dataArray={dataArray} selectedVal={(selectedVal)?selectedVal.toString():''} type={'deliverytime'} orderId={extraData} save={ (order_id, reason) => {
          this.setOrderTime(order_id, reason);
          this.setState({showModal: false});
        }} />;
        props.width = '640px';
        props.height = '480px';
      break;
      case 'reason':
        props.content = <ReasonModal orderId={extraData} save={(order_id, reason, comment) => {
          this.setRenew(order_id, 0, reason, comment);
          this.setState({showModal: false});
        }} />;
        props.width = '640px';
        props.height = '480px';
      break;

      case 'cutlery':
        props.content = <CutleryModal orderId={extraData} save={(order_id, cutlery) => {
          this.setCutlery(order_id, cutlery);
          this.setState({showModal: false});
        }} />;
        props.width = '640px';
        props.height = '480px';
      break;

      case 'discount':
        var {dataArray} = this.props;
        const {commonVouchers} = dataArray;
        props.content = <DiscountModal vouchers={commonVouchers} orderId={extraData.orderId} isFuture={extraData.isFuture} save={(order_id, coupon, comment, is_future) => {
          this.setDiscount(order_id, coupon, comment, is_future);
          this.setState({showModal: false});
        }} />;
        props.width = '640px';
        props.height = '480px';
      break;
      case 'addnotesdish':
          props.content = <AdditionalNotesModal dishId={extraData.dishId} orderId={extraData.orderId} date={extraData.stamp} dishName={extraData.dishName} save={(dishId, orderId, note, date) => {
          
          this.setState({showModal: false});

          this.props.socket.emit('order', {
              type: 'addAdditionalMealsNotes',
              data: {
                'note': note,
                'orderId': orderId,
                'dishId': dishId,
                'date': date,
              }
          });
          
          }} />;
          props.width = '640px';
          props.height = '480px';
      break;
      case 'addtype':
        props.content = <DishtypeModal typeList={extraData.types} orderId={extraData.order_id} userId={extraData.customer_id} date={extraData.date} save={(orderId, type, date, userId) => {

          this.setState({showModal: false});

          this.props.socket.emit('order', {
              type: 'addDishType',
              data: {
                'userId': userId,
                'date': date,
                'orderId': orderId,
                'mealtype': type,
              }
          });
          
        }} />;
        props.width = '640px';
        props.height = '480px';
      break;
      case 'unpause':
        const {orderId, daysDiff, start, enddate} = extraData;
        props.content = <UnpauseModal
            {...modalProps}
            orderId={orderId}
            daysDiff={daysDiff}
            start={start}
            enddate={enddate}
            mealPlan={mealPlan}
            planParams={planParams}
            saveUnpause={(order_id, daysDiff, startDate) => {
              this.props.socket.emit('order', { type: 'afterPauseSet', data: { orderId: order_id, daysDiff: daysDiff, startDate: startDate.unix() } });
              this.setState({showModal: false});
            }}
        />;
        
        props.width = '640px';
        props.height = '480px';
      break;
    }
    props.content && setModal(props);
  };
  
  render() {
    const { dataArray, userData, remainDeliveries, socket } = this.props;
    const { mealPlan, planParams, hideDialog, orderIssues, futureMealPlan, futurePlanParams, futureAdditionalMeals, additionalMeals, pauses, discountstats, futurediscountstats,preparedby,lasteditdby, pause_is_active,futurepreparedby,futurelasteditdby, paymenttran , futurepaymenttran} = this.state;
    const { planList, typeList, dietList, slotsList, products, paymentMethods } = dataArray;
    const { plan, user_id, hasPlan, hasFuturePlan, futurePlan } = userData;
    const { deliveryTime: slot_id, id: order_id,credit_amount,pending_amount,is_single_lunch_box,days_count,exclude_week_day } = planParams;
    const { id, start, end, types, diet, order, renew_status, renew_reason, renew_status_date, renew_comment } = plan || {};
    console.log(plan);
    var status = null;
    var reason = null;
    var color = null;
    const reasonList = [
      '',
      'Traveling',
      'Didn’t like the food',
      'No much options',
      'Delivery Timings',
      'Not my macros',
      'Expensive',
      'No Discounts',
      'Others',
      'No response / reply after contacting',
      'No Feedback',
      'Undecided, will contact later',
    ];

    if (status) {
      var now = Date.now() / 1000;
      var diff = parseInt( (parseInt(now) - renew_status_date) / 60 / 60 / 24 ) + 1;
      if (diff > 3 || diff < 0) {
        status = null;
        reason = null;
      }
    }

    if (!renew_status && renew_status == 0) {
      status = 'Not Renewed';
      color = 'yellow';
    }

    if (renew_status && renew_status == 1) {
      status = 'Renewed';
      color = 'green';
    }

    // if (pauses) {
      
    //   const {adminUsers} = this.props;
    //   const {pause_manager, pause_start, pause_end} = this.state;
    //   var pause_username = 'unknown';
      
    //   if (pause_manager == 1) {
    //         pause_username = 'Admin';
    //   } else if (pause_manager == userData.user_id) {
    //     pause_username = 'Customer';
    //   } else {
    //     Object.keys(adminUsers).map( (el, idx) => {
    //       if (adminUsers[el].id == pause_manager) {
    //         pause_username = adminUsers[el].firstName;
    //       }
    //     });
    //   }

    //   var pause_end_text = 'indefinitely';
    //   if (pause_end) {
    //     pause_end_text = 'until '+moment.unix(pause_end).format('DD MMM');
    //   } 
    //   status = 'Paused from ' + moment.unix(pause_start).format('DD MMM') + ' and '+ pause_end_text + ', set by ' + pause_username;
    //   color = 'yellow';
    // }

    if (typeof(reasonList[renew_reason]) != 'undefined') {
      reason = '('+reasonList[renew_reason]+')';
    } 
    const RenderPause = ({ pauses }) =>{
      const {adminUsers} = this.props;
      const getPauseInfinityDays = (pause) =>{
        const tcount=(pause.mealPlanDates!=undefined)?pause.mealPlanDates.filter(el => pause.date_set<=+el):[];
          return Object.keys(tcount).length;
      }
     var pasues_data=Object.keys(pauses).map(function(pause_date){
            var pause_username = 'unknown';
          
            if (pauses[pause_date].manager_id == 1) {
                  pause_username = 'Admin';
            } else if (pauses[pause_date].manager_id == userData.user_id) {
              pause_username = 'Customer';
            } else {
              Object.keys(adminUsers).map( (el, idx) => {
                if (adminUsers[el].id == pauses[pause_date].manager_id) {
                  pause_username = adminUsers[el].firstName;
                }
              });
            }
            var pause_end_text = 'indefinitely';
            // console.log(pauses);
            if (pauses[pause_date].end_date) {
              pause_end_text = 'until '+moment.unix(pauses[pause_date].end_date).format('DD MMM');
            } 
            var color='gray';
            var leftDays='';
            if(pauses[pause_date].status=='active'){
               color='yellow';
               leftDays =(pauses[pause_date].end_date)?'':':'+getPauseInfinityDays(pauses[pause_date]) + ' days left';
            }
            return  <li className={`reason bold ${color}`}>Paused from {moment.unix(pause_date).format('DD MMM')} and  {pause_end_text} {leftDays} , set by {pause_username}</li>;
        });
        return <ul className="pause-list">{pasues_data}</ul>
    }
    const RemainButtons = ({ customer_id, date, is_future=0 }) => {
      const data = { customer_id, date, type: 'remain', is_future: is_future };
      const remove = () => socket.emit('order', { type: 'delRemain', data });
      return <div className='remain-buttons'>
        <ToolTip
          tooltipText={<div className='tooltip-yn' style={{ width: '250px' }}>
            <div className='text-center' style={{ fontSize: 'large' }}>
              <span>Are you sure?</span>
            </div>
            <Button style={flex} fullWidth color='danger' size='medium' onClick={() => {
              this.setState({ hideDialog: true });
              setTimeout(() => this.setState({ hideDialog: false }));
            }}>
              <Close className='icon-no' /> <span className='button-text no'>No</span>
            </Button>
            <Button style={flex} fullWidth color='success' size='medium' onClick={remove}>
              <Check className='icon-yes' /> <span className='button-text yes'>Yes</span>
            </Button>
          </div>}
          tooltipDirection={'topLeft'}
          trigger={['click']}
          hide={hideDialog}
        >
          <div className='button bg-danger danger-shadow'>Remove</div>
        </ToolTip>
        <div className='button bg-success success-shadow' onClick={() => this.showModal('move', data)}>Move</div>
        
      </div>;
    };
    const OrderButtons = ({ customer_id, date, order_id, delivered, today, is_future=0 }) => {
      if (is_future) {
          order_id = futurePlanParams.id;
      } 
      const data = { customer_id, date, order_id, delivered: !delivered, is_future: is_future };
      // const issue = () => this.showModal('issue', data);
      const notDelivered = () => socket.emit('order', { type: 'toggleDelivered', data });
      const remove = (isupdate) => {
        var removedata=data;
        removedata['ispriceupdate']=isupdate;
        socket.emit('order', { type: 'delDay', data:removedata });
      } 

 
      return (
            <div className='remain-buttons'>
              {/* <div className='button bg-warning warning-shadow' onClick={issue}>Create an issue</div> */}
              
              { date <= today && 
                <div
                  className={`button ${delivered ? 'bg-warning warning-shadow' : 'bg-success success-shadow'}`}
                  onClick={notDelivered}
                >
                  Mark as{delivered ? ' not ' : ' '}delivered
                </div>
              }

              <div className='button bg-success success-shadow' onClick={() => {  
                this.showModal('move', { ...data, type: 'order'}, is_future)}
              }>Move</div>

              {!delivered && 
                <ToolTip
                tooltipText={<div className='tooltip-yn' style={{ width: '250px' }}>
                  <div className='text-center' style={{ fontSize: 'large' }}>
                    <span>Are you sure?</span>
                  </div>
                  <Button style={flex} fullWidth color='danger' size='medium' onClick={() => {
                    this.setState({ hideDialog: true });
                    setTimeout(() => this.setState({ hideDialog: false }));
                  }}>
                    <Close className='icon-no' /> <span className='button-text no'>No</span>
                  </Button>
                  <Button style={flex} fullWidth color='success' size='medium' onClick={() => remove(0)}>
                    <Check className='icon-yes' /> <span className='button-text yes'>Yes, Without price update</span>
                  </Button>
                  <Button style={flex} fullWidth color='success' size='medium' onClick={() => remove(1)}>
                    <Check className='icon-yes' /> <span className='button-text yes'>Yes, With price update</span>
                  </Button>
                </div>}
                tooltipDirection={'topLeft'}
                trigger={['click']}
                hide={hideDialog}
              >
                <div className='button bg-danger danger-shadow'>Remove</div>
                </ToolTip>
             }

             {!delivered && <div className='button bg-primary primary-shadow' onClick={() => this.showModal('addmeals', { ...data, type: 'order'}, is_future)}>Add Meals</div>}
            </div>
      );
    };

    const MealPdfButtons = ({ customer_id, date, order_id=false }) => {
      return (
            <div className='remain-buttons'>
              <div
                className="button bg-warning success-shadow"
                onClick={() => this.dailymenuView(customer_id, date, order_id)}
              >
                Daily Menu Web
              </div>
              <div
                className="button bg-danger success-shadow"
                onClick={() => this.dailymenuPdf(customer_id, date, order_id)}
              >
                Daily Menu PDF
              </div>

              <div
                className="button bg-success success-shadow"
                onClick={() => this.addDishType(customer_id, date, order_id)}
              >
                Add Dish mealType
              </div>
             
            </div>
      );
    };
   
    const DailyCard = ({ stamp, dailyMenu, delivered, cooked, slot_id, type, is_future=false,is_pause_day=false }) => {
      const date = moment.unix(stamp);
      const today = moment().utc().startOf('day').unix();
      const diff = stamp - today;
      const classKey = `${diff < 0 ? '-' : ''}${+!!diff}`;
      const {moveStats, futureMoveStats, futurePlanParams, planParams, pause_start, pause_end, futureOrderId,futureAdditionalMeals, additionalMeals} = this.state; 
      const {adminUsers} = this.props;
      var day_action = null;
      var moved_by = null;
      var reason = null;
      var order_id_for_buttons = false;
      var cslot_id=slot_id;
      if (is_future) {
        order_id_for_buttons = futurePlanParams.id;
        cslot_id=futurePlanParams.deliveryTime;
      } else if (planParams) {
        order_id_for_buttons = planParams.id;
      }

      const reasonList = {
        1: 'Late Delivery',
        2: 'Missed Delivery',
        3: 'Referral Program',
        4: 'Extra Gift',
        5: 'Promotion',
      }

      var paused_day = null;
      // if ( (stamp >= pause_start) && (stamp <= pause_end || pause_end==null) ) {
      //   paused_day = 'paused';
      // }
      if(is_pause_day){
        paused_day = 'paused';
    }
      var theStats = false;
      if (is_future) {
        theStats = futureMoveStats;
      } else {
        theStats = moveStats;
      }

      if (typeof(theStats[stamp]) != 'undefined') {
        var manager_id = theStats[stamp].log_user;
        day_action = theStats[stamp].log_action;
        reason = theStats[stamp].log_reason;
        Object.keys(adminUsers).map( (el, idx) => {
          if (adminUsers[el].id == manager_id) {
            moved_by = adminUsers[el].firstName;
          }

          if (manager_id == 1) {
            moved_by = 'Admin';
          }
        });
        console.log(day_action);
        switch (day_action) {
          case 'moveOrder':
            day_action = 'Moved Day';
          break;
          case 'addmealsMoveOrder':
            case 'addmealsmoveOrder':
            day_action = 'Moved Day with additional meals';
          break;
          case 'addmeals':
            day_action = 'additional meals';
          break;
          
          case 'after_pause':
            case 'addmealsafter_pause':
            day_action = 'after pause';
          break;

          case 'charged':
            case 'addmealscharged':
            day_action = 'charged day';
          break
          
          default:
            day_action = 'Complimentary';
          break;
        }

        if (typeof(reasonList[reason]) != 'undefined') {
          reason = 'Reason: '+reasonList[reason];
        }

      }

      if (moved_by) {
        moved_by = 'Set by '+ moved_by;
      }

      var alladitional = additionalMeals;
      if (is_future) {
        alladitional = futureAdditionalMeals;
      }
                var additional = [];
                var additionalFlag = false;
                if (typeof(alladitional[stamp]) != 'undefined') {
                  Object.keys(alladitional[stamp]).map((elname, idx) => {
                    additional[elname] = [];
                    Object.keys(alladitional[stamp][elname]).map((eltype, idx) => {
                      Object.keys(alladitional[stamp][elname][eltype]).map((eldish, idx) => {

                        additional[elname].push(<p key={`${alladitional[stamp][elname][eltype]}_${idx}`}>
                          { additionalFlag && <a className="additional-edit" onClick={() => this.showModal('editadditional', {orderId: order_id_for_buttons, stamp: stamp, name: elname, type: eltype, dish_id: alladitional[stamp][elname][eltype][eldish], dishName: products[alladitional[stamp][elname][eltype][eldish]].title, products: products, typeList: typeList, is_future:is_future })}>Change</a>}
                          { additionalFlag && <a className="additional-edit" onClick={() => this.showModal('addnotesdish', {orderId: order_id_for_buttons, stamp: stamp, dishId: alladitional[stamp][elname][eltype][eldish], dishName: products[alladitional[stamp][elname][eltype][eldish]].title, is_future:is_future })}>Add Note</a>}
                          {((typeList || {})[eltype] || {}).title}: {((products || {})[alladitional[stamp][elname][eltype][eldish]] || { title: 'deleted' }).title}
                        </p>)
                      });
                    });
                  });
                }
  
      return (
          <div className={`day ${classes[classKey].day} ${paused_day}`}>
          <div className='status'>
            <span>{date.format('DD MMM')} - {stamp}</span>
            <span>{date.format('ddd')}</span><br/>
            {paused_day && <span className="pausedday">{paused_day}</span>}
            {day_action && <span className="movedcopyday">{day_action}</span>}
            {reason && <span className="movedreason">{reason}</span>}
            {moved_by && <span className="movedby">{moved_by}</span>}
          </div>
          {!paused_day && <div className='day-dishes'>
            {Object.keys(dailyMenu).map((el, idx) => <p key={`${dailyMenu[el]}_${idx}`}>
              {((typeList || {})[el] || {}).title}: {((products || {})[dailyMenu[el]] || { title: 'deleted' }).title}
            </p>)}

            {/* {(day_action == 'additional meals' || day_action == 'Moved Day with additional meals') && */}
            <div className="additional-list">
                {additional && Object.keys(additional).map( (name) => {
                  return <div>
                    <b className="add-meals">{name}</b> 
                    {additional[name]}
                    <a className="button bg-primary primary-shadow additional-remove" onClick={() => this.delAdditional(stamp, name,futureOrderId)}>Remove</a>
                  </div>
                })}
            </div>
            {/* } */}
          </div>}

          {!paused_day && <div className={`actions ${classes[classKey].actions}`}>
            <p>{delivered ? 'delivered' : (cooked ? 'cooked' : '')}</p>
            <p>{cslot_id && slotsList ? (slotsList || []).find(({ id }) => +id === +cslot_id).name : 'not set'}</p>
            {type === 'remain' && <RemainButtons date={+stamp} customer_id={+user_id} />}
            {(type === 'order' && !(orderIssues || []).includes(stamp)) && <OrderButtons
              date={+stamp}
              customer_id={+user_id}
              order_id={order_id}
              delivered={delivered}
              today={today}
              is_future={is_future}
            />}
           
            <MealPdfButtons date={+stamp} customer_id={+user_id} order_id={order_id_for_buttons} />
          </div> }
          </div>
      );
    };
    
    const PlanDetails = () => {
      var pauseDetail={};
      var is_pause_day=false;
      var pause_end_date=false;
        return mealPlan && planParams && products ? [
              <div key='-1' className='day'><div className='group-title'>Active order deliveries</div></div>,
              ...Object.keys(mealPlan).map((stamp, key) => {
                const { cooked, packed, delivered, total, ...dailyMenu } = mealPlan[stamp];
                if(pauses[stamp]!=undefined){
                  pauseDetail=pauses[stamp];
                  // pauseDetail.end_date=(pauseDetail.end_date==null)?Object.keys(mealPlan)[Object.keys(mealPlan).length - 1]:pauseDetail.end_date;
                  pause_end_date=(pauseDetail.end_date==null)?Object.keys(mealPlan)[Object.keys(mealPlan).length - 1]:pauseDetail.end_date;
                }
                is_pause_day=false;
                if(pause_end_date && stamp <= pause_end_date){
                  is_pause_day=true;
                }

                return <DailyCard {...{ key, stamp, dailyMenu, slot_id, cooked: cooked === total, delivered: delivered === total, type: 'order',is_future: false,is_pause_day }} />;
              })
        ] : [];
    };

    const FuturePlanDetails = () => {
      var pauseDetail={};
      var is_pause_day=false;
      var pause_end_date=false;
        return futureMealPlan && futurePlanParams && products ? [
              <div key='-1' className='day'><div className='group-title'>Order deliveries</div></div>,
              ...Object.keys(futureMealPlan).map((stamp, key) => {
                const { cooked, packed, delivered, total, ...dailyMenu } = futureMealPlan[stamp];
                if(pauses[stamp]!=undefined){
                  pauseDetail=pauses[stamp];
                  // pauseDetail.end_date=(pauseDetail.end_date==null)?Object.keys(mealPlan)[Object.keys(mealPlan).length - 1]:pauseDetail.end_date;
                  pause_end_date=(pauseDetail.end_date==null)?Object.keys(futureMealPlan)[Object.keys(futureMealPlan).length - 1]:pauseDetail.end_date;
                }
                is_pause_day=false;
                if(pause_end_date && stamp <= pause_end_date){
                  is_pause_day=true;
                }
                return <DailyCard {...{ key, stamp, dailyMenu, slot_id, cooked: cooked === total, delivered: delivered === total, type: 'order', is_future: true,is_pause_day }} />;
              })
        ] : [];
    };

    const RemainDeliveries = () => {
      let slot = null;
      const listByDates = (remainDeliveries || []).reduce((acc, { date, mealType_id: type, cooked, packed, delivered, slot_id, dish_id }) => {
        !slot && (slot = slot_id);
        const dayData = { ...(acc[date] || {}), [type]: dish_id };
        dayData.cooked = (dayData.cooked || 0) + +cooked;
        dayData.packed = (dayData.packed || 0) + +packed;
        dayData.delivered = (dayData.delivered || 0) + +delivered;
        dayData.total = (dayData.total || 0) + 1;
        return { ...acc, [date]: dayData };
      }, {});
      const list = Object.keys(listByDates).map((stamp, idx) => {
        const { cooked, packed, delivered, total, ...dailyMenu } = listByDates[stamp];
        const props = { stamp, dailyMenu, cooked: cooked === total, delivered: delivered === total, slot_id: slot };

        return <DailyCard key={idx} type={'remain'} {...props} />;
      });
      return list.length ? [
        <div key='-1' className='day'><div className='group-title'>Remain deliveries</div></div>,
        ...list
      ] : []; 
    };
    const skipdays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    const getTypes = typesString => typesString && typeList ? typesString.split(',').map(el => typeList[el].title).join(', ') : 'No data';
    const getExcludeWeek =(exclude_week_day)=>(exclude_week_day.length>0)?'No '+exclude_week_day.map(el => skipdays[el]).join(' & '):'Full Week';
    const PlanInfo = ({ plan, planList, dietList, thenew=false ,preparedby,lasteditdby,paymenttran}) => {
      const getDate = date => moment.unix(date).format('DD.MM');
      const {pauses} = this.state;
      const {adminUsers} = this.props;
      const { exclude_week_day,days_count } = plan || {};
      var setBy = '';
      var setPaidBy = '';
      var discountSetBy = '';
      var futureDiscountSetBy = '';

      if (plan && plan.setBy == 1) {
        setBy = 'Admin';
      } else if (plan) {
        Object.keys(adminUsers).map( (el, idx) => {
          if (adminUsers[el].id == plan.setBy) {
            setBy = adminUsers[el].firstName;
          }
        });
      }
      if (plan && plan.setPaidBy == 1) {
        setPaidBy = 'Admin';
      } else if (plan) {
        Object.keys(adminUsers).map( (el, idx) => {
          if (adminUsers[el].id == plan.setPaidBy) {
            setPaidBy = adminUsers[el].firstName;
          }
        });
      }
      if (discountstats && discountstats.discount_set_by == 1) {
        discountSetBy = 'Admin';
      } else if (plan) {
        Object.keys(adminUsers).map( (el, idx) => {
          if (adminUsers[el].id == discountstats.discount_set_by) {
            discountSetBy = adminUsers[el].firstName;
          }
        });
      }

      if (futurediscountstats && futurediscountstats.discount_set_by == 1) {
        futureDiscountSetBy = 'Admin';
      } else if (plan) {
        Object.keys(adminUsers).map( (el, idx) => {
          if (adminUsers[el].id == futurediscountstats.discount_set_by) {
            futureDiscountSetBy = adminUsers[el].firstName;
          }
        });
      }





      var setTime = false;
      if (plan && plan.changeDate) {
        setTime = moment.unix(plan.changeDate).format('DD.MM.YYYY');
      }

      var paystatus = '';
      // if (plan && plan.paymentMethod && paymentMethods && (typeof(paymentMethods[plan.paymentMethod]) != 'undefined') ) {
      //     paystatus = 'Paid by '+ paymentMethods[plan.paymentMethod];
      // } else {
      //     if (thenew && typeof(futurePlanParams) != 'undefined') {
      //       if (plan && futurePlanParams.paymentMethod && (typeof(paymentMethods[futurePlanParams.paymentMethod]) != 'undefined') ) {
      //         paystatus = 'Paid by '+ paymentMethods[futurePlanParams.paymentMethod];
      //       }
      //     } else {
      //       paystatus = 'Not Paid';
      //     }

      // }
      const { payment_data } = planParams;
      var paystatus = '';
      if(thenew){
        if (typeof(futurePlanParams) != 'undefined') {
          const { payment_data:payment_datafuture } = futurePlanParams;
          if(futurePlanParams && futurePlanParams.paymentMethod=='not_paid'){
            paystatus = 'Not Paid';
          }else if(futurePlanParams && payment_datafuture){
            paystatus = 'Paid by '+ payment_datafuture.name;
          }else{
            paystatus = 'Paid by '+ futurePlanParams.paymentMethod;
          }
        }
      }else{
        if(planParams && planParams.paymentMethod=='not_paid'){
          paystatus = 'Not Paid';
        }else if(planParams && payment_data){
          paystatus = 'Paid by '+ payment_data.name;
        }else{
          paystatus = 'Paid by '+ planParams.paymentMethod;
        }
      }
      const whitecolor = {
        color: '#fff'
      };
      return plan && planList && dietList ? (
        <div>
        <Fragment>
          <p className='h-p1'>
          <span className='plan bold'>{(days_count<=0)?planList[plan.id].title:days_count+' Days - '}{(days_count>0)?getExcludeWeek(exclude_week_day):''}</span>: <span className='types'>{`${getDate(plan.start)} - ${getDate(plan.end)}`}</span>
          </p> 
          <p className='h-p2'>
            <span className='type bold'>{dietList[plan.diet]}</span>: <span className='types'>{getTypes(plan.types)}</span>
          </p>
          {status && !pause_is_active && <a onClick={ () => this.renewDecline(order_id)} className="btn btn-primary pointer-renew">Remove Renewed Status</a>}
          {!thenew && <div> <p className={`reason bold ${color}`}>{status} {reason}</p><p className={`reason bold ${color}`}>{renew_comment}</p></div>}
          {!thenew && Object.keys(pauses).length>0 && <RenderPause pauses={pauses} />}
          <p className="paystatus-info-tab">{paystatus} {setPaidBy && <span>, set by {setPaidBy}</span>} {setTime && <span>at {setTime}</span>}</p>
        </Fragment>
        <Fragment>
        {!thenew && discountstats && <div><p className={`reason bold green`}>{discountstats.discount_text} - {discountstats.discount_comment}, set by {discountSetBy}</p></div>}
        {thenew && futurediscountstats && <div><p className={`reason bold green`}>{futurediscountstats.discount_text} - {futurediscountstats.discount_comment}, set by {futureDiscountSetBy}</p></div>}
        </Fragment>
        <Fragment>
          {preparedby&&<p  className={`red`}>Menu prepared By: {preparedby.name} {moment.unix(preparedby.created_date).format('DD.MM.YYYY')}</p>}
      {lasteditdby&&<p className={`red`}>Last Edited: {lasteditdby.name} {moment.unix(lasteditdby.created_date).format('DD.MM.YYYY')}</p>}
      
      {credit_amount>0&&<p>Credit Amount: {`AED ${credit_amount / 100}`}</p>}
      {pending_amount>0&&<p>Pending Amount: {`AED ${pending_amount / 100}`}</p>}
        </Fragment>
        </div>
      ) : (
        <Fragment>
        {!pauses && <p className='h-p1'>No active plan</p>}
        {!thenew && Object.keys(pauses).length>0 && <RenderPause pauses={pauses} />}
        {pauses && <div><p className={`reason bold ${color}`}>{status} {reason}</p><p className={`reason bold ${color}`}>{renew_comment}</p></div>}
        </Fragment>
      ); 
    };
    const flex = {
      display: 'flex', 
      justifyContent: 'space-around'
    };
    const {onhold_id, onhold_future_id,onpaused_id} = this.state;
    var stamps = Object.keys(mealPlan);

    // console.log('===========');
    // console.log(planParams);
    // console.log('===========');
    const thenew1=false;
    const disablebtn=userData.account_status=='banned'?'disable':'';
    var compdata = { customer_id: parseInt(user_id), date: stamps[0], order_id: order_id, delivered: false };
    const majoroptions = [
      {'label':'Pause',action:'pause',isactive:(plan && !pause_is_active)},
      {'label':'UnPause',callfunc:this.unpause,actionType:'func',param:order_id,isactive:(!onpaused_id && pause_is_active && pauses)},
      {'label':'Renew',action:'renew',isactive:(hasPlan && !hasFuturePlan)},
      {'label':'Renew New Format',action:'renewNew',isactive:(hasPlan && !hasFuturePlan)},
      {'label':'Not renewed',action:'reason',isactive:(hasPlan && !hasFuturePlan)}, 
      {'label':'Change meal plan',action:'changeplan',isactive:hasPlan},
    ];
    const meals = [
      {'label':'Edit Mealplan',actionType:'browser',param:`/editplan/${user_id}/${order}`,isactive:plan},
      {'label':'Add/Remove Meal Type',action:'editplan',isactive:hasPlan},
      {'label':'Set Single Lunch Box',callfunc:this.toggleSingleBox,param:order_id,actionType:'func',isactive:(is_single_lunch_box==0)},
      {'label':'Remove Single Lunch Box',callfunc:this.toggleSingleBox,param:order_id,actionType:'func',isactive:(is_single_lunch_box==1)},
    ];
    const payment = [
      {'label':'Bag price',action:'bagprice',param:{orderId: order_id, bagPrice: planParams.bag_price},isactive:hasPlan},
      {'label':'Payment status',action:'paystatus',param:order_id,isactive:hasPlan},
      {'label':'Discount',action:'discount',param: {orderId:order_id, isFuture: false},isactive:plan},
      {'label':'Add complimentary day',action:'complimentary',param:{ ...compdata, type: 'order' },isactive:plan},
      {'label':'Add charged day',action:'charged',param:{ ...compdata, type: 'order' },isactive:plan},
      {'label':'Change price',action:'changeprice',param:{ ...compdata, type: 'order' },isactive:plan},
    ];
    const logistics = [
      {'label':'Delivery time',action:'deliverytime',param:order_id,isactive:plan}
    ];
    const downloads = [
      {'label':'Preview PDF',callfunc:this.viewwebPdf,actionType:'func',param:{user_id},isactive:plan},
      {'label':'English PDF',callfunc:this.downloadPdf,actionType:'func',param:{user_id,order_id :false,$is_arabic:false},isactive:plan},
      {'label':'Arabic PDF',callfunc:this.downloadPdf,actionType:'func',param:{user_id,order_id :false,$is_arabic:true},isactive:plan}
    ];
    const communications = [
      {'label':'Send welcome mail',callfunc:this.sendMail,actionType:'func',param:{order_id :order_id,$is_arabic:false},isactive:plan},
      {'label':'Send revised mail',callfunc:this.sendMail,actionType:'func',param:{order_id :order_id,$is_arabic:true},isactive:plan},
      {'label':'Send Payement Link',callfunc:this.sendPayemntLinkMail,actionType:'func',param:order_id,isactive:(plan && plan.paymentMethod&& plan.paymentMethod == 'not_paid' && !paymenttran)},
      {'label':'Payement Link Status',callfunc:this.viewPaymentTran,actionType:'func',param:paymenttran,isactive:paymenttran}
    ];
    const packing = [
      {'label':'Cutlery',action:'cutlery',param:order_id,isactive:plan}
    ];
    let fmeals=[];
    let fpayment=[];
    let flogistics=[]; 
    let fdownloads=[];
    let fcommunications=[];
    let fpacking=[];
    if(futurePlan){
      var compdata = { customer_id: parseInt(user_id), date: stamps[0], order_id: futurePlanParams.id, delivered: false };
         fmeals = [
            {'label':'Edit Mealplan',actionType:'browser',param:`/editplan/${user_id}/${futurePlanParams.id}`,isactive:futurePlan},
            {'label':'Add/Remove Meal Type',action:'editplan',isactive:futurePlan},
            {'label':'Set Single Lunch Box',callfunc:this.toggleSingleBox,param:futurePlanParams.id,actionType:'func',isactive:(is_single_lunch_box==0)},
            {'label':'Remove Single Lunch Box',callfunc:this.toggleSingleBox,param:futurePlanParams.id,actionType:'func',isactive:(is_single_lunch_box==1)},
          ];
         fpayment = [
            {'label':'Bag price',action:'bagprice',param:{orderId: futurePlanParams.id, bagPrice: planParams.bag_price},isactive:futurePlan},
            {'label':'Payment status',action:'paystatus',param:futurePlanParams.id,isactive:futurePlan},
            {'label':'Discount',action:'discount',param: {orderId:futurePlanParams.id, isFuture: false},isactive:futurePlan},
            {'label':'Add complimentary day',action:'complimentary',param:{ ...compdata, type: 'order' },isactive:futurePlan},
            {'label':'Add charged day',action:'charged',param:{ ...compdata, type: 'order' },isactive:futurePlan}
          ];
          flogistics = [
            {'label':'Delivery time',action:'deliverytime',param:futurePlanParams.id,isactive:futurePlan}
          ];
          fdownloads = [
            {'label':'Preview PDF',callfunc:this.viewwebPdf,actionType:'func',param:{user_id,order_id :futurePlanParams.id},isactive:futurePlan},
            {'label':'English PDF',callfunc:this.downloadPdf,actionType:'func',param:{user_id,order_id :futurePlanParams.id,$is_arabic:false},isactive:futurePlan},
            {'label':'Arabic PDF',callfunc:this.downloadPdf,actionType:'func',param:{user_id,order_id :futurePlanParams.id,$is_arabic:true},isactive:futurePlan}
          ];
          fcommunications = [
            {'label':'Send welcome mail',callfunc:this.sendMail,actionType:'func',param:{order_id :futurePlanParams.id,$is_arabic:false},isactive:futurePlan},
            {'label':'Send revised mail',callfunc:this.sendMail,actionType:'func',param:{order_id :futurePlanParams.id,$is_arabic:true},isactive:futurePlan},
            {'label':'Send Payement Link',callfunc:this.sendPayemntLinkMail,actionType:'func',param:futurePlanParams.id,isactive:(futurePlan && futurePlan.paymentMethod && futurePlan.paymentMethod=='not_paid' && !futurepaymenttran)},
            {'label':'Payement Link',callfunc:this.viewPaymentTran,actionType:'func',param:futurepaymenttran,isactive:futurepaymenttran}
          ];
          fpacking = [
            {'label':'Cutlery',action:'cutlery',param:futurePlanParams.id,isactive:futurePlan}
          ];
    }
    return <div className='customer-plan'>
      <div className='plan-head'>
        <div className='info-part'><PlanInfo {...{ plan, planList, dietList,thenew1,preparedby,lasteditdby,paymenttran }} /></div>
        <div className={`button-part plan-edit-buttons ${disablebtn}`}>
          {plan && <ToolTip
            tooltipText={<div className='tooltip-yn' style={{ width: '250px' }}>
              <div className='text-center' style={{ fontSize: 'large' }}>
                <span>Are you sure?</span>
              </div> 
              {(({ id, payed }) => {
                // if (payed) {
                  return <Fragment>  
                    <Button style={flex} fullWidth color='success' size='medium' onClick={() => this.cancelPlan(id, true)} >
                      <CardGiftcard className='icon-yes' /> <span className='button-text yes'>Yes, Make Coupon</span>
                    </Button>
                    {/* <Button style={flex} fullWidth color='success' size='medium' onClick={() => this.cancelPlan(id, false)} >
                      <MonetizationOn className='icon-yes' /> <span className='button-text yes'>Yes, Refund Money</span>
                    </Button> */}
                    <Button style={flex} fullWidth color='success' size='medium' onClick={() =>{ this.showModal((payed)?'refund':'notpaid'); this.setState({ hideDialog: true });
                setTimeout(() => this.setState({ hideDialog: false }));}} >
                      <MonetizationOn className='icon-yes' /> <span className='button-text yes'>Yes, Refund Money</span>
                    </Button>
                    <Button style={flex} fullWidth color='warning' size='medium' onClick={() => this.removePlan(id)} >
                      <span className='button-text yes'>Created By Mistake (Remove Fully)</span>
                    </Button>
                  </Fragment>;
                // } else {
                //   return <Button style={flex} fullWidth color='success' size='medium' onClick={() => this.cancelPlan(id, false)}>
                //     <Check className='icon-yes' /> <span className='button-text yes'>Yes</span>
                //   </Button>;
                // }
              })(planParams)}
              <Button style={flex} fullWidth color='danger' size='medium' onClick={() => {
                this.setState({ hideDialog: true });
                setTimeout(() => this.setState({ hideDialog: false }));
              }}>
                <Close className='icon-no' /> <span className='button-text no'>No</span>
              </Button>
            </div>}
            tooltipDirection={'topLeft'}
            trigger={['click']}
            hide={hideDialog}
          > 
            <div className='cancel-btn' style={{ background: '#999999' }}>Cancel</div> 
          </ToolTip>}

          {plan && planParams.status == 'active' && plan.paymentMethod=='not_paid' && <ToolTip
            tooltipText={<div className='tooltip-yn' style={{ width: '250px' }}>
              <div className='text-center' style={{ fontSize: 'large' }}>
                <span>Are you sure?</span>
              </div> 

              <Button style={flex} fullWidth color='success' size='medium' onClick={() => {
                this.setOnHold(true, order_id)
              }}>
                <Close className='icon-yes' /> <span className='button-text no'>Yes</span>
              </Button>
             
              <Button style={flex} fullWidth color='danger' size='medium' onClick={() => {
                this.setState({ hideDialog: true });
                setTimeout(() => this.setState({ hideDialog: false }));
              }}>
                <Close className='icon-no' /> <span className='button-text no'>No</span>
              </Button>
            </div>}
            tooltipDirection={'topLeft'}
            trigger={['click']}
            hide={hideDialog}
          > 
            <div  className='hold-btn' style={{ background: '#fbb4c8' }}>Hold On</div> 
          </ToolTip>}

          {!plan && !onpaused_id && !onhold_id && <div onClick={() =>{userData.account_status!='banned'&&this.getLastMealCutlery(user_id)}}>Add New</div>}
          {!plan && !onpaused_id && !onhold_id && <div onClick={() =>{userData.account_status!='banned'&&this.getLastMealCutlery(user_id,true)}}>Add New Format</div>}
          {!plan && onhold_id && !pause_is_active && <div onClick={() => this.setOnHold(false, onhold_id)} style={{ background: '#caddff' }}>Hold Off</div>}
          {onhold_future_id && <div onClick={() => this.setOnHold(false, onhold_future_id, true)} style={{ background: '#caddff' }}>Hold Future Plan Off</div>}
          {!plan && !onpaused_id && pause_is_active && pauses && <div onClick={() => this.unpause(onhold_id)} style={{ background: '#599099' }}>UnPause</div>}
          {!plan && onpaused_id && pauses && <div onClick={() => this.unpause(onpaused_id)} style={{ background: '#599099' }}>UnPause infinity</div>}
          {plan && <MealPlanButtons showModal={this.showModal} options={majoroptions} labelText={'Major'} classes={'major'} />}
          {plan && <MealPlanButtons showModal={this.showModal} options={meals} labelText={'Meals'} classes={'meals'} ></MealPlanButtons> }
          {plan && <MealPlanButtons showModal={this.showModal} options={payment} labelText={'Payment'} classes={'payment'} ></MealPlanButtons> }
          {plan && <MealPlanButtons showModal={this.showModal} options={logistics} labelText={'Logistics'} classes={'logistics'} ></MealPlanButtons> }
          {plan && <MealPlanButtons showModal={this.showModal} options={downloads} labelText={'Downloads'} classes={'downloads'} ></MealPlanButtons> }
          {plan && <MealPlanButtons showModal={this.showModal} options={communications} labelText={'Communications'} classes={'communications'} ></MealPlanButtons> }
          {plan && <MealPlanButtons showModal={this.showModal} options={packing} labelText={'Packing'}  classes={'packing'}  ></MealPlanButtons> }
        </div>
      </div>
      <div className='plan-body scrollable-h'> 
        <RemainDeliveries />
        {plan && <PlanDetails/>}









        {futurePlan && 
          <div className='customer-plan'>
            <div className='plan-head'>
              <h3 className="PlanDetailsHeader">New Plan Details</h3>
                <div className='info-part future-info-part'>
                  <PlanInfo plan={futurePlan} futurePlanParams={futurePlanParams} planList={planList} dietList={dietList} thenew={true} preparedby={futurepreparedby} lasteditdby={futurelasteditdby} paymenttran={futurepaymenttran} />
                </div>
                 <div className='button-part plan-edit-buttons future-buttons-part'>
                  {futurePlan && <ToolTip
            tooltipText={<div className='tooltip-yn' style={{ width: '250px' }}>
              <div className='text-center' style={{ fontSize: 'large' }}>
                <span>Are you sure?</span>
              </div> 
              {(({ id, payed }) => {
                // if (payed) {
                  return <Fragment>  
                    <Button style={flex} fullWidth color='success' size='medium' onClick={() => this.cancelPlan(futurePlanParams.id, true)} >
                      <CardGiftcard className='icon-yes' /> <span className='button-text yes'>Yes, Make Coupon</span>
                    </Button>
                    <Button style={flex} fullWidth color='success' size='medium' onClick={() =>{ this.showModal((payed)?'futurerefund':'notpaid'); this.setState({ hideDialog: true });
                setTimeout(() => this.setState({ hideDialog: false }));}} >
                      <MonetizationOn className='icon-yes' /> <span className='button-text yes'>Yes, Refund Money</span>
                    </Button>
                    <Button style={flex} fullWidth color='warning' size='medium' onClick={() => this.removePlan(futurePlanParams.id)} >
                      <span className='button-text yes'>Created By Mistake (Remove Fully)</span>
                    </Button>
                  </Fragment>;
                // } else {
                //   return <Button style={flex} fullWidth color='success' size='medium' onClick={() => this.cancelPlan(futurePlanParams.id, false)}>
                //     <Check className='icon-yes' /> <span className='button-text yes'>Yes</span>
                //   </Button>;
                // }
              })(futurePlanParams)}
              <Button style={flex} fullWidth color='danger' size='medium' onClick={() => {
                this.setState({ hideDialog: true });
                setTimeout(() => this.setState({ hideDialog: false }));
              }}>
                <Close className='icon-no' /> <span className='button-text no'>No</span>
              </Button>
            </div>}
            tooltipDirection={'topLeft'}
            trigger={['click']}
            hide={hideDialog}
          > 
            <div className='cancel-btn' style={{ background: '#999999' }}>Cancel</div> 
          </ToolTip>}


          {futurePlan && futurePlanParams.status == 'future' && futurePlan.paymentMethod=='not_paid' && <ToolTip
            tooltipText={<div className='tooltip-yn' style={{ width: '250px' }}>
              <div className='text-center' style={{ fontSize: 'large' }}>
                <span>Are you sure?</span>
              </div> 

              <Button style={flex} fullWidth color='success' size='medium' onClick={() => {
                this.setOnHold(true, futurePlanParams.id, true)
              }}>
                <Close className='icon-yes' /> <span className='button-text no'>Yes</span>
              </Button>
             
              <Button style={flex} fullWidth color='danger' size='medium' onClick={() => {
                this.setState({ hideDialog: true });
                setTimeout(() => this.setState({ hideDialog: false }));
              }}>
                <Close className='icon-no' /> <span className='button-text no'>No</span>
              </Button>
            </div>}
            tooltipDirection={'topLeft'}
            trigger={['click']}
            hide={hideDialog}
          > 
            <div  className='hold-btn' style={{ background: '#fbb4c8' }}>Hold On</div> 
          </ToolTip>}

          
          {!futurePlan && futurePlanParams.status == 'hold' && <div className='hold-btn' onClick={() => this.setOnHold(false, futurePlanParams.id)} style={{ background: '#caddff' }}>Hold Off</div>}
          {futurePlan && <MealPlanButtons showModal={this.showModal} options={fmeals} labelText={'Meals'} classes={'meals'} is_future={true} ></MealPlanButtons> }
          {futurePlan && <MealPlanButtons showModal={this.showModal} options={fpayment} labelText={'Payment'} classes={'payment'} is_future={true} ></MealPlanButtons> }
          {futurePlan && <MealPlanButtons showModal={this.showModal} options={flogistics} labelText={'Logistics'} classes={'logistics'} is_future={true} ></MealPlanButtons> }
          {futurePlan && <MealPlanButtons showModal={this.showModal} options={fdownloads} labelText={'Downloads'} classes={'downloads'} is_future={true} ></MealPlanButtons> }
          {futurePlan && <MealPlanButtons showModal={this.showModal} options={fcommunications} labelText={'Communications'} classes={'communications'} is_future={true} ></MealPlanButtons> }
          {futurePlan && <MealPlanButtons showModal={this.showModal} options={fpacking} labelText={'Packing'}  classes={'packing'} is_future={true}  ></MealPlanButtons> }
          </div>
          
          <FuturePlanDetails />

               
            </div>
          </div>
        }
      </div>
    </div>;
  }
}

TabMealPlan.propTypes = {
  socket: PropTypes.object.isRequired,
  dataArray: PropTypes.object.isRequired,
  userData: PropTypes.object.isRequired,
  setModal: PropTypes.func.isRequired,
  getUserInfo: PropTypes.func.isRequired,
  clearModal: PropTypes.func.isRequired,
  spin: PropTypes.func.isRequired,
  stop: PropTypes.func.isRequired,
  clearDefaultDishes: PropTypes.func.isRequired,
  holidays: PropTypes.string,
  remainDeliveries: PropTypes.array,
};

const props = state => ({
  dataArray: state.dataArray,
  holidays: (state.settings['HOLIDAYS'] || {}).value || ''
});

const actions = dispatch => ({
  setModal: object => dispatch(setModal(object)),
  spin: () => dispatch(spin()),
  stop: () => dispatch(stop()),
  clearModal: () => dispatch(clearModal()),
  clearDefaultDishes: () => dispatch(clearDefaultDishes())
});

export default socketConnect(connect(props, actions)(TabMealPlan));
