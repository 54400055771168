import React, { Component, Children, cloneElement } from 'react';
import { connect } from 'react-redux';
import { createBrowserHistory } from 'history';
import { socketConnect } from 'socket.io-react';
import PropTypes from 'prop-types';
import { browserHistory } from 'react-router';
import GridView from 'components/GridView';
import Button from 'components/CustomButtons/Button';
import { Add } from '@material-ui/icons/index';
import { clearNotify, setNotify } from 'store/notify';

class Blog extends Component {
  constructor(props) {
    super(props);
    const { params, userType } = this.props;
    const page = 'page' in params && params.page ? params.page : 1;
    const limit = 20;
    this.state = {
      posts: {},
      limit,
      page,
      maxPages: 1,
      actions: {
        update: {
          tooltip: 'Edit',
          button: 'Edit',
          callback: id => browserHistory.push(`/${userType}/blog/edit/${id}`)
        },
        close: {
          tooltip: 'Delete',
          button: 'Delete',
          callback: id => this.delete(id)
        }
      },
      defFilters: {
        title: {
          type: 'text',
          value: '',
          label: 'Title'
        },
        description: {
          type: 'text',
          value: '',
          label: 'Description'
        },
        blogCategoryId: {
          type: 'select',
          items: {},
          value: '',
          label: 'Category'
        },
        published: {
          type: 'switch',
          items: {
            '': '',
            1: 'Yes',
            0: 'No'
          },
          value: '',
          label: 'Published'
        }
      }
    };
    this.getPosts((page - 1) * limit, limit);
  }

  delete(postId) {
    this.props.socket.emit('blog', {
      type: 'del',
      data: {
        postId
      }
    });
  }

  listener = action => {
    if (this.BlogRef) {
      switch (action.type) {
        case 'listOk':
          this.setState({ ...action.data });
          break;
        case 'delOk':
          const { page, limit } = this.state;
          this.getPosts((page - 1) * limit, limit);
          this.props.setNotify({
            place: 'tc',
            color: 'success',
            icon: undefined,
            message: action.data.message,
            open: true
          });
          setTimeout(() => this.props.clearNotify(), 5000);
          break;
        default:
          if (['listErr', 'delErr'].includes(action.type)) {
            console.error(action.data.message);
          }
          break;
      }
    }
  };

  getPosts(offset, limit, filters) {
    this.props.socket.emit('blog', {
      type: 'list',
      data: {
        offset,
        limit,
        filters
      }
    });
  }

  componentWillMount() {
    this.props.socket.on('blog', this.listener);
  }

  componentWillUnmount() {
    this.props.socket.removeListener('blog', this.listener);
  }

  goTo = page => {
    const { limit } = this.state;
    const history = createBrowserHistory();
    history.push(`/${this.props.userType}/blog/page/${page}`);
    this.setState({ page });
    this.getPosts((page - 1) * limit, limit);
  };

  filters = () => {
    const { defFilters } = this.state;
    const { blogCategories } = this.props.dataArray;
    const items = {
      blogCategoryId: { '': '', ...blogCategories }
    };
    return Object.keys(defFilters).reduce((acc, cur) => ({ ...acc, [cur]: { ...defFilters[cur], items: items[cur] || {} } }), {});
  };

  addNewOne = () => browserHistory.push(`/${this.props.userType}/blog/add`);

  submitFilters = filters => {
    const { page, limit } = this.state;
    this.setState({ filters });
    this.getPosts((page - 1) * limit, limit, filters);
  };

  render() {
    const { page, maxPages, posts, limit, actions } = this.state;
    const { children, dataArray } = this.props;
    const tableHead = {
      index: '#',
      title: {
        type: 'text',
        label: 'Title'
      },
      description: {
        type: 'text',
        label: 'Description'
      },
      blogCategoryId: {
        label: 'Category',
        props: {
          items: dataArray['blogCategories'] || {}
        },
        type: 'select'
      },
      published: {
        type: 'switch',
        label: 'Published'
      },
      actions: 'Actions'
    };
    const { goTo, submitFilters } = this;
    const props = { page: +page, maxPages, limit, rows: posts, tableHead, actions, goTo, filters: this.filters(), submitFilters };
    let child = children && Children.map(
      children,
      child => cloneElement(
        child,
        {}
      )
    );
    return (<div ref={el => (this.BlogRef = el)} className={'full-w'}>
      <Button fullWidth={false} color='success' size='medium' onClick={this.addNewOne}><Add /></Button>
      <GridView {...props} />
      {child}
    </div>);
  }
}

Blog.propTypes = {
  socket: PropTypes.object.isRequired,
  children: PropTypes.object,
  params: PropTypes.object.isRequired,
  userType: PropTypes.string.isRequired,
  dataArray: PropTypes.object.isRequired,
  setNotify: PropTypes.func.isRequired,
  clearNotify: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  dataArray: state.dataArray,
  userType: state.user.type
});

const bindAction = dispatch => ({
  clearNotify: () => dispatch(clearNotify()),
  setNotify: props => dispatch(setNotify(props))
});

export default socketConnect(connect(mapStateToProps, bindAction)(Blog));
