import React ,{useState} from 'react';
import S3 from 'react-aws-s3';
// import AWS from 'aws-sdk'
import PropTypes from 'prop-types';
import { S3_BUCKET, S3_REGION, AWSaccessKeyIdS3, AWSsecretAccessKeyS3 } from 'variables/constants';

function sendImageAws(image,info,finish) { 
    const config = {
        bucketName: S3_BUCKET,
        dirName:info.path, /* optional */
        region: S3_REGION,
        accessKeyId: AWSaccessKeyIdS3,
        secretAccessKey: AWSsecretAccessKeyS3,
        // s3Url: 'https:/your-custom-s3-url.com/', /* optional */
    }
    const ReactS3Client = new S3(config);
    // AWS.config.update({
    //     accessKeyId: AWSaccessKeyIdS3,
    //     secretAccessKey: AWSsecretAccessKeyS3
    // })
    
    if(image.name!=undefined){
      var name=info.id+info.modelName.toLowerCase()+info.attribute+image.name.toLowerCase().replace(/ /g, '-').replace(/[^\w-]+/g, '');
        ReactS3Client
    .uploadFile(image, name)
    .then(data =>{info['imagedata']=data; finish(info,info.id);})
    .catch(err => console.error(err))
       
    }
    
  }
  
  sendImageAws.propTypes = {
    dataForm: PropTypes.object.isRequired,
    finish: PropTypes.func
  };


export default sendImageAws;