import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Grid, IconButton, Tooltip } from 'material-ui';
import ItemGrid from 'components/Grid/ItemGrid';
import Paginator from 'components/Paginator';
import { Text } from 'components/Typography';
import Table from './Table';
import moment from 'moment/moment';
import NoImg from 'assets/img/noimg.svg';
import { Link, browserHistory } from 'react-router';
import { Edit, Delete, Visibility } from '@material-ui/icons';
class List extends Component {
  getComponents = (components, productsFull, amount) => components.map((el, idx) => {
    return (
      <Grid key={idx} className='component-row' container>
        <Grid item md={6} className='component-title'><Text color='success' inline>{productsFull[el]['nickname'] || productsFull[el]['title']}</Text></Grid>
        <Grid item md={6} className='component-amount'><Text color='warning' inline bold>{amount}</Text></Grid>
      </Grid> 
    );
  });

  getParents = (parents, productsFull) => Object.keys(parents).map((el, idx) => {
    return (
      <Grid key={idx} className='component-row' container>
        <Grid item md={6} className='component-title'><Text color='success' inline>{productsFull[el]['nickname'] || productsFull[el]['title']}</Text></Grid>
        <Grid item md={6} className='component-amount'><Text color='warning' inline bold>{parents[el]}</Text></Grid>
      </Grid>
    );
  });

  renderItem(product_id){
    const { productsFull,typeList } = dictionaries;
    var image = (<img className="mealrow-img" src={NoImg}></img>);
    if (productsFull[product_id].image != null) {
        image = (<img className="mealrow-img" src={productsFull[product_id].image}></img>);
    } 
    var type = typeList[type_id].title;
    var name = productsFull[product_id].title;
    var nickname = productsFull[product_id].nickname;
    return ( 
        
      <div className="mealrow-item">
          {image} <br/>
          <span className="mealrow-type">{type}</span><br/>
          <h4>{nickname}</h4>
          <p className="mealrow-realname">{name}</p>
      </div>
  );
  }
  // getRowsByMeal(newdata,brand_cat_id) {
  //   const { list, dictionaries, date, notes } = this.props;
  //   const { brandCategoryList } = dictionaries;
  //   return Object.keys(newdata).map((acc, cur) => {
  //     const { name, nickname,sop_notes,varioations } = newdata[acc] || {};
  //    const data = {
  //     title: <Text customColor='#0068c1' inline bold>{name}</Text>,
  //     subheading: <Text  customColor='#0068c1' bold>{nickname}</Text>,
  //     varioations: <Text  customColor='#0068c1' bold>{varioations}</Text>,
  //     sopNotes: <Text  customColor='#0068c1' bold>{sop_notes}</Text>,
  //     brand_cat_id: brand_cat_id,
  //     dishId: acc,
  //   };
  //   return data;
  //   }, {});

  //   return <div className="mealrow-meals-recipe"><p className="date-date">{brandCategoryList[brand_cat_id]}</p>{body}
  //   </div>;
  // }
  getRowsByMeal(newdata,brand_cat_id) {
    const {  dictionaries,deleteConfirm } = this.props;
    const { brandCategoryList } = dictionaries;
    const body=Object.keys(newdata).map((acc) => {
      if(acc>0){
          // const { title, nickname,image,components } = productsFull[acc] || {};
          const { name, nickname,image,varioations } = newdata[acc] || {};
          var pimage = (<img className="mealrow-img" src={NoImg}></img>);
          if (image != null && image!='') {
              pimage = (<img className="mealrow-img" src={image}></img>);
          } 
        
          return  <div className="mealrow-item">
          {pimage} <br/>
          <p className="mealrow-realname-" style={{marginBottom:0}}>{name} - {acc}</p>
          <Grid container>
              <Tooltip
        key={'view'+acc}
        id='tooltip-view-top'
        title={'View'}
        placement='bottom'
      >
        <IconButton
          onClick={() => browserHistory.push(`/kitchen/cooking/view-chefs-receipe/${acc}`)}
          aria-label={'View'}
         
        >
          <Visibility  />
        </IconButton>
      </Tooltip>
      <Tooltip
        key={'edit'+acc}
        id='tooltip-edit-top'
        title={'Edit'}
        placement='bottom'
      >
        <IconButton
          onClick={() => browserHistory.push(`/kitchen/cooking/dish-edit/${acc}`)}
          aria-label={'Edit'}
         
        >
          <Edit  />
        </IconButton>
      </Tooltip>
      <Tooltip
        key={'delete'+acc}
        id='tooltip-delete-top'
        title={'Delete'}
        placement='bottom'
      >
        <IconButton
          onClick={() =>deleteConfirm(acc)}
          aria-label={'Delete'}
         
        >
          <Delete  />
        </IconButton>
      </Tooltip>
    </Grid>
          {/* <Button color='darkBlue' size='large' onClick={() => browserHistory.push(`/admin/viewrecipe/${acc}`)}>View Recipe</Button> */}
      </div>;
     }});

    return <div className="mealrow-meals-recipe">{body}
    </div>;
  }
  renderContent() {
    const { list,dictionaries, actions } = this.props;
    let content = null;
    let cols = {};
    let rows = {};
    const { brandCategoryList } = dictionaries;
        var i=0;
        content=Object.keys(list).map((el, key) => {
      
          if(list[el]!=undefined){
            if(i==0){
              cols = {
                title: 'Dish',
                subheading: 'Sub Heading',
                varioations: 'Varioations',
                sopNotes: 'Sop Notes',
                actions: 'Actions'
             };
            }
            rows = this.getRowsByMeal(list[el],el);
            // console.log(rows);
            i=1;
            return Object.keys(rows).length ? <Fragment><div className="mealrow-meals-recipe"><p className="date-date">{brandCategoryList[el]}</p>
           {rows}</div></Fragment> : null;
          }
         
        });
        
    return content;
  }

  

  render() {
    const { page, maxPages, goTo, brandId,dictionaries } = this.props;
    const { brandList } = dictionaries;
    return (
      <div className='row'>
        {this.renderContent()}
        {maxPages > 1 && <Grid container>
          <ItemGrid xs={12} sm={12} md={12}>
            <Paginator goToPage={goTo} maxPage={maxPages} current={page} />
          </ItemGrid>
        </Grid>}
      </div>
    );
  }
}

List.propTypes = {
  dictionaries: PropTypes.object.isRequired,
  maxPages: PropTypes.number.isRequired,
  limit: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  list: PropTypes.object.isRequired,
  goTo: PropTypes.func.isRequired,
  splitter: PropTypes.object,
  actions: PropTypes.object,
  date: PropTypes.string
};

List.defaultProps = {
  limit: 20,
  page: 1,
  maxPages: 1,
  goTo: () => {},
  splitter: {},
  date: ''
};
export default List;
