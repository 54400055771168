import React, { Component } from 'react';
import { socketConnect } from 'socket.io-react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Text from 'components/Typography/Text';
import NoImg from 'assets/img/noimg.svg';
import { getArrays } from 'utils';
import ItemGrid from 'components/Grid/ItemGrid';
class ViewChefsReceipe extends Component {
  constructor(props) {
    super(props);
    const { id } = this.props.params;
    this.state = {
      id,
      dishData:{},
      dishComponents:{},
      ingredientPrices:{}
    };
   
  }

  listener = ({ data, type }) => {
    if (this.ViewChefsReceipeRef) {
      switch (type) {
          case 'getChefDishComIngsOk':
            this.setState({dishComponents:data})
          break;
          case 'getChefDishOk':
            this.setState({dishData:data});
            break;
         case 'getIngredientPricesOk':
          this.setState({
            ingredientPrices: data.supplierPrices, 
          });
        break;
        case 'dishIngredientsErr':
          console.log(data);
          //browserHistory.push('/');
          break;
      }
    }
  };

  componentWillMount() {
  
    this.props.socket.on('menu_builder', this.listener);
  }

  componentWillUnmount() {
    this.props.socket.removeListener('menu_builder', this.listener);
  }

  componentDidMount() {
   const { socket, dataArray, params } = this.props;
   const { id } = params;
    const types = ['typeList', 'ingredientListFull'].filter(el => !(el in dataArray));
    types.length && getArrays(socket, types);
    this.getIngredientPrices();
    this.getChefDish(id);
    this.getComponentData(id);
  }

  getChefDish = dishId => this.props.socket.emit('menu_builder', { type: 'getChefDish', data: { dishId } });
  getComponentData = (dish_id) => this.props.socket.emit('menu_builder', { type: 'getChefDishComIngs', data: { dish_id } });
  getIngredientPrices = () => this.props.socket.emit('menu_builder', { type: 'getIngredientPrices' });
  renderDishComponent = () =>{
      const { dishComponents } = this.state;
      var thead =[<th>Name</th>, <th>Qty</th>];
      const table = dishComponents && Object.keys(dishComponents).map((el,index) => {
        const { name,ingredients,prepare_method } = dishComponents[el];
        return (
          <div key={index}>
              <div className='row'><div className="col-lg-12 text-center rec-h-holder"><h5>"{(name!=undefined && name)?name.replace(/&amp;/g, "&"):name}" - Ingredients & Directions</h5></div></div>
              <div className="row">
              <div className="col-lg-6">
                <h6>Ingredients</h6>
                <table className="table table-bordered">
                    <thead className="recp-table-header">
                    <tr key={0}>
                        {thead}
                    </tr>
                    </thead>
                    <tbody>
                      {this.renderIngredientFields(ingredients)}  
                    </tbody>
                </table>
                </div>
                <div className="col-lg-6">
                    <h6>Directions</h6>
                    <p style={{whiteSpace: "pre-wrap"}}>{(prepare_method!=undefined && prepare_method)?prepare_method.replace(/&amp;/g, "&"):prepare_method}</p>
                </div>
              </div>
            </div>
        );
      });
      return table;
  }
  renderIngredientFields = (dishIngredients) => {
    const { dataArray } = this.props;
    const { ingredientListFull } = dataArray;  
    const { ingredientPrices } = this.state;
    let cost=0;
    const table = dishIngredients && ingredientListFull && ingredientPrices && Object.keys(dishIngredients).map((el,index) => {
      const { name , unit_name } = ingredientListFull[el];
      const { perprice } = ingredientPrices[el] || {};
      cost+=((dishIngredients[el].quantity>0&&perprice>0)?((perprice/1000)*dishIngredients[el].quantity):0);
      return (
          <tr key={index}><td>{name}</td><td>{dishIngredients[el].quantity} {unit_name}</td></tr>
      );
    });
    
    table.push(<tr><td>Cost</td><td>{Number(cost).toFixed(2) || 0}</td></tr>)
    return table;
  }
  renderContent = () => {
    
  }
  render() {
        const {  dataArray }  = this.props;
        const {  id, dishData,  }  = this.state;
        console.log(dishData);
        const { productsFull,portionSizeList,dishPacks } = dataArray;
        const RecipeInfo = ()=>{
       const {name,nickname,image,varioations,sop_notes}=dishData;        // console.log(productsFull[product_id]);
        var pimage = (<img  src={NoImg}></img>);
        if (image) {
            pimage = (<img  src={image}></img>);
        } 
        return <div >
          <div className="receipe-container chef-dish">
            <div className="rec-h-holder">
              <h2>{(name!=undefined && name)?name.replace(/&amp;/g, "&"):name}</h2>
            </div>
            <div className='text-center'>
                <h3>{(nickname!=undefined && nickname)?nickname.replace(/&amp;/g, "&"):nickname}</h3>
            </div>
            <div className="rec-body clearfix row">
                <div className="col-lg-6 rec-img-lt text-center">
                  {pimage}
                </div>
                <div className="col-lg-6">
                      <h5>Variations:</h5>
                      <p style={{whiteSpace: "pre-wrap"}}>{(varioations!=undefined && varioations)?varioations.replace(/&amp;/g, "&"):varioations}</p>
                      <h5>SOA Notes:</h5>
                      <p style={{whiteSpace: "pre-wrap"}}>{(sop_notes!=undefined && sop_notes)?sop_notes.replace(/&amp;/g, "&"):sop_notes}</p>
                </div>
            </div>
          <div className="rec-table-wrapper">
              {this.renderDishComponent()}
          </div>
          </div>
        </div>;
        }
       const content = (productsFull!=undefined)?<RecipeInfo />:'';
        return <div ref={el => (this.ViewChefsReceipeRef = el)}>
          {content}
        </div>;
  }
}

ViewChefsReceipe.propTypes = {
  socket: PropTypes.object.isRequired,
  params: PropTypes.object.isRequired, 
  dataArray: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  dataArray: state.dataArray
});

export default socketConnect(connect(mapStateToProps, null)(ViewChefsReceipe));
