import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { socketConnect } from 'socket.io-react';
import { connect } from 'react-redux';
import { Grid } from 'material-ui';
import { browserHistory } from 'react-router';
import { ChevronLeft, ErrorOutline } from '@material-ui/icons/index';
import RegularCard from 'components/Cards/RegularCard';
import Button from 'components/CustomButtons/Button';
import CustomInput from 'components/CustomInput/CustomInput';
import { setNotify, clearNotify } from 'store/notify';
import Select from 'components/Select';
import rstr from 'randomstring';
import Calendar from 'components/Calendar';
import moment from 'moment';

const names = { common: 'CommonVoucher', personal: 'Coupon' };
const forms = { common: 'CommonForm', personal: 'PersonalForm' };

class Form extends Component {
  constructor(props) {
    super(props);
    const { id, type: voucherType } = this.props.params;
    const modelName = names[voucherType];
    this.state = {
      id: id || 'add',
      voucherType,
      modelName,
      errors: {},
      attributes: {},
      labels: {}
    };
  }

  getFormData = (id, modelName) => this.props.socket.emit('other_tables', { type: 'get', data: { modelName, id } });

  listener = ({ type, data }) => {
    if (this.VouchersFormRef) {
      switch (type) {
        case 'getOk':
          this.setState(data);
          break;
        case 'setOk':
          this.showNotification('Saved successfully');
          browserHistory.push(`/admin/finances/vouchers/${this.state.voucherType}`);
          break;
        case 'setErr':
          this.showNotification('Something wrong', 'danger');
          this.setState(data);
          break;
      }
    }
  };

  componentWillMount() {
    const { id, modelName } = this.state;
    this.getFormData(id, modelName);
    this.props.socket.on('other_tables', this.listener);
  }

  componentWillUnmount() {
    this.props.socket.removeListener('other_tables', this.listener);
  }

  showNotification = (message = '', color = 'info') => {
    const { setNotify, clearNotify } = this.props;
    setNotify({
      place: 'tc',
      color,
      icon: ErrorOutline,
      message,
      open: true
    });
    setTimeout(() => clearNotify(), 5000);
  };

  submit = () => {
    const { id, attributes, modelName } = this.state;
    this.props.socket.emit('other_tables', {
      type: 'set',
      data: {
        modelName,
        attributes,
        id
      }
    });
  };

  changeForm = (name, value) => {
    const attributes = { ...this.state.attributes, [name]: value };
    name === 'type' && (attributes['value'] = 0);
    this.setState({ attributes });
  };
  isJsonString =(str) =>{
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
}
  CommonForm = ({ attributes, errors, labels, changeValue, style }) => {
    const { code, type, active, value, for_new_users, start_date, end_date, conditions } = attributes;
    const { type: typeProps,conditions:conditionProps  } = labels;
    const { items: typesList } = (typeProps || {}).props || {};
    const { items: mealTypes,multiple } = (conditionProps || {}).props || {};
    let condition = conditions;
    if(multiple&&this.isJsonString(conditions)){
      condition = JSON.parse(conditions);
    }
    return <RegularCard
      cardTitle={'Vouchers'}
      cardSubtitle='Edit info'
      headerColor='darkBlue'
      content={
        <div>
          <Grid container>
            <Grid item xs={12} sm={12} md={3} style={style}>
              <Select
                placeholder={'Type'}
                list={typesList}
                value={type}
                name={'type'}
                hasError={'type' in errors}
                onChange={this.changeForm}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={3} style={style}>
              <CustomInput
                labelText={'Value'}
                formControlProps={{
                  fullWidth: true
                }}
                error={'value' in errors}
                inputProps={{
                  value: value ? (type === 'fixed' ? +(value / 100).toFixed(0) : value) : 0,
                  type: 'number',
                  name: 'value',
                  onChange: e => changeValue('value', e.target.value, type)
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={3} style={style}>
              <Select
                placeholder={'Active'}
                list={{ 0: 'No', 1: 'Yes' }}
                value={active}
                name={'active'}
                hasError={'active' in errors}
                onChange={this.changeForm}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={3} style={style}>
              <Select
                placeholder={'For New Users Only'}
                list={{ 0: 'No', 1: 'Yes' }}
                value={for_new_users}
                name={'for_new_users'}
                hasError={'for_new_users' in errors}
                onChange={this.changeForm}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={3} style={style}>
              <Calendar
                date={start_date}
                name={'start_date'}
                title={'Start Date'}
                onChange={(value, name) => {
                  this.changeForm(name, value);
                  const date = moment.unix(value).utc();
                  if (end_date && date.isAfter(moment.unix(end_date))) {
                    setTimeout(() => this.changeForm('end_date', date.add(1, 'd').unix()), 10);
                  }
                }}
                controlled
                error={'start_date' in errors}
                formControlProps={{
                  fullWidth: true
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={3} style={style}>
              <Calendar
                date={end_date}
                name={'end_date'}
                title={'End Date'}
                minDate={start_date}
                disabled={!start_date}
                onChange={(value, name) => {
                  const date = moment.unix(value).utc();
                  if (start_date && date.isAfter(moment.unix(start_date))) {
                    this.changeForm(name, date.unix());
                  }
                }}
                controlled
                error={'end_date' in errors}
                formControlProps={{
                  fullWidth: true
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={3} style={style}>
              <CustomInput
                labelText={'Code'}
                formControlProps={{
                  fullWidth: true
                }}
                error={'code' in errors}
                inputProps={{
                  value: code || '',
                  type: 'text',
                  name: 'code',
                  onChange: e => this.changeForm('code', e.target.value)
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={3} style={style}>
              <Button onClick={() => this.changeForm('code', rstr.generate(10))}>Generate</Button>
            </Grid>
            <Grid item xs={12} sm={12} md={3} style={style}>
              <Select
                placeholder={'Meal Type(Only work type credit)'}
                list={mealTypes}
                value={conditions}
                name={'conditions'}
                hasError={'conditions' in errors}
                onChange={this.changeForm}
                multiple={true}
              />
            </Grid>
          </Grid>
        </div>
      }
      footer={<Button color='darkBlue' onClick={this.submit}>Save</Button>}
    />;
  };

  PersonalForm = ({ attributes, errors, labels, changeValue, style }) => {
    const { code, user_id, type, used, value } = attributes;
    const { user_id: userProps, type: typeProps } = labels;
    const { items: typesList } = (typeProps || {}).props || {};
    const { items: usersList } = (userProps || {}).props || {};
    return <RegularCard
      cardTitle={'Vouchers'}
      cardSubtitle='Edit info'
      headerColor='darkBlue'
      content={
        <div>
          <Grid container>
            <Grid item xs={12} sm={12} md={6} style={style}>
              <Select
                placeholder={'User'}
                list={usersList}
                value={user_id}
                name={'user_id'}
                hasError={'user_id' in errors}
                onChange={this.changeForm}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} style={style}>
              <Select
                placeholder={'Type'}
                list={typesList}
                value={type}
                name={'type'}
                hasError={'type' in errors}
                onChange={this.changeForm}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} style={style}>
              <CustomInput
                labelText={'Value'}
                formControlProps={{
                  fullWidth: true
                }}
                error={'value' in errors}
                inputProps={{
                  value: value ? (type === 'fixed' ? +(value / 100).toFixed(0) : value) : 0,
                  type: 'number',
                  name: 'value',
                  onChange: e => changeValue('value', e.target.value, type)
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} style={style}>
              <Select
                placeholder={'Used'}
                list={{ 0: 'No', 1: 'Yes' }}
                value={used}
                name={'used'}
                hasError={'used' in errors}
                onChange={this.changeForm}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={3} style={style}>
              <CustomInput
                labelText={'Code'}
                formControlProps={{
                  fullWidth: true
                }}
                error={'code' in errors}
                inputProps={{
                  value: code || '',
                  type: 'text',
                  name: 'code',
                  onChange: e => this.changeForm('code', e.target.value)
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={9} style={style}>
              <Button onClick={() => this.changeForm('code', rstr.generate(10))}>Generate</Button>
            </Grid>
          </Grid>
        </div>
      }
      footer={<Button color='darkBlue' onClick={this.submit}>Save</Button>}
    />;
  };

  get form() {
    const form = forms[this.state.voucherType];
    return this[form];
  }

  render() {
    const { attributes, errors, labels, voucherType } = this.state;
    const changeValue = (name, _value, type) => {
      let value = 0;
      const [min, max] = [1, 100];
      if (type === 'percent') {
        value = _value < min ? min : (_value > max ? max : _value);
      } else if (type === 'fixed') {
        value = Math.max(1, _value * 100);
      }
      this.changeForm(name, value);
    };
    const style = { padding: '0 15px', margin: '15px 0' };
    return (
      <div ref={el => (this.VouchersFormRef = el)}>
        <Grid container justify={'center'}>
          <Grid item xs={12} sm={12} md={12}>
            <Button color='darkBlue' size='medium' onClick={() => browserHistory.push(`/admin/finances/vouchers/${voucherType}`)}><ChevronLeft /></Button>
            <this.form {...{ attributes, errors, labels, changeValue, style }} />
          </Grid>
        </Grid>
      </div>
    );
  }
}

Form.propTypes = {
  socket: PropTypes.object.isRequired,
  params: PropTypes.object.isRequired,
  setNotify: PropTypes.func.isRequired,
  clearNotify: PropTypes.func.isRequired
};

const actions = dispatch => ({
  setNotify: obj => dispatch(setNotify(obj)),
  clearNotify: obj => dispatch(clearNotify(obj))
});

export default connect(null, actions)(socketConnect(Form));
