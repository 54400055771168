import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { socketConnect } from 'socket.io-react';
import { Grid } from 'material-ui';
import Filters from './filters';
import FoodCostFilters from './FoodCostFilters';
import Button from 'components/CustomButtons/Button';
import { ReasonModal, AddModal, ReasonPayModal } from '../Customers/Details/Modals';
import { clearModal, setModal } from 'store/modal';
import { spin, stop } from 'store/spinner';
import { getArrays, parseDates,getExcludeWeek } from 'utils';
import { browserHistory } from 'react-router';
// import createBrowserHistory from 'history/createBrowserHistory';
import { createBrowserHistory, History } from 'history';
import ShowMoreText from "react-show-more-text";
import moment from 'moment/moment';

class Reports extends Component { 
  constructor(props) {
    super(props);
    // const today = moment().utc().startOf('day');
    this.state = {
      thetype : 'counter',
      mealplanCounter: {},
      customerCounter: {},
      customerDishWish: {},
      dishRating: {},
      ordering: {},
      compdata: {},
      stats: [],
      average: [],
      reasons: [],
      comments: [],
      foodCostList: false,
      dishCostPrice: [],
      revenueSales:[],
      deliveryCounts:[],
      dateFrom:moment().clone().startOf('month').unix(),
      dateTo:moment().clone().endOf('month').unix(),
      notRewFeedbacks:{},
      mobileAppData:{}
    };
    this.getMealplanCounter();
  }

  orderListener = ({ type, data }) => {
      switch (type) {
        case 'getMealplanCounterOk':
          this.setState({
            mealplanCounter: data.res,
            ordering: data.orders,
          });
          //console.log(data);
        break;
        case 'getCustomerCounterOk':
          this.setState({
            customerCounter: data.res
          });
          //console.log(data);
        break;
        case 'customerLikeDisLikeCounterOk':
          this.setState({
            customerDishWish: data.res
          });
        break;
        case 'dishRatingOk':
          this.setState({
            dishRating: data.res
          });
        break;
        case 'getCompaniesListOk':
          this.setState({
            compdata: data.res,
          });
          //console.log(data);
        break;
        case 'getStatsOk':
          console.log(data);
          this.setState({
            'stats' : data.stats,
            'average' : data.average,
          });
        break;
        case 'getReasonsStatsOk':
          console.log(data);
          this.setState({
            'reasons' : data.stats,
            'comments' : data.comments,
          });
        break;
        case 'getDishCostPriceOk':
          this.setState({dishCostPrice:data.dishCostPrice});
          break;
        case 'getReportFoodCostOk':
          this.setState({
            'foodCostList' : data.list
          });
        break;
        case 'getFoodCostRevenueOk':
          this.setState({
            'revenueSales' : data.list,
            'deliveryCounts':data.deliveryCounts
          });
        break;
        case 'getNotRewFeedbackOk':
          this.setState({
            'notRewFeedbacks' : data.totalRating
          });
        break;
        case 'getAppReportOk':
          this.setState({
            'mobileAppData' : data
          });
        break;
      }
  };

  componentWillMount() {
    this.props.socket.on('order', this.orderListener);
    this.props.socket.on('profile', this.orderListener);
    this.props.socket.on('kitchen', this.orderListener);
    this.props.socket.on('rating', this.orderListener);
    this.props.socket.on('mobile_checkout', this.orderListener);
    
    const { dataArray, socket } = this.props;
    const types = ['planList', 'typeList', 'dietList', 'slotsList', 'productsFull', 'products', 'priceList', 'commonVouchers', 'couponTypes', 'paymentMethods', 'companies'].filter(el => !(el in dataArray));
    getArrays(socket, types);
    //clearDefaultDishes();
  }

  componentWillUnmount() {
    this.props.socket.removeListener('order', this.orderListener);
    this.props.socket.removeListener('profile', this.orderListener);
    this.props.socket.removeListener('kitchen', this.orderListener);
    this.props.socket.removeListener('rating', this.orderListener);
    this.props.socket.removeListener('mobile_checkout', this.orderListener);
  }

  getMealplanCounter = () => {
    this.props.socket.emit('order', { type: 'getMealplanCounter', data: { dateFrom: null, dateTo: null } });
  };

  getCompaniesList = () => {
    this.props.socket.emit('order', { type: 'getCompaniesList', data: { dateFrom: null, dateTo: null } });
  };

  getStatistics = () => {
    this.props.socket.emit('order', { type: 'getAllRenewStats', data: { dateFrom: null, dateTo: null } });
  };

  getReasonStatistics = () => {
    this.props.socket.emit('order', { type: 'getStats', data: { dateFrom: null, dateTo: null } });
  };
  getCustomerCount = () => {
    this.props.socket.emit('profile', { type: 'getCustomerCounter', data: { dateFrom: null, dateTo: null } });
  };
  getFoodCost = (dateFrom,dateTo) => {
    this.props.socket.emit('kitchen', { type: 'getReportFoodCost', data: { dateFrom: dateFrom, dateTo: dateTo } });
  };
  getFoodCostRevenue = (dateFrom,dateTo) => {
    this.props.socket.emit('kitchen', { type: 'getFoodCostRevenue', data: { dateFrom: dateFrom, dateTo: dateTo } });
  };
  getDishCostPrice() {
    this.props.socket.emit('kitchen', {
      type: 'getDishCostPrice'
    });
  }
  getDishRating= () => {
    this.props.socket.emit('profile', { type: 'dishRating', data: { dateFrom: null, dateTo: null } });
  };
  getCustomerLikeDishLikeCount= () => {
    this.props.socket.emit('profile', { type: 'customerLikeDisLikeCounter', data: { dateFrom: null, dateTo: null } });
  };
  getNotRewFeedback = () => {
    this.props.socket.emit('rating', { type: 'getNotRewFeedback' });
  };
  exportFoodRevenueCSV(dateFrom) {
    window.open(
      'http://basiligo:basiligo2019!@healthyme.basiligo.ae/admin/site/dailyfoodsalecsv?date='+dateFrom,
      '_blank' // <- This is what makes it open in a new window.
    );
  }
  getMobileAPPMenu = () => {
    this.props.socket.emit('mobile_checkout', { type: 'getAppReport' });
  };
  render() {
    const {thetype, mealplanCounter, ordering,customerCounter,customerDishWish,dishRating, mobileAppData } = this.state;
    const { dataArray } = this.props;
    const {dietList, planList, typeList} = dataArray;
   // console.log(dataArray);
   // console.log(planList);
    var rows = [];
    switch (thetype) {
      case 'counter':
          Object.keys(mealplanCounter).map( (year, idn) => {
             
              rows.push(
                      <tr className="days-left-line">
                        <td colSpan="5">{year}</td>
                      </tr>
              );

              Object.keys(mealplanCounter[year]).map( (month, idm) => {
                  rows.push(
                      <tr className="days-month-line">
                        <td colSpan="5">{month} {year}</td>
                      </tr>
                  );
                  var total = 0;
                  var count = 0;
                  
                 //Object.keys(mealplanCounter[year][month]).map( (diet, idl) => {
                    var ord = ordering[year][month];
                    Object.keys(ord).map( (diet, idl) => {
                      //console.log(mealplanCounter[year][month][parseInt(dietord)]);
                      diet = parseInt(diet);
                      var el = mealplanCounter[year][month][diet];
                      if (typeof(el) != 'undefined' && el) {
                        var ovrow = [];
                      
                        total = total + parseFloat(el.price);
                        count = count + el.amount;
                        Object.keys(el.overview).map( (ov, ids) => {
                          if(typeof el.overview[ov] === 'object'){
                            Object.keys(el.overview[ov]).map( (ov1, ids) => {
                              const exculde_week = ov1.split("-"); 
                              ovrow.push(<li>{ov} - {getExcludeWeek(exculde_week)} <b>x {el.overview[ov][ov1]} </b></li>);
                            });
                          }else{
                            ovrow.push(<li>{(planList[ov]!=undefined)?(planList[ov].title || 'not detected'):ov} <b>x {el.overview[ov]}</b></li>);
                          }
                        });

                        rows.push( 
                          <tr>
                            <td>{idl+1}</td>
                            <td>{dietList[diet]}</td>
                            <td><ul className="report-ul">{ovrow}</ul></td>
                            <td>{el.amount}</td>
                            <td>{el.price.toFixed(2)} AED</td>
                          </tr>
                        );
                      }
                    });

                    

                  //});
                  rows.push(
                      <tr className="total-row">
                        <td colSpan="3" align="left" className="left-align-td"><b>Total Results - {month} {year}</b></td>
                        <td><b>{count}</b></td>
                        <td><b>{total.toFixed(2)} AED</b></td>
                      </tr>
                  );
              });
              
          });
      break;
      case 'renewreasons':
        const {reasons, comments} = this.state;
        //console.log(reasons);
        console.log(comments);
        const reasonList = [
          '',
          'Traveling',
          'Didn’t like the food',
          'No much options',
          'Delivery Timings',
          'Not my macros',
          'Expensive',
          'No Discounts',
          'Others',
          'No response / reply after contacting',
          'No Feedback ',
          'Undecided, will contact later',
        ];
        var rows = [];

        Object.keys(reasons).map((el, idx) => {
          var com = [];
          console.log(comments[el]);
          Object.keys(comments[el]['comment']).map( (eln, idxn) => {
            com.push(<li key={idxn}>{comments[el]['comment'][eln]}</li>);
          });

          rows.push(
            <tr key={idx}>
              <td>{idx+1}</td>
              <td>{reasonList[el]}</td>
              <td>{reasons[el]}</td>
              <td className="notelist">
                <ul>
                  {com}
                </ul>
              </td>
            </tr>
          )
        });
      break;
      case 'renewstats':
        const {stats} = this.state;
        var {average} = this.state;
        //console.log(reasons);
        average = parseFloat(average).toFixed(2);
        var rows = [];

        Object.keys(stats).map((year, idx) => {
          Object.keys(stats[year]).map((month, idl) => {
          rows.push(
            <tr key={idl}>
              <td>{idl+1}</td>
              <td>{month} {year}</td>
              <td>{stats[year][month]}</td>
            </tr>
          )
          });
        });
      break;
      case 'companies':
        const {compdata} = this.state;
        const {dataArray} = this.props;
        const {companies, userList} = dataArray;
        Object.keys(compdata).map( (company_id, idx) => {
          var amount = compdata[company_id].amount;
          var customers = compdata[company_id].customers;
          var customerList = [];
          Object.keys(customers).map( (users, idl) => {
            Object.keys(customers[users]).map( (user_id, idx) => {
              customerList.push(<li>
                <a className="pointer" onClick={ (e) => {
                  browserHistory.push(`/admin/customers/details/${user_id}`);
                }}>{customers[users][user_id]}</a>
              </li>);
            });
            
          });
          rows.push( 
                          <tr>
                            <td>{idx+1}</td>
                            <td>{companies[company_id]}</td>
                            <td><ul className="report-ul">{customerList}</ul></td>
                            <td>{amount}</td>
                          </tr>
          );
        });
      break;
      case 'customercounter':
        (customerCounter)?Object.keys(customerCounter).reverse().map( (year, idn) => {
          rows.push(
                  <tr className="days-left-line">
                    <td colSpan="7">{year}</td>
                  </tr>
          );
          
          Object.keys(customerCounter[year]).map( (month, idm) => {
            var num = 0;
            var dayold = 99;
            var totalcustomer=0;
            var totalrenewvals=0;
            var totalrenewed=0;
            var totalexistcustomer=0;
            rows.push(
              <tr className="days-month-line">
                <td colSpan="7">{moment().month(parseInt(month)-1).format("MMM")+', '+year}</td>
              </tr>
          );
            Object.keys(customerCounter[year][month]).map( (day, idn1) => {
              if (day != dayold) {
                num = num+1;
              }
              dayold = day;
              totalcustomer+=parseInt(customerCounter[year][month][day]['customers']);
              totalrenewvals+=parseInt(customerCounter[year][month][day]['renewvals']);
              totalexistcustomer+=parseInt(customerCounter[year][month][day]['rnexcustomer']);
              totalrenewed+=parseInt(customerCounter[year][month][day]['renewed']);
              rows.push(
                  <tr className="total-row">
                    <td>{'Day '+num}</td>
                    <td>{day+' '+moment().month(parseInt(month)-1).format("MMM")+', '+year}</td>
                    <td><b>{customerCounter[year][month][day]['customers']}</b></td>
                     <td><b>{customerCounter[year][month][day]['rnexcustomer']}</b></td>
                    <td><b>{customerCounter[year][month][day]['renewvals']}</b></td>
                    <td><b>{customerCounter[year][month][day]['renewed']}</b></td>
                    <td><b>{(customerCounter[year][month][day]['renewed']>0&&customerCounter[year][month][day]['renewvals']>0)?Math.floor((customerCounter[year][month][day]['renewed']/customerCounter[year][month][day]['renewvals'])*100):0}</b></td>
                  </tr>
              );
          });
        rows.push(<tr className="row-border-top">
                  <td colspan="2" align="left"><b>Total ({moment().month(parseInt(month)-1).format("MMM")}, {year}):</b></td>
                  <td><b>Total Customers</b></td>
                  <td><b>Total Existing Returning Customers</b></td>
                  <td className="green-text"> <b>Total Upcoming no of renewals</b></td>
                  <td className="green-text"> <b>Total Renewed</b></td>
                  <td className="green-text"><b>Renewal %</b></td>
          </tr>)
        rows.push(<tr className="row-border-top">
          <td colspan="2" align="left"></td>
          <td><b>{totalcustomer}</b></td>
          <td><b>{totalexistcustomer}</b></td>
          <td> <b>{totalrenewvals}</b></td>
          <td> <b>{totalrenewed}</b></td>
          <td><b>{(totalrenewed>0&&totalrenewvals>0)?Math.floor((totalrenewed/totalrenewvals)*100):0}</b></td>
        </tr>)
        });
          
      }):'';
      break;
      case 'customerdishwish':
        (customerDishWish)?Object.keys(customerDishWish).map( (year, idn) => {
          rows.push(
                  <tr className="days-left-line">
                    <td colSpan="7">{year}</td>
                  </tr>
          );
          
          Object.keys(customerDishWish[year]).reverse().map( (month, idm) => {
            var num = 0;
            var dayold = 99;
            rows.push(
              <tr className="days-month-line">
                <td colSpan="4">{moment().month(parseInt(month)-1).format("MMM")+', '+year}</td>
              </tr>
          );
            Object.keys(customerDishWish[year][month]).reverse().map( (day, idn1) => {
              if (day != dayold) {
                num = num+1;
              }
              dayold = day;
              rows.push(
                <tr className="days-day-line">
                  <td colSpan={4}>{day+' '+moment().month(parseInt(month)-1).format("MMM")+', '+year}</td>
                </tr>
            );
              Object.keys(customerDishWish[year][month][day]).map( (mealType, idn1) => {
              Object.keys(customerDishWish[year][month][day][mealType]).map( (dishId, idn1) => {
                var meal_type= (typeList[mealType]!=undefined)?typeList[mealType].title:'';
              rows.push(
                  <tr className="total-row">
                    <td colSpan={1} align="left" className='dish-name'>{customerDishWish[year][month][day][mealType][dishId]['name']}</td>
                    <td align="left">{meal_type}</td>
                    <td><b>{(customerDishWish[year][month][day][mealType][dishId]['likes']!=undefined)?customerDishWish[year][month][day][mealType][dishId]['likes']:0}</b></td>
                    <td><b>{(customerDishWish[year][month][day][mealType][dishId]['dislikes']!=undefined)?customerDishWish[year][month][day][mealType][dishId]['dislikes']:0}</b></td>
                  </tr>
              );
              });
            });
          });
          
        });
          
      }):'';
      break;
      case 'dishrating':
        (dishRating)?Object.keys(dishRating).reverse().map( (year, idn) => {
            rows.push(
                    <tr className="days-left-line">
                      <td colSpan="7">{year}</td>
                    </tr>
            );
            
            Object.keys(dishRating[year]).map( (month, idm) => {
              var num = 0;
              var dayold = 99;
              var totallove=0;
              var totalgood=0;
              var totalok=0;
              var totalnotbad=0;
              var totalbad=0;
              var totalbad=0;
              rows.push(
                <tr className="days-month-line">
                  <td colSpan="7">{moment().month(parseInt(month)-1).format("MMM")+', '+year}</td>
                </tr>
            );
              Object.keys(dishRating[year][month]).map( (day, idn1) => {
                if (day != dayold) {
                  num = num+1;
                }
                dayold = day;
                rows.push(
                  <tr className="total-row">
                    <td colSpan={7}>{day+' '+moment().month(parseInt(month)-1).format("MMM")+', '+year}</td>
                  </tr>
              );
                Object.keys(dishRating[year][month][day]).map( (dishId, idn1) => {
                  var love=(dishRating[year][month][day][dishId][5]!=undefined)?dishRating[year][month][day][dishId][5]:0;
                  var good=(dishRating[year][month][day][dishId][4]!=undefined)?dishRating[year][month][day][dishId][4]:0;
                  var ok=(dishRating[year][month][day][dishId][3]!=undefined)?dishRating[year][month][day][dishId][3]:0;
                  var notbad=(dishRating[year][month][day][dishId][2]!=undefined)?dishRating[year][month][day][dishId][2]:0;
                  var bad=(dishRating[year][month][day][dishId][1]!=undefined)?dishRating[year][month][day][dishId][1]:0;
                  totallove+=parseInt(love);
                  totalgood+=parseInt(good);
                  totalok+=parseInt(ok);
                  totalnotbad+=parseInt(notbad);
                  totalbad+=parseInt(bad);
                rows.push(
                    <tr className="total-row">
                      <td colSpan={1}>{dishRating[year][month][day][dishId]['name']}</td>
                      <td><b>{love}</b></td>
                      <td><b>{good}</b></td>
                      <td><b>{ok}</b></td>
                      <td><b>{notbad}</b></td>
                      <td><b>{bad}</b></td>
                      <td><b>{(dishRating[year][month][day][dishId]['count']!=undefined)?dishRating[year][month][day][dishId]['count']:0}</b></td>
                    </tr>
                );
                });
            });
            rows.push(<tr className="row-border-top">
              <td colspan="1" align="left"><b>Total ({moment().month(parseInt(month)-1).format("MMM")}, {year}):</b></td>
              <td className="green-text"><b>Total "Love it"</b></td>
              <td className="green-text"><b>Total "Good"</b></td>
              <td className="green-text"> <b>Total "Its ok" </b></td>
              <td> <b>Total "Not good"</b></td>
              <td><b>Total "Bad"</b></td>
              <td></td>
      </tr>)
      rows.push(<tr className="row-border-top">
      <td colspan="1" align="left"></td>
      <td><b>{totallove}</b></td>
      <td><b>{totalgood}</b></td>
      <td> <b>{totalok}</b></td>
      <td> <b>{totalnotbad}</b></td>
      <td> <b>{totalbad}</b></td>
      <td></td>
</tr>)
          });
            
        }):'';
      break;
      case 'foodcost':
        const { foodCostList,dishCostPrice } = this.state;
          Object.keys(foodCostList).reverse().map( (date) => {
              let datas = []; 
              let foodcostpertotal=0;
              let foodcosttotal=0;
              let totaldaylen=0;
              Object.keys(foodCostList[date]).map((mealtypename)=>{
                  let foodcostpert=0;
                  let foodcostto =0;
                  let totalcost=0;
                  let totalprice=0;
                  let totallength = Object.keys(foodCostList[date][mealtypename]).length;
                  datas[mealtypename]=[];
                  Object.keys(foodCostList[date][mealtypename]).map((dishId)=>{
                    const { count,saleprice } = foodCostList[date][mealtypename][dishId];
                    let foodcost = dishCostPrice[dishId] || 0;
                    foodcost =(foodcost>0)?foodcost.toFixed(2):0;
                    let persale  = (saleprice>0)?(saleprice/100).toFixed(2):0;
                    let foodcostper  = (foodcost>0&&persale>0)?Math.round((foodcost/persale).toFixed(2)*100):0;
                    foodcostto+=parseFloat(foodcost);
                    foodcostpert+=foodcostper;
                    totalcost+=(foodcost*count);
                    totalprice+=(persale*count);
                  });
                  totaldaylen+=totallength;
                  foodcostpertotal+=(foodcostpert/totallength);
                  foodcosttotal+=totalcost;
                  // console.log(totaldaylen);
                  datas[mealtypename].push({
                    'foodcostpert':foodcostpert/totallength,
                    'foodcostto':foodcostto,
                    'totalcost':totalcost,
                    'totalprice':totalprice
                  })
                  
              });
              rows.push(
                      <tr className="days-left-line">
                        <td >{moment.unix(date).format("ddd, MMMM Do YYYY")}</td>
                        <td >{(foodcostpertotal.toFixed(2))}</td>
                        <td >{(foodcosttotal/totaldaylen).toFixed(2)}</td>
                        <td >{(datas['Breakfast']!=undefined)?(datas['Breakfast'][0]['foodcostpert'].toFixed(2)*100)/100:0}</td>
                        <td >{(datas['Breakfast']!=undefined)?datas['Breakfast'][0]['totalcost'].toFixed(2):0}</td>
                        <td >{(datas['Mains']!=undefined)?(datas['Mains'][0]['foodcostpert'].toFixed(2)*100)/100:0}</td>
                        <td >{(datas['Mains']!=undefined)?datas['Mains'][0]['totalcost'].toFixed(2):0}</td>
                        <td >{(datas['Snacks']!=undefined)?(datas['Snacks'][0]['foodcostpert'].toFixed(2)*100)/100:0}</td>
                        <td >{(datas['Snacks']!=undefined)?datas['Snacks'][0]['totalcost'].toFixed(2):0}</td>
                      </tr>
              );
          });
      break;
      case 'foodcostrevenue':
        const { revenueSales,deliveryCounts } = this.state;
        Object.keys(revenueSales).map( (year, idx) => {
          // Object.keys(revenueSales[year]).map( (month, idl) => {
           
           for (var month=12; month>0; month--) {
             if (typeof(revenueSales[year][month]) != 'undefined') {
               var refund_amount = 0;
               var refund_count = 0;
               rows.push(<tr className="days-left-line">
                 <td colspan="6">{moment().month(parseInt(month)-1).format("MMM")}, {year}</td>
               </tr>);
               var num = 0;
               var dayold = 99;
               var totals = revenueSales[year][month]['total'];
     
               var saleprice = 0;
               
               var saleprice = 0;
               var cost = 0; 
               var foodcostper = 0;
               var earning = 0;
              var total_saleprice = 0;
              var total_cost = 0;
              var total_foodcostper = 0;
              var total_earning = 0;
              var date='';
             Object.keys(revenueSales[year][month]).map( (day, idm) => {
              date =revenueSales[year][month][day]['date'];
              saleprice=revenueSales[year][month][day]['saleprice']/100;
              cost=revenueSales[year][month][day]['cost'];
              foodcostper=(cost>0&&saleprice>0)?Math.round((cost/saleprice) * 100):0;
              earning=(cost>0&&saleprice>0)?saleprice-cost:0;
              total_saleprice+=saleprice;
              total_cost+=cost;
              total_foodcostper+=foodcostper;
              total_earning+=earning;
              rows.push(<tr className="finday">
                <td >{day+' '+moment().month(parseInt(month)-1).format("MMM")+', '+year}</td>
                <td >{deliveryCounts[date]}</td>
                <td ><b>{(saleprice).toFixed(2)} AED</b></td>
                <td><b>{(cost).toFixed(2)} AED</b></td>
                <td ><b>{foodcostper} % </b></td>
                <td className="green-text"><b>{earning.toFixed(2)}</b></td>
              </tr>);
             });
     
            
     
             rows.push(<tr className="row-border-top">
                     <td colspan="3" align="left"><b>Total ({moment().month(parseInt(month)-1).format("MMM")}, {year}):</b></td>
                     <td><b>Total Sale Price</b></td>
                     <td > <b>Total Cost</b></td>
                     <td className="green-text"> <b>Total Earning</b></td>
             </tr>)
             
            
               rows.push(<tr className="row-border-top-final">
                     <td colspan="3" align="left">Final Total Results:</td>
                     <td><b>{total_saleprice.toFixed(2)}</b></td>
                     <td ><b>{total_cost.toFixed(2)} AED</b></td>
                     <td className="green-text" ><b>{total_earning.toFixed(2)} AED</b></td>
               </tr>)
           //});
           } }
         });
      break;
      case 'notrenewfeedback':
        const {notRewFeedbacks} = this.state;
        // console.log(notRewFeedbacks);
        (notRewFeedbacks)?Object.keys(notRewFeedbacks).reverse().map( (year, idn) => {
          rows.push(
                  <tr className="days-left-line">
                    <td colSpan="10">{year}</td>
                  </tr>
          );
          Object.keys(notRewFeedbacks[year]).map( (month, idm) => {
          
            rows.push(
              <tr className="days-month-line">
                <td colSpan="10">{moment().month(parseInt(month)-1).format("MMM")+', '+year}</td>
              </tr>
          );
            Object.keys(notRewFeedbacks[year][month]).map( (day, idn1) => {
            //   rows.push(
            //     <tr className="total-row">
            //       <td colSpan={7}>{day+' '+moment().month(parseInt(month)-1).format("MMM")+', '+year}</td>
            //     </tr>
            // );
              Object.keys(notRewFeedbacks[year][month][day]).map( (dishId, idn1) => {
              const  { firstName, lastName, rating, rating2, rating3, rating4, feedback, user_id,inspirefeedback,healthfeedback,improvementfeed } =notRewFeedbacks[year][month][day][dishId];
              rows.push(
                  <tr className="total-row" key={dishId}>
                    <td >{day+' '+moment().month(parseInt(month)-1).format("MMM")+', '+year}</td>
                    <td ><a className="pointer" onClick={ (e) => {
                  browserHistory.push(`/admin/customers/details/${user_id}`);
                }}>{firstName} {lastName}</a></td>
                    <td><b>{rating}</b></td>
                    <td><b>{rating2}</b></td>
                    <td><b>{rating3}</b></td>
                    <td><b>{rating4}</b></td>
                    <td className="green-text">
                        <ShowMoreText
                        /* Default options */
                        lines={3}
                        more="Show more"
                        less="Show less"
                        className="content-css"
                        anchorClass="my-anchor-css-class"
                        // onClick={this.executeOnClick}
                        expanded={false}
                        width={280}
                        truncatedEndingComponent={"... "}
                    >
                      {feedback}
                    </ShowMoreText>
                        </td>
                  <td className="green-text">
                        <ShowMoreText
                        /* Default options */
                        lines={3}
                        more="Show more"
                        less="Show less"
                        className="content-css"
                        anchorClass="my-anchor-css-class"
                        // onClick={this.executeOnClick}
                        expanded={false}
                        width={280}
                        truncatedEndingComponent={"... "}
                    >
                      {inspirefeedback}
                    </ShowMoreText>
                        </td>
                        <td className="green-text">
                        <ShowMoreText
                        /* Default options */
                        lines={3}
                        more="Show more"
                        less="Show less"
                        className="content-css"
                        anchorClass="my-anchor-css-class"
                        // onClick={this.executeOnClick}
                        expanded={false}
                        width={280}
                        truncatedEndingComponent={"... "}
                    >
                      {healthfeedback}
                    </ShowMoreText>
                        </td>
                        <td className="green-text">
                        <ShowMoreText
                        /* Default options */
                        lines={3}
                        more="Show more"
                        less="Show less"
                        className="content-css"
                        anchorClass="my-anchor-css-class"
                        // onClick={this.executeOnClick}
                        expanded={false}
                        width={280}
                        truncatedEndingComponent={"... "}
                    >
                      {improvementfeed}
                    </ShowMoreText>
                        </td>
                  </tr>
              );
              });
          });
        });
        }):'';
      break;
      case 'addedmenuapp':
          (mobileAppData)?Object.keys(mobileAppData).reverse().map( (year, idn) => {
              rows.push(
                      <tr className="days-left-line">
                        <td colSpan="4">{year}</td>
                      </tr>
              );
              
              Object.keys(mobileAppData[year]).map( (month, idm) => {
                var num = 0;
                var dayold = 99;
                var totalpaidcustomer=0;
                var totalnotpaidcustomer=0;
                rows.push(
                  <tr className="days-month-line">
                    <td colSpan="4">{moment().month(parseInt(month)-1).format("MMM")+', '+year}</td>
                  </tr>
              );
                Object.keys(mobileAppData[year][month]).map( (day, idn1) => {
                  if (day != dayold) {
                    num = num+1;
                  }
                  dayold = day;
                  totalpaidcustomer+=parseInt(mobileAppData[year][month][day]['paid']);
                  totalnotpaidcustomer+=parseInt(mobileAppData[year][month][day]['not_paid']);
                  rows.push(
                      <tr className="total-row">
                        <td>{day+' '+moment().month(parseInt(month)-1).format("MMM")+', '+year}</td>
                        <td><b>{mobileAppData[year][month][day]['paid']}</b></td>
                        <td><b>{mobileAppData[year][month][day]['not_paid']}</b></td>
                        <td><b>{mobileAppData[year][month][day]['not_paid']+mobileAppData[year][month][day]['paid']}</b></td>
                      </tr>
                  );
              });
              rows.push(<tr className="row-border-top">
              <td colspan="1" align="left"><b>Total ({moment().month(parseInt(month)-1).format("MMM")}, {year}):</b></td>
              <td><b>Total Paid</b></td>
              <td><b>Total Not Paid</b></td>
              <td><b>Total</b></td>
      </tr>)
      rows.push(<tr className="row-border-top">
      <td colspan="1" align="left"></td>
      <td><b>{totalpaidcustomer}</b></td>
      <td><b>{totalnotpaidcustomer}</b></td>
      <td><b>{totalpaidcustomer+totalnotpaidcustomer}</b></td>
</tr>)
            });
              
          }):'';
      break;
    }
    return (
      <Grid container>
        <Grid item md={12} className="whiteArea">
            <h1>Reports</h1>
            
            {thetype == 'foodcost' && <Filters applyFilters={(filter) => {
              console.log(filter);
                this.getFoodCost(filter['periodFrom'],filter['periodTo']);
                setTimeout(() => this.setState({dateFrom:filter['periodFrom'],dateTo:filter['periodTo'] }), 50);
              }} socket={this.props.socket}/>}
            
            <Button color={(thetype == 'counter') ? 'success' : 'warning'} size='medium' onClick={() => {
              this.setState({
                thetype: 'counter',    
              });
            }}>Mealplans Counter</Button>

            <Button color={(thetype == 'companies') ? 'success' : 'warning'} size='medium' onClick={() => {
              this.setState({
                thetype: 'companies',    
              });
              this.getCompaniesList();
            }}>Companies List</Button>

            <Button color={(thetype == 'renewstats') ? 'success' : 'warning'} size='medium' onClick={() => {
              this.setState({
                thetype: 'renewstats',    
              });
              //this.getCompaniesList();
              this.getStatistics();
            }}>Renewals Statistics</Button>

            <Button color={(thetype == 'renewreasons') ? 'success' : 'warning'} size='medium' onClick={() => {
              this.setState({
                thetype: 'renewreasons',    
              });
              this.getReasonStatistics();
              //this.getCompaniesList();
            }}>Not Renew reasons</Button>
             <Button color={(thetype == 'customerdishwish') ? 'success' : 'warning'} size='medium' onClick={() => {
              this.setState({
                thetype: 'customerdishwish',    
              });
              this.getCustomerLikeDishLikeCount();
            }}>Customer Dish Dislikes & Dish Likes</Button>
              <Button color={(thetype == 'dishrating') ? 'success' : 'warning'} size='medium' onClick={() => {
              this.setState({
                thetype: 'dishrating',    
              });
              this.getDishRating();
            }}>Dish Rating</Button>
            <Button color={(thetype == 'customercounter') ? 'success' : 'warning'} size='medium' onClick={() => {
              this.setState({
                thetype: 'customercounter',    
              });
              this.getCustomerCount();
            }}>New + Renewals Count</Button>
          <Button color={(thetype == 'foodcost') ? 'success' : 'warning'} size='medium' onClick={() => {
             console.log(this.state);
              this.setState({
                thetype: 'foodcost',    
                dateFrom:moment().clone().startOf('month').unix(),     
                dateTo:moment().clone().endOf('month').unix(),     
              }, function() {
                this.getFoodCost(this.state.dateFrom,this.state.dateTo);
              });
              this.getDishCostPrice();
            }}>Food Cost</Button>
            <Button color={(thetype == 'foodcostrevenue') ? 'success' : 'warning'} size='medium' onClick={() => {
              this.setState({
                thetype: 'foodcostrevenue',
                dateFrom:moment().clone().startOf('year').unix(),     
                dateTo:moment().clone().endOf('month').unix(),     
              }, function() {
                this.getFoodCostRevenue(this.state.dateFrom,this.state.dateTo);
              });
            }}>Food Cost % </Button>
            <Button color={(thetype == 'notrenewfeedback') ? 'success' : 'warning'} size='medium' onClick={() => {
              this.setState({
                thetype: 'notrenewfeedback',
                dateFrom:moment().clone().startOf('year').unix(),     
                dateTo:moment().clone().endOf('month').unix(),     
              }, function() {
                this.getNotRewFeedback();
              });
            }}>Not Renew Feedback</Button>
            <Button color={(thetype == 'addedmenuapp') ? 'success' : 'warning'} size='medium' onClick={() => {
              this.setState({
                thetype: 'addedmenuapp',
                dateFrom:moment().clone().startOf('year').unix(),     
                dateTo:moment().clone().endOf('month').unix(),     
              }, function() {
               this.getMobileAPPMenu();
              });
            }}>Customers Added Menu on App</Button>
            <div>
            {(thetype == 'foodcost') && <Filters applyFilters={(filter) => {
                (thetype == 'foodcost')?this.getFoodCost(filter['periodFrom'],filter['periodTo']):this.getFoodCostRevenue(filter['periodFrom'],filter['periodTo']);
                setTimeout(() => this.setState({dateFrom:filter['periodFrom'],dateTo:filter['periodTo'] }), 50);
              }} dateFrom={this.state.dateFrom} dateTo={this.state.dateTo} socket={this.props.socket} />}
              
            {(thetype == 'foodcostrevenue') && <FoodCostFilters applyFilters={(filter) => {
                this.getFoodCostRevenue(filter['periodFrom'],filter['periodTo']);
                setTimeout(() => this.setState({dateFrom:filter['periodFrom'],dateTo:filter['periodTo'] }), 50);
              }} dateFrom={this.state.dateFrom} dateTo={this.state.dateTo} exportFoodRevenueCSV={(filter) => { 
                this.exportFoodRevenueCSV(filter['exportDay']);
              }} socket={this.props.socket} />}
            
            {thetype == 'counter' && <table className="Tabled">
              <thead className="roundedHeader">
                <tr id="header-row">
                  <th scope="col">#</th>
                  <th scope="col">Diet</th>
                  <th scope="col" align="left">Mealplan Type</th>
                  <th scope="col">Amount</th>
                  <th scope="col">Revenue</th>
                </tr>
              </thead>
              <tbody>
                {rows}
              </tbody>
            </table>}
            
            {thetype == 'companies' && <table className="Tabled">
              <thead className="roundedHeader">
                <tr id="header-row">
                  <th scope="col">#</th>
                  <th scope="col">Company Name</th>
                  <th scope="col">Customers</th>
                  <th scope="col">Amount</th>
                </tr>
              </thead>
              <tbody>
                {rows}
              </tbody>
            </table>}

            {thetype == 'renewstats' && <table className="Tabled">
              <thead className="roundedHeader">
                <tr id="header-row">
                  <th scope="col">#</th>
                  <th scope="col">Month</th>
                  <th scope="col">Amount of Customers</th>
                </tr>
              </thead>
              <tbody>
                {rows}
              </tbody>
            </table>}

            {thetype == 'renewreasons' && <table className="Tabled">
              <thead className="roundedHeader stickyheader">
                <tr id="header-row">
                  <th scope="col">#</th>
                  <th scope="col">Reasons</th>
                  <th scope="col">Amount of Customers</th>
                  <th scope="col">Comments</th>
                </tr>
              </thead>
              <tbody>
                {rows}
              </tbody>
            </table>}
            {thetype == 'dishrating' && <table className="Tabled">
              <thead className="roundedHeader stickyheader">
              <tr id="header-row">
                 <th><b>Dish</b></th>
                 <th><b>Love it</b></th>
                 <th><b>Good</b></th>
                 <th><b>Its ok </b></th>
                 <th><b>Not good </b></th>
                 <th><b>Bad</b></th>
                 <th><b>Review Count</b></th>
               </tr>
              </thead>
              <tbody>
                {rows}
              </tbody>
            </table>}
            {thetype == 'customercounter' && <table className="Tabled">
              <thead className="roundedHeader stickyheader">
                <tr id="header-row">
                 <th><b>#</b></th>
                 <th><b>Date</b></th>
                 <th><b>New Customers added</b></th>
                 <th><b>Existing Returning Customers</b></th>
                 <th><b> Renewal customers </b></th>
                 <th><b> Renewed </b></th>
                 <th><b> Renewal % </b></th>
               </tr>
              </thead>
              <tbody>
                {rows}
              </tbody>
            </table>}
            {thetype == 'customerdishwish' && <table className="Tabled">
              <thead className="roundedHeader stickyheader">
              <tr id="header-row">
                 <th><b>Name</b></th>
                 <th><b>Meal Type</b></th>
                 <th><b>Dish Likes</b></th>
                 <th><b> Dish Dislikes </b></th>
               </tr>
              </thead>
              <tbody>
                {rows}
              </tbody>
            </table>}
            {thetype == 'foodcost' && <table className="Tabled">
              <thead className="roundedHeader stickyheader">
                <tr id="header-row">
                  <th scope="col">#</th>
                  <th scope="col">Average Food Cost% of the day</th>
                  <th scope="col">Average food cost (value) of the day</th>
                  <th scope="col">Average Food Cost% of Breakfast</th>
                  <th scope="col">Total Food Cost (value) for Breakfast</th>
                  <th scope="col">Average Food Cost% of Mains</th>
                  <th scope="col">Total Food Cost (value) for Mains</th>
                  <th scope="col">Average Food Cost% of Snacks</th>
                  <th scope="col">Total Food Cost (value) for Snacks</th>
                </tr>
              </thead>
              <tbody>
                {rows}
              </tbody>
            </table>}
            {thetype == 'foodcostrevenue' && <table className="Tabled">
              <thead className="roundedHeader stickyheader">
                <tr id="header-row">
                  <th scope="col">Date</th>
                  <th scope="col">No of deliveries</th>
                  <th scope="col">Total Selling Price</th>
                  <th scope="col">Total Cost Price</th>
                  <th scope="col">Food Cost % = CP / SP %</th>
                  <th scope="col">Earning value = SP - CP</th>
                </tr>
              </thead>
              <tbody>
                {rows}
              </tbody>
            </table>}
            {thetype == 'notrenewfeedback' && <table className="Tabled">
              <thead className="roundedHeader stickyheader">
                <tr id="header-row">
                  <th scope="col">Date</th>
                  <th scope="col">Name</th>
                  <th scope="col">Overall Rating</th>
                  <th scope="col">Food Rating</th>
                  <th scope="col">Customer Service Rating</th>
                  <th scope="col">Delivery Rating</th>
                  <th scope="col">Comments</th>
                  <th scope="col">Experience</th>
                  <th scope="col">Customer Health Goal</th>
                  <th scope="col">Suggestions</th>
                </tr>
              </thead>
              <tbody>
                {rows}
              </tbody>
            </table>}
            {thetype == 'addedmenuapp' && <table className="Tabled">
              <thead className="roundedHeader stickyheader">
                <tr id="header-row">
                  <th scope="col">Date</th>
                  <th scope="col">Paid</th>
                  <th scope="col">Not Paid</th>
                  <th scope="col">Total</th>
                </tr>
              </thead>
              <tbody>
                {rows}
              </tbody>
            </table>}
            </div> 

        </Grid>
      </Grid>
    );    
  }


}

Reports.propTypes = {
  socket: PropTypes.object.isRequired,
  setModal: PropTypes.func.isRequired,
  clearModal: PropTypes.func.isRequired,
  spin: PropTypes.func.isRequired,
  stop: PropTypes.func.isRequired,
  dataArray: PropTypes.object.isRequired,
}; 

const props = state => ({
  dataArray: state.dataArray,
  pinsList: Object.keys(state.pins.customers),
});

const actions = dispatch => ({
  setModal: object => dispatch(setModal(object)),
  addPin: (group, id) => dispatch(addPin(group, id)),
  removePin: (group, id) => dispatch(removePin(group, id)),
  clearNotify: () => dispatch(clearNotify()),
  clearModal: () => dispatch(clearModal()),
  setNotify: props => dispatch(setNotify(props)),
  spin: () => dispatch(spin()),
  stop: () => dispatch(stop()),
});

export default socketConnect(connect(props, actions, null, { withRefs: true })(Reports));