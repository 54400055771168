import React, { Component, Children, cloneElement } from 'react';
import PropTypes from 'prop-types';
import { Grid } from 'material-ui';
import ItemGrid from 'components/Grid/ItemGrid';
import { browserHistory } from 'react-router';
import Button from 'components/CustomButtons/Button';

class Cooking extends Component {
  constructor(props) {
    super(props);
    const curPath = [...props.routes].reverse()[0].path;
    this.current = ['sheet', 'special', 'recipe','food-cost','food-cost-all','market-list','chefs-receipes'].includes(curPath) ? curPath : 'sheet';
    this.state = {
      tabs: [
        { title: 'Cooking Sheet', slug: 'sheet', to: 'sheet' },
        { title: 'Special', slug: 'special', to: 'special' },
        { title: 'Recipe', slug: 'recipe', to: 'recipe' },
        { title: 'Food Cost of the Day', slug: 'food-cost', to: 'food-cost' },
        { title: 'Food Cost - All dishes', slug: 'food-cost-all', to: 'food-cost-all' },
        { title: 'Market List', slug: 'market-list', to: 'market-list' },
        { title: 'Chef\'s Receipes', slug: 'chefs-receipes', to: 'chefs-receipes' }
      ]
    };
  }

  changeTab = to => {
    this.current = to;
    browserHistory.push(`/kitchen/cooking${to ? `/${to}` : ''}`);
  };

  componentDidMount() {
    const { location } = this.props;
    if (!location.pathname.includes(this.current)) {
      browserHistory.push(`/kitchen/cooking/${this.current}`);
    }
  }

  render() {
    const { tabs } = this.state;
    const { routes } = this.props;
    const curPath = [...routes].reverse()[0].path;
    const buttons = tabs.map((tab, idx) => {
      const { title, slug, to } = tab;
      return <Button key={idx} color={(slug === curPath) ? 'darkBlue' : 'white'} onClick={() => this.changeTab(to)}>{title}</Button>;
    });
    const children = this.props.children && Children.map(
      this.props.children,
      child => cloneElement(child, {})
    );
    return <Grid container>
      <div className='tabsCustom'>
        <ItemGrid md={12}>
          {buttons}
        </ItemGrid>
      </div>
      <div className='afterTabsCustom full-w kitchen-o'>
        <ItemGrid md={12}>
          {children}
        </ItemGrid>
      </div>
    </Grid>;
  }
}

Cooking.propTypes = {
  location: PropTypes.object.isRequired,
  routes: PropTypes.array,
  children: PropTypes.any
};

export default Cooking;
