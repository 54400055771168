import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { connect } from 'react-redux';
import { socketConnect } from 'socket.io-react';
import CustomCard from 'components/Cards/CustomCard';
import Filters from './Filters';
import { getArrays } from 'utils';

class All extends Component {
  constructor(props) {
    super(props);
    this.types = ['binded', 'customers'];
    this.state = {
      customers: null,
      binded: null,
      priorities: {},
      errors: {},
      filters: {},
      status: 'all'
    };
  }

  componentWillMount() {
    this.props.socket.on('packaging', this.listener);
  }

  componentWillUnmount() {
    this.props.socket.removeListener('packaging', this.listener);
  }

  componentDidMount() {
    const { socket, dataArray } = this.props;
    const types = ['deliveryTime', 'typeList'].filter(el => !(el in dataArray));
    types.length && getArrays(socket, types);
    socket.emit('packaging', { type: 'get', data: { types: this.types, special: true } });
  }

  listener = ({ type, status, subtype, data }) => {
    if (this.PackagingAllRef) {
      if (status === 'ok') {
        if (type === 'get') {
          if (['binded', 'customers'].includes(subtype)) {
            this.setState(data);
          }
        }
      } else if (status === 'err') {
        const { message, errors } = data;
        console.error(message);
        errors && this.setState({ errors });
      } else {
        console.log({ type, status, subtype, data });
      }
      return null;
    }
  };

  getDatesFromPeriod = (from, to, inclusions = '[]') => {
    const [ low, hi ] = [ ...inclusions ];
    const date = moment.unix(from).utc();
    const end = moment.unix(to).utc();
    const dates = [];
    low === '(' && date.add(1, 'd');
    while (date.isBefore(end)) {
      dates.push(date.unix());
      date.add(1, 'd');
    }
    hi === ']' && dates.push(to);
    return dates;
  };

  applyFilter = (filters = {}) => {
    const { periodFrom, periodTo, status, window } = filters;
    const dates = this.getDatesFromPeriod(periodFrom, periodTo, '[]');
    const { filters: oldFilters } = this.state;
    if (periodFrom !== oldFilters.periodFrom || periodTo !== oldFilters.periodTo || window !== oldFilters.window) {
      this.props.socket.emit('packaging', { type: 'get', data: { dates, window: window || null, types: this.types, special: true } });
      this.setState({ filters: { periodFrom, periodTo, window } });
    } else if (status !== oldFilters.status) {
      this.setState({ status });
    }
  };

  renderUserCard = (data, date, inProcess = false, windows = {}, typeList = {}, statusFilter = 'all') => {
    const { id, username, menu, window, needsCutlery, special } = data;
    const packedCount = Object.values(menu || {}).reduce((acc, { packed }) => acc + packed, 0);
    const packed = Object.keys(menu || {}).length === packedCount;
    if (statusFilter === 'all' || (statusFilter === 'done' && packed) || (statusFilter === 'process' && inProcess) || (statusFilter === 'future' && !packed && !inProcess)) {
      const types = Object.keys(menu).reduce((acc, cur, idx) => `${acc}${idx ? ', ' : ''}${(typeList[cur] || {}).title || ''}`, '');
      const statusClassName = packed ? 'done' : (inProcess ? 'process' : '');
      return <CustomCard noPadding marginOnePx key={id}>
        <div className='package'>
          <div className='status'>
            <span className={statusClassName} />
          </div>
          <div className='package-desc'>
            <p className='name'>{username}</p>
            <p className='date'>{date}</p>
            <p className='time'>{windows[window] || ''}</p>
            <p className='id'>{`id: ${id}`}</p>
            <p className='package-dishes'>{types}</p>
          </div>
          <div className='actions-part'>
            {packed && <div className='simple-button bg-success success-shadow' onClick={() => this.props.showPrintModal({ menu, needsCutlery, username })}>Print list</div>}
          </div>
          {!!special && <div className='special-label bg-warning'><span>special</span></div>}
        </div>
      </CustomCard>;
    }
  };

  renderStatistics = (data = {}, windows = {}) => {
    return Object.keys(data).map(id => {
      const { total, ready } = data[id];
      const statusClassName = !ready ? 'future' : (ready === total ? 'done' : 'current');
      return <div className={statusClassName} key={id}>
        <p>{windows[id] || ''}</p>
        <p>{`${ready}/${total}`}</p>
      </div>;
    });
  };

  getStatistics = customers => {
    return Object.values(customers).reduce((acc, users) => {
      return Object.values(users).reduce((acc, user) => {
        const { window, menu } = user;
        const res = Object.values(menu).reduce((acc, typeData) => {
          return { total: acc.total + 1, ready: acc.ready + !!typeData['packed'] };
        }, { total: 0, ready: 0 });
        return { ...acc, [+window]: acc[+window] ? { total: acc[+window].total + res.total, ready: acc[+window].ready + res.ready } : res };
      }, acc);
    }, {});
  };

  render() {
    const { dataArray } = this.props;
    const { customers, errors, binded, status } = this.state;
    const { deliveryTime, typeList } = dataArray;
    (deliveryTime || {})[0] = 'Not planned';
    const renderFlag = !!(deliveryTime && typeList && customers && binded);
    const statData = this.getStatistics(customers || {});
    return (
      <div className='package-board' ref={el => (this.PackagingAllRef = el)}>
        {renderFlag && <Fragment>
          <div className='top-position styled all finances'>
            <Filters deliveryTime={deliveryTime} errors={errors} applyFilters={this.applyFilter} />
          </div>
          <div className='packs-holder'>
            {Object.keys(customers).map(unix => {
              const date = moment.unix(unix).utc().format('DD MMM, ddd');
              return Object.keys(customers[unix]).map(id => {
                const inProcess = binded.find(el => el['customer_id'] === id && +el['date'] === +unix);
                return this.renderUserCard({ ...customers[unix][id], id }, date, inProcess, deliveryTime, typeList, status);
              });
            })}
          </div>
          <div className='statistics'>
            {this.renderStatistics(statData, deliveryTime)}
          </div>
        </Fragment>}
      </div>
    );
  }
}

All.propTypes = {
  showPrintModal: PropTypes.func.isRequired,
  socket: PropTypes.object,
  dataArray: PropTypes.object
};

const props = state => ({
  dataArray: state.dataArray
});

export default socketConnect(connect(props)(All));
