import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { socketConnect } from 'socket.io-react';
import { connect } from 'react-redux';
import { Grid } from 'material-ui';
import { Remove, Add } from '@material-ui/icons/index';
import { setModal, clearModal } from 'store/modal';
import { setNotify, clearNotify } from 'store/notify';
import { getArrays } from 'utils';
import moment from 'moment/moment';
import ModalWrapper from 'components/Modal';
import CustomCard from 'components/Cards/CustomCard';
import Button from 'components/CustomButtons/Button';
import Text from 'components/Typography/Text';
import List from './List';
import Filters from './Filters';
import printJS from 'print-js';
import YesNo from 'components/Dialog/YesNo';

class ChefsReceipes extends Component {
  constructor(props) {
    super(props);
    const limit = 10000;
    const page = 1;
    const today = moment().utc().startOf('day');
    const filters = {
      periodFrom: today.clone().add(1, 'd').unix(),
      periodTo: today.clone().add(1, 'd').unix()
    };
    this.state = {
      xyz: false,
      list: false,
      usersidesList: false,
      showCookedModal: false,
      limit,
      page,
      maxPages: 1,
      filters,
      splitter: {},
      errors: {},
      cookedData: {},
      mealTypes: [],
      deliveries: [],
      dishGroups: [],
      notes: {},
      activeBrand:false
    };
    // this.getList((page - 1) * limit, limit, filters);
  }

  getList(offset, limit, filters) {
    this.props.socket.emit('menu_builder', {
      type: 'getChefDishList',
      data: {
        offset,
        limit,
        filters
      }
    });
  }

  listener = action => {
    const { page, limit, filters } = this.state;
    if (this.ChefsReceipesRef) {
      switch (action.type) {
        case 'getChefDishListOk':
          this.setState(action.data);
          break;
        case 'setOk':
          this.getList((page - 1) * limit, limit, filters);
          this.setState({ cookedData: {}, showCookedModal: false });
          break;
          case 'delChefDishOk':
            this.showNotification(action.data.message);
            this.getList((page - 1) * limit, limit, filters);
       break;  
        default:
          if (action.type.includes('Err')) {
            const { errors } = action.data;
            this.setState({ errors });
          }
          break;
      }
    }
  };

  goTo = page => {
    const { limit, filters } = this.state;
    this.getList((page - 1) * limit, limit, filters);
    setTimeout(() => this.setState({ page }), 5);
    this.ChefsReceipesRef && this.ChefsReceipesRef.parentElement.parentElement.parentElement.parentElement.parentElement.parentElement.scrollIntoView();
  };

  componentWillMount() {
    this.props.socket.on('menu_builder', this.listener);
  }

  componentWillUnmount() {
    this.props.socket.removeListener('menu_builder', this.listener);
  }

  componentDidMount() {
    if (this.ChefsReceipesRef) {
      setTimeout(() => this.ChefsReceipesRef && this.setState({ xyz: true }), 100);
      const { socket, dataArray } = this.props;
      const types = ['brandCategoryList', 'brandList'].filter(el => !(el in dataArray));
      types.length && getArrays(socket, types);
    }
  }
  deleteConfirm =(id) =>{
    const { socket, setModal, clearModal } = this.props;
    const del = () => {
      socket.emit('menu_builder', {
        type: 'delChefDish',
        data: {
          id
        }
      });
      clearModal();
    };
    var stations = [];
    stations.push(<h6>Are you sure and confirm?</h6>);
    setModal({
      content: (<YesNo actionYes={del} actionNo={() => clearModal()} message={'Are you sure ?'} />),
      width: '30%',
      height: '20vh'
    });
  }
  renderCookedModal = ({ dish, date, maxAmount, count }) => {
    const setCooked = () => {
      this.props.socket.emit('menu_builder', { type: 'set', data: { dish, date, count } });
    };
    return <Grid container style={{ userSelect: 'none' }}>
      <Grid item md={12} style={{ textAlign: 'center', padding: '35px 0' }}>
        <Text style={{ fontSize: '24px' }} bold paragraph color={'darkBlue'}>How many dishes are cooked?</Text>
      </Grid>
      <Grid item md={4}>
        <Button
          onClick={() => this.setState({ cookedData: { ...this.state.cookedData, count: count > 1 ? count - 1 : 1 } })}
          pullRight
          disabled={count === 1}
          color={'success'}
          size={'medium'}
        >
          <Remove />
        </Button>
      </Grid>
      <Grid item md={4} style={{ textAlign: 'center' }}>
        <Text style={{ fontSize: '42px' }} color={'darkBlue'}>{count || 1}</Text>
      </Grid>
      <Grid item md={4}>
        <Button
          onClick={() => this.setState({ cookedData: { ...this.state.cookedData, count: count < maxAmount ? count + 1 : maxAmount } })}
          disabled={count === maxAmount}
          color={'success'}
          size={'medium'}
        >
          <Add />
        </Button>
      </Grid>
      <Grid item md={12} style={{ paddingTop: '40px' }}>
        <Button
          onClick={setCooked}
          color={'darkBlue'}
          style={{ display: 'block', margin: '0 auto' }}
        >
          OK
        </Button>
      </Grid>
    </Grid>;
  };
  filterByBrand = (id)=>{
    this.setState({ activeBrand: +id });
    const { page ,limit } = this.state; 
    let newPage = page;
    let offset = (page - 1) * limit
    if (offset === 0) {
      newPage = 1;
    }
    this.getList(offset, limit, {brand_id:+id});
  }
  render() {
    const { xyz, page, maxPages, list, limit, errors, splitter, mealTypes, dishGroups: activeDishGroups, deliveries, showCookedModal, cookedData,usersidesList , activeBrand } = this.state;
    const { brandCategoryList, brandList } = this.props.dataArray;
    const render = list && brandCategoryList && brandList;
    const actions = {
      cooked: (id, { date, maxAmount }) => this.setState({ showCookedModal: true, cookedData: { dish: id, date, maxAmount, count: 1 } }),
      print: (id, row) => {
        this.props.setModal({
          content: <div>
            {/*<div id={'printable-block'} style={{ width: '100%', height: '100%', padding: '25px' }}>
              
            </div>
            <Button size={'large'} color={'darkBlue'} pullRight onClick={() => printJS('printable-block', 'html')}>Print</Button>*/}
           
          </div>
        });
      }
    };
   
    const props = { page, maxPages, limit, actions, goTo: this.goTo, dictionaries: { brandCategoryList, brandList }, splitter,deleteConfirm:this.deleteConfirm };
    const brandIds = Object.keys(list || {});
    const content = render ? brandIds.map((brand_id, idx) => <List key={idx} {...props} brandId={brand_id} list={list[brand_id]}  />) : null;
    const buttons = Object.keys(brandList || {}).map((value, idx) => {
      return <Button key={idx} color={(+value === +activeBrand) ? 'darkBlue' : 'white'} onClick={() => this.filterByBrand(value)}>
        {brandList[value]}
      </Button>;
    });
    return (
      <Fragment>
        {buttons}
        <div className='filtesHolder finances'>
          <CustomCard marginOnePx>
            
            <Filters
              errors={errors}
              brand_id={activeBrand}
              applyFilters={(filters, offset) => {
                let newPage = page;
                if (offset === 0) {
                  newPage = 1;
                }
                this.getList(offset, limit, { brand_id:+activeBrand,...filters});
                setTimeout(() => this.setState({ page: newPage, filters:{ brand_id:+activeBrand,...filters}}), 50);
              }}
              offset={(page - 1) * limit}
              setSplitter={splitter => this.setState({ splitter })}
              splitter={splitter}
              mealTypes={mealTypes}
              activeDishGroups={activeDishGroups}
              deliveries={deliveries}
            />
            
          </CustomCard>
        </div>
        <CustomCard marginOnePx>
          <div ref={el => (this.ChefsReceipesRef = el)} className={xyz ? 'xyz-fin' : 'xyz'}>
            {content}
          </div>
        </CustomCard>
        <ModalWrapper width='30%' height='30vh' handleClose={() => this.setState({ showCookedModal: false })} open={showCookedModal}>
          {this.renderCookedModal(cookedData)}
        </ModalWrapper>
      </Fragment>
    );
  }
}

ChefsReceipes.propTypes = {
  socket: PropTypes.object.isRequired,
  setModal: PropTypes.func.isRequired,
  clearModal: PropTypes.func.isRequired,
  dataArray: PropTypes.object.isRequired,
  setNotify: PropTypes.func.isRequired,
  clearNotify: PropTypes.func.isRequired
};

const props = state => ({
  dataArray: state.dataArray,
  userType: state.user.type
});

const actions = dispatch => ({
  setModal: object => dispatch(setModal(object)),
  clearModal: () => dispatch(clearModal()),
  clearNotify: () => dispatch(clearNotify()),
  setNotify: props => dispatch(setNotify(props))
});

export default socketConnect(connect(props, actions)(ChefsReceipes));
