import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import Button from 'components/CustomButtons/Button';
import PlanInfo from './PlanInfo';
import PlanInfoNew from './PlanInfoNew';
import DishesInfo from './DishesInfo';
import DishesInfoedit from './DishesInfoedit';
import Text from 'components/Typography/Text';
import moment from 'moment/moment';
import { connect } from 'react-redux';
import CustomCard from 'components/Cards/CustomCard';
import ModalWrapper from 'components/ModalWrapper';
import { intersect,diff } from 'lib';
class AddMealPlanModal extends Component {
  constructor(props) {
    super(props);
    const { errors, dataArray, userData, prevPlanParams, minDate,build,isNewFormat } = props;
    const { mealType, cutlery, deliveryTime: deliverySlot, diet, mealPlan:planId, paymentMethod, pricetype,exclude_week_day:excludedWeek,is_single_lunch_box,days_count:dayCounts  } = prevPlanParams || {};
    let days_count=dayCounts;
    let exclude_week_day=excludedWeek;
    let mealPlan=planId;
    if(isNewFormat && build == 'renew' && days_count<=0){
      const { excludeWeekDay,count:day } = (dataArray['planList'] || {})[mealPlan] || {};
      days_count=(days_count>0)?days_count:day;
      exclude_week_day=(exclude_week_day!=undefined && exclude_week_day.length>0)?exclude_week_day:(excludeWeekDay || []);
      mealPlan=(days_count>0)?this.getMealPlanId(days_count):planId;
    }
    const planParams = {
      mealType: mealType || dataArray['defaultTypes'],
      deliverySlot: deliverySlot || userData['slot_id'] || ((dataArray['slotsList'] || [])[0] || { id: null })['id'],
      cutlery: cutlery || true,
      date: minDate || moment().utc().startOf('day').unix(),
      diet,
      mealPlan,
      paymentMethod:(build!='editplan')?'not_paid':paymentMethod,
      pricetype,
      exclude_week_day:(exclude_week_day!=undefined)?exclude_week_day:[],
      is_single_lunch_box:(is_single_lunch_box!=undefined)?true:false,
      days_count
    };

    var {bagPrice} = dataArray;
    this.state = {
      errors,
      planParams,
      mealPlan: {},
      step: 1,
      showModal: false,
      modalMessage:'Please, select dishes for all dates in plan',
      bagPrice: bagPrice,
    };
  }

  unsetError(errors = {}, names = []) {
    if (names.length === 1) {
      delete errors[names[0]];
    } else if (names.length > 1) {
      errors = { ...errors, [names[0]]: this.unsetError(errors[names[0]], names.splice(1)) };
    }
    return errors;
  }
  getMealPlanId= (count) =>{
    const { dataArray} = this.props;
    const { planDayRange } = dataArray;
    count=parseInt(count);
    let planId=0;
    planDayRange && Object.keys(planDayRange).forEach((mealPlanId, index) => {
          const { range_from, range_to } = planDayRange[mealPlanId] || {};
          if(parseInt(range_from)<=count && parseInt(range_to)>=count){
            planId=mealPlanId;
            return mealPlanId;
          }
    });
    return parseInt(planId);
  }
  changeParams = (name, value) => {
    if (this.AddMealPlanModal) {
      let { errors } = this.state;
      if (name in errors) {
        errors = this.unsetError(errors, [name]);
      }
      const { userData, dataArray } = this.props;
      const { planList, commonVouchers } = dataArray;
      const { vouchers } = userData;
      const { planParams: oldParams } = this.state;
      const planParams = { ...oldParams, [name]: value };
      const { mealType: mealTypeId, diet: dietId, mealPlan: mealPlanId, coupon ,pricetype,days_count } = planParams;
      const { count } = (planList || {})[mealPlanId] || {};
      let planId =mealPlanId;
      let daysCount= count;
      if(days_count>0){
        planId =this.getMealPlanId(days_count);
        daysCount= days_count;
        planParams.mealPlan=planId;
    }
      const [voucher_field, vou] = (() => {
        if (coupon) {
          const [type, cid] = coupon.split('_');
          const { list, field } = {
            c: { list: commonVouchers || [], field: 'voucher_id' },
            p: { list: vouchers || [], field: 'coupon_id' }
          }[type];
          return [field, list.find(({ id }) => +id === +cid)];
        }
        return [null, null];
      })();
      const clearPrice = this.calcPrice(dietId, planId, mealTypeId, daysCount ,pricetype);
       /* Calculate Discount Price based on Meal Types and conditions */
       let isMealTypeDis =0;
       let isVouval=false;
       // console.log(vou);
       if(vou && vou.conditions!=null  && vou.conditions!='' ){
         vou.conditions =(typeof vou.conditions === 'string' || vou.conditions instanceof String )?JSON.parse(vou.conditions):vou.conditions;
         isMealTypeDis=intersect(mealTypeId,vou.conditions).length;   
         if(isMealTypeDis<=0 && planId){
           alert("Please select different discount.Unable to apply. It's based meal types.");
           isVouval=true;
         }
       }
      const discount = (vou && vou.conditions!=null && vou.conditions.length>0 && isMealTypeDis)?this.calcDiscountPrice (vou.conditions,dietId,planId,daysCount,pricetype):(vou ? (vou.type === 'fixed' ? +vou.value : (vou.type === 'percent' ? (+vou.value / 100) * clearPrice : 0)) : 0);
      planParams.price = clearPrice - discount;
      planParams.voucher_field = voucher_field;
      planParams.coupon_id = (vou || {}).id;
      this.setState({ planParams, errors, clearPrice: clearPrice });
      if(isVouval){
          setTimeout(this.changeParams ('voucher_id', ''), 1000);
      }
    }
  };

  calcPrice = (dietId, planId, types, daysCount, pricetype=0) => {
    const { build, dataArray } = this.props;
    const { priceList } = dataArray;
     
    if (priceList && dietId && planId && types && build!='editplan') {
      const dayPrice = priceList
        .filter(el => +el.diet_id === +dietId && +el.mealPlan_id === +planId && types.includes(+el.mealType_id))
        .reduce((acc, cur) =>{ if(pricetype){ return acc + +cur.newValue }else{ return acc + +cur.value } }, 0);
        
      return dayPrice * +daysCount;
    } else if(build == 'editplan') {
      return 0;
    }else{
      return 0;
    }
  };
  calcDiscountPrice = (types,dietId,planId,daysCount,pricetype) =>{
    const { build, dataArray } = this.props;
    const { priceList } = dataArray;
    if (priceList && dietId && planId && types && build!='editplan') {
      const dayPrice = priceList
        .filter(el => +el.diet_id === +dietId && +el.mealPlan_id === +planId && types.includes(+el.mealType_id))
        .reduce((acc, cur) =>{ if(pricetype){ return acc + +cur.newValue }else{ return acc + +cur.value } }, 0);
        
      return dayPrice * +daysCount;
    } else if(build == 'editplan') {
      return 0;
    }else{
      return 0;
    }
  }
  changePlan = (currentDate, currentType, dishId) => {
    if (this.AddMealPlanModal) {
      const { mealPlan } = this.state;
      this.setState({ mealPlan: { ...mealPlan, [currentDate]: { ...mealPlan[currentDate], [currentType]: dishId } } });
    }
  };

  changeBag = () => {
    const {bagPrice} = this.state;
    const {dataArray} = this.props;
    var bag = bagPrice;
    if (bag != 0) {
      bag= 0;
    } else {
      const {bagPrice} = dataArray;
      bag = bagPrice;
    }

    this.setState({ bagPrice: bag });
  }

  checkPlan = (_planParams, _mealPlan) => {
    const { build,prevPlanParams } = this.props;
    const planParams = _planParams || this.state.planParams;
    const mealPlan = _mealPlan || this.state.mealPlan;
    let flag = true;
    if(build=='editplan'){
    
      var addMealtype=diff(planParams.mealType,prevPlanParams.mealType);
      var removeMealtype=diff(prevPlanParams.mealType,planParams.mealType);
      var oldorderId=prevPlanParams.id;
      if(addMealtype.length>0||removeMealtype.length>0){
          this.props.save({ mealPlan, planParams,addMealtype,removeMealtype,oldorderId }, 'editplan', build, this.state.bagPrice, this.state.clearPrice);
       }else{
        this.setState({ showModal: true,modalMessage:'Please add or remove the meal type'});
       }
    }else{ 
        const typesAmount = (planParams.mealType || []).length;
        for (const key in mealPlan) {
          const el = mealPlan[key];
          if (Object.keys(el).length < typesAmount) {
            flag = false;
          } else {
            for (const type in el) {
              if (!el[type]) {
                flag = false;
              }
            }
          }
        }
        if (flag) {
          this.props.save({ mealPlan, planParams }, 'add', build, this.state.bagPrice, this.state.clearPrice);
        } else {
          this.setState({ showModal: true });
        }
   }
  };

  nextStep = (auto = false) => {
    const { planParams } = this.state;
    const required = ['mealType', 'diet', 'mealPlan', 'date', 'deliverySlot', 'price'];
    const errors = {};
    let flag = false;
    required.forEach(el => {
      if (!(el in planParams) || !planParams[el]) {
        if (el != 'Price' && planParams[el] !== 0) {
          console.log(el);
          console.log(planParams[el]);
          errors[el] = 'This field is required';
          flag = true;
        }
      }
    });

    console.log(errors);
    if (flag) {
      this.setState({ errors });
    } else {
      const { planParams } = this.state;
      const { mealPlan: planId, date, days_count, exclude_week_day } = planParams;
      const { excludedDates, dataArray, getCustomPlans,build,prevPlanParams } = this.props;
      
      const { planList } = dataArray;
      const planInfo = planList[planId] || {};
      const { count, excludeWeekDay } = planInfo;
      let daysCount = count;
      let excludeWeeks = excludeWeekDay;
      if(days_count>0 && exclude_week_day!=undefined){
           daysCount = days_count;
           excludeWeeks = exclude_week_day;
      }
      const { diet, mealType } = planParams;
      var newmealType=mealType;
      var newdate=date;
      if(build=='editplan'){
        // console.log(prevPlanParams);
        
        newmealType=diff(mealType,prevPlanParams.mealType);
        var removeMealtype=diff(prevPlanParams.mealType,mealType);
        if(newmealType.length>0||removeMealtype.length>0){
            
        }else{
          this.setState({ showModal: true,modalMessage:'Please add or remove the meal type'});
          return null;
        }
          newdate =moment().utc().startOf('day').unix()+ 3600 * 24;
          newdate = (date>=prevPlanParams.date)?date:newdate;
      }
     
      getCustomPlans(diet, newmealType);
      var mealPlan=[];
      if(build=='editplan'){
        mealPlan = this.filleditPlanDates(newdate);
      }else{
        mealPlan = this.fillPlanDates(newdate, daysCount, excludeWeeks, excludedDates);
      }
      if (auto) {
        this.autoFill(mealPlan, planParams);
      } else {
        this.setState({ step: 2, mealPlan });
      }
    }
  };
   /* Recursive call for get start date fill the dish filter from excludeWeekDay */
   getinitialDate=(includedDays,startDate)=>{
    var initialDate = moment.unix(startDate);
    if(includedDays.includes(initialDate.weekday())){
      return startDate;
    }else{
       return this.getinitialDate(includedDays,startDate+3600*24);
    }
  };
  filleditPlanDates= (startDate) => {
    const { stamps } = this.props;
    let dates = {};
    if(stamps[0]>=startDate){
      dates[stamps[0]] = {};
    }
    stamps.reduce((acc, date) => {
      if(date>=startDate){
        dates[date] = {};
      }
    });
    return dates;
  };
  fillPlanDates = (startDate, count, excludedDays, excludedDates) => {
    const includedDays = Array(7).fill(0).map((el, key) => key).filter(el => !excludedDays.includes(el));
    const initialDate = moment.unix(startDate);
    // const initialDate =(includedDays.length)?moment.unix(this.getinitialDate(includedDays,startDate)):moment.unix(startDate);
    let dates = {};
    for (let i = 0; i < count; i++) {
      const date = initialDate.addWeekdaysFromSet(i, includedDays, excludedDates).unix();
      dates[date] = {};
    }
    return dates;
  };

  autoFill = (mealPlan, planParams) => {
    const { customPlans } = this.props;
    const { mealType: types } = planParams || {};
    const plansByTypes = customPlans.reduce((acc, { mealType_id, data }) => ({ ...acc, [+mealType_id]: data }), {});
    const getDish = (date, type) => {
      const day = moment.unix(date).date() - 1;
      const dishList = (plansByTypes[+type] || {})[day] || [];
      const idx = ~~(Math.random() * dishList.length);
      return dishList[idx];
    };
    const details = Object.keys(mealPlan).reduce((acc, date) => {
      const dayData = types.reduce((acc, type) => {
        return { ...acc, [+type]: getDish(date, type) };
      }, {});
      return { ...acc, [+date]: dayData };
    }, {});
    this.checkPlan(planParams, details);
  };

  checkIsAllergiesOrDislike=(productsFull,allergies,dislikes,dishid)=>{
    const { ingredients }=productsFull[dishid] || {};
    let is_allergies=false;
    if(ingredients!=undefined&&ingredients.length>0){
        if (allergies.length>0) {
          is_allergies = intersect(ingredients,allergies).length;
        }
        if(dislikes.length>0&&!is_allergies){
          is_allergies = intersect(ingredients,dislikes).length;   
        }
        if(!is_allergies){
          return  dishid;
        }
        return  null;
      }else{
        return  dishid;
      }
  };

  fillDefaults = (defaultDishes) => {
    const { mealPlan, planParams } = this.state;
    const { dataArray, userData,customPlans,prevPlanParams,build} = this.props;
    const { productsFull } = dataArray;
    
    
    const { mealType: types } = planParams || {};
    var newmealType=types;
    if(build=='editplan'){
      newmealType=diff(types,prevPlanParams.mealType);
    }

    const getDish = (date, type, existDish) => {
      const day = moment.unix(date).date() - 1; // TODO: check logic
      const sameDayDish = Object.values(existDish);
      const plan = customPlans.find(el => +el.mealType_id === +type) || {};
      let dish=+(defaultDishes[+type] || {})[day] || null;
      /* Start Disable the dislike/allergies dish */
        let prodKeys = (plan.data || {})[day] || [];
        const {allergies,dislikes,dishdislikes,eliminate_groups}=userData;
        let is_allergies=this.checkIsAllergiesOrDislike(productsFull,allergies,dislikes,dish);
        // console.log('is_allergies');
        // console.log(is_allergies);
        if(eliminate_groups.length>0 && is_allergies){
          const { group, spicy } =productsFull[dish] || {};
          // is_allergies=(eliminate_groups.indexOf(group)>-1)?null:dish;
          is_allergies=(intersect(group,eliminate_groups).length>0)?null:dish;
          if(is_allergies){
            is_allergies=(eliminate_groups.indexOf(14)>-1 && spicy==1)?null:dish; // Spicy Group and dish set as spicy
          }
        }

        if(dishdislikes.length>0 && is_allergies){
          is_allergies=(dishdislikes.indexOf(dish)>-1)?null:dish;
        }
        if(sameDayDish.length>0 && is_allergies){
          is_allergies=(sameDayDish.indexOf(dish)>-1)?null:dish;
        }

        if(is_allergies!=null){
          return dish;
        }else{
          prodKeys=prodKeys.filter(function (dishId) {
            const { group,spicy } = productsFull[dishId] || {};
            let isEliminate = true;
            if(eliminate_groups.length>0){ 
              // isEliminate = !(eliminate_groups.indexOf(group)>-1); 
              isEliminate = !intersect(group,eliminate_groups).length;
              if(isEliminate){
                  isEliminate=!(eliminate_groups.indexOf(14)>-1 && spicy==1); // Spicy Group and dish set as spicy
              }
            }
            if(dishdislikes.length>0 && isEliminate){
              isEliminate = !(dishdislikes.indexOf(+dishId)>-1); 
            }
            return isEliminate;
        });
        var validDish = prodKeys.find((dishId, idx) => {
          if(dishId!=dish){
            let is_allergies=this.checkIsAllergiesOrDislike(productsFull,allergies,dislikes,dishId);
            if(sameDayDish.length>0 && is_allergies){
              is_allergies=(sameDayDish.indexOf(dishId)>-1)?null:dishId;
            }
            if(is_allergies!=null||(prodKeys.length-1)==idx){
              return dishId;
            }
          }
        });
        return (validDish != undefined )?validDish:dish; // Set default dish,If the all dish are dislike/allergies
        }
       /* End Disable the dislike/allergies dish */

    };

    return Object.keys(mealPlan).reduce((acc, date) => {
      const dayData = newmealType.reduce((acc, type) => {
        return { ...acc, [+type]: getDish(date, type, acc) };
      }, {});

      return { ...acc, [+date]: dayData };
    }, {});
  };

  componentWillReceiveProps(nextProps, nextContext) {
    const { defaultDishes } = nextProps;
    if (Object.keys(defaultDishes || {}).length) {
      const mealPlan = this.fillDefaults(defaultDishes);
      this.setState({ mealPlan });
    }
  }

  componentDidMount() {
    const { build } = this.props;
    const { planParams } = this.state;
    if (build === 'renew') {
      this.changeParams('diet', planParams.diet);
    }
  }

  render() {
    const { errors, planParams, mealPlan, step, showModal,modalMessage } = this.state;
    const { dataArray, userData, excludedDates, customPlans, minDate, build,prevPlanParams,isNewFormat,mostUsedValues } = this.props;
    const commonProps = { errors, dataArray, userData, planParams, customPlans, minDate, build,prevPlanParams,mostUsedValues };
    var actionname='Add new';
    if(build === 'renew'){
      actionname='Renew';
    }else if(build === 'editplan'){
      actionname='Edit';
    }
    return (
      <div ref={el => (this.AddMealPlanModal = el)} className={isNewFormat?'new-order new-format':'new-order'}>
        <Text color={isNewFormat?'darkBlue':'info'} bold className={'m-heading'}  style={{ fontSize: 'larger' }}>{actionname} meal plan for {userData.firstName} {userData.lastName}</Text>
        <div className='scroll-wrapper'>
          {step === 1 && !isNewFormat &&<PlanInfo {...commonProps} changeParams={this.changeParams} excludedDates={excludedDates} bagPrice={this.state.bagPrice} changeBag={this.changeBag} />}
          {step === 1 && isNewFormat && <PlanInfoNew {...commonProps} changeParams={this.changeParams} excludedDates={excludedDates} bagPrice={this.state.bagPrice} changeBag={this.changeBag} />}
          {step === 2 &&actionname!='Edit'&&<DishesInfo {...commonProps} mealPlan={mealPlan} changePlan={this.changePlan} />}
          {step === 2 &&actionname=='Edit'&&<DishesInfoedit {...commonProps} mealPlan={mealPlan} changePlan={this.changePlan} />}
        </div>
        <div className='modal-button-handler end-flex'>
          {step === 1 && <Button color='darkBlue' onClick={() => this.nextStep(false)}>Next step</Button>}
          {/* {step === 1 && <Button color='darkBlue' onClick={() => this.nextStep(true)}>Fill Auto</Button>} */}
          {step === 2 && <Fragment>
            <Button color='darkBlue' onClick={() => this.setState({ step: 1 })}>Prev step</Button>
            <Button color='darkBlue' onClick={() => this.checkPlan()}>Save</Button>
          </Fragment>} 
        </div> 
        <ModalWrapper md={4} close={() => this.setState({ showModal: false })} show={showModal} closeButton>
          <CustomCard plainCard>
            <Text style={{ fontSize: '18px' }}>{modalMessage}</Text>
          </CustomCard>
        </ModalWrapper>
      </div>
    );
  }
}

AddMealPlanModal.propTypes = {
  errors: PropTypes.object.isRequired,
  userData: PropTypes.object.isRequired,
  dataArray: PropTypes.object.isRequired,
  excludedDates: PropTypes.array,
  save: PropTypes.func.isRequired,
  getCustomPlans: PropTypes.func.isRequired,
  customPlans: PropTypes.array,
  defaultDishes: PropTypes.object,
  build: PropTypes.string,
  minDate: PropTypes.number,
  prevPlanParams: PropTypes.object
};

AddMealPlanModal.defaultProps = {
  prevPlanParams: {},
  build: 'newPlan'
};

const props = ({ customPlans, defaultDishes }) => ({ customPlans, defaultDishes });

export default connect(props)(AddMealPlanModal);
