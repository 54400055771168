import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { socketConnect } from 'socket.io-react';
import { connect } from 'react-redux';
import SelectInput from 'components/SelectInput';
import CustomInput from 'components/CustomInput/CustomInput';
import Calendar from 'components/Calendar';
import { getArrays } from 'utils';
import Button from 'components/CustomButtons/Button';
import moment from 'moment/moment';
import { browserHistory } from 'react-router';

class Filters extends Component {
  constructor(props) {
    super(props);
    const today = moment().utc().startOf('day');
    this.defFilters = {
      title:''
    };
    Object.freeze(this.defFilters); 
    this.state = {
      filters: this.defFilters,
      today,
      offset: props.offset,
      mealtype: 0,
    };
  }

  componentDidMount() {
    const { dataArray, socket } = this.props;
    const types = ['brandList','brandCategoryList'].filter(el => !(el in dataArray));
    types.length && getArrays(socket, types);
  }

  componentWillReceiveProps(next) {
    if (this.FilterRef && next.offset !== this.state.offset) {
      this.setState({ offset: next.offset });
    }
  }

  pdfButton(periodFrom, periodTo) {
    const {mealtype} = this.state;
    window.open(
      'http://basiligo:basiligo2019!@healthyme.loseweight.ae/admin/site/cooklistpdf?date_from='+periodFrom+'&date_to='+periodTo+'&type='+mealtype,
      '_blank'
    );
  }

  previewButton(periodFrom, periodTo) {
    const {mealtype} = this.state;
    window.open(
      'http://basiligo:basiligo2019!@healthyme.loseweight.ae/admin/site/cooklist?date_from='+periodFrom+'&date_to='+periodTo+'&type='+mealtype,
      '_blank'
    );
  }

  render() {
    const { filters, offset, today } = this.state;
    const { dataArray, errors, applyFilters, setSplitter, splitter, mealTypes, activeDishGroups, deliveries } = this.props;
    const onChange = (e, _value, _name) => {
      const name = _name || e.target.name;
      const value = _value || e.target.value;
      this.setState({ filters: { ...this.state.filters, [name]: value }, offset: name === 'sort' ? offset : 0 });
    };
    const { brandList,brandCategoryList } = dataArray;
    const {  brand_id, brand_category_id } = filters;
    const brands = { '': 'All', ...brandList };
    const brandCategory = { '': 'All', ...brandCategoryList };
    console.log();
    return (
      <Fragment>
        <form
          ref={el => (this.FilterRef = el)}
          className='fix-on-filters'
          onSubmit={e => {
            e.preventDefault();
            applyFilters(filters, offset);
            return false;
          }}
          onKeyPress={e => {
            if (e.key === 'Enter') {
              applyFilters(filters, offset);
            }
          }}
        > 
          {/* <SelectInput
            labelText='Brand'
            items={brands || {}}
            value={brand_id || ''}
            error={'brand_id' in errors}
            formControlProps={{
              fullWidth: false,
              style: {
                minWidth: '13%',
                marginRight: '10px'
              }
            }}
            inputProps={{
              name: 'brand_id',
              onChange
            }}
          />
          <SelectInput
            labelText='Brand Category'
            items={brandCategory || {}}
            value={brand_category_id || ''}
            formControlProps={{
              fullWidth: false,
              style: {
                minWidth: '13%',
                marginRight: '10px'
              }
            }}
            inputProps={{
              name: 'brand_category_id',
              onChange
            }}
          /> */}
          <CustomInput
            labelText='Search by dish name'
            formControlProps={{
              fullWidth: false,
              style: {
                margin: '0 20px 0 4px',
                minWidth: '20%'
              }
            }}
            error={'title' in errors}
            inputProps={{
              value: filters.title || '',
              type: 'text',
              name: 'title',
              onChange,
              disabled:!this.props.brand_id
            }}
          />
          <div className='buttons-on-filters'>
          <Button size={'large'} color={'darkBlue'} pullRight onClick={() => browserHistory.push(`/kitchen/cooking/dish-add`)}>Add Dish</Button>
            <Button color='danger' size='large' onClick={() => {
              applyFilters(this.defFilters, offset);
              this.setState({ filters: this.defFilters });
            }}>Clear</Button>
            <Button color='darkBlue' size='large' pullRight type='submit'>Apply</Button>
          </div>
        </form>
      </Fragment>
    );
  }
}

Filters.propTypes = {
  socket: PropTypes.object.isRequired,
  dataArray: PropTypes.object.isRequired,
  errors: PropTypes.object,
  offset: PropTypes.number.isRequired,
  applyFilters: PropTypes.func.isRequired,
  setSplitter: PropTypes.func.isRequired,
  splitter: PropTypes.object.isRequired,
  mealTypes: PropTypes.array.isRequired,
  deliveries: PropTypes.array.isRequired,
  activeDishGroups: PropTypes.array.isRequired
};

Filters.defaultProps = {
  offset: 0,
  errors: {}
};

const props = state => ({
  dataArray: state.dataArray
});

export default socketConnect(connect(props, null)(Filters));
