import React from 'react';
import { withStyles, Modal } from 'material-ui';
import PropTypes from 'prop-types';
import { Close } from '@material-ui/icons';

const styles = theme => ({
  paper: {
    position: 'absolute',
    width: theme.spacing.unit * 50,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 4
  }
});

class ModalWrapper extends React.Component {
  render({ ...props }) {
    const { classes, children, open, handleClose } = this.props;
    return (
      <Modal
        aria-labelledby='simple-modal-title'
        aria-describedby='simple-modal-description'
        open={open}
        onClose={handleClose}
        onBackdropClick={handleClose}
        disableEnforceFocus
      >
        <div className={`modal-wrapper-custom ${classes.paper}`} onClick={handleClose} >
          <div
            className={'modal-body'}
            onClick={e => {
              e.stopPropagation();
              return false;
            }}
          >
            <div className='modal-close-button'>
              <Close onClick={handleClose} />
            </div>
            {children}
          </div>
        </div>
      </Modal>
    );
  }
}

ModalWrapper.propTypes = {
  classes: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  children: PropTypes.any
};

export default withStyles(styles)(ModalWrapper);
