import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import SelectInput from 'components/SelectInput';
import Button from 'components/CustomButtons/Button';
import moment from 'moment';
import Calendar from 'components/Calendar';

class Filters extends Component {
  constructor(props) {
    super(props);
    const today = moment().utc().startOf('day');
    this.defFilters = {
      periodFrom: today.unix(),
      periodTo: today.clone().add(1, 'd').unix(),
      status: 'all'
    };
    Object.freeze(this.defFilters);
    this.state = {
      filters: this.defFilters,
      today
    };
  }

  render() {
    const { filters, today } = this.state;
    const { errors, applyFilters, deliveryTime } = this.props;
    const onChange = (e, _value, _name) => {
      const name = _name || e.target.name;
      const value = _value || e.target.value;
      this.setState({ filters: { ...this.state.filters, [name]: value } });
    };
    const { periodFrom, periodTo, window, status } = filters;
    const rFlag = !!(deliveryTime);
    const deliveriesList = { '': 'All', ...deliveryTime };
    return rFlag ? (
      <Fragment>
        <form
          ref={el => (this.FilterRef = el)}
          className='fix-on-filters'
          onSubmit={e => {
            e.preventDefault();
            applyFilters(filters);
            return false;
          }}
          onKeyPress={e => {
            if (e.key === 'Enter') {
              applyFilters(filters);
            }
          }}
        >
          <Calendar
            date={periodFrom || today}
            name={'periodFrom'}
            title={'From date'}
            minDate={moment().utc().startOf('day').unix()}
            onChange={(value, name) => {
              const date = moment.unix(value).utc();
              onChange(undefined, date.isSameOrAfter(today) ? date.unix() : today.unix(), name);
              if (date.isAfter(moment.unix(periodTo))) {
                setTimeout(() => onChange({}, date.unix(), 'periodTo'), 10);
              }
            }}
            controlled
            error={'periodFrom' in errors}
            formControlProps={{
              fullWidth: false,
              style: {
                margin: '0 20px 0 4px',
                minWidth: '10%'
              }
            }}
          />
          <Calendar
            date={periodTo || today}
            name={'periodTo'}
            title={'To date'}
            minDate={moment.unix(periodTo || today).utc().startOf('day').unix()}
            onChange={(value, name) => {
              const date = moment.unix(value).utc();
              const from = filters.periodFrom ? moment.unix(filters.periodFrom) : today;
              onChange(undefined, date.isSameOrAfter(today) && date.isAfter(from) ? date.unix() : today.unix(), name);
            }}
            controlled
            error={'periodTo' in errors}
            formControlProps={{
              fullWidth: false,
              style: {
                margin: '0 20px 0 4px',
                minWidth: '10%'
              }
            }}
          />
          <SelectInput
            labelText='Filter by window'
            items={deliveriesList}
            value={window || ''}
            error={'window' in errors}
            formControlProps={{
              fullWidth: false,
              style: {
                minWidth: '10%',
                marginRight: '20px'
              }
            }}
            inputProps={{
              name: 'window',
              onChange
            }}
          />
          <SelectInput
            labelText='Filter by status'
            items={{ all: 'All', done: 'Done', process: 'In process', future: 'Future' }}
            value={status}
            formControlProps={{
              fullWidth: false,
              style: {
                minWidth: '10%',
                marginRight: '20px'
              }
            }}
            inputProps={{
              name: 'status',
              onChange
            }}
          />
          <div className='buttons-on-filters'>
            <Button color='danger' size='large' onClick={() => {
              applyFilters(this.defFilters);
              this.setState({ filters: this.defFilters });
            }}>Clear</Button>
            <Button color='darkBlue' size='large' pullRight type='submit'>Search</Button>
          </div>
        </form>
      </Fragment>
    ) : null;
  }
}

Filters.propTypes = {
  deliveryTime: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  applyFilters: PropTypes.func.isRequired
};

export default Filters;
