import React, { Component } from 'react';
import PropTypes from 'prop-types';
import CustomInput from 'components/CustomInput/CustomInput';
import RegularCard from 'components/Cards/RegularCard';
import Button from 'components/CustomButtons/Button';
import ItemGrid from 'components/Grid/ItemGrid';
import { Grid } from 'material-ui';
import rstr from 'randomstring';
import SelectInput from 'components/SelectInput';

class PassChange extends Component {
  constructor(props) {
    super(props);
    const { formtype,type } =this.props;
    var val=(formtype=='usertype')?type:'';
    this.state = {
      pass: val
    };
  }

  render() {
    const { pass } = this.state;
    const { email, username, formtype, types } = this.props;
    var title = `Change password for ${username}`;
    if(formtype=='usertype'){
      title= `Change Role for ${username}`;
    }
    return (
      <div>
        <Grid container>
          <ItemGrid md={12}>
            <RegularCard
              cardTitle={title}
              cardSubtitle={email}
              content={
                <div>
                  {formtype=='password'&&<Grid container>
                    <ItemGrid xs={12} sm={12} md={12}>
                      <CustomInput
                        labelText='New password'
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          value: pass,
                          onChange: e => this.setState({ pass: e.target.value })
                        }}
                      />
                    </ItemGrid>
                    <ItemGrid xs={12} sm={12} md={12}>
                      <Button onClick={() => this.setState({ pass: rstr.generate(16) })}>Generate</Button>
                      <Button onClick={() => this.props.save(pass)} pullRight color={'darkBlue'}>Save</Button>
                    </ItemGrid>
                  </Grid>}
                  {formtype=='usertype'&&<Grid container>
                    <ItemGrid xs={12} sm={12} md={12}>
                      <SelectInput
                          labelText={'User Category'}
                          items={types}
                          value={pass || ''}
                          name={'type'}
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={{
                            onChange: e => this.setState({ pass: e.target.value })
                          }}
                        />
                    </ItemGrid>
                    <ItemGrid xs={12} sm={12} md={12}>
                      <Button onClick={() => this.props.save(pass)} pullRight color={'darkBlue'}>Save</Button>
                    </ItemGrid>
                  </Grid>}
                </div>
              }
            />
          </ItemGrid>
        </Grid>
      </div>
    );
  }
}

PassChange.propTypes = {
  username: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  save: PropTypes.func.isRequired
};

export default PassChange;
