const PHOTOS_PATH = '/photos/';
const PHOTOS_PATH_MIN = '/photos/';
const MAPS_API_KEY = 'AIzaSyBEdvNdAlB2eDMxEv-1X34IPRQ071OBjBI';
const ABU_CENTER = { lat: 24.434712, lng: 54.408945 };
const S3_BUCKET ='prod-basiligo';
const S3_REGION ='me-south-1';
const AWSaccessKeyIdS3 ='AKIA4BFCJPR7NA4OZHOZ';
const AWSsecretAccessKeyS3 ='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';
const AWS_PHOTO='photos';
const AWS_PHOTO_URL='https://'+S3_BUCKET+'.s3.'+S3_REGION+'.amazonaws.com/'+AWS_PHOTO+'/';

export {
  PHOTOS_PATH,
  PHOTOS_PATH_MIN,
  MAPS_API_KEY,
  ABU_CENTER,
  S3_BUCKET,
  S3_REGION,
  AWSaccessKeyIdS3,
  AWSsecretAccessKeyS3,
  AWS_PHOTO,
  AWS_PHOTO_URL
};
