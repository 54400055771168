import React, { Component } from 'react';
import PropTypes from 'prop-types';
import GoogleMap from 'google-map-react';
import { connect } from 'react-redux';

const bootstrapURLKeys = {
  language: 'en',
  region: 'GB',
  libraries: ['places'].join(','),
  v: '3.35'
};

class Map extends Component {
  constructor(props) {
    super(props);
    const { center } = props;
    this.state = {
      center
    };
  }

  getCenter() {
    window.navigator.geolocation.getCurrentPosition(pos => {
      const { latitude: lat, longitude: lng } = pos.coords;
      this.setState({ center: { lat, lng } });
    });
  }

  componentDidMount() {
    const { center } = this.state;
    if (this.MapRef && !(center && (center.lat && center.lng))) {
      this.getCenter();
    }
  }

  componentWillReceiveProps(next) {
    const { center: thisCenter } = this.state;
    const { center: nextCenter } = next;
    if (this.MapRef && (thisCenter.lat !== nextCenter.lat || thisCenter.lng !== nextCenter.lng)) {
      this.getCenter();
    }
  }

  render() {
    const { onLoad, zoom, children, apiKey, ...rest } = this.props;
    const { center } = this.state;
    return (
      apiKey ? <GoogleMap
        ref={el => (this.MapRef = el)}
        bootstrapURLKeys={{ ...bootstrapURLKeys, key: apiKey }}
        onGoogleApiLoaded={onLoad}
        yesIWantToUseGoogleMapApiInternals
        center={center || { lat: 0, lng: 0 }}
        zoom={zoom}
        {...rest}
      >
        {children}
      </GoogleMap> : null
    );
  }
}

Map.propTypes = {
  center: PropTypes.shape({
    lat: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    lng: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  }),
  zoom: PropTypes.number,
  onLoad: PropTypes.func,
  children: PropTypes.any,
  apiKey: PropTypes.string.isRequired
};

Map.defaultProps = {
  zoom: 11,
  onLoad: () => {}
};

const props = state => ({
  apiKey: (state.settings['MAPS_API_KEY'] || {}).value
});

export default connect(props)(Map);
