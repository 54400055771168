import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { socketConnect } from 'socket.io-react';
import PropTypes from 'prop-types';
import { clearModal, setModal } from 'store/modal';
import { spin, stop } from 'store/spinner';
import { PreviewModal } from '../Modals';
import sendFile from 'handler/sendFile';

class Files extends Component {

  constructor(props) {
    super(props);
    this.state = {
      file: null,
      reader: null,
      files: [],
      preview: null,
    };
    this.getFiles();
  }

  listener = ({ type, data }) => {
    this.props.stop()
      switch (type) {
        case 'saveFileOk':
          this.getFiles();
        break;
        case 'getFileRawOk':
          this.setState({
            preview: data.preview,
          });
          this.showPreviewModal();
        break;
        case 'removeFileOk':
          this.getFiles();
        break;
        case 'getFilesOk':
          this.setState({
            files: data.files
          });
          this.props.stop();
        break;
        case 'saveFileErr':
          alert(data.message);
        break;
      }
  };

  _handleSubmit(e) {
    e.preventDefault();
    this.props.spin();
   // this.props.stop();
    const {file, reader} = this.state;
    const {user_id, socket} = this.props;
    
    const allowed_types = [
      'application/pdf', //pdf
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document', //docx
      'application/msword', //doc
      'image/jpeg', //jpeg, jpg
      'audio/ogg',
      'audio/mpeg',
    ];

    if (allowed_types.indexOf(file.type) != -1) {
      if (file.size < 250000) {
        var modelName = 'Files';
        var attribute = 'thefile';
        const dataForm = new FormData();
        dataForm.append('access_token', this.props.user.token);
        dataForm.append('user_id', user_id);
        dataForm.append('name', file.name);
        dataForm.append('size', file.size);
        dataForm.append('filetype', file.type);
        dataForm.append('modelName', modelName);
        dataForm.append('attribute', attribute);
        dataForm.append(`${modelName}[${attribute}]`, file);
        sendFile.call(this, dataForm, this.getFiles());
      } else {
        alert('A big File Size, please use 250 kb maximum');
      }
    } else {
      alert('Not Allowed File Type');
    }
  }

  getFiles = () => {
    //this.props.spin();
    this.props.socket.emit('profile', { type: 'getFiles', data: { 
        'userId': this.props.user_id,
    } });
  }

  previewFile(hash) {
    this.props.spin();
    this.props.socket.emit('profile', { type: 'getFileRaw', data: { 
        'hash': hash,
    }});
  }

  showPreviewModal() {
    this.props.stop();
    const {setModal} = this.props;
    const {preview} = this.state;
    const props = {
      width: '55%',
      height: '56vh',
      display: true
    };

    props.content = <PreviewModal src={preview}  />;
    props.width = '640px';
    props.height = '480px';
    
    setModal(props);
  }

  downloadFile(hash) {
    window.open(
          'https://basiligo:basiligo2019!@report.basiligo.ae/admin/site/getfile?hash='+hash,
          //'_blank' // <- This is what makes it open in a new window.
    );
  }

  removeFile(hash) {
    this.props.spin();
    this.props.socket.emit('profile', { type: 'removeFile', data: { 
        'userId': this.props.user_id,
        'hash' : hash,
    } });
  }

  _handleImageChange(e) {
    e.preventDefault();

    let reader = new FileReader();
    let file = e.target.files[0];

    reader.onloadend = () => {
      this.setState({
        file: file,
        reader: reader, 
        //imagePreviewUrl: reader.result
      });
    }

    reader.readAsDataURL(file)
  }

  componentDidUpdate() {
    this.getFiles();
  }

  componentWillMount() {
    this.props.socket.on('profile', this.listener);
  }

  componentWillUnmount() {
    this.props.socket.removeListener('profile', this.listener);
  }

  render() {
    const {files} = this.state;
    const rows = [];
    Object.keys(files).map((el, idx) => {
      var size = files[el].size/1000;
      size = size.toFixed(2); 
      var preview = null;
      if (files[el].type == 'image/jpeg') {
        preview = (<span><a className="btn btn-primary file-download" onClick={() => { this.previewFile(files[el].hash) }}>Preview</a>&nbsp;|&nbsp;</span>);
      }
      rows.push(
        <tr>
          <td>{idx+1}</td>
          <td>{files[el].name}</td>
          <td>{files[el].type}</td>
          <td>{size} kb</td>
          <td>
              {preview}
              <a className="btn btn-primary file-download" onClick={() => { this.downloadFile(files[el].hash) }}>Download</a>
              &nbsp;|&nbsp;
              <a className="btn btn-primary file-download" onClick={() => { this.removeFile(files[el].hash) }}>Remove</a>
          </td>
        </tr>
      ); 
    });
    return (
      <div>
        <div className="fileUpload">
          <form onSubmit={(e)=>this._handleSubmit(e)}>
            <input className="fileInput" 
              type="file" 
              onChange={(e)=>this._handleImageChange(e)} />
            <button className="submitButton" 
              type="submit" 
              onClick={(e)=>this._handleSubmit(e)}>Upload File</button>
          </form>
        </div>
        <br/>
        <span className="files-head">Existed files:</span>
        <table className="Tabled-small">
            <thead className="roundedHeader">
              <tr id="header-row">
                <th scope="col">#</th>
                <th scope="col">Name</th>
                <th scope="col">Type</th>
                <th scope="col">Size</th>
                <th scope="col">Actions</th>
              </tr>
            </thead>
            <tbody> 
                {rows}
            </tbody>
        </table>
      </div>
    )
  }
}


Files.propTypes = {
  dataArray: PropTypes.object,
  socket: PropTypes.object.isRequired,
  userData: PropTypes.object,
  errors: PropTypes.object,
  setModal: PropTypes.func.isRequired,
  clearModal: PropTypes.func.isRequired,
  spin: PropTypes.func.isRequired,
  stop: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
};

const props = state => ({
  dataArray: state.dataArray,
  user: state.user,
});

const actions = dispatch => ({
  setModal: object => dispatch(setModal(object)),
  spin: () => dispatch(spin()),
  stop: () => dispatch(stop()),
  clearModal: () => dispatch(clearModal()),
});

export default socketConnect(connect(props, actions)(Files));
