/* eslint-disable no-extend-native */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Grid } from 'material-ui';
import ItemGrid from 'components/Grid/ItemGrid';
import ChartHandler from './ChartHandler';
import { Line, Bar } from 'components/Charts';
import moment from 'moment';
import { socketConnect } from 'socket.io-react';
import Button from 'components/CustomButtons/Button';
import { pushModal, clearModalStack } from 'store/modalStack';
import ProgressEdit from './ProgressEdit';

Array.prototype.sum = function () {
  return this.reduce((acc, cur) => acc + cur, 0);
};

Array.prototype.mid = function () {
  return this.sum() / this.length;
};

class TabBodyAnalysis extends Component {
  constructor(props) {
    super(props);
    const { user_id } = props;
    this.state = {
      activeYear:moment().year(),
      weightHistory:{},
      BMIHistory:{},
      SMMHistory:{},
      BFMHistory:{},
      progress: {
        bust: {},
        waist: {},
        hips: {},
        leftBiceps: {},
        rightBiceps:{},
        thigh:{},
        calf:{},
      }
    };
    this.getWeightHistory(user_id);
    this.getBodyMeasurements(user_id);
  }
  getWeightHistory = (user_id) =>{
    this.props.socket.emit('progress',{type:'getWeightHistory', data: { user_id }});
  }
  getBodyMeasurements = (user_id) =>{
    this.props.socket.emit('progress',{type:'getBodyMeasurements', data: { user_id }});
  }
  getProgress = user_id => this.props.socket.emit('progress', { type: 'get', data: { user_id } });

  listener = ({ type, data }) => {
    if (this.BodyAnalysisRef) {
      if (type === 'getWeightHistoryOk') {
        this.setState({ ...data});
      } else if (type === 'getBodyMeasurementsOk') {
        this.setState({ ...data});
      } else if (['getErr', 'setErr'].includes(type)) {
        console.error(data);
      }
    }
  };

  componentWillMount() {
    this.props.socket.on('progress', this.listener);
  }

  componentWillUnmount() {
    this.props.socket.removeListener('progress', this.listener);
  }

  getLabels = (type, initialDate = moment().utc().startOf('day'), activeYear ,keys) => {
    let labels = {};
    let lowDate = initialDate.clone();
    // const { activeYear }=this.state;
    // console.log(activeYear);
    switch (type) {
      case 'year':
        let existKeys = [];
        keys&&keys.filter(el => (moment.unix(el).year()==activeYear)).map((date)=>{
          existKeys.push(moment.unix(date).format('MMM'))
          labels = { ...labels, [date]: moment.unix(date).format('MMM Do') };
        });
        lowDate.subtract(12 * 1, 'month').startOf('month');
        while (lowDate.add(1, 'month').isSameOrBefore(initialDate)) {
          const stamp = lowDate.unix();
          if(!existKeys.includes(lowDate.format('MMM')) ){
            labels = { ...labels, [stamp]: lowDate.format('MMM') };
          }
        }
        // keys&&keys.filter(el => (moment.unix(el).year()==activeYear)).map((date)=>{
        //   labels = { ...labels, [date]: moment.unix(date).format('MMM Do') };
        // });
        break;
      
    }
    return labels;
  };

  _groupData = (data, dates, calc = 'sum', mul = 1) => {
    const stamps = Object.keys(dates);
    const dif = +stamps[1] - +stamps[0];
    let dataSet = stamps.reduce((acc, cur) => {
      const tmp = Object.keys(data).reduce((res, stamp) => +stamp == +cur  ? [...res, data[stamp]] : res, []);
      return { ...acc, [+cur]: tmp };
    }, {});
    return Object.values(dataSet).map(set => set.length ? (set[calc]() * mul).toFixed(2) : null);
  };

  getProps = (dataObject) => {
    const { label, data, period, calcType, mul, additional, initialDate, activeYear,min } = dataObject;
    const keys = Object.keys(data);
    const labels = this.getLabels(period, initialDate, activeYear,keys);
    // console.log(labels);
    return {
      labels: Object.values(labels),
      timestamps: Object.keys(labels),
      min,
      label:label,
      dataSets: {
        [label]: {
          data: this._groupData(data, labels, calcType, mul),
          ...additional
        }
      }
    };
  };

  componentWillReceiveProps(nextProps, nextContext) {
    if (this.props.user_id !== nextProps.user_id) {
      this.getWeightHistory(nextProps.user_id);
      this.getBodyMeasurements(nextProps.user_id);
    }
  }

  saveProgress = (progress, user_id) => {
    this.props.socket.emit('progress', {
      type: 'set',
      data: { progress, user_id }
    });
  };

  openEditModal = () => {
    const { userData } = this.props;
    const { regDate, user_id } = userData;
    const { progress } = this.state;
    this.props.pushModal({
      content: <ProgressEdit regDate={+regDate} progress={progress} saveProgress={progress => this.saveProgress(progress, user_id)} />,
      size: {
        width: 760,
        height: 490
      }
    });
  };
  calculateAge = (birthday) => { // birthday is a date
    const birth = moment.unix(birthday);
    var today = Date.now()/1000;
    var age = null;
    if (birthday) {
      var birthYear = birth.format('YYYY');
      var todayYear = moment.unix(today).format('YYYY');
      var birthMonth = birth.format('MM');
      var todayMonth = moment.unix(today).format('MM');

      var age = todayYear - birthYear;
      if (birthMonth > todayMonth) {
        age = age - 1;
      }
    }

    if (age) {
      age = age;
    } 
    return age;
  }
  getBodyFatMassText =(age,bodyFatMass,gender)=>{
    let text ='';
    if(gender=='male'){

      if(age>20 && age<=40){
        text="Underfat";
        if(bodyFatMass>8 && bodyFatMass <=19){
          text="Healthy";
        }else if(bodyFatMass>19 && bodyFatMass <=25){
          text="Overweight";
        }else if(bodyFatMass>25){
          text="Obese";
        }
      }else if(age>40 && age<=60){
        text="Underfat";
        if(bodyFatMass>11 && bodyFatMass <=22){
          text="Healthy";
        }else if(bodyFatMass>22 && bodyFatMass <=27){
          text="Overweight";
        }else if(bodyFatMass>27){
          text="Obese";
        }
      }else if(age>60 && age<=79){
        text="Underfat";
        if(bodyFatMass>13 && bodyFatMass <=25){
          text="Healthy";
        }else if(bodyFatMass>25 && bodyFatMass <=30){
          text="Overweight";
        }else if(bodyFatMass>30){
          text="Obese";
        }
      }

    }else{
    
      if(age>20 && age<=40){
        text="Underfat";
        if(bodyFatMass>21 && bodyFatMass <=33){
          text="Healthy";
        }else if(bodyFatMass>33 && bodyFatMass <=39){
          text="Overweight";
        }else if(bodyFatMass>39){
          text="Obese";
        }
      }else if(age>40 && age<=60){
        text="Underfat";
        if(bodyFatMass>23 && bodyFatMass <=35){
          text="Healthy";
        }else if(bodyFatMass>35 && bodyFatMass <=40){
          text="Overweight";
        }else if(bodyFatMass>40){
          text="Obese";
        }
      }else if(age>60 && age<=79){
        text="Underfat";
        if(bodyFatMass>24 && bodyFatMass <=36){
          text="Healthy";
        }else if(bodyFatMass>36 && bodyFatMass <=42){
          text="Overweight";
        }else if(bodyFatMass>42){
          text="Obese";
        }
      }
   }

   return text;
  }
  calculateBodyFatMass =(gender,BMI,age) =>{
      let bodyFatMass=Math.ceil((1.20 * BMI) + (0.23 * age) - 5.4);
      if(gender=='male'){
        bodyFatMass = Math.ceil((1.20 * BMI) + (0.23 * age) - 16.2);
      }
      return bodyFatMass;
  }
  calculateIdealBodyWeight = (gender,height) =>{
    let bodyweight= Math.ceil(45.5 + (0.91 * (height - 152.4)));
    if(gender=='male'){
       bodyweight = Math.ceil(50 + (0.91 * ( height - 152.4)));
    }
    return bodyweight;
  }
  render() {
    const { userData } = this.props;
    const { firstName , lastName, height,weight:cweight,gender,birthday,regDate } =userData;
    console.log(userData);
    var BMI = ( (cweight/1000) / ((height/100)*(height/100)) ).toFixed(1);
    const { weightHistory,BMIHistory,SMMHistory,BFMHistory,activeYear } = this.state;
    console.log(BMIHistory);
    let yearList ={};
    let regYear = moment.unix(regDate).year();
    const differ =activeYear-regYear;
    if(differ>0){
      for(var i=0;i<=differ;i++){
        yearList[regYear+i]=regYear+i;
      }
    }
    const buttonStyle = {
      position: 'absolute',
      top: '25px',
      right: '25px'
    };
    let age = this.calculateAge(birthday);
    let lastUpdate =(weightHistory && Object.keys(weightHistory).length)?Object.keys(weightHistory)[Object.keys(weightHistory).length-1]:regDate;
    let bodyFatMass = (gender&& BMI &&age )?this.calculateBodyFatMass(gender,BMI,age):null;
    let idealBodyWeight = (gender&& height )? this.calculateIdealBodyWeight(gender,height):null;
    return (
      <div ref={el => (this.BodyAnalysisRef = el)} className='progress scrollable-h'>
        <Grid container>
        <Grid container>
          <ItemGrid md={3} xs={12}>
             <p><strong>Name</strong></p>
             <p>{firstName} {lastName}</p>
          </ItemGrid>
          <ItemGrid md={3} xs={12}>
             <p><strong>Height</strong></p>
             <p>{height} cm</p>
          </ItemGrid>
          <ItemGrid md={3} xs={12}>
             <p><strong>Age</strong></p>
             <p>{age}</p>
          </ItemGrid>
          <ItemGrid md={3} xs={12}>
             <p><strong>Gender</strong></p>
             <p>{gender}</p>
          </ItemGrid>
        </Grid>
        <Grid container>
        <ItemGrid md={3} xs={12}>
             <p><strong>Current Weight</strong></p>
             <p>{cweight/1000}</p>
             <p>Last Update At : {moment.unix(lastUpdate).format('DD/MM/YYYY')}</p>
             </ItemGrid>
          <ItemGrid md={3} xs={12}>
             <p><strong>BMI</strong></p>
             <p>{BMI}</p>
             <p>{(BMI>=18.5&&BMI<=24.9)?'Normal':'Over'}</p>
             </ItemGrid>
          <ItemGrid md={3} xs={12}>
             <p><strong>Body Fat Mass</strong></p>
             <p className='body-mass-text'>{bodyFatMass}</p>
             {bodyFatMass&&<p>{this.getBodyFatMassText(age,bodyFatMass,gender)}</p>}
             </ItemGrid>
          <ItemGrid md={3} xs={12}>
             <p><strong>Ideal Body Weight</strong></p>
             <p>{idealBodyWeight}</p>
          </ItemGrid>
          </Grid>
          <ChartHandler
          caption={'Weight'}
          renderer={props => <Line {...props} />}
          activeYear={activeYear}
          gender
          yearList={yearList}
          propser={(period, activeYear) => this.getProps({
            label: 'Weight',
            data: weightHistory,
            calcType: 'mid',
            mul: 0.001,
            initialDate: moment().utc().startOf('day'),
            period,
            activeYear,
            min:1
          })}
          isEmpty={!(weightHistory && Object.keys(weightHistory).length)}
        />
         <ChartHandler
          caption={'BMI'}
          activeYear={activeYear}
          gender
          yearList={yearList}
          renderer={props => <Line {...props} />}
          propser={(period, activeYear) => this.getProps({
            label: 'BMI',
            data: BMIHistory,
            calcType: 'mid',
            mul:0.001,
            initialDate: moment().utc().startOf('day'),
            period,
            activeYear,
            min:1
          })}
          isEmpty={!(BMIHistory && Object.keys(BMIHistory).length)}
        />
        
        <ChartHandler
          caption={'Skeletal Muscle Mass'}
          activeYear={activeYear}
          gender
          yearList={yearList}
          renderer={props => <Line {...props} />}
          propser={(period, activeYear) => this.getProps({
            label: 'Skeletal Muscle Mass',
            data: SMMHistory,
            calcType: 'mid',
            mul: 1,
            initialDate: moment().utc().startOf('day'),
            period,
            activeYear
          })}
          isEmpty={!(SMMHistory && Object.keys(SMMHistory).length)}
        />
        <ChartHandler
          caption={'Percentage Body Fat'}
          activeYear={activeYear}
          gender
          yearList={yearList}
          renderer={props => <Line {...props} />}
          propser={(period, activeYear) => this.getProps({
            label: 'Percentage Body Fat',
            data: BFMHistory,
            calcType: 'mid',
            mul: 0.001,
            initialDate: moment().utc().startOf('day'),
            period,
            activeYear
          })}
          isEmpty={!(BFMHistory && Object.keys(BFMHistory).length)}
        />
        </Grid>
        {/* <Button
          color={'darkBlue'}
          size={'medium'}
          pullRight
          onClick={this.openEditModal}
          style={buttonStyle}
        >
          Edit progress
        </Button> */}
      </div>
    );
  }
}

TabBodyAnalysis.propTypes = {
  socket: PropTypes.object.isRequired,
  user_id: PropTypes.number.isRequired,
  userData: PropTypes.object.isRequired,
  dataArray: PropTypes.object,
  pushModal: PropTypes.func,
  clearModalStack: PropTypes.func
};

const props = state => ({
  dataArray: state.dataArray
});

const actions = dispatch => ({
  pushModal: obj => dispatch(pushModal(obj)),
  clearModalStack: () => dispatch(clearModalStack())
});

export default connect(props, actions)(socketConnect(TabBodyAnalysis));
