import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
//import createBrowserHistory from 'history/createBrowserHistory';
import { browserHistory } from 'react-router';
import CustomInput from 'components/CustomInput/CustomInput';
import Button from 'components/CustomButtons/Button';
import SelectInput from 'components/SelectInput';

class Filters extends Component {
  constructor(props) {
    super(props);
    this.defFilters = {
      firstName: {
        type: 'text',
        value: ''
      },
      email: {
        type: 'text',
        value: ''
      },
      phone: {
        type: 'text',
        value: ''
      },
      lastName: {
        type: 'text',
        value: ''
      },
      area: {
        type: 'select',
        value: ''
      },
      slot_id: {
        type: 'select',
        value: ''
      },
      nationality: {
        type: 'text',
        value: ''
      },
      notrenew_reason: {
        type: 'select',
        value: ''
      }
    };
    Object.freeze(this.defFilters);
    this.state = {
      filters: this.defFilters
    };
  }

  componentDidMount() {
    const { getArrays, dataArray } = this.props;
    const types = ['areasList', 'slotsList','countryList'].filter(el => !(el in dataArray));
    if (types.length) {
      getArrays(types);
    }
  }

  render() {
    const { filters } = this.state;
    const { errors, applyFilters, dataArray } = this.props;
    const { areasList, slotsList,countryList } = dataArray;

    const reasonList = [
      '',
      'Traveling',
      'Didn’t like the food',
      'No much options',
      'Delivery Timings',
      'Not my macros',
      'Expensive',
      'No Discounts',
      'Others',
    ];

    const areas = { '': 'All', ...(areasList || {}) };
    const countries = { '': 'All', ...(countryList || {}) };
    const deliveryTimes = (slotsList || []).reduce((acc, { id, name }) => ({ ...acc, [id]: name }), { '': 'All' });
    const onChange = (e, _value, _name) => {
      const name = _name || e.target.name;
      const value = _value || e.target.value;
      this.setState({ filters: { ...filters, [name]: { ...filters[name], value } } });
      console.log(this.state);
    };
    return (
      <Fragment>
        <form
          ref={el => (this.FilterRef = el)}
          className='fix-on-filters'
          onSubmit={e => {
            e.preventDefault();
            applyFilters(filters);
            return false;
          }}
          onKeyPress={e => {
            if (e.key === 'Enter') {
              applyFilters(filters);
            }
          }}
        >
          <CustomInput
            labelText='Search by phone number'
            formControlProps={{
              fullWidth: false,
              style: {
                margin: '0 10px 0 4px',
                minWidth: '10%'
              }
            }}
            error={'phone' in errors}
            inputProps={{
              value: filters.phone.value,
              type: 'text',
              name: 'phone',
              onChange
            }}
          />
          <CustomInput
            labelText='Search by email'
            formControlProps={{
              fullWidth: false,
              style: {
                margin: '0 10px 0 4px',
                minWidth: '10%'
              }
            }}
            error={'email' in errors}
            inputProps={{
              value: filters.email.value,
              type: 'text',
              name: 'email',
              onChange
            }}
          />
          <CustomInput
            labelText='Search by first name'
            formControlProps={{
              fullWidth: false,
              style: {
                margin: '0 10px 0 4px',
                minWidth: '10%'
              }
            }}
            error={'firstName' in errors}
            inputProps={{
              value: filters.firstName.value,
              type: 'text',
              name: 'firstName',
              onChange
            }}
          />
          <CustomInput
            labelText='Search by last name'
            formControlProps={{
              fullWidth: false,
              style: {
                margin: '0 10px 0 4px',
                minWidth: '10%'
              }
            }}
            error={'lastName' in errors}
            inputProps={{
              value: filters.lastName.value,
              type: 'text',
              name: 'lastName',
              onChange
            }}
          />
          <CustomInput
            labelText='Search by nationality'
            formControlProps={{
              fullWidth: false,
              style: {
                margin: '0 10px 0 4px',
                minWidth: '10%'
              }
            }}
            error={'nationality' in errors}
            inputProps={{
              value: filters.nationality.value,
              type: 'text',
              name: 'nationality',
              onChange
            }}
          />
          {/* <SelectInput
            labelText='Filter by area'
            items={areas}
            value={filters.area.value || ''}
            error={'area' in errors}
            formControlProps={{
              fullWidth: false,
              style: {
                minWidth: '10%',
                marginRight: '10px'
              }
            }}
            inputProps={{
              name: 'area',
              onChange
            }}
          /> */}
          {/* <SelectInput
            labelText='Filter by delivery time'
            items={deliveryTimes}
            value={filters['slot_id'].value || ''}
            error={'slot_id' in errors}
            formControlProps={{
              fullWidth: false,
              style: {
                minWidth: '10%',
                marginRight: '10px'
              }
            }}
            inputProps={{
              name: 'slot_id',
              onChange
            }}
          />
          <SelectInput
            labelText='Filter by not renew reason'
            items={reasonList}
            value={filters.notrenew_reason.value || ''}
            error={'notrenew_reason' in errors}
            formControlProps={{
              fullWidth: false,
              style: {
                minWidth: '10%',
                marginRight: '10px'
              }
            }}
            inputProps={{
              name: 'notrenew_reason',
              onChange
            }}
          /> */}
         
          <div className='buttons-on-filters'>
            <Button color='danger' size='large' onClick={() => {
              this.setState({ filters: this.defFilters });
              applyFilters(this.defFilters);
            }}>Clear</Button>
            <Button color='darkBlue' size='large' type='submit'>Apply</Button>
          </div>
        </form>
      </Fragment>
    );
  }
}

Filters.propTypes = {
  errors: PropTypes.object.isRequired,
  dataArray: PropTypes.object.isRequired,
  applyFilters: PropTypes.func.isRequired,
  getArrays: PropTypes.func.isRequired
};

const props = state => ({
  dataArray: state.dataArray
});

export default connect(props, null)(Filters);
