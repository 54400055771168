import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  withStyles,
  Table,
  TableBody,
  TableCell,
  TableRow,
  IconButton,
  Grid as MUIGrid,
  Tooltip,
  TableHead
} from 'material-ui';
import { Edit, Delete, Visibility,ContentCopy } from '@material-ui/icons';
import tasksStyle from 'assets/jss/material-dashboard-react/tasksStyle.jsx';

import ItemGrid from 'components/Grid/ItemGrid';
import { Text } from 'components/Typography';
import noImage from 'assets/no_img.svg';

import { iterator } from 'utils';
import { PHOTOS_PATH_MIN } from 'variables/constants';

const columns = ['Info', 'Meal Type', 'Dish group', 'Label', 'Program', 'Total calories', 'Rating', 'Actions'];

class Grid extends Component {
  renderHeaderRow = (classes) => {
    return <TableRow>
      {columns.map((el, key) => {
        return (
          <TableCell
            className={classes.tableCell + ' ' + classes.tableHeadCell}
            key={key}
          >
            <Text color='white'>{el}</Text>
          </TableCell>
        );
      })}
    </TableRow>;
  };

  renderRow = (item, idx) => {
    const { classes, dictionaries } = this.props;
    const { dietList, dishGroups, dishTypes, dishLabels, typeList } = dictionaries;
    const { id, type, dish_group_id, diets, calories, rating, label_id,dish_groups_id,mealTypes } = item;
    const cellClassName = classes.tableCell + ' ' + classes.tableHeadCell;
    const cell = (inner, key, classVal='dishrow') => (<TableCell className={cellClassName+' '+classVal} key={`cell_${key}`}>{inner}</TableCell>);
    const iter = iterator();
    const collectDiets = () => (diets || '').split(',').reduce((acc, cur, idx) => `${acc}${idx ? ', ' : ''}${dietList[cur]}`, '');
    const collectmealTypes = () => (mealTypes || '').split(',').reduce((acc, cur, idx) => `${acc}${idx ? ', ' : ''}${(typeList[cur]!=undefined)?typeList[cur].title:''}`, '');
    const collectdishGroups = () => (dish_groups_id || '').split(',').reduce((acc, cur, idx) => `${acc}${idx ? ', ' : ''}${dishGroups[cur]}`, '');
    const getRating = () => Array(5).fill(0).map((el, idx) => idx < Math.round(rating) ? <span key={idx}>&#x2605;</span> : <span key={idx}>&#x2606;</span>);
    return (
      <TableRow className={classes.tableRow} key={idx}>
        {cell(this.renderInfoCell(item), iter(),'dinfo')}
        {cell(<Text bold color='success'>{collectmealTypes()}</Text>, iter(),'dtype')}
        {cell(<Text bold color='primary'>{collectdishGroups()}</Text>, iter(),'dgroup')}
        {cell(<Text bold color='danger'>{dishLabels[label_id] || ''}</Text>, iter(),'dlabel')}
        {cell(<Text bold color='warning' paragraph>{collectDiets()}</Text>, iter(),'dprogram')}
        {cell(<Text bold color='success'>{`${calories / 1000} kCal`}</Text>, iter(),'dcal')}
        {cell(<Text color='info' style={{ fontSize: '24px' }}>{getRating()}</Text>, iter(),'drate')}
        {cell(this.renderActions(id), iter())}
      </TableRow>
    );
  };

  renderInfoCell = (item) => {
    const { id,image, name, nickname, description, fat, protein, carb, name_arabic } = item;
    const { classes } = this.props;
    const format = (val) => {
      val = val / 1000;
      return isNaN(val) ? '-' : val.toFixed(2);
    };
    const imgUrl = image ? `${PHOTOS_PATH_MIN}${image}` : noImage;
    const descLength = 100;
    const shortDesc = description.length > descLength ? `${description.substr(0, descLength)}...` : description;
    let nickename = (nickname && nickname!=null)?nickname.replace(/&amp;/g, "&"):'';
    let shortDescStr = (shortDesc)?shortDesc.replace(/&amp;/g, "&"):shortDesc;
    return (
      <MUIGrid container>
        <ItemGrid md={3}><div className={classes.imagePreview320} style={{ backgroundImage: `url(${imgUrl})` }} /></ItemGrid>
        <ItemGrid md={9}>
          <h4>{nickename!=null && nickename} - {id}</h4>
          <p>{name_arabic!=null && name_arabic.replace(/&amp;/g, '&')}</p>
          {/* <Text paragraph inline={false}>{shortDescStr}</Text> */}
          <Text paragraph inline={false} color='success'>{`Fat ${format(fat)} g / Protein ${format(protein)} g / Carbs ${format(carb)} g`}</Text>
        </ItemGrid>
      </MUIGrid>
    );
  };

  renderActions = id => {
    const { classes, actions } = this.props;
    return Object.keys(actions).map((el, idx) => {
      const { tooltip, button, callback } = actions[el];
      let icon = null;
      switch (el) {
        case 'update':
          icon = (
            <Edit className={classes.tableActionButtonIcon + ' ' + classes.edit} />
          );
          break;
          case 'duplicate':
            icon = (
              <ContentCopy className={classes.tableActionButtonIcon + ' ' + classes.edit} />
            );
            break;
        case 'close':
          icon = (
            <Delete className={classes.tableActionButtonIcon + ' ' + classes.close} />
          );
          break;
        case 'view':
        case 'viewlog':
          icon = (
            <Visibility className={classes.tableActionButtonIcon + ' ' + classes.view} />
          );
          break;
      }
      return <Tooltip
        key={idx}
        id='tooltip-top'
        title={tooltip}
        placement='top'
        classes={{ tooltip: classes.tooltip }}
      >
        <IconButton
          onClick={() => callback(id)}
          aria-label={button}
          className={classes.tableActionButton}
        >
          {icon}
        </IconButton>
      </Tooltip>;
    });
  };

  render() {
    const { classes, list } = this.props;
    return (
      <Table className={classes.table}>
        <TableHead className='primaryTableHeader roundedHeader'>
          {this.renderHeaderRow(classes)}
        </TableHead>
        <TableBody>
          {list.map(this.renderRow)}
        </TableBody>
      </Table>
    );
  }
}

Grid.propTypes = {
  classes: PropTypes.object.isRequired,
  list: PropTypes.array.isRequired,
  dictionaries: PropTypes.object.isRequired,
  offset: PropTypes.number.isRequired,
  actions: PropTypes.object
};

Grid.defaultProps = {
  actions: {
    update: () => {},
    delete: () => {}
  }
};

export default withStyles(tasksStyle)(Grid);
