import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Grid } from 'material-ui';
import ItemGrid from 'components/Grid/ItemGrid';
import Paginator from 'components/Paginator';
import { Text } from 'components/Typography';
import Table from './Table';
import moment from 'moment/moment';
import NoImg from 'assets/img/noimg.svg';
import { Link, browserHistory  } from 'react-router';
import Button from 'components/CustomButtons/Button';
class List extends Component {
  getComponents = (components, productsFull, amount) => components.map((el, idx) => {
    return (
      <Grid key={idx} className='component-row' container>
        <Grid item md={6} className='component-title'><Text color='success' inline>{productsFull[el]['nickname'] || productsFull[el]['title']}</Text></Grid>
        <Grid item md={6} className='component-amount'><Text color='warning' inline bold>{amount}</Text></Grid>
      </Grid> 
    );
  });

  getParents = (parents, productsFull) => Object.keys(parents).map((el, idx) => {
    return (
      <Grid key={idx} className='component-row' container>
        <Grid item md={6} className='component-title'><Text color='success' inline>{productsFull[el]['nickname'] || productsFull[el]['title']}</Text></Grid>
        <Grid item md={6} className='component-amount'><Text color='warning' inline bold>{parents[el]}</Text></Grid>
      </Grid>
    );
  });

  renderItem(product_id){
    const { productsFull,typeList } = dictionaries;
    var image = (<img className="mealrow-img" src={NoImg}></img>);
    if (productsFull[product_id].image != null) {
        image = (<img className="mealrow-img" src={productsFull[product_id].image}></img>);
    } 
    var type = typeList[type_id].title;
    var name = productsFull[product_id].title;
    var nickname = productsFull[product_id].nickname;
    return ( 
        
      <div className="mealrow-item">
          {image} <br/>
          <span className="mealrow-type">{type}</span><br/>
          <h4>{nickname}</h4>
          <p className="mealrow-realname">{name}</p>
      </div>
  );
  }
  getRowsByMeal(newdata,mealType_id,is_side=false) {
    const { list, dictionaries, date, notes } = this.props;
    const { productsFull,typeList } = dictionaries;
    const body=Object.keys(newdata).map((acc) => {
      if(acc>0){
          const { title, nickname,image,components } = productsFull[acc] || {};
          var pimage = (<img className="mealrow-img" src={NoImg}></img>);
          if (image != null) {
              pimage = (<img className="mealrow-img" src={image}></img>);
          } 
        
          return  <div className="mealrow-item">
          {pimage} <br/>
          <p className="mealrow-realname">{title} - {acc}</p>
          <Link
                to={`/admin/viewrecipe/${acc}`}
                className={'button-link'}
                target="_blank"
                
              >
                View Recipe
              </Link>
          {/* <Button color='darkBlue' size='large' onClick={() => browserHistory.push(`/admin/viewrecipe/${acc}`)}>View Recipe</Button> */}
      </div>;
     }});

    return <div className="mealrow-meals-recipe"><p className="date-date">{(is_side)?'Sides for ':'Dish for '+typeList[mealType_id].title}</p>{body}
    </div>;
  }
  renderContent() {
    const { list,dictionaries, splitter,usersidesList, actions } = this.props;
    // console.log(usersidesList);
    const { type, values } = splitter;
    const { dishGroups, typeList } = dictionaries;
    let content = null;
    let cols = {};
    let rows = {};
  
        var i=0;
        content=Object.keys(typeList).map((el, key) => {
      
          if(list[el]!=undefined){
            if(i==0){
              cols = {
                title: 'Dish for '+typeList[el].title,
                // type: 'Meal Type',
               // symbol: 'Symbol',
                notes: 'Notes',
                amount: 'Serving',
                actions: 'Actions'
              };
              i=1;
             }else{
              cols = {
                title:'Dish for '+typeList[el].title,
                // type: 'Meal Type',
               // symbol: 'Symbol',
                notes: '',
                amount: '',
                actions: ''
              };
             }
            
            rows = this.getRowsByMeal(list[el],el);
            return Object.keys(rows).length ? rows : null;
          }
         
        });
        
    return content;
  }
  renderSideContent() {
    const { dictionaries,usersidesList } = this.props;
    const {  typeList } = dictionaries;
    let content = null;
    let cols = {};
    let rows = {};
    var i=0;

    content=Object.keys(typeList).map((el, key) => {
      if(usersidesList!=undefined&&usersidesList[el]!=undefined){
        if(i==0){
          cols = {
            title: 'Side for '+typeList[el].title,
            // type: 'Meal Type',
            // symbol: 'Symbol',
            notes: 'Notes',
            amount: 'Serving',
            actions: 'Actions'
          };
          i=1;
          }else{
          cols = {
            title:'Side for '+typeList[el].title,
            // type: 'Meal Type',
            // symbol: 'Symbol',
            notes: '',
            amount: '',
            actions: ''
          };
          }
        rows = this.getRowsByMeal(usersidesList[el],el,true);
        return Object.keys(rows).length ? rows : null;
      }
      
    });
    return content;
  }

  render() {
    const { page, maxPages, goTo, date } = this.props;
    return (
      <div className='row'>
        <div className='date-date'>{moment.unix(date).format('DD MMM')}</div>
        {this.renderContent()}
        {this.renderSideContent()}
        {maxPages > 1 && <Grid container>
          <ItemGrid xs={12} sm={12} md={12}>
            <Paginator goToPage={goTo} maxPage={maxPages} current={page} />
          </ItemGrid>
        </Grid>}
      </div>
    );
  }
}

List.propTypes = {
  dictionaries: PropTypes.object.isRequired,
  maxPages: PropTypes.number.isRequired,
  limit: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  list: PropTypes.object.isRequired,
  goTo: PropTypes.func.isRequired,
  splitter: PropTypes.object,
  actions: PropTypes.object,
  date: PropTypes.string
};

List.defaultProps = {
  limit: 20,
  page: 1,
  maxPages: 1,
  goTo: () => {},
  splitter: {},
  date: ''
};
export default List;
