import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { socketConnect } from 'socket.io-react';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';
import Snackbar from 'components/Snackbar/Snackbar';
import Navigation from 'components/Header/Navigation';
import ModalWrapper from 'components/Modal';
import ModalStack from './ModalStack';
import { clearModal } from 'store/modal';
import { clearNotify } from 'store/notify';
import { clearBackLocation } from 'store/backLoc';
import { getMenuLinks } from 'variables/menu';
import { withStyles } from 'material-ui';
import { ChevronLeft } from '@material-ui/icons';
import appStyle from 'assets/jss/material-dashboard-react/appStyle.jsx';
import Spinner from 'components/Spinner';
import { Person } from '@material-ui/icons/index';

class DashboardLayout extends Component {
  constructor(props) {
    super(props);
    const { userType } = this.props;
    // var links = userType ? getMenuLinks(userType) : []
    var links = userType ? getMenuLinks('admin') : []
    links.push({
      path: '',
      sidebarName: this.props.username,
      navbarName: this.props.username,
      icon: Person,
      roles: ['admin']
    });
    this.state = {
      links: links
    };
  }

  getRoute() {
    return this.props.location.pathname !== '/maps';
  }

  componentDidUpdate() {
    this.refs.mainPanel.scrollTop = 0;
  }

  logout(e) {
    e.preventDefault();
    const { browserId, socket } = this.props;
    socket.emit('login', { type: 'logout', data: { browserId } });
  }

  componentWillReceiveProps(next) {
    if (this.LayoutRef && this.props.userType !== next.userType) {
      // this.setState({ links: getMenuLinks(next.userType) });
      this.setState({ links: getMenuLinks('admin') });
    }
  }

  render() {
    const { classes, children, token, userType, notifyProps, modal, clearModal, customBackLocation, clearBackLocation, location, showSpinner,dataArray, ...rest } = this.props;
    const { display, content: modalContent, onClose: onCloseModal, width, height } = modal;
    const { links } = this.state;
    const closeNotify = () => this.props.clearNotify();
    const { pathname } = location;
    return (
      <div className={classes.wrapper} ref={el => (this.LayoutRef = el)}>
        {!['/admin', '/logistic', '/kitchen', '/packager'].includes(pathname) && <div className='back-side-butt' onClick={() => {
          if (customBackLocation) {
            browserHistory.push(customBackLocation);
            clearBackLocation();
          } else {
            browserHistory.goBack();
          }
        }}>
          <div className='icon-back'><ChevronLeft /></div>
        </div>}
        <div className={`${classes.mainPanel} ${/* token ? classes.withSidebar : '' */''}`} ref='mainPanel'>
          {token && <Navigation
            links={links}
            logout={e => this.logout(e)}
            userType={userType}
            color='blue'
            location={location}
            dataArray={dataArray}
            {...rest}
          />}
          {this.getRoute() ? (
            <div className={classes.content}>
              <div className={classes.container}>{children}</div>
            </div>
          ) : (
            <div className={classes.map}>{children}</div>
          )}
        </div>
        <Snackbar
          {...notifyProps}
          closeNotification={closeNotify}
          close
        />
        <ModalWrapper
          open={display}
          handleClose={() => {
            clearModal();
            onCloseModal && onCloseModal();
          }}
          width={width}
          height={height}
        >
          {modalContent}
        </ModalWrapper>
        <ModalStack />
        {showSpinner && <Spinner />}
      </div>
    );
  }
}

DashboardLayout.propTypes = {
  socket: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  clearNotify: PropTypes.func.isRequired,
  clearModal: PropTypes.func.isRequired,
  children: PropTypes.node,
  location: PropTypes.object,
  modal: PropTypes.object,
  notifyProps: PropTypes.object,
  token: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  browserId: PropTypes.string,
  userType: PropTypes.string,
  customBackLocation: PropTypes.string,
  stack: PropTypes.array,
  clearBackLocation: PropTypes.func,
  showSpinner: PropTypes.bool,
  dataArray: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  dataArray: state.dataArray,
  browserId: state.user.browserId,
  token: state.user.token,
  userType: state.user.type,
  username: state.user.username,
  modal: state.modal,
  notifyProps: state.notify,
  showSpinner: state.spinner,
  customBackLocation: state.backLocation,
  stack: state.modalStack
});

const bindAction = dispatch => ({
  clearNotify: () => dispatch(clearNotify()),
  clearBackLocation: () => dispatch(clearBackLocation()),
  clearModal: () => dispatch(clearModal())
});

export default socketConnect(connect(mapStateToProps, bindAction)(withStyles(appStyle)(DashboardLayout)));
