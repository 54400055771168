import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { socketConnect } from 'socket.io-react';
import { connect } from 'react-redux';
import Days from 'components/EditPlanDay';
import { clearModal, setModal } from 'store/modal';
import { SidesModal } from './Details/Modals';
import { Alert } from 'react-bootstrap';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
class EditPlan extends Component {

	constructor(props) { 
    	super(props);
    	const { params } = props; 
  		const {order_id, user_id} = params;
  		this.initialState = { 
	     	order_id: order_id,
    		user_id: user_id,
    		mealplan: false,
        altmeals: false, 
    		edit: false,
        firstName: null,
        lastName: null, 
        all_notes: {},
        pauses: false,
        usersides: [],
        sidesList: [],
        showDialog:false,
        editdaylist:{},
        products:false,
        userData:false
	    };
    	this.state = this.initialState;
    	// this.getOrder(order_id);
      // this.getAlternativeMeals(order_id);
      // this.getProfile(user_id);
      // this.getNotes(user_id, order_id);
      // this.getPauseInfo(order_id);
      // this.getDietProducts(order_id);
      // this.getUserSides(user_id, order_id);
      // this.getSidesList(order_id);
	}
  componentDidMount() {
    const { params } = this.props;
    const {order_id, user_id} = params;
    if(order_id!=undefined){
      this.getOrder(order_id);
      this.getAlternativeMeals(order_id);
      this.getProfile(user_id);
      this.getNotes(user_id, order_id);
      this.getPauseInfo(order_id);
      // this.getDietProducts(order_id);
      this.getUserSides(user_id, order_id);
      this.getSidesList(order_id);
    }
  }
  	componentWillMount() {
  		this.props.socket.on('order', this.listener);
      this.props.socket.on('profile', this.listener);
      this.props.socket.on('default_dishes', this.listener);
      this.props.socket.on('menu_builder', this.listener);
  		this.props.socket.on('settings', this.listener);
  	}

  	componentWillUnmount() {
    	this.props.socket.removeListener('order', this.listener);
      this.props.socket.removeListener('profile', this.listener);
      this.props.socket.removeListener('default_dishes', this.listener);
      this.props.socket.removeListener('menu_builder', this.listener);
    	this.props.socket.removeListener('settings', this.listener);
      this.setState({showDialog:false });
  	}

  	getOrder(orderId) {
	    this.props.socket.emit('order', { type: 'getAllPlan', data: { orderId: orderId } });
  	}

    getPauseInfo(orderId) {
      this.props.socket.emit('order', { type: 'getPause', data: { orderId: orderId } });
    };

    getAlternativeMeals(orderId) {
      this.props.socket.emit('order', { type: 'getAlternativeMeals', data: { orderId: orderId, withsides: true } });
    }

    getProfile(userId) {
      this.props.socket.emit('profile', { type: 'get', data: { userId: userId } });
    }

    getNotes(user_id, order_id) {
      this.props.socket.emit('default_dishes', { type: 'getNotes', data: { order_id: order_id, user_id: user_id } });
    }

    getDietProducts(order_id, user_id) { 
      this.props.socket.emit('order', { type: 'getDietProducts', data: { orderId: order_id, userId: user_id} });
    }

    getUserSides(user_id, order_id) {
      var data = {userId: user_id, orderId: order_id}
      this.props.socket.emit('order', { type: 'getUserSides', data: data });
    }

    
    getSidesList = (order_id) => this.props.socket.emit('menu_builder', { type: 'getSidesList', data: { orderId: order_id } });
    
    delUserSides = (entry_id) => this.props.socket.emit('order', { type: 'delUserSides', data: { entryId: entry_id } });

    editSides = (prod_id, date) => {
      
      const {setModal, dataArray} = this.props;
      const {usersides, sidesList, products} = this.state;
      const { productsFull } = dataArray;
      const props = {
        width: '640px',
        height: '56vh',
        display: true
      };

      var sides = [];

      if (typeof(usersides[date]) != 'undefined') {
        sides = usersides[date];  
      }

      props.content = <SidesModal
         //products={products}
         usersides={usersides}
         date={date}
         sidesList={sidesList}
         productId={prod_id}
         products={productsFull}
         delSides={this.delUserSides}
         socket={this.props.socket}
         save={ (productId, sides, date, thetype) => {
          const {params} = this.props;
          const {user_id, order_id} = params;
          
          if (thetype == 2) {
            thetype = 'addons';
          } else {
            thetype = 'alternative';
          } 
          var data = {productId: productId, sides: sides, user_id: user_id, order_id: order_id, date: date, 'typeSides': thetype}
          console.log(data);
          this.props.socket.emit('order', { type: 'setUserSides', data: data });
         }}
      />;
      props.content && setModal(props);
    }

  	listener = ({ type, data }) => {
        const {user_id, order_id} = data;
        const {clearModal} = this.props;
    	//if (this.CustomersRef) {
      		switch (type) {
        		case 'getAllPlanOk':
        			this.setState({
        				mealplan: data
        			});
        		break;
            case 'getEditRequest':
              // console.log(type);
              // console.log('this.props.params');
              // console.log(this.props.params);
              console.log('data');
              console.log(data);
              if(this.props.params.order_id==data.orderId && this.props.params.user_id==data.userId){
               //alert('Note : Customer editing on meal plan User Id : '+data.userId+' and Order ID:'+data.orderId);
               this.showAlertDialog(data);
              }
            break;
            case 'getSidesListOk':
              this.setState({
                sidesList: data
              });
            break;

            case 'delUserSidesOk':
               this.getUserSides(user_id, order_id);
               clearModal();
            break;

            case 'setUserSidesOk':
              this.getUserSides(user_id, order_id);
              clearModal();
            break;

            case 'getUserSidesOk':
              this.setState({
                usersides: data
              });
              console.log(data);
            break;

            case 'getDietProductsOk':
              this.setState({
                products: data,
              });
            break;

            case 'getPauseOk':
                if (typeof(data.end_date) != 'undefined') {
                  this.setState({
                      pauses: true,
                      pause_manager: data.manager_id,
                      pause_start: data.date_set,
                      pause_end: data.end_date
                  });
                } else {
                  this.setState({
                      pauses: false,
                      pause_manager: null,
                      pause_start: null,
                      pause_end: null
                  });
                }
            break;

            case 'getNotesOk':
              console.info(typeof(data));
                this.setState({
                  all_notes:data
                });
            break;

            case 'getAlternativeMealsOk':
                  this.setState({
                    altmeals: data
                  });
            break;

            case 'getOk':
              const { userData } = data;
              if(userData!=undefined){
                this.setState({
                  userData: userData,
                  firstName: userData.firstName,
                  lastName: userData.lastName,
                });
              }
              
            break;

        		case 'getErr':
        			console.log('websocket error:'+data.message);
        		break;
      		}
    	//}
  	};
  showAlertDialog = (data) =>{
    const { setModal } = this.props;
    const props = {
      width: '640px',
      height: '56vh',
      display: true
    };
    props.content =this.displayAlertData(data);
    setModal(props);
  }
  displayAlertData = (data) =>{
      return <div className="edit-modal-inner edit-sides-modal">
        <p>Note : Customer editing on meal plan User Id : {data.userId}  and Order ID: {data.orderId}</p>
      </div>;
  }
  setUnsavedVal=(action,index)=>{
    var {editdaylist}=this.state;
    if(action=='pop'){
      delete editdaylist[index]  //delete a key
    }else{
      editdaylist[index]='test';
    }
    var isshow=(Object.keys(editdaylist).length>0)?true:false;
    this.setState({ editdaylist: editdaylist,showDialog:isshow });
  }
	render() {
    const {products, sidesList} = this.state;
		const {params, dataArray} = this.props;
		const {planList, dietList, typeList, ingredientList, dishLabels, productsFull} = dataArray;
		const {order_id, user_id} = params; 
		const {mealplan, altmeals, userData, firstName, lastName, all_notes, pauses, pause_start, pause_end, usersides,showDialog} = this.state;

    if (mealplan && productsFull && userData) {
				const days = [];
        let diet_id='';
				for (var k in mealplan) {

          if(mealplan[k][0]!=undefined && mealplan[k][0].diet_id!=diet_id){
            (diet_id!='' && days.push(<div className='diet-change'>Plan Changed from <b className='diet-name-edit'>{dietList[diet_id]}</b> to <b className='diet-name-edit'>{dietList[mealplan[k][0].diet_id]}</b> on {k && moment.unix(k).format('Do MMM YYYY')}</div>));
            diet_id=mealplan[k][0].diet_id;
          }

          days.push(<div><Days 
                            usersides={usersides} 
                            editSides={this.editSides} 
                            timestamp={k} 
                            pause_start={pause_start} 
                            pause_end={pause_end} 
                            updateNotes={this.updateNotes} 
                            mealplan={mealplan} 
                            all_notes={all_notes} 
                            ingredientList={ingredientList} 
                            products={productsFull} 
                            userData={userData} 
                            typeList={typeList} 
                            altmeals={altmeals} 
                            dishLabels={dishLabels} 
                            user_id={user_id} 
                            order_id={order_id} 
                            socket={this.props.socket} 
                            setUnsavedVal={this.setUnsavedVal} 
                            diet_id={diet_id} 
                            /></div>);
				}
				return(
          <div>
          {showDialog&&<Alert bsStyle="unsaved" ><ErrorOutlineIcon style={{ verticalAlign: "middle" }} /><strong> Please click on "Finish" button before moving to the next day. Highlighted items are the selected ones.</strong></Alert>}
					<div className="mealrow-area">
						<h1 className="mealrow-h5">Edit Mealplan of {firstName} {lastName}</h1>
						{days}
					</div>
          </div>
				);
		} else {
			return(<h1 className="mealrow-h5">No Active Mealplans</h1>)
		}
	}
}
 
EditPlan.propTypes = {
  socket: PropTypes.object.isRequired,
  params: PropTypes.object.isRequired,
  dataArray: PropTypes.object.isRequired,
  setModal: PropTypes.func.isRequired,
  clearModal: PropTypes.func.isRequired,
};

const props = state => ({
  dataArray: state.dataArray
});

const actions = dispatch => ({
  setNotify: obj => dispatch(setNotify(obj)),
  clearNotify: () => dispatch(clearNotify()),
  setModal: object => dispatch(setModal(object)),
  clearModal: () => dispatch(clearModal()),
});

export default socketConnect(connect(props, actions)(EditPlan));