import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { socketConnect } from 'socket.io-react';
import { connect } from 'react-redux';
import { setModal, clearModal } from 'store/modal';
import { setNotify, clearNotify } from 'store/notify';
import { getArrays } from 'utils';
import moment from 'moment/moment';
import CustomCard from 'components/Cards/CustomCard';
import Filters from './Filters';
import {
  Table as MUITable,
  TableBody,
  TableCell,
  TableRow,
  TableHead
} from 'material-ui';

class MarketList extends Component {
  constructor(props) {
    super(props);
    const limit = 10000;
    const page = 1;
    const today = moment().clone().add(2, 'd').utc().startOf('day');
    const filters = {
      periodFrom: today.unix(),
      periodTo: today.clone().add(7, 'd').unix()
    };
    this.state = {
      xyz: false,
      list: false,
      usersidesList: false,
      showCookedModal: false,
      limit,
      page,
      maxPages: 1,
      filters,
      splitter: {},
      errors: {},
      activeIng: [],
      activeIngTypes: [],
      IngTypes: [],
      ingrTypes: [],
     
    };
    this.getList( filters);
  }

  getList(filters) {
    this.props.socket.emit('kitchen', {
      type: 'getMarketList',
      data: {
        filters
      }
    });
  }

  listener = action => {
    const { filters } = this.state;
    if (this.MarketListRef) {
      switch (action.type) {
        case 'getMarketListOk':
          this.setState(action.data);
          break;
        default:
          if (action.type.includes('Err')) {
            const { errors } = action.data;
            this.setState({ errors });
          }
          break;
      }
    }
  };

  goTo = page => {
    const { limit, filters } = this.state;
    this.getList((page - 1) * limit, limit, filters);
    setTimeout(() => this.setState({ page }), 5);
    this.MarketListRef && this.MarketListRef.parentElement.parentElement.parentElement.parentElement.parentElement.parentElement.scrollIntoView();
  };

  componentWillMount() {
    this.props.socket.on('kitchen', this.listener);
  }

  componentWillUnmount() {
    this.props.socket.removeListener('kitchen', this.listener);
  }

  componentDidMount() {
    if (this.MarketListRef) {
      setTimeout(() => this.MarketListRef && this.setState({ xyz: true }), 100);
      const { socket, dataArray } = this.props;
      const types = ['ingredientTypes','ingredientList'].filter(el => !(el in dataArray));
      types.length && getArrays(socket, types);
    }
  }
  render() {
    const { list,  errors,splitter,ingrTypes,activeIng,activeIngTypes,IngTypes } = this.state;
    const { ingredientTypes,ingredientList } = this.props.dataArray;
    const render = list && ingredientTypes;
    const dates = Object.keys(list || {});
    let renderdate =dates&&dates.map((date, idx) =>{
      return <TableCell>{moment.unix(date).format('DD MMM')}<br/>{moment.unix(date).format('ddd')}</TableCell>
    });
    const List =()=>{
      let rows =[];
      activeIngTypes&&activeIngTypes.map((typeId,idx1)=>{
        rows.push(<TableRow className='bold group stickyheader'><TableCell colSpan={dates.length+2}><strong>{ingredientTypes[typeId]}</strong></TableCell></TableRow>);
        activeIng&&activeIng.map((ingId,idx1)=>{
          if(IngTypes[ingId]==typeId){
          let ingrName=ingredientList[ingId];
          let total=0;
          let inunit_name='';
          let tabltd=dates&&dates.map((date, idx) =>{
              let {qty,unit_name,type_id} = (list[date]!=undefined &&list[date][typeId]!=undefined && list[date][typeId][ingId]!=undefined)?list[date][typeId][ingId]:{};
                // console.log(unit_name);
                switch(unit_name){
                  case 'kg':
                  case 'piece(s)':
                  case 'liter(s)':
                    qty=(qty>0)?(qty/100):0;
                    total=total+qty;
                    qty=qty.toFixed(1);
                  break;
                  case 'g':
                  case 'ml':
                    qty=(qty>0)?(qty/100000):0;
                    total=total+qty;
                    qty=qty.toFixed(1);
                    unit_name=(unit_name=='g')?'kg':'liter(s)';
                  break;
                  default:
                    qty='-';
                    unit_name='';
                  break;
                }
                inunit_name=unit_name;
                return <TableCell>{qty} {unit_name}</TableCell>;
            });
            rows.push(<TableRow><TableCell>{ingrName}</TableCell>{tabltd}<TableCell>{total.toFixed(1)} {inunit_name}</TableCell></TableRow>);
          }
      });
    });
      return rows;

    };
    const content = render ? <List  /> : null;
    return (
      <Fragment>
        <div className='filtesHolder finances'>
          <CustomCard marginOnePx>
            <Filters
              errors={errors}
              applyFilters={(filters) => {
                this.getList( filters);
                setTimeout(() => this.setState({ filters }), 50);
              }}
              setSplitter={splitter => this.setState({ splitter })}
              splitter={splitter}
              ingrTypes={ingrTypes}
            />
          </CustomCard>
        </div>
        <CustomCard marginOnePx>
          <div ref={el => (this.MarketListRef = el)} className={'xyz-fin'}>
          <MUITable className='marketList'>
            <TableHead className='primaryTableHeader roundedHeader mini stickyheader'>
            <TableRow>
              <TableCell>Ingredients</TableCell>
              {renderdate}
              <TableCell>Total</TableCell>
            </TableRow>
            </TableHead>
              <TableBody>
              {content}
              </TableBody>
            
            </MUITable>
          </div>
        </CustomCard>
      </Fragment>
    );
  }
}

MarketList.propTypes = {
  socket: PropTypes.object.isRequired,
  setModal: PropTypes.func.isRequired,
  clearModal: PropTypes.func.isRequired,
  dataArray: PropTypes.object.isRequired,
  setNotify: PropTypes.func.isRequired,
  clearNotify: PropTypes.func.isRequired
};

const props = state => ({
  dataArray: state.dataArray,
  userType: state.user.type
});

const actions = dispatch => ({
  setModal: object => dispatch(setModal(object)),
  clearModal: () => dispatch(clearModal()),
  clearNotify: () => dispatch(clearNotify()),
  setNotify: props => dispatch(setNotify(props))
});

export default socketConnect(connect(props, actions)(MarketList));
