import { socketConnect } from 'socket.io-react';
import { Grid, InputLabel, Checkbox } from 'material-ui';
import { browserHistory } from 'react-router';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Check } from '@material-ui/icons/index';
import Select from 'components/Select';
import SelectInput from 'components/SelectInput';
import CustomInput from 'components/CustomInput/CustomInput';
import RegularCard from 'components/Cards/RegularCard';
import Button from 'components/CustomButtons/Button';
import ItemGrid from 'components/Grid/ItemGrid';
import ModalWrapper from 'components/Modal';
import { getArrays } from 'utils';
import MaskInput from 'components/CustomInput/MaskInput';
import Calendar from 'components/Calendar';
import { Select2 } from "select2-react-component";
import { AutoComplete } from "select2-react-component";
import { spin, stop } from 'store/spinner';
import { parsePhoneNumber } from 'react-phone-number-input';
import PhoneNumberInput from 'components/CustomInput/PhoneInput';

class Form extends Component {
  constructor(props) {
    super(props);
    this.types = ['admin', 'delivery', 'kitchen', 'logistic', 'customer', 'packager','manager','accounts','nutritionist','guest_nutritionist','no_access'];
    const { params, location } = this.props;
    const { type, id } = params;
    const path = location.pathname.split('/').filter(el => el);
    path.splice(type ? 3 : 2);
    const backPath = `/${path.join('/')}`;
    this.state = {
      errors: {},
      userData: {
        account_status: 'not_verify',
        language: 'en-US',
        homeAddress: {
          default: true
        }
      },
      type: this.types.includes(type) ? type : 'customer',
      backPath
    };
    this.getUserData(id);
  }

  socketProfile = action => {
    console.log(action);
    this.props.stop();
    if (this.FormRef) {
      switch (action.type) {
        case 'getOk':
          this.setState({ ...action.data });
          break;
        case 'setAdminOk':
          this.props.stop();
          alert(action.data.message);
          browserHistory.push(this.state.backPath);
          break;
        case 'setAdminErr':
        case 'setAdminErr1':
        case 'setAdminErr2':
        case 'setAdminErr3':
          this.props.stop();
          let { errors } = action.data;
          const addressFields = this.props.dataArray['addressFields'] || {};
          const addresses = ['workAddress', 'homeAddress', 'publicAddress'];
          if (errors) {
            addresses.forEach(address => {
              if (address in errors && errors[address].length) {
                if (!(errors[address][0] instanceof Object)) {
                  const fieldsErrors = Object.keys(addressFields).reduce((acc, cur) => ({ ...acc, [cur]: 'This field is required' }), {});
                  errors = { ...errors, [address]: fieldsErrors };
                } else {
                  errors = { ...errors, [address]: errors[address][0] };
                }
              }
            });
          } else {
            errors = {};
          }
          this.setState({ errors });
          break;
      }
    }
  };

  componentWillMount() {
    this.props.socket.on('profile', this.socketProfile);
  }

  componentWillUnmount() {
    this.props.socket.removeListener('profile', this.socketProfile);
  }

  componentWillReceiveProps(nextProps, nextContext) {
    const type = nextProps.params.type;
    if (this.FormRef && type && this.types.includes(type) && type !== this.state.type) {
      this.setState({ type });
    }
  }

  getUserData(userId) {
    userId && this.props.socket.emit('profile', {
      type: 'get',
      data: {
        userId
      }
    });
  }

  submit(e) {
    e.preventDefault();
    this.props.spin();
    const { userData, type } = this.state;
    if(userData['whatsUpnumber']!='' && userData['whatsUpnumber']!=null){
      let phcode = parsePhoneNumber(userData['whatsUpnumber']);
      userData['WPC_CODE']= (phcode)?phcode.countryCallingCode:null;
    }
    if(userData['phone']!='' && userData['phone']!=null){
      let phcode = parsePhoneNumber(userData['phone']);
      userData['PC_CODE']= (phcode)?phcode.countryCallingCode:null;
    }
    this.props.socket.emit('profile', { type: 'setAdmin', data: { userData: { ...userData, type } } });
  }

  renderInput = (name, value, info, change, hasError, fieldName) => {
    let input = '';
    let onChange = e => change(name, e.target.value);
    const { type, label, props } = info;
    const { areasListFull: areas } = this.props.dataArray;
    switch (type) {
      case 'text':
        const max = props.max || Infinity;
        onChange = e => {
          const { name, value } = e.target;
          change(name, value.length > max ? value.substr(0, max) : value);
        };
        input = (<CustomInput
          labelText={label}
          formControlProps={{
            fullWidth: true
          }}
          error={hasError}
          inputProps={{
            value: value || '',
            type,
            name,
            onChange
          }}
        />);
        break;
      case 'select':

        if (name == 'area') {
          var curEmirate = +(this.state.userData[fieldName] || {})['emirate'] || 0;
          var data_em = [];
          var test = (areas || []).reduce(
            (acc, { id, name, emirate_id: eid }) => {
              if (curEmirate === +eid) { 
                data_em.push({label: name, value: id}); 
              } 
          });


          input = (
            <Fragment>
            <Select2 data={data_em} placeholder={label} value={null} update={ val => {
           change(name, val);
          }}></Select2>
          {hasError && <p style={{color: "#f44336",fontSize: "12px",marginTop: "-20px"}}>Please select area</p>}
            </Fragment>
          );
        } else {
          var curEmirate = +(this.state.userData[fieldName] || {})['emirate'] || 0;
          var list = props.items;
          input = (<SelectInput
            labelText={label}
            items={list}
            value={('' + value) || ''}
            name={name}
            error={hasError}
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              onChange,
              disabled: name === 'area' && !curEmirate,
              title: name === 'area' && !curEmirate ? 'Select emirate first' : ''
            }}
          />);
        }
      break;
    }
    return input;
  };

  unsetError(errors = {}, names = []) {
    if (names.length === 1) {
      delete errors[names[0]];
    } else if (names.length > 1) {
      errors = { ...errors, [names[0]]: this.unsetError(errors[names[0]], names.splice(1)) };
    }
    return errors;
  }

  renderAddressFields = (data, fieldName, changeUser, errors) => {
    const addressFields = this.props.dataArray['addressFields'] || {};
    const onchange = (name, value) => {
      const result = { ...data, [name]: value };
      changeUser(fieldName, result, [fieldName, name]);
    };
    return Object.keys(addressFields).map((el, idx) => {
      const value = (data && data[el]) || '';
      const input = this.renderInput(el, value, addressFields[el], onchange, !!(errors && errors[el]), fieldName);
      return (
        <ItemGrid xs={12} sm={12} md={12} key={idx}>
          {input}
        </ItemGrid>
      );
    });
  }; 

  componentDidMount() {
    const { dataArray, socket } = this.props;
    const types = ['language', 'gender', 'accStatuses', 'addressFields', 'areasListFull','countryList','deliveryPartner'].filter(el => !(el in dataArray));
    getArrays(socket, types);
  }

  render() {
    const { userData, errors, backPath } = this.state;
    const { dataArray, params } = this.props;
    const genders = dataArray.gender || {};
    const accStatuses = dataArray['accStatuses'] || {};
    const languages = dataArray.language || {};
    const countryList = dataArray.countryList || {}; 
    const deliveryPartner = dataArray.deliveryPartner || {}; 
    const medicalConditions = dataArray.medicalConditions || {}; 
    const { account_status, birthday, email, firstName, gender, height, language, lastName, medicalCondition, phone, weight,customer_type,nationality,delivery_partner_id,deliveryInstructions, whatsUpnumber} = userData;
    var {type} = userData;
    
    if ( ((backPath == '/admin/customers') || (backPath == '/admin/users/customer')) && (typeof(type) == 'undefined') ) {
      type = 'customer';
    }

    const changeUser = (name, value, errorsToUnset) => this.FormRef && this.setState({
      userData: { ...userData, [name]: value },
      errors: errorsToUnset ? this.unsetError(errors, errorsToUnset) : this.unsetError(errors, [name])
    });
    const workAddress = this.renderAddressFields(userData['workAddress'], 'workAddress', changeUser, errors['workAddress']);
    const homeAddress = this.renderAddressFields(userData['homeAddress'], 'homeAddress', changeUser, errors['homeAddress']);
    const publicAddress = this.renderAddressFields(userData['publicAddress'], 'publicAddress', changeUser, errors['publicAddress']);
    const onClose = () => browserHistory.push(backPath);
    const setDefAddress = address => {
      const { workAddress, homeAddress } = userData;
      const addressesData = { workAddress: { ...workAddress, default: address === 'workAddress' }, homeAddress: { ...homeAddress, default: address === 'homeAddress' } };
      this.setState({ userData: { ...userData, ...addressesData } });
    };
    const customer_types = {'normal':'Normal','vvip':'VVIP','care':'Extra Careful'};
    const pubIsDef = userData['publicAddress'] ? userData['publicAddress'].default : false;
    return (
      <ModalWrapper open handleClose={onClose} ref={el => (this.FormRef = el)}>
        <div className='scroll-wrapper'>
          <Grid container>
            <ItemGrid xs={12} sm={12} md={6}>
              <RegularCard
                cardTitle='System info'
                cardSubtitle=''
                content={
                  <div>
                    <Grid container>
                      <ItemGrid xs={12} sm={12} md={12}>
                        <CustomInput
                          labelText='Email'
                          formControlProps={{
                            fullWidth: true
                          }}
                          error={'email' in errors}
                          inputProps={{
                            value: email || '',
                            onChange: e => changeUser('email', e.target.value)
                          }}
                        />
                      </ItemGrid>
                      <ItemGrid xs={12} sm={12} md={6}>
                        <SelectInput
                          labelText='Account Status'
                          items={accStatuses}
                          value={account_status || ''}
                          name='account_status'
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={{
                            onChange: e => changeUser('account_status', e.target.value)
                          }}
                        />
                      </ItemGrid>
                      <ItemGrid xs={12} sm={12} md={6}>
                        <SelectInput
                          labelText='Language'
                          items={languages}
                          value={language || ''}
                          name='language'
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={{
                            onChange: e => changeUser('language', e.target.value)
                          }}
                        />
                      </ItemGrid>
                    </Grid>
                  </div>
                }
              />
             
            </ItemGrid>
            <ItemGrid xs={12} sm={12} md={6}>
              <RegularCard
                cardTitle='Profile info'
                cardSubtitle='Complete profile'
                content={
                  <div>
                    <Grid container>
                      <ItemGrid xs={12} sm={12} md={6}>
                        <CustomInput
                          labelText='First Name'
                          formControlProps={{
                            fullWidth: true
                          }}
                          error={'firstName' in errors}
                          inputProps={{
                            value: firstName || '',
                            onChange: e => changeUser('firstName', e.target.value)
                          }}
                        />
                      </ItemGrid>
                      <ItemGrid xs={12} sm={12} md={6}>
                        <CustomInput
                          labelText='Last Name'
                          formControlProps={{
                            fullWidth: true
                          }}
                          error={'lastName' in errors}
                          inputProps={{
                            value: lastName || '',
                            onChange: e => changeUser('lastName', e.target.value)
                          }}
                        />
                      </ItemGrid>
                      <ItemGrid xs={12} sm={12} md={6}>
                        <PhoneNumberInput
                                labelText='Calling Number'
                                formControlProps={{
                                  fullWidth: true
                                }}
                                error={'phone' in errors}
                                phoneProps={{ 
                                value:phone,
                                defaultCountry:"AE",
                                international:true,
                                countryCallingCodeEditable:false,
                                onChange:(value) => changeUser('phone', value)
                          }}
                          inputProps={{
                            
                          }}
                                />
                        {/* <MaskInput
                          labelText='Calling Number'
                          formControlProps={{
                            fullWidth: true
                          }}
                          error={'phone' in errors}
                          inputProps={{}}
                          maskProps={{
                            value: phone || '',
                            onChange: e => changeUser('phone', e.target.value),
                            mask: '+\\9\\7\\1(99)999 99 99 9',
                            permanents: [0, 1, 2, 3, 4, 7, 11, 14, 17],
                            alwaysShowMask: false,
                            maskChar: ' '
                          }}
                        /> */}
                      </ItemGrid>
                      <ItemGrid xs={12} sm={12} md={6}>
                        <PhoneNumberInput
                            labelText='WhatsApp Number'
                            formControlProps={{
                              fullWidth: true
                            }}
                            error={'whatsUpnumber' in errors}
                            phoneProps={{ 
                                value:whatsUpnumber,
                                defaultCountry:"AE",
                                international:true,
                                countryCallingCodeEditable:false,
                                onChange:(value) => changeUser('whatsUpnumber', value)
                            }}
                            />
                        {/* <MaskInput
                          labelText='WhatsApp Number'
                          formControlProps={{
                            fullWidth: true
                          }}
                          error={'whatsUpnumber' in errors}
                          inputProps={{}}
                          maskProps={{
                            value: whatsUpnumber || '',
                            onChange: e => changeUser('whatsUpnumber', e.target.value),
                            mask: '+\\9\\7\\1(99)999 99 99 99',
                            permanents: [0, 1, 2, 3, 4, 7, 11, 14, 17],
                            alwaysShowMask: false,
                            maskChar: ' '
                          }}
                        /> */}
                    </ItemGrid>
                      <ItemGrid xs={12} sm={12} md={3}>
                        <CustomInput
                          labelText='Weight (kg)'
                          formControlProps={{
                            fullWidth: true
                          }}
                          error={'weight' in errors}
                          inputProps={{
                            value: (weight / 1000) || '',
                            type: 'number',
                            onChange: e => changeUser('weight', (e.target.value < 0 ? 0 : (e.target.value > 500 ? 500 : e.target.value)) * 1000)
                          }}
                        />
                      </ItemGrid>
                      <ItemGrid xs={12} sm={12} md={3}>
                        <CustomInput
                          labelText='Height (cm)'
                          formControlProps={{
                            fullWidth: true
                          }}
                          error={'height' in errors}
                          inputProps={{
                            value: height || '',
                            type: 'number',
                            onChange: e => changeUser('height', e.target.value < 0 ? 0 : (e.target.value > 300 ? 300 : e.target.value))
                          }}
                        />
                      </ItemGrid>
                      <ItemGrid xs={12} sm={12} md={6}>
                        <Calendar
                          title={'Date of Birth'}
                          date={birthday}
                          view={'decade'}
                          name={'birthday'}
                          minDate={moment().utc().startOf('day').subtract(100, 'y').unix()}
                          maxDate={moment().utc().startOf('day').subtract(0, 'y').unix()}
                          onChange={(value, name) => changeUser(name, value)}
                          error={'birthday' in errors}
                          controlled
                        />
                      </ItemGrid>
                      <ItemGrid xs={12} sm={12} md={6}>
                        <SelectInput
                          labelText='Gender'
                          items={genders}
                          value={gender || ''}
                          name='gender'
                          error={'gender' in errors}
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={{
                            onChange: e => changeUser('gender', e.target.value)
                          }}
                        />
                      </ItemGrid>
                      <ItemGrid xs={12} sm={12} md={12}>
                        <InputLabel style={{ color: '#AAAAAA' }}>
                          Medical condition
                        </InputLabel>
                        {/* <CustomInput
                          labelText='Please type here...'
                          formControlProps={{
                            fullWidth: true
                          }}
                          error={'medicalCondition' in errors}
                          inputProps={{
                            multiline: true,
                            rows: 3,
                            value: medicalCondition || '',
                            onChange: e => changeUser('medicalCondition', e.target.value)
                          }}
                        /> */}
                          <Select
                        placeholder='Medical Condition'
                        name='medicalCondition'
                        value={medicalCondition || []}
                        hasError={medicalCondition in errors}
                        list={medicalConditions || {}}
                        onChange={changeUser}
                        multiple
                      />
                      </ItemGrid>
                      <ItemGrid xs={12} sm={12} md={6}>
                       
                        <SelectInput
                        labelText='Customer Type'
                        items={customer_types}
                        value={customer_type || ''}
                        name='customer_type'
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          onChange: e => changeUser('customer_type', e.target.value)
                        }}
                      />
                      </ItemGrid>
                      <ItemGrid xs={12} sm={12} md={6}>
                      
                      <Select
                        placeholder='Nationality'
                        name='nationality'
                        value={nationality || ''}
                        list={countryList || {}}
                        onChange={changeUser}
                      />
                      </ItemGrid>
                    </Grid>
                  </div>
                }
              />
              {type === 'customer' && <RegularCard
                cardTitle='Delivery Partner'
                cardSubtitle=''
                content={
                  <div>
                    <Grid container>
                        <ItemGrid xs={12} sm={12} md={12}>
                          <Select
                            placeholder='Delivery Partner'
                            name='delivery_partner_id'
                            value={delivery_partner_id || ''}
                            list={deliveryPartner || {}}
                            onChange={changeUser}
                          />
                        </ItemGrid>
                    </Grid>
                  </div>}
                  /> }
             {type === 'customer' && <RegularCard
                  cardTitle='Delivery Instructions'
                  content={
                    <div>
                      <Grid container>
                        <ItemGrid xs={12} sm={12} md={12}>
                          <CustomInput
                            labelText='Please type here...'
                            formControlProps={{
                              fullWidth: true
                            }}
                            error={'deliveryInstructions' in errors}
                            inputProps={{
                              multiline: true,
                              rows: 6,
                              value: deliveryInstructions || '',
                              onChange: e => changeUser('deliveryInstructions', e.target.value)
                            }}
                          />
                        </ItemGrid>
                      </Grid>
                    </div>
                  }
                />}
            </ItemGrid>
            <ItemGrid xs={12} sm={12} md={12}>
              <RegularCard
                cardTitle='Main address'
                cardSubtitle=''
                content={
                  <div>
                    <Grid container>
                      {homeAddress}
                      {/* <ItemGrid md={12}>
                        <div className='checkHolder'>
                          <Checkbox
                            checked='false'
                            checkedIcon={<Check className={`${(userData['homeAddress'] || {}).default ? 'checked' : 'unChecked'} `} />}
                            icon={<Check />}
                            onClick={() => setDefAddress('homeAddress')}
                            classes={{
                              checked: 'unChecked'
                            }}
                          />
                          Use as default
                        </div>
                      </ItemGrid> */}
                    </Grid>
                  </div>
                }
              />
               {type === 'customer1' && <RegularCard
                cardTitle='2nd address'
                cardSubtitle=''
                content={
                  <div>
                    <Grid container>
                      {workAddress}
                      {/* <ItemGrid md={12}>
                        <div className='checkHolder'>
                          <Checkbox
                            checked='false'
                            checkedIcon={<Check className={`${(userData['workAddress'] || {}).default ? 'checked' : 'unChecked'} `} />}
                            icon={<Check />}
                            onClick={() => setDefAddress('workAddress')}
                            classes={{
                              checked: 'unChecked'
                            }}
                          />
                          Use as default
                        </div>
                      </ItemGrid> */}
                    </Grid>
                  </div>
                }
              />}
              {type === 'customer1' && <RegularCard
                cardTitle='3rd Address'
                cardSubtitle=''
                content={
                  <div>
                    <Grid container>
                      {publicAddress}
                      <ItemGrid md={12}>
                        <div className='checkHolder'>
                          <Checkbox
                            checked='false'
                            checkedIcon={<Check className={`${pubIsDef ? 'checked' : 'unChecked'} `} />}
                            icon={<Check />}
                            onClick={() => this.setState({ userData: { ...userData, publicAddress: { ...(userData['publicAddress'] || {}), default: !pubIsDef } } })}
                            classes={{
                              checked: 'unChecked'
                            }}
                          />
                          Use as default
                        </div>
                      </ItemGrid>
                    </Grid>
                  </div>
                }
              />}
            </ItemGrid>
          </Grid>
        </div>
        <div className='modal-button-handler right'>
          <Button color='success' onClick={e => this.submit(e)}>{`${params.id ? 'Update' : 'Create'} Profile`}</Button>
        </div>
      </ModalWrapper>
    );
  }
}

Form.propTypes = {
  socket: PropTypes.object.isRequired,
  dataArray: PropTypes.object.isRequired,
  params: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired
  // user: PropTypes.object.isRequired,
  // userType: PropTypes.string.isRequired
};

const mapStateToProps = state => ({
  dataArray: state.dataArray,
  user: state.user,
  userType: state.user.type
});
const actions = dispatch => ({
  spin: () => dispatch(spin()),
  stop: () => dispatch(stop()),
});
export default socketConnect(connect(mapStateToProps, actions)(Form));
