import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router';
import { connect } from 'react-redux';
import { socketConnect } from 'socket.io-react';
import moment from 'moment/moment';
import { getArrays } from 'utils';
import { setModal } from 'store/modal';
import CustomCard from 'components/Cards/CustomCard';
import Text from 'components/Typography/Text';
import Button from 'components/CustomButtons/Button';
import ItemGrid from 'components/Grid/ItemGrid';

class MobileCheckoutList extends Component {
  constructor(props) {
    super(props);
    const { user_id } = props;
    this.state = {
      checkouts: [],
      coupons: []
    };
    this.getOrders(user_id);
  }

  getOrders = (userId) => this.props.socket.emit('mobile_checkout', { type: 'list', data: { userId } });

  listener = ({ status, type, data }) => {
    const { errors, checkouts, message } = data;
    if (status === 'ok' && this.MobileCheckoutListRef) {
      if (type === 'list') {
        this.setState({ checkouts });
      }
    } else {
      if (type === 'removeOk' || type === 'setManualStatusOk') {
        this.props.setModal({
          display: true,
          width: '25%',
          height: '10vh',
          content: <div className='text-center' style={{ padding: 'calc(5vh - 1.5em)' }}>
            <Text color='success' paragraph style={{ fontSize: 'larger' }}>{message}</Text>
          </div>
        });
        this.getOrders(this.props.user_id);
      } else if (type.includes('Err')) {
        this.setState({ errors });
      }
    }
  };

  componentWillMount() {
    this.props.socket.addEventListener('mobile_checkout', this.listener);
  }

  componentWillUnmount() {
    this.props.socket.removeListener('mobile_checkout', this.listener);
  }

  componentWillReceiveProps(nextProps, nextContext) {
    if (this.props.user_id !== nextProps.user_id) {
      this.getOrders(nextProps.user_id);
    }
  }

  componentDidMount() {
    const { socket, dataArray } = this.props;
    const types = ['typeList', 'dietList', 'planList'].filter(type => !(type in dataArray));
    types.length && getArrays(socket, types);
  }

  cancelPlan = (checkoutId) => {
    const { user_id: userId } = this.props;
    this.props.socket.emit('mobile_checkout', { type: 'remove', data: { checkoutId, userId } });
  };

  setStatus = (status, order_id) => {
    console.log(status);
    console.log(order_id);
    this.props.socket.emit('mobile_checkout', { type: 'setManualStatus', data: { orderId: order_id, status: status } });
  }
  downloadPdf(id, order_id = false,$is_arabic=false) {
    window.open(
      'http://localhost/loseweightapp/www/admin/site/getpdf?user_id='+id+'&order_id='+order_id+'&is_arabic='+$is_arabic,
      '_blank' // <- This is what makes it open in a new window.
    );
  }
  downloadVoucherPdf(id, order_id = false,$is_arabic=false) {
    window.open(
      'http://localhost/loseweightapp/www/admin/site/getrefundpdf?user_id='+id+'&order_id='+order_id,
      '_blank' // <- This is what makes it open in a new window.
    );
  }
  changeTab(slug) {
    this.setState({current:slug});
  };
  isJsonString =(str) =>{
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
}
  render() {
    const { checkouts, coupons } = this.state;
    const { dataArray,user_id } = this.props;
    const { typeList, dietList, planList } = dataArray;
    const Orders = () => {
      const getTypes = (typesArr = []) => typeList ? typesArr.map(el => typeList[el].title).join(', ') : 'No data';
      const getDate = date => moment.unix(date).format('DD.MM.YYYY');
      const skipdays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
      if(!Array.isArray(checkouts)){
        console.log(checkouts);
      
      return typeList && dietList && planList ? Object.keys(checkouts).reverse().map((orderId, idx) => {
        if(checkouts[orderId]['action']==undefined){
        const { id, status, payed, payment_date, coupon_id, price, diet_id, mealPlan_id, paymentMethod, mealTypes, start, end, cutlery,is_refund,days_count,exclude_week_day,payment_data } = checkouts[orderId];
        const getExcludeWeek =()=>(exclude_week_day&&exclude_week_day.length>0)?'No '+exclude_week_day.map(el => skipdays[el]).join(' & '):'Full Week';
        // console.info('cutlery');
        // console.info(cutlery);
        const Coupon = () => {
          console.log(coupons);
          if (typeof(coupon_id)!='undefined'&&coupon_id) {
            const { type, value } = (coupons || []).find(({ id }) => +id === +coupon_id);
            const types = { fixed: `AED ${+value / 100}`, percent: `${+value / 100}%` };
            return `Coupon: ${types[type]}`;
          } else {
            return 'Without coupon';
          }
        };
        let payBy = paymentMethod;
        if(paymentMethod=='not_paid'){
            payBy='Not Paid'
        }else if(payment_data){
          payBy = payment_data.name;
        }
        const cancel = e => {
          e.stopPropagation();
          e.preventDefault();
          this.cancelPlan(id);
          return false;
        };
        console.log(mealTypes);
        return <div style={{ width: '100%' }} key={idx}>
          
             <CustomCard noPadding marginOnePx>
              <div className={`regular history-${status} mobile-checkout`}>
            
                <div className='customer-owerview gistory'>
                  <p className={`location status ${status}`}>{`# ${id}`} - {status}</p>
                  <p className='duration'>{`${getDate(start)} - ${getDate(end)}`}</p>
                  <p className='price bold'>{`AED ${(parseInt(price)) / 100}`}</p>
                </div>
                <div className='customer-desc'>
                  <p>
                    <span className='name bold'>{(days_count<=0)?planList[mealPlan_id].title:days_count+' Days - '}{(days_count>0)?getExcludeWeek():''}</span>
                  </p>
                  <p className='pinfo'>
                    <span className='plan bold'>{dietList[diet_id]}</span>: <span className='types'>{mealTypes!=null?getTypes(this.isJsonString(mealTypes)?JSON.parse(mealTypes):mealTypes):'No Data'}</span>
                  </p>
                  {paymentMethod=='not_paid' && <p><p className='status paid'><span style={{'text-transform':'capitalize'}}>{payBy}</span></p></p>}
                  {!payed&&<p className='status paid'>{`Collection at ${getDate(start)}`} ({payBy})</p>}
                </div>
                <div className='actions-part'>
                  {status != 'remove' && <div className='buttons'> 
                  {status != 'completed' && <div className='simple-button bg-warning warning-shadow order-history-btn' onClick={cancel}>Delete</div>}
                    <Link onlyActiveOnIndex={false} to={`/admin/checkout-details/${id}`}>
                      <div className='simple-button bg-danger danger-shadow order-history-btn'>Details</div>
                    </Link>
                    <Link onlyActiveOnIndex={false} to={`/admin/checkout-logs/${id}`}>
                      <div className='simple-button bg-danger danger-shadow order-history-btn'>View Logs</div>
                    </Link>
                      {/* <div className='simple-button bg-danger danger-shadow order-history-btn'onClick={() =>this.downloadPdf(user_id,id)}>Saved PDF</div> */}
                  </div>}
                </div>                
              </div>
            </CustomCard>
            </div>;
            }else{
               return  <div style={{ width: '100%' }} key={idx}><CustomCard noPadding marginOnePx>
               <div className={`regular history-cancelled`}>
                  <div className='customer-owerview gistory'>
                   <p className={`location status cancelled`}>Removed</p>
                   <p className='id bold'>{`id: ${orderId}`}</p>
                   <p className='duration'>{checkouts[orderId]['description']} on {getDate(checkouts[orderId]['created_date'])}</p>
                  </div>
                  <div className='actions-part'>
                   <div className='buttons'> 
                     <Link onlyActiveOnIndex={false} to={`/admin/order-logs/${orderId}`}>
                       <div className='simple-button bg-danger danger-shadow order-history-btn'>View Logs</div>
                     </Link>
                   </div>
                 </div>
               </div>
             </CustomCard></div>;
            }
      }) : [];
      }else{
          return <div style={{ width: '100%' }} key={0}> <CustomCard noPadding marginOnePx>
          <p style={{fontSize:20,textAlign:'center',fontWeight:'bold'}}>The checkout list is empty</p>
          </CustomCard>
       </div>;
      }
    };
    return (
      <div ref={el => (this.MobileCheckoutListRef = el)} style={{ height: 'calc(100vh - 315px)', padding: '10px' }} className={'scrollable-h'} >
        <div className={'history-holder'}>
          {/* <RemoveLogs /> */}
          <Orders />
        </div>
      </div>
    );
  }
}

MobileCheckoutList.propTypes = {
  socket: PropTypes.object.isRequired,
  dataArray: PropTypes.object.isRequired,
  user_id: PropTypes.number.isRequired,
  setModal: PropTypes.func.isRequired
};

const props = state => ({
  dataArray: state.dataArray
});

const actions = dispatch => ({
  setModal: obj => dispatch(setModal(obj))
});

export default socketConnect(connect(props, actions)(MobileCheckoutList));