import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment/moment';
import { Grid } from 'material-ui';
import Button from 'components/CustomButtons/Button';
import { Text } from 'components/Typography';
import CustomInput from 'components/CustomInput/CustomInput';
import SelectInput from 'components/SelectInput';

require('moment-weekday-calc');

class ReasonPayModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      reason: null,
    };
  }

  render() {
    const { reason } = this.state;
    const { save, orderId, dataArray, type , selectedVal} = this.props;
    const {paymentMethods, deliveryTime,slotsList} = dataArray;
    // console.log(paymentMethods);


    var text = 'Set Payment Status';
    var theitems = paymentMethods;
    if (type == 'deliverytime') {
      text = 'Set Delivery Time';
      var slots = {};
      Object.keys(slotsList).map( (id, el) => {
        slots[ slotsList[id].id] = slotsList[id].name;
      });
      theitems = slots;

    }
    return (
      <div>
        <div className='edit-modal-inner' style={{ paddingBottom: '40px' }}>
          <Grid container >
            <Grid item md={12} style={{ textAlign: 'center', padding: '20px 0 10px' }}>
              <Text bold customColor='#000' inline style={{ fontSize: 'large' }}>{text}</Text>
            </Grid>
            <Grid item md={12}>
                  <SelectInput
                    labelText='Status'
                    items={theitems || {}}
                    value={reason || selectedVal }
                    formControlProps={{
                      fullWidth: true 
                    }}
                    inputProps={{
                      onChange: e => this.setState({reason: e.target.value})
                    }}
                  />
            </Grid>
            
          </Grid>
        </div>
        <div className='modal-button-handler right'>
          <Button color='darkBlue' onClick={() => save(orderId, reason)}>Save</Button>
        </div>
      </div>
    );
  }
}


ReasonPayModal.propTypes = {
  save: PropTypes.func.isRequired
};

export default ReasonPayModal;