import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment/moment';
import { Checkbox, Grid } from 'material-ui';
import Button from 'components/CustomButtons/Button';
import Calendar from 'components/DateTime';
import SelectInput from 'components/SelectInput';
import { RadioButtonChecked, RadioButtonUnchecked } from '@material-ui/icons/index';
import Text from 'components/Typography/Text';

require('moment-weekday-calc');

class MoveDeliveryModal extends Component {
  constructor(props) {
    super(props);
    const { excludedDates, includedDays, ifComplimentary, freezeDate, lastBagCount, pricetype } = props;
    var minDate = moment().utc().startOf('day').addWeekdaysFromSet(0, includedDays, excludedDates);
    if (ifComplimentary == 'addmeals') {
      //minDate = moment().utc().startOf('month').addWeekdaysFromSet(0, includedDays, excludedDates);
      minDate = moment(freezeDate);
          console.log(" MIN DATE "+ minDate);
    }
    var deliveryBagCount = lastBagCount;
    if(lastBagCount >= 1){
      deliveryBagCount = Number.parseInt(deliveryBagCount) + 1;
      var obj = {}; 
      obj['deliveryName'] = 'Custom Bag #'+deliveryBagCount;
      this.setState(obj);
    }
    this.state = {
      minDate,
      resumeDate: false,
      reason: 1,
      comreason: 'Missing Item',
      mealaction: 1,
      deliveryName: 'Custom Bag #'+deliveryBagCount,
      pricetype
    };
  }



  render() {
    const { resumeDate, minDate, deliveryName, pricetype } = this.state;
    const { excludedDates, includedDays, save, ifComplimentary, freezeDate } = this.props;
    let maxDate = '';
    maxDate = minDate.clone().add(5, 'months');
    if(ifComplimentary== 'addmeals'){
      maxDate = freezeDate;
    }
 
    const renderDay = (props, curd) => {
      if (!curd.isBetween(minDate, maxDate, null, '[]') || !includedDays.includes(curd.day()) || excludedDates.includes(curd.format('DD/MM/YYYY'))) {
        props.className += ' disabled';
        delete props.onChange;
        delete props.onClick;
      } else if (curd.isSame(resumeDate || minDate)) {
        props.className += ' selected';
      }
      return <td {...props}>{curd.date()}</td>;
    };

    const reasons = {
      1: 'Late Delivery',
      2: 'Missed Delivery',
      3: 'Referral Program',
      4: 'Extra Gift',
      5: 'Promotion',
    }
    const complimentarytype = {
      1: 'Complimentary',
      2: 'Move Date',
    }
    const complimentaryReasons = {
      'Missing Item': 'Missing Item',
      'Referral': 'Referral',
      'Special Offer': 'Special Offer',
      'Food Not Good': 'Food Not Good',
      'Logistics Delay': 'Logistics Delay',
      'Delivered to wrong address': 'Delivered to wrong address',
      'Birthday Offer': 'Birthday Offer',
      'Others': 'Others',
    }
    const mealactions = {
      1: 'Add meals from default diet\'s menu',
      2: 'Add meals from customer\'s day',
    }

    const pricetypes={0:'Old Price',1:'New Price'};

    const changeParams = (name, value) => {
      console.log(name);
      console.log(value);
      var obj = {}; 
      obj[name] = value;
      this.setState(obj);
    };
 
    return (
      <div> 
        <div className='edit-modal-inner' style={{ paddingBottom: '40px' }}>
          <Grid container >
          {ifComplimentary == 'copy' && 

            <div className="complimentarySide">
              <Grid item md={12} style={{ textAlign: 'center', padding: '20px 0' }}>
                <div className='day-editing t2'>Select Complimentary Reason</div>
              </Grid>
              <SelectInput 
                labelText='Delivery time'
                items={reasons || {}}
                value={'' + this.state.reason}
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  onChange: e => changeParams('reason', e.target.value)
                }}
              />
            </div>

          }

           {ifComplimentary == 'addmeals' && 

            <div className="complimentarySide">
              <Grid item md={12} style={{ textAlign: 'center', padding: '20px 0' }}>
                <div className='day-editing t2'>Select add meals action</div>
              </Grid>
              <SelectInput 
                labelText='Add Meals Action'
                items={mealactions || {}}
                value={'' + this.state.mealaction}
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  onChange: e => changeParams('mealaction', e.target.value)
                }}
              />
              <Grid item md={12} style={{ textAlign: 'center', padding: '20px 0' }}>
                <div className='day-editing t2'>Set Delivery Name</div>
              </Grid>
              <span>{deliveryName}</span>
              {/* <CustomInput
                labelText='Delivery Name'
                formControlProps={{
                  fullWidth: false,
                  style: {
                    margin: '0 10px 0 4px',
                    minWidth: '100%'
                  }
                }}
                inputProps={{
                  value: deliveryName,
                  type: 'text',
                  //name: 'email',
                  onChange: e => changeParams('deliveryName', e.target.value)
                }}
              /> */}
            </div>
          

          }
          {(ifComplimentary == 'addmeals' || ifComplimentary == 'charged' || ifComplimentary == 'changeprice') && 
            <div className="complimentarySide"> <Grid item md={12} style={{ textAlign: 'center', padding: '20px 0' }}>
                <div className='day-editing t2'>Select Price {ifComplimentary=='changeprice'?'To Change':''}</div>
              </Grid>
              {pricetypes && Object.keys(pricetypes).map((priceType, idx) => {
                return (
                  <Grid item key={idx}>
                    <Checkbox
                      checked={+pricetype === +priceType}
                      icon={<RadioButtonUnchecked className='unchecked-radio' />}
                      checkedIcon={<RadioButtonChecked className='checked-radio' />}
                      onClick={() => changeParams('pricetype', +priceType)}
                      classes={{ checked: 'unChecked' }}
                    />
                    <Text>{pricetypes[priceType]}</Text>
                  </Grid>
                );
              })
              }
              </div>}
            {ifComplimentary == 'complimentary' &&
                <div className="complimentarySide">
                <Grid item md={12} style={{ textAlign: 'center', padding: '20px 0' }}>
                  <div className='day-editing t2'>Complimentary Type</div>
                </Grid>
                <SelectInput 
                    labelText='Type'
                    items={complimentarytype || {}}
                    value={'' + this.state.reason}
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      onChange: e => changeParams('reason', e.target.value)
                    }}
                  />
                  <small>Based on complimentary type price calculation will affect.</small>
                </div>
            }
             {ifComplimentary == 'complimentary' && 

                <div className="complimentarySide">
                  <Grid item md={12} style={{ textAlign: 'center', padding: '20px 0' }}>
                    <div className='day-editing t2'>Select Complimentary Reason</div>
                  </Grid>
                  <SelectInput 
                    labelText='Delivery time'
                    items={complimentaryReasons || {}}
                    value={'' + this.state.comreason}
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      onChange: e => changeParams('comreason', e.target.value)
                    }}
                  />
                </div>

                }
            {ifComplimentary != 'changeprice' && <div><Grid item md={12} style={{ textAlign: 'center', padding: '20px 0' }}>
              <div className='day-editing t2'>Select resume date</div>
            </Grid>
            <Grid item md={12} style={{ borderTop: '1px solid #bebebe', borderBottom: '1px solid #bebebe', padding: '10px' }}>
              {ifComplimentary== 'addmeals' && 
              <Calendar
                controlled
                value={freezeDate}
                onChange={resumeDate => this.setState({ resumeDate })}
                onViewModeChange={() => false}
                renderDay={renderDay}
                onNavigateBack={(a, t, c) => moment().utc().startOf(t).isBefore(c.utc())}
                onNavigateForward={(a, t, c) => c.clone().add(a, t).startOf(t).isBefore(moment().add(5, 'month'))}
                freezeDate={freezeDate}
              />}
              {ifComplimentary!= 'addmeals' && 
              <Calendar
                controlled
                value={resumeDate || minDate}
                onChange={resumeDate => this.setState({ resumeDate })}
                onViewModeChange={() => false}
                renderDay={renderDay}
                onNavigateBack={(a, t, c) => moment().utc().startOf(t).isBefore(c.utc())}
                onNavigateForward={(a, t, c) => c.clone().add(a, t).startOf(t).isBefore(moment().add(5, 'month'))}
                freezeDate={freezeDate}
              />}
            </Grid></div>}
            
            
          </Grid>
        </div>
        <div className='modal-button-handler right'>
          <Button color='darkBlue' onClick={() => save((resumeDate || minDate).unix(), this.state.reason,this.state.comreason, this.state.mealaction, deliveryName, this.state.pricetype)}>
            Save
          </Button>
        </div>
      </div>
    );
  }
}

MoveDeliveryModal.propTypes = {
  excludedDates: PropTypes.array.isRequired,
  includedDays: PropTypes.array.isRequired,
  save: PropTypes.func.isRequired
};

export default MoveDeliveryModal;
