import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Grid } from 'material-ui';
import ItemGrid from 'components/Grid/ItemGrid';
import Paginator from 'components/Paginator';
import { Text } from 'components/Typography';
import Table from './Table';
import moment from 'moment/moment';

class List extends Component {
  getComponents = (components, productsFull, amount) => components.map((el, idx) => {
    return (
      <Grid key={idx} className='component-row' container>
        <Grid item md={6} className='component-title'><Text color='success' inline>{productsFull[el]['nickname'] || productsFull[el]['title']}</Text></Grid>
        <Grid item md={6} className='component-amount'><Text color='warning' inline bold>{amount}</Text></Grid>
      </Grid> 
    );
  });

  getParents = (parents, productsFull) => Object.keys(parents).map((el, idx) => {
    return (
      <Grid key={idx} className='component-row' container>
        <Grid item md={6} className='component-title'><Text color='success' inline>{productsFull[el]['nickname'] || productsFull[el]['title']}</Text></Grid>
        <Grid item md={6} className='component-amount'><Text color='warning' inline bold>{parents[el]}</Text></Grid>
      </Grid>
    );
  });

  getRows() {
    const { list, dictionaries, date, notes } = this.props;
   
    const { productsFull } = dictionaries;

    return Object.keys(list).reduce((acc, cur) => {
      //console.log(productsFull[cur]);
      const { title, nickname, components } = productsFull[cur] || {};
      const { count, cookedCount, symbol } = list[cur];
      var notes_text = '';
      if (typeof(notes[date]) != 'undefined') {
        if (typeof(notes[date][cur]) != 'undefined') {
          for (var k in notes[date][cur]) {
            if ( typeof(notes[date][cur][k]) == 'string') {
              notes_text = notes_text + ' - ' + notes[date][cur][k] + '\n';
            }
          
          }
        }
      }
      const data = {
        title: <Text customColor='#0068c1' inline bold>{nickname || title}</Text>,
        //symbol: <Text customColor='#0068c1' inline bold>{symbol}</Text>,
        notes: <Text  customColor='#f44336' bold>{notes_text}</Text>,
        amount: <Text color='warning' inline bold>{count}</Text>,
        //cooked: <Text color='success' inline bold>{cookedCount}</Text>,
        //components: components ? this.getComponents(components, productsFull, count) : null,
        //maxAmount: count - cookedCount,
        //cookedCount: +cookedCount,
        date
      };
      return { ...acc, [cur]: data };
    }, {});
  }

  getRowsByMealType(mealType) {
    const { list, dictionaries, date } = this.props;
    const { productsFull } = dictionaries;
    return Object.keys(list).reduce((acc, cur) => {
      const { mealTypes, cooked } = list[cur];
      if (+mealType in mealTypes) {
        const { title, nickname, components } = productsFull[cur];
        const data = {
          title: <Text customColor='#0068c1' inline bold>{nickname || title}</Text>,
          amount: <Text color='warning' inline bold>{mealTypes[mealType]}</Text>,
          cooked: <Text color='success' inline bold>{(cooked || {})[mealType]}</Text>,
          components: components ? this.getComponents(components, productsFull, mealTypes[mealType]) : null,
          maxAmount: +mealTypes[mealType],
          cookedCount: +cooked[mealType],
          date
        };
        return { ...acc, [cur]: data };
      } else {
        return acc;
      }
    }, {});
  }

  getSimplesFromComposites = () => {
    const { dictionaries, list } = this.props;
    const { productsFull } = dictionaries;
    let simples = { ...list };
    Object.keys(list).forEach(el => {
      const { components } = productsFull[el];
      if (components.length) {
        const { count, mealType } = list[el];
        components.forEach(comp => {
          const { count: _count, parents } = simples[comp] || {};
          const oldParentAmount = (parents && parents[el]) || +count;
          simples[comp] = { mealType, count: _count ? +_count + +count : +count, parents: { ...(parents || {}), [el]: oldParentAmount } };
        });
        delete simples[el];
      }
    });
    return simples;
  };

  getRowsByDishGroup(dishGroup) {
    const { dictionaries } = this.props;
    const { productsFull } = dictionaries;
    const list = this.getSimplesFromComposites();
    return Object.keys(list).reduce((acc, cur) => {
      const { title, nickname, group } = productsFull[cur] || {};
      const { parents, count } = list[cur];
      if (!dishGroup || +group === +dishGroup) {
        const data = {
          title: <Text customColor='#0068c1' inline bold>{nickname || title}</Text>,
          amount: <Text color='warning' inline bold>{count}</Text>,
          components: parents ? this.getParents(parents, productsFull) : null
        };
        return { ...acc, [cur]: data };
      } else {
        return acc;
      }
    }, {});
  }
  getRowsByMeal(newdata,mealType_id) {
    // console.log(newdata);
    const { list, dictionaries, date, notes } = this.props;
    const { productsFull,typeList } = dictionaries;
    return Object.keys(newdata).reduce((acc, cur) => {
      //console.log(productsFull[cur]);
      // console.log(cur);
      const { title, nickname, components } = productsFull[cur] || {};
      const { count, cookedCount, symbol,type,side } = newdata[cur];
      // console.log(side);
      var notes_text = '';
      if (typeof(notes[date]) != 'undefined') {
        if (typeof(notes[date][cur]) != 'undefined') {
          if(notes[date][cur][mealType_id] != undefined){
            for (var k in notes[date][cur][mealType_id]) {
              if ( typeof(notes[date][cur][mealType_id][k]) == 'string') {
                notes_text = notes_text + ' - ' + notes[date][cur][mealType_id][k] + '\n';
              }
            }
          }else{
            for (var k in notes[date][cur]) {
                if ( typeof(notes[date][cur][k]) == 'string') {
                  notes_text = notes_text + ' - ' + notes[date][cur][k] + '\n';
                }
            }
          }
      }
    }
      const data = {
        title: <Text customColor='#0068c1' inline bold>{nickname || title}</Text>,
        // type:typeList[type].title,
        //symbol: <Text customColor='#0068c1' inline bold>{symbol}</Text>,
        notes: <Text  customColor='#f44336' bold>{notes_text}</Text>,
        amount: <Text color='warning' inline bold>{count}</Text>,
        //cooked: <Text color='success' inline bold>{cookedCount}</Text>,
        //components: components ? this.getComponents(components, productsFull, count) : null,
        //maxAmount: count - cookedCount,
        //cookedCount: +cookedCount,
        mealType_id:mealType_id,
        date
      };
      return { ...acc, [cur]: data };
    }, {});
  }
  renderContent() {
    const { list,dictionaries, splitter, actions } = this.props;
    const { type, values } = splitter;
    const { dishGroups, typeList } = dictionaries;
    let content = null;
    let cols = {};
    let rows = {};
    const _type = values && values.length ? type : null;
    switch (_type) {
      case 'meal':
        content = values.map((el, key) => {
          cols = {
            title: typeList[el].title,
            amount: 'Serving',
            cooked: 'Cooked',
            components: 'Components',
            actions: 'Actions'
          };
          rows = this.getRowsByMealType(el);
          return Object.keys(rows).length ? <Table {...{ cols, rows, key }} /> : null;
        });
        break;
      case 'dish':
        content = values.map((el, key) => {
          cols = {
            title: dishGroups[el],
            amount: 'Serving',
            components: 'Parent Meal'
          };
          rows = this.getRowsByDishGroup(el);
          return Object.keys(rows).length ? <Table {...{ cols, rows, key }} /> : null;
        });
        break;
      default:
        // cols = {
        //   title: 'Dish',
        //  // symbol: 'Symbol',
        //   notes: 'Notes',
        //   amount: 'Serving',
        //   actions: 'Actions'
        // };
        // content = <Table {...{ cols, rows: this.getRows(), actions }} />;
        var i=0;
        content=Object.keys(typeList).map((el, key) => {

          if(list[el]!=undefined){
            if(i==0){
              cols = {
                title: 'Dish for '+typeList[el].title,
                // type: 'Meal Type',
               // symbol: 'Symbol',
                notes: 'Notes',
                amount: 'Serving',
                actions: 'Actions'
              };
              i=1;
             }else{
              cols = {
                title:'Dish for '+typeList[el].title,
                // type: 'Meal Type',
               // symbol: 'Symbol',
                notes: '',
                amount: '',
                actions: ''
              };
             }
            //  console.log(list[el]);
            rows = this.getRowsByMeal(list[el],el);
            return Object.keys(rows).length ? <Table {...{ cols, rows, key }} /> : null;
          }
         
        });
        break;
    }
    return content;
  }

  render() {
    const { page, maxPages, goTo, date } = this.props;
    return (
      <div>
        <div className='date-date'>{moment.unix(date).format('DD MMM')}</div>
        {this.renderContent()}
        {maxPages > 1 && <Grid container>
          <ItemGrid xs={12} sm={12} md={12}>
            <Paginator goToPage={goTo} maxPage={maxPages} current={page} />
          </ItemGrid>
        </Grid>}
      </div>
    );
  }
}

List.propTypes = {
  dictionaries: PropTypes.object.isRequired,
  maxPages: PropTypes.number.isRequired,
  limit: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  list: PropTypes.object.isRequired,
  goTo: PropTypes.func.isRequired,
  splitter: PropTypes.object,
  actions: PropTypes.object,
  date: PropTypes.string
};

List.defaultProps = {
  limit: 20,
  page: 1,
  maxPages: 1,
  goTo: () => {},
  splitter: {},
  date: ''
};
export default List;
