import React, { Component } from 'react';
import { socketConnect } from 'socket.io-react';
import PropTypes from 'prop-types';
import { Grid } from 'material-ui';
import { browserHistory } from 'react-router';
import { AddAlert, ChevronLeft } from '@material-ui/icons/index';
import { connect } from 'react-redux';
import Dropzone from 'react-dropzone';
import moment from 'moment';

import RegularCard from 'components/Cards/RegularCard';
import Button from 'components/CustomButtons/Button';
import CustomInput from 'components/CustomInput/CustomInput';
import ItemGrid from 'components/Grid/ItemGrid';
import SelectInput from 'components/SelectInput';
import Snackbar from 'components/Snackbar/Snackbar';
import noImage from 'assets/no_img.svg';
import sendImage from 'handler/sendImage';
import sendImageAws from 'handler/sendImageAws';
import Calendar from 'components/Calendar';
import Select from 'components/Select';
import DishComponent from './DishComponent';
import { getArrays, unsetErrors } from 'utils';

class Form extends Component {
  constructor(props) {
    super(props);
    const { params } = this.props;
    const id = 'id' in params && params.id ? params.id : 'add';
    const name ="chef-dishes";
    const modelName = this.formatModelName(name);
    this.state = {
      id,
      modelName,
      errors: {},
      attributes: {},
      labels: {},
      image: {},
      needImageUpload: '',
      message: '',
      note: false,
      color: false,
      dishComponents:{},
      ingredientPrices:{},
      isIngrPriceLoad:false,
      closeNote: () => this.setState({ note: false, message: '' })
    };
    this.getFormData(modelName, id);
    this.getComponentData(id);
  }

  formatModelName = (name, delimiter = '') => {
    const capitalize = str => `${str.substr(0, 1).toUpperCase()}${str.substr(1)}`;
    return name.split('-').reduce((acc, cur) => `${acc}${capitalize(cur)}${delimiter}`, '');
  };

  componentWillReceiveProps(next) {
    const name ="chef-dishes";
    const nextModelName = this.formatModelName(name);
    const nextId = 'id' in next.params && next.params.id ? next.params.id : 'add';
    const { modelName, id } = this.state;
    if ((nextModelName !== modelName || id !== nextId) && this.FormRef) {
      this.setState({ modelName: nextModelName, id: nextId });
      this.getFormData(nextModelName, nextId);
      this.getComponentData(nextId);
    }
  }

  showNotification(message, color = 'info') {
    if (this.FormRef) {
      this.setState({ note : true, message, color });
      setTimeout(() => this.setState({ note : false, message: '' }), 5000);
    }
  }

  listener = ({ type, data }) => {
    if (this.FormRef) {
      switch (type) {
        case 'getOk':
          this.setState(data);
          break;
        case 'setOk':
          const { needImageUpload, modelName, image } = this.state;
          const { modelName: routeModelName } = this.props.params;
          const saved = (id, message) => {
            browserHistory.push(`/kitchen/cooking/dish-edit/${id}`);
            this.showNotification(message);
          };
          let compontents = this.DishComponentRef.state.compontents;
          console.log(compontents);
          if (compontents) {
            this.saveComponentIngredientsData(data.id, compontents);
          }
          if(needImageUpload){
            this.saveImage('ChefDishes', 'image', data.id, image, () => {
              saved(data.id, data.message);
            });
          }else {
            saved(data.id, data.message);
          }
          break;
        case 'getChefDishComIngsOk':
          this.setState({dishComponents:data,isIngrPriceLoad:true})
        break;
        case 'getIngredientPricesOk':
          this.setState({
            ingredientPrices: data.supplierPrices, 
          });
        break;
        case 'setErr':
          const { errors } = data || {};
          if (this.state.modelName === 'Ingredient' && (errors || {}).name) {
            this.showNotification(errors.name[0], 'warning');
          }
          this.setState({ errors });
          break;
        default:
          console.log(type);
          console.log(data);
        break;
      }
    }
  };

  componentWillMount() {
    this.props.socket.on('other_tables', this.listener);
    this.props.socket.on('menu_builder', this.listener);
  }

  componentWillUnmount() {
    this.props.socket.removeListener('other_tables', this.listener);
    this.props.socket.removeListener('menu_builder', this.listener);
  }

  getFormData = (modelName, id) => this.props.socket.emit('other_tables', { type: 'get', data: { modelName, id } });
  getComponentData = (dish_id) => this.props.socket.emit('menu_builder', { type: 'getChefDishComIngs', data: { dish_id } });

  submit = () => {
    const { modelName, labels, id, image } = this.state;
    const data = { ...this.state.attributes };
    // if (data.image && data.image.preview) {
    //     this.setState({ needImageUpload: true, 'image': data.image });
    // }
    const attribute = Object.keys(labels).filter(label => labels[label].type === 'image')[0];
    console.log(attribute);
    // console.log(data);
    console.log(image);
    if (image && image.preview) {
        this.setState({ needImageUpload: attribute });
    }
    if (attribute && Object.keys(image).length) {
      delete data[attribute];
    }
    console.log(data);
    this.sendData(modelName, data, id); 
  };
  saveComponentIngredientsData = (id, attributes,data,callback) => {
      this.props.socket.emit('menu_builder', {
        type: 'saveChefDishIngredients',
        data: {
          attributes,
          dish_id:id
        }
      });
      // callback(data.id,data.message);
  };
  sendData = (modelName, attributes, id) => {
    
    this.props.socket.emit('other_tables', {
      type: 'set',
      data: {
        modelName,
        attributes,
        id
      }
    });
  };
  sendImageData = (data, id) => {
    // console.log(data);
    // console.log(id);
    if(data.imagedata!=undefined){
      this.props.socket.emit('menu_builder', {
        type: 'setChefAWSFile',
        data: { data, id }
      }); 
    }
  };
  saveImage = (modelName, attribute, id, image, callback) => {
    const dataForm = new FormData();
    dataForm.append('access_token', this.props.user.token);
    dataForm.append('type', 'modelImage');
    dataForm.append('modelName', modelName);
    dataForm.append('id', id);
    dataForm.append('attribute', attribute);
    dataForm.append(`${modelName}[${attribute}]`, image);
    // sendImage.call(this, dataForm, callback);
    var  dishinfo =  new Object();
    dishinfo['id']=id;
    dishinfo['attribute']=attribute;
    dishinfo['modelName']=modelName;
    dishinfo['path']='photos';
    sendImageAws.call(this, image,dishinfo, (info,id)=>{this.sendImageData(info,id)});
  };

  changeForm = (name, value) => this.setState({ attributes: { ...this.state.attributes, [name]: value } });

  renderSimpleInput = (name, value, label, error, change, itemProps = {}, type = 'text') => {
    const { min, max, multiline, rows, mul, readonly } = itemProps;
    let onChange = e => change(e.target.name, e.target.value);
    if (type === 'text' && max) {
      const maxVal = max || Infinity;
      onChange = e => {
        const { name, value } = e.target;
        change(name, value.length > maxVal ? value.substr(0, maxVal) : value);
      };
    }
    if (type === 'number' && ('max' in itemProps || 'min' in itemProps || mul)) {
      const maxVal = 'max' in itemProps ? +max : Infinity;
      const minVal = 'min' in itemProps ? min : -Infinity;
      const mulVal = +mul || 1;
      onChange = e => {
        const value = +e.target.value / mulVal;
        change(e.target.name, value < minVal ? minVal : (value > maxVal ? maxVal : value));
      };
    }
    return (<CustomInput
      labelText={label}
      formControlProps={{
        fullWidth: true
      }}
      error={error}
      inputProps={{
        value: (type === 'number' && mul ? value * mul : value) || '',
        disabled: readonly,
        multiline,
        rows,
        type,
        name,
        onChange
      }}
    />);
  };
  
  onChangeComponent =(e) =>{
    const { dishComponents } = this.state;
    let indexValue = e.target.name;
    this.setState({ dishComponents: { ...dishComponents, [indexValue]:{ ...dishComponents[indexValue], ['name']: e.target.value }}});
  }
  onChangeCompIng =(cindex,ingId,value)=>{
    const { dishComponents } = this.state;
    this.setState({ dishComponents: { ...dishComponents, [cindex]:{ ...dishComponents[cindex], ['ingredients']:{...dishComponents[cindex]['ingredients'],[ingId]:value}}}});
  }
  renderComponents = (dataArray) => {
    const { ingredientPrices,dishComponents,isIngrPriceLoad } = this.state;
    const props = { dataArray,ingredientPrices,compontents:dishComponents };
    if(ingredientPrices && isIngrPriceLoad){
     return <DishComponent {...props}  ref={el => (this.DishComponentRef = el)} />;
    }
  };

  renderSimpleDateInput = (name, value, label, error, change, itemProps = {}) => {
    const { format } = itemProps;
    return (<Calendar
      controlled
      onChange={value => change(name, value)}
      date={+value || moment().unix()}
      name={name}
      title={label || ''}
      formControlProps={{
        fullWidth: true
      }}
      format={format}
    />);
  };
  isJsonString =(str) =>{
      try {
          JSON.parse(str);
      } catch (e) {
          return false;
      }
      return true;
  }
  renderSelect = (name, value, label, error, change, props = {}) => {
    const multiple = !!props.multiple;
      if(multiple&&this.isJsonString(value)){
        value = JSON.parse(value);
      }
    return (<Select
      placeholder={label || ''}
      multiple={multiple}
      list={props.items}
      value={(multiple ? value : '' + value) || (multiple ? [] : '')}
      name={name}
      disabled={props.readonly}
      onChange={change}
    />);
  };
  //
  // renderSelect = (name, value, label, error, change, props = {}) => {
  //   const onChange = e => change(name, e.target.value);
  //   const multiple = !!props.multiple;
  //   return (<SelectInput
  //     labelText={label}
  //     multiple={multiple}
  //     items={props.items}
  //     value={(multiple ? value : '' + value) || (multiple ? [] : '')}
  //     name={name}
  //     empty={props.empty || ''}
  //     formControlProps={{
  //       fullWidth: true
  //     }}
  //     inputProps={{
  //       disabled: props.readonly,
  //       onChange
  //     }}
  //   />);
  // };

  onDrop(files) {
    this.setState({ image: files[0] });
  }

  renderImagePicker = (name, value, label, error, change, props = {}) => {
    const path = props.path || '';
    const image = this.state.image.preview || (path + value) || noImage;
    return (
      <Dropzone
        onDrop={(files) => this.onDrop(files)}
        className='drop-zone'
        style={{
          backgroundImage: `url(${image})`,
          height: '200px',
          width: 'auto',
          maxWidth: '400px',
          position: 'relative',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: '100% auto'
        }}
      >
        {image=='null' && <div className='drop-zone-label'><p>Click to upload or drop image here</p></div>}
      </Dropzone>);
  };

  renderInput(name, labels, attributes) {
    const info = labels[name];
    const value = attributes[name] ;
    const errors = this.state.errors || {};
    let input = '';
    if (info.length) {
      input = this.renderSimpleInput(name, value, info, name in errors, this.changeForm);
    } else {
      const { type, label, props, middleware } = info;
      let mul = 1;
      let max = Infinity;
      if (middleware === 'formatVouchers') {
        const { formatter } = props;
        const typesProps = formatter[attributes.type] || {};
        mul = typesProps.mul || 1;
        max = typesProps.max || Infinity;
      }
      switch (type) {
        case 'text':
          let val=(value!=undefined && value)?value.replace(/&amp;/g, "&"):value;
          input = this.renderSimpleInput(name, val, label, name in errors, this.changeForm, props);
          break;
        case 'number':
          const onChange = (_name, _value) => this.changeForm(_name, (_value > max ? max : _value) / mul);
          input = this.renderSimpleInput(name, value * mul, label, name in errors, onChange, props, 'number');
          break;
        case 'select':
          input = this.renderSelect(name, value, label, name in errors, this.changeForm, props);
          break;
        case 'switch':
          input = this.renderSelect(name, '' + value, label, name in errors, this.changeForm, { items: { 0: 'No', 1: 'Yes' } });
          break;
        case 'image':
          input = this.renderImagePicker(name, value, label, name in errors, this.changeForm, props);
          break;
        case 'date':
          input = this.renderSimpleDateInput(name, value, label, name in errors, this.changeForm, props);
          break;
      }
    }
    return input;
  }

  renderFields(attributes, labels) {
    return Object.keys(labels).map((attribute, key) => {
      if (attribute in attributes) {
        const params = labels[attribute].params || {};
        return attribute!='prepare_method' && (<ItemGrid key={key} xs={params.xs || 12} sm={params.sm || 12} md={params.md || 12}>
          {this.renderInput(attribute, labels, attributes)}
        </ItemGrid>);
      }
    });
  }
  componentDidMount() {
    const { socket, dataArray } = this.props;
    const types = ['ingredientList', 'ingredientListFull','ingredientPrices'].filter(el => !(el in dataArray));
    types.length && getArrays(socket, types);
    this.getIngredientPrices();
  }
  getIngredientPrices = () => this.props.socket.emit('menu_builder', { type: 'getIngredientPrices' });
  render() {
    const { attributes, labels, message, note, closeNote, color,modelName } = this.state;
    const { dataArray } = this.props;
    const backLink =  `kitchen/cooking/chefs-receipes`;
    return (
      <div ref={el => (this.FormRef = el)}>
        <Grid container>
          <ItemGrid xs={12} sm={12} md={12}>
            <Button color='darkBlue' size='medium' onClick={() => browserHistory.push(backLink)}><ChevronLeft /></Button>
            <RegularCard
              cardTitle={this.formatModelName(modelName, ' ')}
              cardSubtitle='Edit info'
              headerColor='darkBlue'
              content={
                <div>
                  <Grid container>
                    {this.renderFields(attributes, labels)}
                    <ItemGrid  md={12}>
                      {this.renderComponents(dataArray)}
                     
                    </ItemGrid>
                    {/* <ItemGrid  md={12}>{labels && labels['prepare_method']!=undefined && this.renderInput('prepare_method', labels, attributes)}</ItemGrid> */}
                  </Grid>
                </div>
              }
              footer={<Button color='darkBlue' onClick={this.submit}>Save</Button>}
            />
          </ItemGrid>
        </Grid>
        <Snackbar
          place='tc'
          color={color || 'info'}
          icon={AddAlert}
          message={message}
          open={note}
          closeNotification={closeNote}
          close
        />
      </div>
    );
  }
}

Form.propTypes = {
  socket: PropTypes.object.isRequired,
  params: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  dataArray: PropTypes.object.isRequired,
  customBackLink: PropTypes.string
};

const mapStateToProps = state => ({
  user: state.user,
  dataArray: state.dataArray
});

export default socketConnect(connect(mapStateToProps, null)(Form));