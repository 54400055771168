import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment/moment';
import { Grid } from 'material-ui';
import { connect } from 'react-redux';
import Button from 'components/CustomButtons/Button';
import { Text } from 'components/Typography';
import Calendar from 'components/DateTime';

require('moment-weekday-calc');

class UnpauseModal extends Component {
  constructor(props) {
    super(props);
    const { start ,errors, mealPlan, planParams, daysDiff } = props;
    const minDate = moment().utc().add(2, 'd').startOf('day').unix();
    const allDates = Object.keys(mealPlan);
    const dates = allDates.filter(el => +el > +minDate);
    const today = moment();
    !today.isUTC() && today.utc();
    today.startOf('day');
    this.state = {
      errors,
      mealPlan,
      planParams,
      skipped: {},
      daysDiff,
      dates,
      allDates,
      resumeDate: false,
      startDate:(moment().unix()>start)?today: moment.unix(start).subtract(1, "day"), // 09 Mar Start 14 Mar
    };
  }

  diffAndSet = val => {
    const {start,enddate} = this.props;
    var diff = parseInt(val.unix()) - parseInt(start);
    diff = diff/24/3600;
    diff = parseInt(diff)+1;
    if(enddate){
      diff=(enddate>=val.unix())?diff:0;
    }

    this.setState({ 
      startDate: val, 
      daysDiff: diff,
    });
  }

  recalculateDates = (resumeDate, dates, includesDay) => {
    const { excludedDates, defaultDishes, save } = this.props;
    const { mealPlan, planParams } = this.state;
    const { mealType: types } = planParams || {};
    const firstDate = dates[0];
    const lastDate = dates.reverse()[0];
    const unchangedDates = resumeDate > lastDate ? [] : dates.filter(date => date >= resumeDate);
    const mapDatesToDays = unchangedDates.reduce((acc, date) => {
      const dayOfMonth = moment.unix(date).date();
      return { ...acc, [dayOfMonth]: date };
    }, {});
    const date = moment.unix(resumeDate > lastDate ? resumeDate : lastDate);
    const newDatesPlan = {};
    const amount = dates.length - unchangedDates.length;
    const nextDate = (date, amount) => {
      return date.clone().addWeekdaysFromSet(amount, includesDay, excludedDates);
    };
    for (let i = +!(resumeDate > lastDate); i <= amount; i++) {
      const newDate = nextDate(date, i);
      const dayNum = newDate.date();
      newDatesPlan[newDate.unix()] = dayNum in mapDatesToDays
        ? mealPlan[mapDatesToDays[dayNum]]
        : types.reduce((acc, type) => ({ ...acc, [+type]: +defaultDishes[type][dayNum - 1] }), {});
    }
    const prevPlan = Object.keys(mealPlan)
      .filter(date => ((date < firstDate) || unchangedDates.includes(date)))
      .reduce((acc, date) => ({ ...acc, [date]: mealPlan[date] }), {});
    save({ mealPlan: { ...prevPlan, ...newDatesPlan }, planParams }, 'pause');
  };
  validateDate=(current)=>{
    const { start,enddate } = this.props;
    var startdate=(moment().unix()>start)?moment().subtract(1, "day"): moment.unix(start).subtract(2, "day");
    var isBefore=true;
    if(enddate&&enddate!=null){
      // isBefore =!current.isBefore(moment.unix(enddate));
    }
    return current.isAfter(startdate)&&isBefore;
  }
  render() {
    const { planParams, resumeDate, startDate, dates, daysDiff } = this.state;
    const { userData, dataArray, excludedDates, saveUnpause, start, orderId,enddate } = this.props;
    const { dietList, planList } = dataArray;
    const { firstName, lastName } = userData;
    const userName = `${firstName} ${lastName}`;
    const { diet: dietId, mealPlan: planId } = planParams || {};
    const excluded = (planList && planList[planId] && planList[planId]['excludeWeekDay']) || [];
    const includesDay = Array(7).fill(0).map((el, key) => key).filter(el => !excluded.includes(el));
    const dietName = dietList && dietId ? dietList[dietId] : '';
    const today = moment();
    !today.isUTC() && today.utc();
    today.startOf('day');
    const newDate = today.addWeekdaysFromSet(3, includesDay, excludedDates);
    const renderDay = (props, curd) => {
     /* if (!curd.isBetween(newDate, newDate.clone().add(3, 'months'), null, '[]') || excluded.includes(curd.day()) || excludedDates.includes(curd.format('DD/MM/YYYY'))) {
        props.className += ' disabled';
        delete props.onChange;
        delete props.onClick;
      } else */
      // if (curd.isSame(resumeDate || newDate)) {
      //   props.className += ' selected';
      // }
      if(enddate && curd.unix()>enddate){
      
        props.className += ' disabled';
        delete props.onChange;
        delete props.onClick;
      }
      return <td {...props}>{curd.date()}</td>;
    };
    return (
      <div ref={el => (this.PausePlanModalRef = el)} className='edit-modal-inner'>
        <div>
          <Text bold customColor='#000' inline style={{ fontSize: 'large' }}>{userName}</Text>
          <Text bold color='success' inline style={{ fontSize: 'large' }}>&nbsp;{dietName}</Text>
        </div>
        <p>Select a Start Date to unpause the Meal Plan from this date.</p>
        {!enddate&&<p> You have <b>{daysDiff} missing days</b>. <br/>This days will be added to the end of the mealplan</p>}
        <div className='day-editing text-center'>Select a Start Date</div>
        <div style={{ borderTop: '1px solid #bebebe', borderBottom: '1px solid #bebebe', padding: '10px' }}>
          <Calendar
            controlled
            dateValidator={this.validateDate}
            value={startDate || newDate}
            onChange={val => this.diffAndSet(val)}
            onViewModeChange={() => false}
            renderDay={renderDay}
            onNavigateBack={(a, t, c) => moment().utc().startOf(t).isBefore(c.utc())}
            onNavigateForward={(a, t, c) => c.clone().add(a, t).startOf(t).isBefore(moment().add(3, 'month'))}
          />
        </div>

        <div className='modal-button-handler'>
          <Button
            color='darkBlue'
            onClick={() => {
              saveUnpause(orderId, daysDiff, startDate);
              //this.recalculateDates((resumeDate || newDate).unix(), [...dates], includesDay);
            }}
          >
            Save
          </Button>
        </div>
      </div>
    );
  }
}

UnpauseModal.propTypes = {
  errors: PropTypes.object.isRequired,
  userData: PropTypes.object.isRequired,
  mealPlan: PropTypes.object,
  planParams: PropTypes.object,
  excludedDates: PropTypes.array,
  currentDate: PropTypes.number,
  dataArray: PropTypes.object.isRequired,
  customPlans: PropTypes.array,
  save: PropTypes.func.isRequired,
  defaultDishes: PropTypes.object
};

const props = ({ customPlans, defaultDishes }) => ({ customPlans, defaultDishes });

export default connect(props)(UnpauseModal);
