import { persistCombineReducers } from 'redux-persist';
import createEncryptor from 'redux-persist-transform-encrypt';
import storage from 'redux-persist/es/storage';
import { reducer as formReducer } from 'redux-form';
import userReducer from './user';
import dataArrayReducer from './dataArray';
import modalReducer from './modal';
import modalStackReducer from './modalStack';
import notifyReducer from './notify';
import burgerMenuReducer from './burgerMenu';
import pinsReducer from './pins';
import planBuilder from './planBuilder';
import settings from './settings';
import customPlansReducer from './customPlans';
import defaultDishesReducer from './defaultDishes';
import backLocationReducer from './backLoc';
import spinnerReducer from './spinner';

const encryptor = createEncryptor({
  secretKey: 'GU;"7?6$&QS,F,+"mxac+f{`*pycFc*kB(W,\\e66uW"\\XJVjh*KT9Nx8tFG&6_DAp{]G^`?#_`(wqb`*_{~UK}.:+Q.[Ds#(=a,_wQ-*tkSm}\')AV{a!<X#bew%DU.%/'
});

const config = {
  key: 'root',
  transforms: [encryptor],
  blacklist: ['dataArray', 'clientType', 'mealPlan', 'modal', 'notify'],
  storage
};

export const makeRootReducer = (asyncReducers) => {
  return persistCombineReducers(config, {
    settings,
    planParams: planBuilder,
    user: userReducer,
    dataArray: dataArrayReducer,
    modal: modalReducer,
    modalStack: modalStackReducer,
    notify: notifyReducer,
    form: formReducer,
    burger: burgerMenuReducer,
    pins: pinsReducer,
    customPlans: customPlansReducer,
    defaultDishes: defaultDishesReducer,
    backLocation: backLocationReducer,
    spinner: spinnerReducer,
    ...asyncReducers
  });
};

export const injectReducer = (store, { key, reducer }) => {
  if (Object.hasOwnProperty.call(store.asyncReducers, key)) return;

  store.asyncReducers[key] = reducer;
  store.replaceReducer(makeRootReducer(store.asyncReducers));
};

export default makeRootReducer;
