import React, { Component } from 'react';
import {
  withStyles,
  Grid,
  Table as MUITable,
  TableBody,
  IconButton,
  TableCell,
  TableRow,
  Tooltip
} from 'material-ui';

import PropTypes from 'prop-types';

import tasksStyle from 'assets/jss/material-dashboard-react/tasksStyle.jsx';
import TableHead from 'material-ui/Table/TableHead';
import noImage from 'assets/no_img.svg';
import Button from 'components/CustomButtons/Button';
import { Edit, Delete} from '@material-ui/icons';
import { browserHistory } from 'react-router';

const mapWidth = {
  title: '400px',
  amount: '100px',
  components: 'calc(100% - 700px)',
  actions: '100px'
};

class Table extends Component {
  renderHeaderRow = (cols, classes) => {
    return <TableRow id='header-row'>
      {Object.keys(cols).map((col, key) => {
        const width = mapWidth[col] || 'auto';
        return (
          <TableCell
            key={key}
            className={classes.tableCell + ' ' + classes.tableHeadCell}
            style={{ width }}
          >
            {cols[col]}
          </TableCell>
        );
      })}
    </TableRow>;
  };

  renderActions = (id, row) => {
    const { actions } = this.props;
    // console.log(row);
    const { cooked, print } = actions;
    const { maxAmount,date,dishId } = row;
    const style = { marginLeft: '5px' };
    return <Grid container>
      <Tooltip
        key={'edit'+id}
        id='tooltip-top'
        title={'Edit'}
        placement='top'
      >
        <IconButton
          onClick={() => browserHistory.push(`/kitchen/cooking/dish-edit/${dishId}`)}
          aria-label={'Edit'}
         
        >
          <Edit  />
        </IconButton>
      </Tooltip>
      <Tooltip
        key={'delete'+id}
        id='tooltip-top'
        title={'Delete'}
        placement='top'
      >
        <IconButton
          onClick={() => callback(onClickAttr)}
          aria-label={'Delete'}
         
        >
          <Delete  />
        </IconButton>
      </Tooltip>
    </Grid>;
  };

  renderCell = (data, elName) => {
    const { cols } = this.props;
    if (cols[elName] instanceof Object) {
      let result = '';
      const { type, props } = cols[elName];
      switch (type) {
        case 'text':
          result = data.length > 30 ? `${data.substr(0, 30)}...` : data;
          break;
        case 'number':
          const mul = props.mul || 1;
          result = data * mul;
          break;
        case 'image':
          const image = data ? props['pathMin'] + data : noImage;
          result = <div className='image-preview-320' style={{ backgroundImage: `url(${image})` }} />;
          break;
        case 'select':
          const items = props.items || {};
          if (props.multiple) {
            result = data && data.reduce && data.length ? data.reduce((acc, cur, idx) => `${acc}${items[cur]}${idx < data.length - 1 ? ', ' : ''}`, '') : '';
          } else {
            result = items[data];
          }
          break;
        case 'switch':
          result = +data ? 'Yes' : 'No';
          break;
        default:
          result = data;
          break;
      }
      return result;
    } else {
      return data;
    }
  };

  render() {
    const { classes, rows, cols,brandCatId, brandCategoryList } = this.props;
    // console.log(rows);
    return (
      <MUITable className={classes.table}>
        {(cols && Object.keys(cols).length) ? (
          <TableHead className='primaryTableHeader roundedHeader mini'>
            {this.renderHeaderRow(cols, classes)}
          </TableHead>
        ) : null}
        <TableBody>
          {Object.keys(rows).map(id => (
            <TableRow key={id} className={classes.tableRow + ' whiteRow'}>
              {Object.keys(cols).map((elName, idx) => {
                let res = '';
                let className = '';
                if (elName === 'actions') {
                  res = this.renderActions(id, rows[id]);
                  className = classes.tableActions;
                } else {
                  res = rows[id][elName];
                  className = classes.tableCell;
                }
                return (
                  <TableCell key={idx} className={className}>
                    {res}
                  </TableCell>
                );
              })}
            </TableRow>
          ))}
        </TableBody>
      </MUITable>
    );
  }
}

Table.propTypes = {
  classes: PropTypes.object.isRequired,
  rows: PropTypes.object.isRequired,
  cols: PropTypes.object,
  actions: PropTypes.object
};

Table.defaultProps = {
  cols: {
    content: 'Content',
    actions: 'Actions'
  },
  actions: {
    cooked: () => {},
    print: () => {}
  }
};

export default withStyles(tasksStyle)(Table);
